/* eslint-disable */
import { SettingLayout } from '../components/SettingLayout'
import SortableList from '../components/SortableList'
import './SettingLaxative.scss'
import * as facilityAction from '@/actions/FacilityAction'
import * as laxativeAction from '@/actions/LaxativeAction'
import AppDialog from '@/components/App/AppDialog'
import AppLoading from '@/components/App/AppLoading'
import { localizedUiWords } from '@/features/locale'
import { LocaleContext } from '@/providers/LocaleProvider'
import authStore from '@/stores/AuthStore'
import facilityStore from '@/stores/FacilityStore'
import laxativeStore from '@/stores/LaxativeStore'
import { lengthConsideredCharacterWidth } from '@/utils/string'
import { Box, Grid, Typography } from '@material-ui/core'
import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import Form from 'react-jsonschema-form'

export const SettingLaxative = () => {
  const [locale] = useContext(LocaleContext)
  const uiWords = localizedUiWords(locale.lang).pages.settings
  const appDialogUiWords = localizedUiWords(locale.lang).components.appDialog

  const [facilityDetail, setFacilityDetail] = useState(null)
  const [laxatives, setLaxatives] = useState(null)
  const [loading, setLoading] = useState(false)
  const [dialog, setDialog] = useState(false)
  const [errorDialog, setErrorDialog] = useState(false)
  const [count, setCount] = useState(null)
  const [deleteLaxativeId, setDeleteLaxativeId] = useState(null)
  const [deleteLaxativeName, setDeleteLaxativeName] = useState('')
  const [errors, setErrors] = useState({})

  useEffect(() => {
    laxativeStore.on('laxatives_all', (v) => {
      setLaxatives(v)
      setCount(v.length)
      setLoading(false)
    })

    laxativeStore.on('laxatives_add', (v) => {
      laxativeAction.laxatives()
    })

    laxativeStore.on('laxatives_edit', (v) => {
      laxativeAction.laxatives()
    })

    laxativeStore.on('laxatives_delete', (v) => {
      laxativeAction.laxatives()
      setLoading(false)
    })

    laxativeStore.on('laxatives_delete_error', (v) => {
      setErrorDialog(true)
      setLoading(false)
    })

    facilityStore.on('facility_detail', (v) => {
      setFacilityDetail(v)
      setLoading(false)
    })

    facilityStore.on('facility_edit', (v) => {
      facilityAction.facility(facilityStore.getFacilityDetail().id)
    })

    // すでにデータあれば通信しない
    const l = laxativeStore.getLaxatives()
    if (l !== null) {
      setLaxatives(l)
      setCount(l.length)
    } else {
      laxativeAction.laxatives()
      setLoading(true)
    }

    // すでにデータあれば通信しない
    if (facilityStore.getFacilityDetail() !== null) {
      setFacilityDetail(facilityStore.getFacilityDetail())
    } else if (authStore.getFacilityId()) {
      // 念のためFacilityIdを取得しているか確認
      facilityAction.facility(authStore.getFacilityId())
      setLoading(true)
    }

    return () => {
      laxativeStore.removeAllListeners('laxatives_all')
      laxativeStore.removeAllListeners('laxatives_add')
      laxativeStore.removeAllListeners('laxatives_edit')
      laxativeStore.removeAllListeners('laxatives_delete')
      laxativeStore.removeAllListeners('laxatives_delete_error')
      facilityStore.removeAllListeners('facility_detail')
      facilityStore.removeAllListeners('facility_edit')
    }
  }, [])

  const handleAlertPrioritizeHourSetting = (e) => {
    const alertPrioritizeHour = e.formData.alert_prioritize_hour
    facilityAction.editFacility(
      { medicine_alert_prioritize_hour: alertPrioritizeHour },
      authStore.getFacilityId()
    )
    setLoading(true)
  }

  // ダイアログ内のハンドリング
  const handleDialogClick = (e) => {
    switch (e) {
      case appDialogUiWords.yesButtonLabel:
        laxativeAction.deleteLaxative(deleteLaxativeId)
        setDialog(false)
        setLoading(true)
        break
      case appDialogUiWords.noButtonLabel:
        setDialog(false)
        break
      default:
        break
    }
  }

  // ダイアログクローズ時のハンドリング
  const handleDialogClosing = () => {
    setDialog(false)
  }

  const handleErrorDialogClick = (e) => {
    switch (e) {
      case appDialogUiWords.closeButtonLabel:
        setErrorDialog(false)
        break
      default:
        break
    }
  }

  const handleErrorDialogClosing = () => {
    setErrorDialog(false)
  }

  const validate = (e) => {
    // 登録済みの下剤が 20 以上の場合
    if (parseInt(count) >= 20) {
      return {
        laxative: uiWords.overLimitLaxativeErrorMessage
      }
    }

    if (lengthConsideredCharacterWidth(e.formData.laxative.toString()) > 20) {
      return {
        laxative: uiWords.overLimitLaxativeNameErrorMessage
      }
    }

    // 重複チェック
    if (isAlreadyExists(e.formData.laxative)) {
      return {
        laxative: uiWords.alreadyRegisteredLaxativeErrorMessage
      }
    }

    return {}
  }

  // 重複チェック
  const isAlreadyExists = (name) => {
    return _.filter(laxatives, { name }).length > 0
  }

  // ユーザー追加下剤の登録
  const handleSubmit = (e) => {
    const errors = validate(e)
    if (Object.keys(errors).length > 0) {
      setErrors(errors)
      return
    }

    laxativeAction.addLaxative({ name: e.formData.laxative })
    setLoading(true)
    setErrors({})
  }

  // 登録されている下剤を1件削除
  const handleDeleteLaxative = (id) => {
    const deleteLaxativeId = parseInt(id)
    const deleteLaxative = _.find(laxatives, (item) => {
      return item.id === deleteLaxativeId
    })

    setDeleteLaxativeId(deleteLaxativeId)
    setDeleteLaxativeName(deleteLaxative.name)
    setDialog(true)
    setErrors({})
  }

  const handleSortChange = (changeDetail) => {
    // rankは1からなので，indexに1足す
    laxativeAction.editLaxative(
      { rank: changeDetail.newIndex + 1 },
      changeDetail.changeId
    )
    setLoading(true)
  }

  // 下剤件数
  const counter = count !== null ? count.toString() : '0'

  // フォームの設定
  const alertPrioritizeHourSchema = {
    type: 'object',
    properties: {
      alert_prioritize_hour: {
        type: 'string',
        enum: [
          '1',
          '2',
          '3',
          '4',
          '5',
          '6',
          '7',
          '8',
          '9',
          '10',
          '11',
          '12',
          '13',
          '14',
          '15',
          '16',
          '17',
          '18',
          '19',
          '20',
          '21',
          '22',
          '23',
          '24'
        ],
        enumNames: [
          '1',
          '2',
          '3',
          '4',
          '5',
          '6',
          '7',
          '8',
          '9',
          '10',
          '11',
          '12',
          '13',
          '14',
          '15',
          '16',
          '17',
          '18',
          '19',
          '20',
          '21',
          '22',
          '23',
          '24'
        ],
        default:
          facilityDetail !== null
            ? facilityDetail.medicine_alert_prioritize_hour
            : '3',
        title: '　'
      }
    }
  }

  const alertPrioritizeHourUiSchema = {
    alert_prioritize_hour: {
      classNames: 'raise-priority-duration-input'
    }
  }

  const laxativeSchema = {
    type: 'object',
    required: ['laxative'],
    properties: {
      laxative: {
        type: 'string',
        title: uiWords.laxativeNameLabel
      }
    }
  }
  const laxativeUiSchema = {
    laxative: {
      classNames: 'laxative-name-input'
    }
  }

  for (const param in laxativeSchema.properties) {
    if (errors[param] && !loading) {
      if (!laxativeUiSchema[param]) {
        laxativeUiSchema[param] = {}
      }
      laxativeUiSchema[param]['ui:help'] = (
        <div className="error">{errors[param]}</div>
      )
    }
  }

  const sortableListProps =
    laxatives !== null
      ? laxatives.map((v) => {
          return {
            id: v.id,
            value: v.name
          }
        })
      : []

  return (
    <SettingLayout activeTab={'laxative'}>
      <Box id="setting-laxative-tab" className="setting-tab-content">
        <Typography variant="h1" className="setting-heading">
          {uiWords.laxativeTabTitle}
        </Typography>

        <Box className="raise-priority-duration-box">
          <Typography variant="h2" className="setting-sub-heading">
            {uiWords.durationRaisingPriorityTitle}
          </Typography>

          <Typography variant="body1" className="setting-description">
            {uiWords.durationRaisingPriorityDescription}
          </Typography>

          <Grid container className="priority-threshold-config">
            <Grid item className="setting-field-label">
              {uiWords.durationRaisingPriorityLabel}
            </Grid>

            <Grid item>
              <Form
                className="priority-threshold-form"
                schema={alertPrioritizeHourSchema}
                uiSchema={alertPrioritizeHourUiSchema}
                onSubmit={handleAlertPrioritizeHourSetting}
                showErrorList={false}
                noValidate={true}
              >
                <Typography variant="body1" className="hour-unit">
                  {uiWords.hoursUnit}
                </Typography>

                <button type="submit">{uiWords.updateButtonLabel}</button>
              </Form>
            </Grid>
          </Grid>
        </Box>

        <Box className="add-laxative-box">
          <Typography variant="h2" className="setting-sub-heading">
            {uiWords.addLaxativeTitle}
          </Typography>

          <Typography variant="body1" className="setting-description">
            {uiWords.registerLaxativeDescription}
          </Typography>

          <Form
            className="add-laxative-form"
            schema={laxativeSchema}
            uiSchema={laxativeUiSchema}
            onSubmit={handleSubmit}
            showErrorList={false}
            noValidate={true}
          >
            <button type="submit">{uiWords.addButtonLabel}</button>
          </Form>

          <Box className="laxative-list-box">
            <Box className="laxative-counter">
              <Box className="registered-laxative-counter-label">
                {uiWords.registeredLaxativesLabel}
              </Box>
              <Box
                className={
                  parseInt(counter) >= 20 ? 'counter exceed-limit' : 'counter'
                }
              >
                {`(${counter}/20)`}
              </Box>
            </Box>

            <Box>
              <SortableList
                id="laxative-list"
                data={sortableListProps}
                onClickDeleteElement={handleDeleteLaxative}
                onSortChange={handleSortChange}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      <AppLoading isActive={loading} />

      <AppDialog
        isActive={dialog}
        title={appDialogUiWords.deleteMessage}
        choiceButtonLists={[
          appDialogUiWords.yesButtonLabel,
          appDialogUiWords.noButtonLabel
        ]}
        onClick={handleDialogClick}
        onDialogClosing={handleDialogClosing}
      />

      <AppDialog
        isActive={errorDialog}
        title={`${deleteLaxativeName}は記録済みのため削除できません。`}
        choiceButtonLists={[appDialogUiWords.closeButtonLabel]}
        onClick={handleErrorDialogClick}
        onDialogClosing={handleErrorDialogClosing}
      />
    </SettingLayout>
  )
}
