import TAlertLog, { initialAlertLog } from "./TAlertLog";
import TGroup from "./TGroup";
import TLaxationLog, { initialLaxationLog } from "./TLaxationLog";
import TMStatus, { initialTMStatus } from "./TMStatus";
type TCarePerson = {
  id: number;
  facility_id: number;
  sensor_id: number;
  group_id: number;
  groups: Array<TGroup>;
  alert_log_id: number;
  alert_log: TAlertLog;
  laxation_log_id: number;
  laxation_log: TLaxationLog;
  name: string;
  kana: string;
  place: string;
  birth_date: string;
  gender: string;
  description: string;
  created: string;
  modified: string;
  m_status: TMStatus;
  status_code: string;
};

export const initialCarePerson: TCarePerson = {
  id: 0,
  facility_id: 0,
  sensor_id: 0,
  group_id: 0,
  groups: [],
  alert_log_id: 0,
  alert_log: initialAlertLog,
  laxation_log_id: 0,
  laxation_log: initialLaxationLog,
  name: "",
  kana: "",
  place: "",
  birth_date: "",
  gender: "",
  description: "",
  created: "",
  modified: "",
  m_status: initialTMStatus,
  status_code: "",
};

export default TCarePerson;
