import SystemConst from '@/constants/SystemConstants'
import _ from 'lodash'
import * as moment from 'moment'

const ITEM_URINE = 'item-urine'
const ITEM_FECES = 'item-feces'
const ITEM_BLANK = 'item-blank-average'

/**
 * タイムラインのアイテム作成(平均)
 */
export default class AverageItemCreator {
  // public

  /**
   * タイムラインアイテムの作成
   *
   * @param {object} パラメータ
   *                  number：interval 平均表示時のアイテムの間隔(分)
   *                  moment：currentDate カレント日付
   *                  array：average 排泄平均情報
   * @return {array} タイムラインのアイテム
   */
  static createItem(param) {
    const items = []
    let hour = 0
    while (hour < 24) {
      const startHour = param.currentDate.clone().hour(hour)
      const halfHour = startHour.clone().add(param.interval / 2, 'minutes')
      const endHour = startHour.clone().add(param.interval, 'minutes')

      const dataList = param.average.filter(
        (data) => hour === moment(data.datetime).hour()
      )

      // 平均データが存在しない時刻に対してもアイテムを必ず設定する。
      // その設定するアイテムに紐づいたクラスに対して、CSSを当てることにより
      // 平均インターバル時刻単位ごとにタイムラインの縦軸を表示する
      if (dataList.length <= 0) {
        items.push(this.buildItem(ITEM_BLANK, startHour, endHour, ''))
      } else {
        const data = dataList[0]
        const urineData = this.buildExcretionData(
          data,
          SystemConst.ExcretionType.urine
        )
        const fecesData = this.buildExcretionData(
          data,
          SystemConst.ExcretionType.feces
        )

        // 尿を表示する。
        if (urineData.exist === true) {
          items.push(
            this.buildItem(
              ITEM_URINE,
              startHour,
              halfHour,
              this.makeContent(urineData.amount)
            )
          )
        } else {
          items.push(this.buildItem(ITEM_BLANK, startHour, halfHour, ''))
        }

        // 便を表示する。
        if (fecesData.exist === true) {
          items.push(
            this.buildItem(
              ITEM_FECES,
              halfHour,
              endHour,
              this.makeContent(fecesData.amount)
            )
          )
        } else {
          items.push(this.buildItem(ITEM_BLANK, halfHour, endHour, ''))
        }
      }
      hour += param.interval / 60
    }

    return items
  }

  // private

  /**
   * アイテム構築
   * @param {string} className クラス名
   * @param {moment} start アイテムの開始時刻
   * @param {moment} end アイテムの終了時刻
   * @param {string} content アイテム内に表示するコンテンツ
   * @return {object} タイムラインアイテム
   */
  static buildItem(className, start, end, content) {
    return {
      className,
      start,
      end,
      content,
      type: 'range',
      group: -1,
      editable: { updateTime: false, updateGroup: false }
    }
  }

  /**
   * コンテンツ構築
   * @param {number} totalAmount 排泄量
   * @return {string} アイテム内に表示するコンテンツ
   */
  static makeContent(totalAmount) {
    if (totalAmount === 0) return ''
    return totalAmount.toString()
  }

  /**
   * 排泄データの構築
   * @param {object} excretionResult 排泄情報
   * @param {number} type 排泄種別
   * @return {obejct} 排泄データ
   *                   amount:number 排泄量
   *                   leaked:boolean おむつ漏れ
   *                   exist:boolean 対象の排泄種別の存在有無
   */
  static buildExcretionData(excretionResult, type) {
    let amount = 0
    let isExist = false
    _.each(excretionResult.excretionResultAmount, (data) => {
      // 同一の排泄種別の情報のみ取得する。
      if (data.type === type) {
        isExist = true
        if (data.amount > 0) {
          amount += data.amount
        }
      }
    })
    return { amount, leaked: false, exist: isExist }
  }
}
