/* eslint-disable */
import { localizedUiWords } from '@/features/locale'
import { LocaleContext } from '@/providers/LocaleProvider'
import _ from 'lodash'
import * as moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

class UserDetailTable extends Component {
  static contextType = LocaleContext

  calcAge(birthDate) {
    // ゼロパディングするための関数
    const paddingZero = (num, digit) => ('0000' + num).slice(-1 * digit)

    // 誕生日
    const birthDateSplit = birthDate.split('-')
    const birth = new Date(
      parseInt(birthDateSplit[0], 10),
      parseInt(birthDateSplit[1], 10) - 1,
      parseInt(birthDateSplit[2], 10)
    )
    const y2 = paddingZero(birth.getFullYear(), 4)
    const m2 = paddingZero(birth.getMonth() + 1, 2)
    const d2 = paddingZero(birth.getDate(), 2)

    // 今日の日付
    const today = new Date()
    const y1 = paddingZero(today.getFullYear(), 4)
    const m1 = paddingZero(today.getMonth() + 1, 2)
    const d1 = paddingZero(today.getDate(), 2)

    const age = Math.floor(
      (Number(y1 + m1 + d1) - Number(y2 + m2 + d2)) / 10000
    )

    return age
  }

  getGroupNameByCarePersonGroupId() {
    const groupId = this.props.personDetail.group_id
    const groups = this.props.personDetail.groups

    // TODO: (groupName について) 早期リターンの検討
    const [locale] = this.context
    const uiWords = localizedUiWords(locale.lang).pages.userDetail
    let groupName = uiWords.userTableNoGroupValue

    _.forEach(groups, (group) => {
      if (group.id === groupId) {
        groupName = group.name
      }
    })

    return groupName
  }

  render() {
    const [locale] = this.context
    const uiWords = localizedUiWords(locale.lang).pages.userDetail

    let userTable
    if (this.props.personDetail !== null) {
      userTable = (
        <table>
          <tbody>
            <tr>
              <td colSpan="2">
                <div className="flex space-between">
                  <p id="name">
                    {this.props.personDetail.name}
                    <span>{this.props.personDetail.kana}</span>
                  </p>
                  <div className="flex align-center">
                    <div className="user-status flex align-center">
                      <div className="icon icon-icons_wet"></div>
                      <div>
                        {this.props.personDetail.alert_log
                          ? moment(
                              this.props.personDetail.alert_log.created
                            ).format('MM/DD HH:mm')
                          : uiWords.userTableNoDataMessage}
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="flex space-between">
                  <p>{uiWords.userTableGroupLabel}</p>
                  <p>{this.getGroupNameByCarePersonGroupId()}</p>
                </div>
              </td>
              <td>
                <div className="flex space-between">
                  <p>{uiWords.userTableRoomLabel}</p>
                  <p>{this.props.personDetail.place}</p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      )
    }
    return <div>{userTable}</div>
  }
}

UserDetailTable.propTypes = {
  personDetail: PropTypes.any
}

export default UserDetailTable
