import { SensorError } from './SensorErrors'
import React, { ReactNode } from 'react'

type ErrorMessageProps = {
  errorType: SensorError
  errorMessage: ReactNode
}

export const ErrorMessage = ({
  errorType,
  errorMessage
}: ErrorMessageProps) => {
  return (
    <td className="error_td">
      <div className={'error-icon icon-' + errorType}>{errorMessage}</div>
    </td>
  )
}
