import './SettingLayout.scss'
import { SettingTabs } from './SettingTabs'
import { MainLayout } from '@/components/Layout'
import { Container } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'

// TODO:
// React 18 でないと TSX にしたときに Box コンポーネントで
// エラーが起きるため、一旦 JSX で定義

// type SettingsLayoutProps = {
//   children: React.ReactNode
//   activeTab: string
// }

export const SettingLayout = ({ children, activeTab }) => {
  return (
    <MainLayout>
      <Container id="settings">
        <SettingTabs activeTab={activeTab} />
        {children}
      </Container>
    </MainLayout>
  )
}

SettingLayout.propTypes = {
  children: PropTypes.node,
  activeTab: PropTypes.string
}
