/* eslint-disable */
import * as alertAction from '../actions/AlertAction'
import * as AppAction from '../actions/AppAction'
import * as excretionResultAction from '../actions/ExcretionResultAction'
import * as laxationLogsAction from '../actions/LaxationLogsAction'
import * as laxativeAction from '../actions/LaxativeAction'
import * as DeleteExcretionResultAction from '../actions/indivisualExcretionResults/DeleteExcretionResultAction'
import * as ExcretionSettingAction from '../actions/indivisualExcretionResults/ExcretionSettingAction'
import * as UserExcretionResultsAction from '../actions/indivisualExcretionResults/UserExcretionResultsAction'
import AppDialog from '../components/App/AppDialog'
import { AppHeader } from '../components/App/AppHeader'
import AppLoading from '../components/App/AppLoading'
import EventConst from '../constants/EventConstants'
import SystemConst from '../constants/SystemConstants'
import IndivisualTimeline from '../features/careNotes/components/CareNoteTimeline/IndivisualTimeline'
import UserSelectField from '../features/careNotes/components/UserSelectField'
import AppStore from '../stores/AppStore'
import errorStore from '../stores/ErrorStore'
import excretionResultStore from '../stores/ExcretionResultStore'
import laxationLogsStore from '../stores/LaxationLogsStore'
import laxativeStore from '../stores/LaxativeStore'
import AmountAverageStore from '../stores/indivisualExcretionResults/AmountAverageStore'
import DeleteExcretionResultStore from '../stores/indivisualExcretionResults/DeleteExcretionResultStore'
import ExcretionSettingStore from '../stores/indivisualExcretionResults/ExcretionSettingStore'
import UserExcretionResultsStore from '../stores/indivisualExcretionResults/UserExcretionResultsStore'
import './IndividualExcretionResults.scss'
import {
  CareNoteDialog,
  CareNoteExplanation,
  CareNoteLegends,
  CareNoteDatePicker
} from '@/features/careNotes/components'
import { CareNoteSummary } from '@/features/careNotes/components/CareNoteSummary'
import { localizedUiWords } from '@/features/locale'
import { LocaleContext } from '@/providers/LocaleProvider'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { ArrowBack } from '@material-ui/icons'
import _ from 'lodash'
import * as moment from 'moment'
import React, { Component } from 'react'
import Form from 'react-jsonschema-form'
import { withRouter } from 'react-router-dom'

const INIT_AMOUNT_AVERAGE = 60

/**
 * ユーザごとの個別排泄記録ページ
 *
 * TODO: Individualをタイポしてる
 */
class IndivisualExcretionResults extends Component {
  static contextType = LocaleContext

  /**
   * コンストラクタ
   */
  constructor() {
    super()
    this.state = {
      currentDatetime: UserExcretionResultsStore.getCurrentDateTime(),
      carePerson: UserExcretionResultsStore.getCarePerson(),
      comment: UserExcretionResultsStore.getStatistics(),
      amountAverage: UserExcretionResultsStore.getAmountAverage(),
      excretionDetailDatas: ExcretionSettingStore.getDetailExcretionResults(),
      detailExcretionResultsDate:
        ExcretionSettingStore.getDetailExcretionResultsDate(),
      alertLog: ExcretionSettingStore.getAlertLog(),
      deleteExcretionResult:
        DeleteExcretionResultStore.getDeleteExcretionResult(),
      isAppLoading: AppStore.getAppLoading(),
      amountAverageList: AmountAverageStore.getAmountAverageList(),
      laxativeDatas: laxativeStore.getLaxatives(),
      unitList: laxativeStore.getUnitList(),
      unsavedExcretionDetailDatas: [],
      laxationLogsDetailDatas: ExcretionSettingStore.getLaxationLogs(),
      unsavedLaxationLogsDetailDatas: [],
      deletedExcretionResultId: null,
      laxationLogsId: null,
      deletedLaxationLogsId: null,
      laxativeAlerts: null,
      dialogExcretion: false,
      dialogLaxation: false,
      excretionDetailData: {
        subject_id: null,
        name: '',
        confirmed: '',
        result: 'none',
        urine_amount: 'none',
        amount: 'none',
        firmness: 'none',
        description: '',
        isEdit: false
      },
      laxationLogsDetailData: {
        subject_id: null,
        name: '',
        dosage_date: '',
        laxative_id: null,
        amount: '',
        unit_id: null,
        isEdit: false
      },
      activeTab: 1, // 1: 排泄記録, 2: 下剤記録
      reservedActiveTab: 0, // 0のときは使用しない
      unsavedExcretionIndex: 0,
      unsavedLaxationLogsIndex: 0,
      isGettingTimelineCompleted: {
        excretionResults: false,
        laxationLogs: false
      },
      dateSort: this.getSortInfoByCookie()
    }

    this.onChangeDateSort = this.onChangeDateSort.bind(this)
  }

  /**
   * コンポーネントマウント時
   */
  componentDidMount() {
    UserExcretionResultsStore.on(
      EventConst.action_type.user_excretion_results.current_date,
      () => {
        this.setState({
          currentDatetime: UserExcretionResultsStore.getCurrentDateTime()
        })
      }
    )
    UserExcretionResultsStore.on(
      EventConst.action_type.user_excretion_results.care_person,
      () => {
        this.setState({
          carePerson: UserExcretionResultsStore.getCarePerson()
        })
      }
    )
    UserExcretionResultsStore.on(
      EventConst.action_type.user_excretion_results.statistics,
      () => {
        this.setState({
          comment: UserExcretionResultsStore.getStatistics()
        })
      }
    )
    UserExcretionResultsStore.on(
      EventConst.action_type.user_excretion_results.amount_average,
      () => {
        this.setState({
          amountAverage: UserExcretionResultsStore.getAmountAverage()
        })
      }
    )
    ExcretionSettingStore.on(
      EventConst.action_type.excretion_setting.excretion_results,
      () => {
        const detailDatas = ExcretionSettingStore.getDetailExcretionResults()
        this.setState({
          excretionDetailDatas: detailDatas,
          detailExcretionResultsDate:
            ExcretionSettingStore.getDetailExcretionResultsDate(),
          dialogExcretion: detailDatas.length > 0
        })
      }
    )

    ExcretionSettingStore.on(
      EventConst.action_type.excretion_setting.excretion_results_time,
      () => {
        this.setState({
          detailExcretionResultsDate:
            ExcretionSettingStore.getDetailExcretionResultsDate()
        })
      }
    )

    ExcretionSettingStore.on(
      EventConst.action_type.excretion_setting.laxation,
      () => {
        const detailDatas = ExcretionSettingStore.getLaxationLogs()
        this.setState({
          laxationLogsDetailDatas: detailDatas,
          dialogLaxation: detailDatas.length > 0
        })
      }
    )

    ExcretionSettingStore.on(
      EventConst.action_type.excretion_setting.alert_log,
      () => {
        this.setState({
          alertLog: ExcretionSettingStore.getAlertLog()
        })
      }
    )
    DeleteExcretionResultStore.on(
      EventConst.action_type.delete_excretion_result.excretion_result,
      () => {
        this.setState({
          deleteExcretionResult:
            DeleteExcretionResultStore.getDeleteExcretionResult()
        })
      }
    )
    DeleteExcretionResultStore.on(
      EventConst.action_type.delete_excretion_result.clear,
      () => {
        this.setState({
          deleteExcretionResult:
            DeleteExcretionResultStore.getDeleteExcretionResult()
        })
      }
    )
    AppStore.on(EventConst.action_type.app.app_loading, () => {
      this.setState({
        isAppLoading: AppStore.getAppLoading()
      })
    })
    AmountAverageStore.on(
      EventConst.action_type.amount_average.average_list,
      () => {
        this.setState({
          amountAverageList: AmountAverageStore.getAmountAverageList()
        })
      }
    )

    laxativeStore.on(EventConst.action_type.laxative.laxatives_all, () => {
      this.setState({
        laxativeDatas: laxativeStore.getLaxatives(),
        dialogLaxation: true
      })
    })
    laxativeStore.on(
      EventConst.action_type.laxative.medicine_unit_master,
      () => {
        this.setState({
          unitList: laxativeStore.getUnitList()
        })
      }
    )

    // ExcretionDialogに流す
    excretionResultStore.on('excretion_result_for_dialog_from_delete', (v) => {
      let excretionDetailDatas = []
      _.each(v.data, (excretionDetailData) => {
        if (excretionDetailData.alert_log_id !== null) {
          // 通知時刻を取るためにAPIを更に呼ぶ
          alertAction.alert(excretionDetailData.alert_log_id)
        }
        excretionDetailDatas.push({
          id: excretionDetailData.id,
          name: excretionDetailData.name,
          subject_id: excretionDetailData.subject_id,
          alert_log_id: excretionDetailData.alert_log_id,
          confirmed: moment(excretionDetailData.confirmed),
          result: excretionDetailData.result,
          amount: excretionDetailData.amount,
          urine_amount: excretionDetailData.urine_amount,
          leaked: excretionDetailData.leaked,
          type: excretionDetailData.type,
          firmness: excretionDetailData.firmness,
          description: excretionDetailData.description,
          isEdit: false
        })
      })
      // 既存データがある場合ダイアログを表示
      let showDialog = false
      let activeTab = 1
      let unsavedDatas = []
      let currentIndex = 0

      // 未保存データと結合する。
      const unsavedExcretionDetailDatas =
        this.state.unsavedExcretionDetailDatas.concat()
      excretionDetailDatas = _.union(
        excretionDetailDatas,
        _.filter(unsavedExcretionDetailDatas, { id: null })
      )
      // 既存データがある場合、データの整合性を保つ。ない場合は初期値を準備する。
      if (excretionDetailDatas.length > 0) {
        showDialog = true
        unsavedDatas = this.state.unsavedExcretionDetailDatas.concat()
        currentIndex = _.findIndex(unsavedDatas, {
          id: this.state.deletedExcretionResultId
        })
        currentIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex
        _.remove(unsavedDatas, { id: this.state.deletedExcretionResultId })
        unsavedDatas.sort((currentData, nextData) => {
          const currentDate =
            Object.prototype.toString.call(currentData.confirmed) ===
            '[object Date]'
              ? currentData.confirmed.format()
              : currentData.confirmed
          const nextDate =
            Object.prototype.toString.call(nextData.confirmed) ===
            '[object Date]'
              ? nextData.confirmed.format()
              : nextData.confirmed
          return currentDate > nextDate ? 1 : -1
        })
      } else {
        const subjectId = parseInt(
          v.url
            .substring(v.url.search('subject_id'), v.url.indexOf('&'))
            .split('=')[1]
        )

        // eslint-disable-next-line
        const person = _.filter(this.props.location.state.carePeople, {
          id: subjectId
        })[0]

        const excretionDetailData = this.state.excretionDetailData
        excretionDetailData.subject_id = subjectId
        excretionDetailData.confirmed = this.state.detailExcretionResultsDate
        excretionDetailData.name = person.name
        excretionDetailData.place = person.place
        excretionDetailDatas.push(excretionDetailData)
        excretionDetailDatas.sort((currentData, nextData) => {
          return currentData.confirmed.format() > nextData.confirmed.format()
            ? 1
            : -1
        })

        // 未保存の編集中下剤記録があるか
        if (this.state.unsavedLaxationLogsDetailDatas.length > 0) {
          showDialog = true
          const editingData = _.filter(
            this.state.unsavedLaxationLogsDetailDatas,
            { isEdit: true }
          )
          // あれば下剤記録タブを表示する
          if (editingData.length > 0) {
            activeTab = 2
          }
        }
      }

      this.setState({
        excretionDetailDatas,
        unsavedExcretionDetailDatas: unsavedDatas,
        unsavedExcretionIndex: currentIndex,
        dialogExcretion: showDialog,
        activeTab
      })
    })

    excretionResultStore.on('excretion_result_delete', (v) => {
      this.getExcretionResultsForDialog(
        this.state.detailExcretionResultsDate,
        this.state.carePerson.id
      )
      setTimeout(() => {
        UserExcretionResultsAction.updateUserExcretionResults(
          this.state.carePerson.id
        )
      }, 0)
    })

    laxationLogsStore.on('laxation_logs_detail_from_delete', (v) => {
      let laxationLogsDetailDatas = []
      _.each(v.data, (laxationLogsDetailData) => {
        laxationLogsDetailDatas.push({
          id: laxationLogsDetailData.id,
          subject_id: laxationLogsDetailData.subject_id,
          dosage_date: moment(laxationLogsDetailData.administered),
          laxative_id: laxationLogsDetailData.laxative_id,
          amount: laxationLogsDetailData.amount,
          unit_id: laxationLogsDetailData.unit_id,
          isEdit: false
        })
      })

      let showDialog = false
      let activeTab = 2
      let unsavedDatas = []

      // 未保存データと結合する。
      const unsavedLaxationLogsDetailDatas =
        this.state.unsavedLaxationLogsDetailDatas.concat()
      laxationLogsDetailDatas = _.union(
        laxationLogsDetailDatas,
        _.filter(unsavedLaxationLogsDetailDatas, { id: null })
      )
      // 既存データがある場合、データの整合性を保つ。ない場合は初期値を準備する。
      if (laxationLogsDetailDatas.length > 0) {
        showDialog = true

        unsavedDatas = this.state.unsavedLaxationLogsDetailDatas.concat()
        _.remove(unsavedDatas, { id: this.state.deletedLaxationLogsId })
      } else {
        const subjectId = parseInt(
          v.url
            .substring(v.url.search('subject_id'), v.url.indexOf('&'))
            .split('=')[1]
        )

        const person = _.filter(this.props.location.state.carePeople, {
          id: subjectId
        })[0]

        const laxationLogsDetailData = this.state.laxationLogsDetailData
        laxationLogsDetailData.dosage_date =
          this.state.detailExcretionResultsDate
        laxationLogsDetailData.subject_id = subjectId
        laxationLogsDetailData.name = person.name
        laxationLogsDetailData.place = person.place
        laxationLogsDetailDatas.push(laxationLogsDetailData)
        laxationLogsDetailDatas.sort((currentData, nextData) => {
          return currentData.dosage_date.format() >
            nextData.dosage_date.format()
            ? 1
            : -1
        })

        // 未保存の編集中排泄記録があるか
        if (this.state.unsavedExcretionDetailDatas.length > 0) {
          showDialog = true

          const editingData = _.filter(this.state.unsavedExcretionDetailDatas, {
            isEdit: true
          })

          // あれば排泄記録タブを表示する
          if (editingData.length > 0) {
            activeTab = 1
          }
        }
      }

      this.setState({
        laxationLogsDetailDatas,
        unsavedLaxationLogsDetailDatas: unsavedDatas,
        dialogLaxation: showDialog,
        activeTab
      })
    })

    laxationLogsStore.on('laxation_logs_delete', (v) => {
      this.getLaxationLogsForDialog(
        this.state.detailExcretionResultsDate,
        this.state.carePerson.id
      )
      setTimeout(() => {
        UserExcretionResultsAction.updateUserExcretionResults(
          this.state.carePerson.id
        )
      }, 0)
    })

    errorStore.on(EventConst.action_type.error.error_422, (v) => {
      console.log('更新に失敗しました。(422)')
      this.onError()
    })

    errorStore.on(EventConst.action_type.error.error_500, (v) => {
      console.log('更新に失敗しました。(500)')
      this.onError()
    })

    errorStore.on(EventConst.action_type.error.other_status_error, (v) => {
      console.log('更新に失敗しました。(%d)', v.status)
      this.onError()
    })

    errorStore.on(EventConst.action_type.error.unexpected_error, (v) => {
      console.log('unexpected_error', v)
      this.onError()
    })

    // ページ遷移時の状態を継承
    this.setPageTransitionState()
  }

  /**
   * コンポーネントアンマウント時
   */
  componentWillUnmount() {
    UserExcretionResultsStore.removeAllListeners(
      EventConst.action_type.user_excretion_results.current_date
    )
    UserExcretionResultsStore.removeAllListeners(
      EventConst.action_type.user_excretion_results.care_person
    )
    UserExcretionResultsStore.removeAllListeners(
      EventConst.action_type.user_excretion_results.statistics
    )
    UserExcretionResultsStore.removeAllListeners(
      EventConst.action_type.user_excretion_results.amount_average
    )
    ExcretionSettingStore.removeAllListeners(
      EventConst.action_type.excretion_setting.excretion_results
    )
    ExcretionSettingStore.removeAllListeners(
      EventConst.action_type.excretion_setting.alert_log
    )
    ExcretionSettingStore.removeAllListeners(
      EventConst.action_type.excretion_setting.excretion_results_time
    )
    ExcretionSettingStore.removeAllListeners(
      EventConst.action_type.excretion_setting.laxation
    )
    DeleteExcretionResultStore.removeAllListeners(
      EventConst.action_type.delete_excretion_result.excretion_result
    )
    DeleteExcretionResultStore.removeAllListeners(
      EventConst.action_type.delete_excretion_result.clear
    )
    AppStore.removeAllListeners(EventConst.action_type.app.app_loading)
    AmountAverageStore.removeAllListeners(
      EventConst.action_type.amount_average.average_list
    )
    laxativeStore.removeAllListeners(
      EventConst.action_type.laxative.laxatives_all
    )
    laxativeStore.removeAllListeners(
      EventConst.action_type.laxative.medicine_unit_master
    )
    errorStore.removeAllListeners(EventConst.action_type.error.error_422)
    errorStore.removeAllListeners(EventConst.action_type.error.error_500)
    errorStore.removeAllListeners(
      EventConst.action_type.error.other_status_error
    )
    errorStore.removeAllListeners(EventConst.action_type.error.unexpected_error)
    excretionResultStore.removeAllListeners(
      'excretion_result_for_dialog_from_delete'
    )
    excretionResultStore.removeAllListeners('excretion_result_delete')
    laxationLogsStore.removeAllListeners('laxation_logs_detail_from_delete')
    laxationLogsStore.removeAllListeners('laxation_logs_delete')
  }

  getCookie() {
    const res = {}

    const cookieStr = document.cookie
    if (cookieStr !== '') {
      const cookies = cookieStr.split('; ')
      cookies.forEach((v) => {
        v = v.split('=')
        res[v[0]] = v[1]
      })
    }

    return res
  }

  getSortInfoByCookie() {
    const res = this.getCookie()
    return res.careDateSort ? res.careDateSort : 'desc'
  }

  /**
   * 排泄記録カレンダー日付変更
   * @param {object} e クリックイベント
   */
  handleDatetimeFieldChange(e) {
    const datetime = e.formData.confirmed.substring(0, 10)
    if (/^\d{4}\/\d{2}\/\d{2}$/.test(datetime)) {
      this.onClickDateCalendar(
        moment(datetime, SystemConst.DateFormat.Calendar)
      )
    } else {
      console.log('handleDatetimeFieldChange error')
      console.log(e.formData.confirmed)
    }
  }

  /**
   * タイムライン作成
   */
  createTimeline() {
    return (
      <div className={this.createTimelineClass()}>
        <IndivisualTimeline
          sort={this.state.dateSort}
          onGroupLabelClick={this.onGroupLabelClick.bind(this)}
          onAdd={this.handleAdd.bind(this)}
          onUpdate={this.handleUpdate.bind(this)}
        />
      </div>
    )
  }

  /**
   * タイムラインのクラス名作成
   *
   * @return {string} クラス名
   */
  createTimelineClass() {
    let className = 'indivisualTimeline timeline'
    if (this.state.amountAverage === 180) {
      className += ' three-time'
    } else if (this.state.amountAverage === 360) {
      className += ' six-time'
    }
    return className
  }

  /**
   * コメント作成
   */
  GetComment() {
    const userComment = `この日までの31日間で、\n${this.state.carePerson.name}様は、\n`

    return userComment + this.GetExcretionResultComment()
  }

  /**
   * 排泄記録コメント作成
   */
  GetExcretionResultComment() {
    const noneCount = this.state.comment.noneCoount
    const leakCount = this.state.comment.leakCoount
    let excretionResultComment = ''
    if (noneCount > 0 && leakCount > 0) {
      excretionResultComment = `${noneCount}回の空振りと、\n${leakCount}回のおむつ漏れがありました。`
    } else if (noneCount > 0 && leakCount <= 0) {
      excretionResultComment = `${noneCount}回の空振りがあり、\nおむつ漏れはありませんでした。`
    } else if (noneCount <= 0 && leakCount > 0) {
      excretionResultComment = `空振りはなく、\n${leakCount}回のおむつ漏れがありました。`
    } else {
      excretionResultComment = `空振りもおむつ漏れも\nありませんでした。`
    }

    return excretionResultComment
  }

  /**
   * ページ遷移による状態の引継ぎ
   */
  setPageTransitionState() {
    if (
      this.props.location.state !== null &&
      this.props.location.state.selectDatetime !== null &&
      this.props.location.state.carePerson !== null
    ) {
      this.initializePage(
        moment(
          this.props.location.state.selectDatetime,
          SystemConst.DateFormat.Calendar
        ),
        this.props.location.state.carePerson
      )
    } else {
      console.log('setPageTransitionState error')
    }
  }

  /**
   * 排泄記録ページへの遷移
   * @param {moment}  date 日付
   */
  transitionExcretionResultsPage(date) {
    this.props.history.push({
      pathname: '/excretionResults',
      state: { selectDatetime: date.format(SystemConst.DateFormat.Calendar) }
    })
  }

  /**
   * 排泄記録詳細画面表示有無
   * @return {boolean} 排泄記録詳細画面表示有無
   */
  isExcretionDialogActive() {
    // 出来れば、詳細画面が保持しているデータ数でdisableを判断したい。
    return this.state.dialogExcretion && this.state.dialogLaxation
  }

  /**
   * 削除画面表示有無
   * @return {boolean} 削除画面表示有無
   */
  isAppDialogActive() {
    return this.state.deleteExcretionResult.id !== -1
  }

  /**
   * 削除対象の排泄記録ID取得
   * @return {number} 削除対象の排泄記録ID
   */
  getExcretionId() {
    return this.state.deleteExcretionResult.id !== -1
      ? this.state.deleteExcretionResult.id
      : null
  }

  /**
   * 排泄記録情報取得
   * @param {moment} datetime 選択した日付/時刻
   * @param {number} subjectId 利用者ID
   */
  getExcretionResultsForDialog(datetime, subjectId) {
    const startHour = moment(datetime).format('YYYY-MM-DDTHH:00:00+09:00')
    const endHour = moment(datetime).format('YYYY-MM-DDTHH:59:59+09:00')
    const query = {
      subject_id: subjectId,
      start: startHour,
      end: endHour
    }
    excretionResultAction.excretionResultsForDialogFromDelete(query)
  }

  /**
   * 下剤情報取得
   * @param {moment} datetime 選択した日付/時刻
   * @param {number} subjectId 利用者ID
   */
  getLaxationLogsForDialog(datetime, subjectId) {
    const startHour = moment(datetime).format('YYYY-MM-DDTHH:00:00+09:00')
    const endHour = moment(datetime).format('YYYY-MM-DDTHH:59:59+09:00')
    const query = {
      subject_id: subjectId,
      start: startHour,
      end: endHour
    }
    laxationLogsAction.laxationLogsDetailFromDelete(query)
  }

  // DOMイベントアクション定義

  /**
   * タイムラインの空アイテムをクリック時の動作
   */
  handleAdd(datetime) {
    ExcretionSettingAction.createNewExcretionResults(datetime)
    this.setState({
      dialogExcretion: true,
      dialogLaxation: true,
      activeTab: 1
    })
  }

  /**
   * タイムラインのアイテムをクリック時の動作
   */
  handleUpdate(datetime) {
    ExcretionSettingAction.createUpdateExcretionResults(
      datetime,
      datetime.format(SystemConst.DateFormat.detailExcretionResultStart),
      datetime.format(SystemConst.DateFormat.detailExcretionResultEnd)
    )
    this.setState({
      activeTab: 1
    })
  }

  /**
   * 個別排泄記録ページの初期化
   * @param {moment} date 選択した日付
   * @param {object} carePerson 利用者情報
   */
  initializePage(date, carePerson) {
    UserExcretionResultsAction.updateCurrentDate(date)
    UserExcretionResultsAction.updateCarePerson(carePerson)
    UserExcretionResultsAction.updateAverageAmount(INIT_AMOUNT_AVERAGE)

    UserExcretionResultsAction.updateUserExcretionResults(
      carePerson.id,
      date,
      INIT_AMOUNT_AVERAGE
    )

    laxativeAction.laxatives()
    laxativeAction.medicineUnitMaster()
  }

  /**
   * 排泄記録カレンダーの日付クリック時の動作
   * @param {moment}  date 選択した日付
   */
  onClickDateCalendar(date) {
    UserExcretionResultsAction.updateCurrentDate(date)

    UserExcretionResultsAction.updateUserExcretionResults(
      this.state.carePerson.id,
      date
    )
  }

  /**
   * 排泄記録カレンダーの今日クリック時の動作
   */
  onClickTodayCalendar() {
    this.onClickDateCalendar(moment())
  }

  /**
   * グループラベルクリック
   */
  onGroupLabelClick(date) {
    this.transitionExcretionResultsPage(moment(date))
  }

  /**
   * 戻るボタンクリック
   */
  onClickBack() {
    this.transitionExcretionResultsPage(this.state.currentDatetime)
  }

  /**
   * 排泄記録詳細画面閉じるボタン押下
   */
  handleDialogClosing(e) {
    setTimeout(() => {
      ExcretionSettingAction.clearExcretionResults()
    }, 0)

    this.setState({
      activeTab: 1,
      dialogExcretion: false,
      dialogLaxation: false,
      unsavedExcretionDetailDatas: [],
      unsavedLaxationLogsDetailDatas: [],
      unsavedExcretionIndex: 0,
      unsavedLaxationLogsIndex: 0
    })
  }

  /**
   * 排泄記録詳細画面の処理終了時にコール
   */
  onCompleteExcretionDialog() {
    // dipather内で再度dispatherを実行してしまうため、
    // タイマーで実行する。
    setTimeout(() => {
      UserExcretionResultsAction.updateUserExcretionResults(
        this.state.carePerson.id,
        this.state.currentDatetime
      )
    }, 0)

    this.setState({
      dialogExcretion: false,
      dialogLaxation: false,
      loading: true,
      unsavedExcretionDetailDatas: [],
      unsavedLaxationLogsDetailDatas: []
    })
  }

  /**
   * 排泄記録詳細画面のローディング前に呼ばれる。
   */
  onLoading() {}

  /**
   * 排泄記録詳細画面削除ボタン押下
   */
  onRemoveExcretionDialog(e) {
    DeleteExcretionResultAction.createDeleteExcretionResult({
      id: e.excretionResultId
    })

    switch (this.state.activeTab) {
      case 1:
        this.setState({
          reservedActiveTab: 2,
          unsavedExcretionDetailDatas: e.unsavedExcretionDatas,
          unsavedLaxationLogsDetailDatas: e.unsavedLaxationLogsDatas,
          unsavedLaxationLogsIndex: e.unsavedLaxationLogsIndex
        })
        break
      case 2:
        this.setState({
          reservedActiveTab: 1,
          laxationLogsId: e.laxationLogsId,
          unsavedLaxationLogsDetailDatas: e.unsavedLaxationLogsDatas,
          unsavedExcretionDetailDatas: e.unsavedExcretionDatas,
          unsavedExcretionIndex: e.unsavedExcretionIndex
        })
        break
      default:
        break
    }
  }

  /**
   * 排泄記録削除画面閉じた場合に呼ばれる
   */
  onCloseAppDialog() {
    DeleteExcretionResultAction.clearDeleteExcretionResult()
  }

  /**
   * リクエストエラー発生時に呼ばれる。
   */
  onError() {
    // dipather内で再度dispatherを実行してしまうため、
    // タイマーで実行する。
    setTimeout(() => {
      AppAction.finishLoaded()
    }, 0)
  }

  /**
   * 排泄記録削除画面ボタンクリック時
   */
  handleExcretionDeletingDialogClick(e) {
    const [locale] = this.context
    const uiWords = localizedUiWords(locale.lang).components.appDialog

    switch (e) {
      case uiWords.okButtonLabel: {
        const excretionResultId = this.getExcretionId()
        DeleteExcretionResultAction.deleteExcretionResult()
        const unsavedExcretionDetailDatas =
          this.state.unsavedExcretionDetailDatas.concat()

        if (unsavedExcretionDetailDatas.length < 2) {
          // 排泄記録に編集中のデータはあるか
          if (
            _.filter(unsavedExcretionDetailDatas, { isEdit: true }).length > 0
          ) {
            // 下剤記録タブにデータはあるか。
            if (this.state.unsavedLaxationLogsDetailDatas.length > 0) {
              // 編集中のデータはあるか。
              if (
                _.filter(this.state.unsavedLaxationLogsDetailDatas, {
                  isEdit: true
                }).length > 0
              ) {
                // 下剤記録タブに編集中のデータがあるので閉じない。
              } else {
                // 下剤記録タブに編集中のデータはないので閉じてよい。
                this.handleDialogClosing(e)
              }
            } else {
              // 下剤記録タブにデータはないので閉じてよい。
              this.handleDialogClosing(e)
            }
          } else {
            // 下剤記録タブにデータはあるか。
            if (this.state.unsavedLaxationLogsDetailDatas.length > 0) {
              // 編集中のデータはあるか。
              if (
                _.filter(this.state.unsavedLaxationLogsDetailDatas, {
                  isEdit: true
                }).length > 0
              ) {
                // 下剤記録タブに編集中のデータがあるので閉じない。
              } else {
                // 下剤記録タブに編集中のデータはないので閉じてよい。
                this.handleDialogClosing(e)
              }
            } else {
              // 下剤記録タブにデータはない。排泄記録にも編集中のデータはないので閉じてよい
              this.handleDialogClosing(e)
            }
          }

          // データの整合性を合わせる。
          _.remove(unsavedExcretionDetailDatas, { id: excretionResultId })

          // データがない場合の初期値準備
          if (unsavedExcretionDetailDatas.length === 0) {
            const excretionDetailData = { ...this.state.excretionDetailData }
            const person = _.filter(this.props.location.state.carePeople, {
              id: this.state.carePerson.id
            })[0]
            excretionDetailData.subject_id = person.id
            excretionDetailData.name = person.name
            excretionDetailData.place = person.place
            excretionDetailData.confirmed =
              this.state.detailExcretionResultsDate
            this.setState({
              excretionDetailDatas: [excretionDetailData],
              unsavedExcretionDetailDatas: []
            })
          }
        }

        this.setState({
          deletedExcretionResultId: excretionResultId
        })
        break
      }
      case uiWords.cancelButtonLabel:
        break
      default:
        break
    }
  }

  /**
   * 下剤情報削除画面ボタンクリック時
   */
  handleLaxationLogsDeletingDialogClick(e) {
    const [locale] = this.context
    const uiWords = localizedUiWords(locale.lang).components.appDialog

    switch (e) {
      case uiWords.okButtonLabel: {
        laxationLogsAction.deleteLaxationLogs(this.state.laxationLogsId)

        const unsavedLaxationLogsDetailDatas =
          this.state.unsavedLaxationLogsDetailDatas.concat()

        if (unsavedLaxationLogsDetailDatas.length < 2) {
          // 下剤記録に編集中のデータはあるか。
          if (
            _.filter(unsavedLaxationLogsDetailDatas, { isEdit: true }).length >
            0
          ) {
            // 排泄記録タブにデータはあるか。
            if (this.state.unsavedExcretionDetailDatas.length > 0) {
              // 編集中のデータはあるか。
              if (
                _.filter(this.state.unsavedExcretionDetailDatas, {
                  isEdit: true
                }).length > 0
              ) {
                // 排泄記録タブに編集中のデータがあるので閉じない。
              } else {
                // 排泄記録タブに編集中のデータはないので閉じてよい。
                this.handleDialogClosing(e)
              }
            } else {
              // 排泄記録タブにデータはないので閉じてよい。
              this.handleDialogClosing(e)
            }
          } else {
            // 排泄記録タブにデータはあるか。
            if (this.state.unsavedExcretionDetailDatas.length > 0) {
              // 編集中のデータはあるか。
              if (
                _.filter(this.state.unsavedExcretionDetailDatas, {
                  isEdit: true
                }).length > 0
              ) {
                // 排泄記録タブに編集中のデータがあるので閉じない。
              } else {
                // 排泄記録タブに編集中のデータはないので閉じてよい。
                this.handleDialogClosing(e)
              }
            } else {
              // 排泄記録タブにデータはないので閉じてよい。
              this.handleDialogClosing(e)
            }
          }

          // データの整合性を合わせる。
          _.remove(unsavedLaxationLogsDetailDatas, {
            id: this.state.laxationLogsId
          })
          // データがない場合の初期値準備
          if (unsavedLaxationLogsDetailDatas.length === 0) {
            let laxationLogsDetailData = {
              ...this.state.laxationLogsDetailData
            }
            const person = _.filter(this.props.location.state.carePeople, {
              id: this.state.carePerson.id
            })[0]
            laxationLogsDetailData.subject_id = person.id
            laxationLogsDetailData.name = person.name
            laxationLogsDetailData.place = person.place
            laxationLogsDetailData.dosage_date =
              this.state.detailExcretionResultsDate
            this.setState({
              laxationLogsDetailDatas: [laxationLogsDetailData],
              unsavedLaxationLogsDetailDatas: []
            })
          }
        }
        this.setState({
          laxationLogsId: null,
          deletedLaxationLogsId: this.state.laxationLogsId
        })
        break
      }
      case uiWords.cancelButtonLabel:
        this.setState({
          laxationLogsId: null
        })
        break
      default:
        break
    }
  }

  /**
   * 量平均変更時に呼ばれる
   * @param {object} e クリックイベント
   */
  onAverageAmountChange(e) {
    const averageAmount = parseInt(e.target.value, 10)
    UserExcretionResultsAction.updateAverageAmount(averageAmount)
    UserExcretionResultsAction.updateUserExcretionResults(
      this.state.carePerson.id,
      this.state.currentDatetime,
      averageAmount
    )
  }

  /**
   * 排泄入力ダイアログのレコードが変更されてタイミングで呼ばれる、
   * @param {object} e クリックイベント
   */
  handleRecordChange(e) {
    this.setState({
      activeTab: e.activeTab,
      unsavedExcretionDetailDatas: e.unsavedExcretionDatas,
      unsavedLaxationLogsDetailDatas: e.unsavedLaxationLogsDatas
    })
  }

  onChangeDateSort(e) {
    const cookieSec = 7 * 24 * 60 * 60
    this.setState({
      dateSort: e.target.value
    })
    document.cookie = `careDateSort=${e.target.value}; max-age=${cookieSec}`
  }

  /**
   * 描画
   */
  render() {
    const [locale] = this.context
    const uiWords = localizedUiWords(locale.lang).pages.excretionResults

    return (
      <div id="excretion-results">
        <AppHeader />
        <div id="individual-excretion-results">
          <div className="head-container">
            <div className="head-content-left-block">
              {/* 戻るボタン */}
              <IconButton
                className="backButton"
                onClick={this.onClickBack.bind(this)}
              >
                <ArrowBack />
                <div className="backButtonLabel">{uiWords.backButtonLabel}</div>
              </IconButton>

              {/* 利用者選択ボックス */}
              <div className="users">
                <UserSelectField groupId={this.props.location.state.groupId} />
              </div>
            </div>
            <CareNoteExplanation />
            <CareNoteLegends />
          </div>

          <div className="flex">
            <div className="left-fields">
              <CareNoteDatePicker
                currentDatetime={this.state.currentDatetime}
                onClick={this.onClickTodayCalendar.bind(this)}
                onChange={this.handleDatetimeFieldChange.bind(this)}
              />
              <CareNoteSummary
                residentName={this.state.carePerson.name}
                missingCount={this.state.comment.noneCoount}
                leakageCount={this.state.comment.leakCoount}
              />

              {/* 平均排泄量の表示方法の選択ボックス */}
              <div className="average-excretion-amount-select">
                <div className="average-excretion-amount-select-label">
                  {uiWords.averageExcretionAmountSelectLabel}
                </div>
                <div className="average hpad-pulldown-1">
                  <select
                    onChange={this.onAverageAmountChange.bind(this)}
                    value={this.state.amountAverage}
                  >
                    {this.state.amountAverageList.map((average) => {
                      const hour = average / 60
                      return (
                        <option key={average} value={average}>
                          {uiWords.showAverageAmountOptionTemplate(hour)}
                        </option>
                      )
                    })}
                  </select>
                </div>
              </div>

              {/* 日付ソート */}
              <div className="sort-select">
                <div className="sort-select-label">
                  {uiWords.sortSelectLabel}
                </div>
                <div className="hpad-pulldown-1">
                  <select
                    onChange={this.onChangeDateSort}
                    value={this.state.dateSort}
                  >
                    <option value="desc">{uiWords.sortByDateDescOption}</option>
                    <option value="asc">{uiWords.sortByDateAscOption}</option>
                  </select>
                </div>
              </div>
            </div>
            {this.createTimeline()}
          </div>

          <AppLoading isActive={this.state.isAppLoading} />

          <CareNoteDialog
            isActive={this.isExcretionDialogActive()}
            confirmed={this.state.detailExcretionResultsDate}
            excretionResultId={this.getExcretionId()}
            alertLogs={this.state.alertLog}
            carePeople={this.props.location.state.carePeople}
            onLoading={this.onLoading.bind(this)}
            onComplete={this.onCompleteExcretionDialog.bind(this)}
            onRemove={this.onRemoveExcretionDialog.bind(this)}
            onDialogClosing={this.handleDialogClosing.bind(this)}
            onRecordChange={this.handleRecordChange.bind(this)}
            excretionDetailDatas={this.state.excretionDetailDatas}
            laxationLogsDetailDatas={this.state.laxationLogsDetailDatas}
            where="excretionResults"
            activeTab={this.state.activeTab}
            reservedActiveTab={this.state.reservedActiveTab}
            unsavedExcretionDetailDatas={this.state.unsavedExcretionDetailDatas}
            unsavedLaxationLogsDetailDatas={
              this.state.unsavedLaxationLogsDetailDatas
            }
            unsavedExcretionIndex={this.state.unsavedExcretionIndex}
            unsavedLaxationLogsIndex={this.state.unsavedLaxationLogsIndex}
            laxativeDatas={this.state.laxativeDatas}
            unitList={this.state.unitList}
          />

          <AppDialog
            isActive={this.isAppDialogActive()}
            title={
              this.state.activeTab === 1
                ? uiWords.deleteExcretionRecordDialog
                : uiWords.deleteLaxativeRecordDialog
            }
            choiceButtonLists={[
              uiWords.deleteDialogOkButtonLabel,
              uiWords.deleteDialogCancelButtonLabel
            ]}
            onClick={
              this.state.activeTab === 1
                ? this.handleExcretionDeletingDialogClick.bind(this)
                : this.handleLaxationLogsDeletingDialogClick.bind(this)
            }
            onDialogClosing={this.onCloseAppDialog.bind(this)}
          />
        </div>
      </div>
    )
  }
}

export default withRouter(IndivisualExcretionResults)
