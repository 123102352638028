/* eslint-disable */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { AxiosError } from "axios";
import request, { Token } from "./CustomAxios";
import { AsyncThunkConfig } from "../../store";
import API from "../../constants/ApiConstants";
import ValidationErrors from "../ValidationErrors";
import TAlertLog from "../Types/TAlertLog";

// get
export type GetAlertLogsState = {
  alertLogs: Array<TAlertLog>;
  message: string;
  isLoading: boolean;
};

const initialGetAlertLogs: GetAlertLogsState = {
  alertLogs: [],
  message: "",
  isLoading: false,
};
// care_subject_id=1&group_id=1,2,3&active=true&start=2017-06-01&end=2017-07-31&with_related_laxation_log=true
type GetAlertLogsParam = {
  params: {
    care_subject_id?: number;
    group_id?: number;
    active?: boolean;
    start?: string;
    end?: string;
    with_related_laxation_log?: boolean;
  };
  login: Token;
};

// edit
export type EditAlertLogsState = {
  success: boolean;
  message: string;
  isLoading: boolean;
};

const initialEditAlertLogs: EditAlertLogsState = {
  success: false,
  message: "",
  isLoading: false,
};

export type EditAlertLogsParam = {
  alertLogId: string;
  carePersonId: string;
  confirmed: string;
  leaked: boolean;
  data: { active: boolean; result: string };
  login: Token;
};

export type AlertLogsState = {
  getAlertLogs: GetAlertLogsState;
  editAlertLogs: EditAlertLogsState;
};

export const fetchGetAlertLogs = createAsyncThunk<
  Object,
  GetAlertLogsParam,
  AsyncThunkConfig<ValidationErrors>
>("AlertLogs/all", async (arg, thunkAPI) => {
  let response: any = [];
  try {
    response = await request(arg.login).get(API.Alert.All, {
      params: arg.params,
    });
  } catch (err: any) {
    let error: AxiosError<ValidationErrors> = err;
    if (!error.response) {
      throw err;
    }
    return thunkAPI.rejectWithValue(error.response.data);
  }

  return response;
});

const fetchGetAlertLogsSlice = createSlice<GetAlertLogsState, any>({
  name: "fetch/all/AlertLogs",
  initialState: initialGetAlertLogs,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(fetchGetAlertLogs.fulfilled, (state: any, action: any) => {
      console.log("AlertLogs fulfilled");
      state.alertLogs = action.payload.data.data;
      state.isLoading = false;
    });
    builder.addCase(fetchGetAlertLogs.pending, (state: any, action: any) => {
      console.log("AlertLogs pending");
      state.isLoading = true;
    });
    builder.addCase(fetchGetAlertLogs.rejected, (state: any, action: any) => {
      console.log("AlertLogs rejected");
      state.status = "error";
      state.message = action.error.message;
      state.isLoading = false;
    });
  },
});

//edit
export const fetchEditAlertLogs = createAsyncThunk<
  Object,
  EditAlertLogsParam,
  AsyncThunkConfig<ValidationErrors>
>("AlertLogs/edit", async (arg, thunkAPI) => {
  let response: any = [];
  try {
    const replaceString =
      API.Alert.Edit.replace(":id", arg.alertLogId) +
      "/?confirmed=" +
      arg.confirmed +
      "&leaked=" +
      arg.leaked;

    response = await request(arg.login).put(replaceString, arg.data);
  } catch (err: any) {
    let error: AxiosError<ValidationErrors> = err;
    if (!error.response) {
      throw err;
    }
    return thunkAPI.rejectWithValue(error.response.data);
  }

  return response;
});

const fetchEditAlertLogsSlice = createSlice<EditAlertLogsState, any>({
  name: "fetch/edit/AlertLogs",
  initialState: initialEditAlertLogs,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(fetchEditAlertLogs.fulfilled, (state: any, action: any) => {
      console.log("edit AlertLogs fulfilled");
      state.result = action.payload.data.data;
      state.isLoading = false;
    });
    builder.addCase(fetchEditAlertLogs.pending, (state: any, action: any) => {
      console.log("edit AlertLogs pending");
      state.isLoading = true;
    });
    builder.addCase(fetchEditAlertLogs.rejected, (state: any, action: any) => {
      console.log("edit AlertLogs rejected");
      state.status = "error";
      state.message = action.error.message;
      state.isLoading = false;
    });
  },
});

const reducers = combineReducers<AlertLogsState>({
  getAlertLogs: fetchGetAlertLogsSlice.reducer,
  editAlertLogs: fetchEditAlertLogsSlice.reducer,
});

export default reducers;
