/* eslint-disable */
import GroupBoxHead from '../components/GroupBoxHead'
import GroupMemberList from '../components/GroupMemberList'
import { SettingLayout } from '../components/SettingLayout'
import './SettingGroup.scss'
import * as carePeopleAction from '@/actions/CarePeopleAction'
import * as groupAction from '@/actions/GroupAction'
import AppDialog from '@/components/App/AppDialog'
import AppLoading from '@/components/App/AppLoading'
import GroupSelectField from '@/components/common/GroupSelectField'
import UserSearchField from '@/components/common/UserSearchField'
import { localizedUiWords } from '@/features/locale'
import { LocaleContext } from '@/providers/LocaleProvider'
import carePeopleStore from '@/stores/CarePeopleStore'
import errorStore from '@/stores/ErrorStore'
import groupStore from '@/stores/GroupStore'
import { Box, Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import Form from 'react-jsonschema-form'

export const SettingGroup = () => {
  const [carePeople, setCarePeople] = useState(null)
  const [groups, setGroups] = useState(null)
  const [name, setName] = useState(null)
  const [groupId, setGroupId] = useState(null)
  const [checkedGroups, setCheckedGroups] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [dialog, setDialog] = useState(false)
  const [userRemovalDialog, setUserRemovalDialog] = useState(false)
  const [removingUserId, setRemovingUserId] = useState(null)

  const [locale] = useContext(LocaleContext)
  const uiWords = localizedUiWords(locale.lang).pages.settings
  const dialogUiWords = localizedUiWords(locale.lang).components.appDialog

  // フォームの設定
  const schema1 = {
    type: 'object',
    properties: {
      name: {
        type: 'string',
        title: uiWords.newGroupInputLabel
      }
    }
  }

  const uiSchema1 = {
    name: { 'ui:placeholder': uiWords.newGroupInputPlaceholder }
  }

  const schema2 = {
    type: 'object',
    properties: {
      name: { type: 'string' }
    }
  }

  const schema3 = {
    type: 'object',
    properties: {
      group: { type: 'number' }
    }
  }

  const customFields1 = {
    userSearch: UserSearchField
  }

  const uiSchema2 = {
    name: {
      'ui:placeholder': uiWords.newGroupInputPlaceholder,
      'ui:field': 'userSearch'
    }
  }

  // グループセレクトフィールドはajax通信が必要なため外だし
  const customFields2 = {
    groupSelect: GroupSelectField
  }

  const uiSchema3 = {
    group: {
      'ui:field': 'groupSelect'
    }
  }

  useEffect(() => {
    carePeopleStore.on('people_all', (v) => {
      if (v.length > 0) {
        setCarePeople(v)
        setLoading(false)
      }
    })

    carePeopleStore.on('person_edit', (v) => {
      carePeopleAction.carePeople()
      setLoading(true)
    })

    groupStore.on('group_all', (v) => {
      setGroups(v)
      setLoading(false)
    })

    groupStore.on('group_add', (v) => {
      groupAction.groups()
      setLoading(true)
    })

    groupStore.on('group_edit', (v) => {
      groupAction.groups()
      setLoading(true)
    })

    groupStore.on('group_all_delete', (v) => {
      groupAction.groups()
    })

    groupStore.on('error', (v) => {
      setLoading(false)
    })

    errorStore.on('400_error', (v) => {
      setLoading(false)
      setError(uiWords.alreadyExistGroupErrorMessage)
    })

    carePeopleAction.carePeople()
    groupAction.groups()
    setLoading(true)
    return () => {
      carePeopleStore.removeAllListeners('people_all')
      carePeopleStore.removeAllListeners('person_edit')
      groupStore.removeAllListeners('group_all')
      groupStore.removeAllListeners('group_add')
      groupStore.removeAllListeners('group_edit')
      groupStore.removeAllListeners('group_all_delete')
      groupStore.removeAllListeners('error')
      groupStore.removeAllListeners('400_error')
    }
  }, [])

  const handleSubmit1 = (e) => {
    groupAction.addGroup(e.formData)
  }

  /**
   * 利用者追加フォームのsubmitハンドラ
   */
  const addResidentSubmitHandler = (e) => {
    const resident = carePeople.find((resident) => {
      return name === resident.name
    })

    if (resident) {
      carePeopleAction.editPerson(
        {
          group_id: groupId
        },
        resident.id
      )
      setLoading(true)
      setError('')
      return
    }

    setError(uiWords.notFoundResidentErrorMessage)
  }

  const handleChangeName = (e) => {
    if (_.keys(e.formData).length === 0 && !e.formData.name) return
    setName(e.formData.name)
  }

  const handleChangeGroupId = (e) => {
    if (_.keys(e.formData).length === 0 && !e.formData.groupId) return
    setGroupId(e.formData.group)
  }

  const removeUser = (e) => {
    setRemovingUserId(e.userId)
    setUserRemovalDialog(true)
  }

  const removeGroup = (e) => {
    // 何かを1つでも選択していたら
    if (checkedGroups.length > 0) {
      setDialog(true)
    }
  }

  // チェックが入ったグループだけを配列に格納する
  const handleClickCheckbox = (e) => {
    const newCheckedGroups = checkedGroups.slice(0, checkedGroups.length)
    // チェックがtrueならpush
    if (e.target.checked) {
      newCheckedGroups.push(e.target.getAttribute('data-groupid'))
    } else {
      // 一度チェックしていら、配列から削除
      _.pull(newCheckedGroups, e.target.getAttribute('data-groupid'))
    }
    setCheckedGroups(newCheckedGroups)
  }

  // ダイアログ内のハンドリング
  const handleDialogClick = (e) => {
    switch (e) {
      case dialogUiWords.yesButtonLabel:
        groupAction.deleteAllGroup({
          id: checkedGroups
        })
        setDialog(false)
        setLoading(true)
        break
      case dialogUiWords.noButtonLabel:
        setDialog(false)
        break
      default:
        break
    }
  }

  // グループ削除確認ダイアログクローズ時のハンドリング
  const handleDialogClosing = () => {
    setDialog(false)
  }

  // ユーザ削除確認ダイアログ内のハンドリング
  const handleUserRemovalDialogClick = (e) => {
    switch (e) {
      case dialogUiWords.yesButtonLabel:
        carePeopleAction.editPerson(
          {
            group_id: null
          },
          removingUserId
        )
        setUserRemovalDialog(false)
        setLoading(true)
        break
      case dialogUiWords.noButtonLabel:
        setUserRemovalDialog(false)
        break
      default:
        break
    }
    setRemovingUserId(null)
  }

  // ユーザ削除確認ダイアログクローズ時のハンドリング
  const handleUserRemovalDialogClosing = () => {
    setUserRemovalDialog(false)
  }

  // グループ名更新
  const handleUpdateGroupName = (e) => {
    groupAction.editGroup(e.formData, e.formData.id)
    setLoading(true)
    setError('')
  }

  let groupBox
  // carePeople/groupsのデータが読み込まれてから描画
  if (carePeople !== null && groups !== null) {
    groupBox = _.map(groups, (v, k) => {
      return (
        <Box key={v.id} className="group-box">
          <GroupBoxHead
            groupId={v.id}
            groupName={v.name}
            onClickCheckbox={handleClickCheckbox}
            onUpdate={handleUpdateGroupName}
          />
          <GroupMemberList
            groupId={v.id}
            carePeople={carePeople}
            onChange={removeUser}
          />
        </Box>
      )
    })
  }

  return (
    <SettingLayout activeTab="group">
      <Box id="setting-group-tab" className="setting-tab-content">
        <Typography variant="h1" className="setting-heading">
          {uiWords.groupTabTitle}
        </Typography>

        <Box className="create-new-group-form-container">
          <Typography variant="h2" className="setting-sub-heading">
            {uiWords.createNewGroupTitle}
          </Typography>

          <Form
            schema={schema1}
            uiSchema={uiSchema1}
            onSubmit={handleSubmit1}
            className="create-new-group-form rjsf"
          >
            <button type="submit">{uiWords.createButtonLabel}</button>
          </Form>
        </Box>

        <Box className="manage-group-member-section">
          <Typography variant="h2" className="setting-sub-heading">
            {uiWords.manageGroupMemberTitle}
          </Typography>

          <Box className="manage-group-member-form-container">
            <Grid container className="manage-group-member-form">
              {/* 利用者入力フォーム */}
              <Grid item className="input-target-resident-form">
                <p className="input-target-resident-form-label">
                  {uiWords.newResidentInputLabel}
                </p>
                <Form
                  className="add-resident-form rjsf"
                  schema={schema2}
                  fields={customFields1}
                  uiSchema={uiSchema2}
                  onChange={handleChangeName}
                >
                  <button type="submit" className="hide" />
                </Form>
              </Grid>

              {/* グループ選択フォーム */}
              <Grid item className="select-group-form-container">
                <Form
                  schema={schema3}
                  fields={customFields2}
                  uiSchema={uiSchema3}
                  onChange={handleChangeGroupId}
                  onSubmit={addResidentSubmitHandler}
                >
                  <button type="submit">{uiWords.addButtonLabel}</button>
                </Form>
              </Grid>
            </Grid>
            {error !== '' ? (
              <div className="error-message">{error}</div>
            ) : (
              <></>
            )}
          </Box>

          <Box className="group-member-box-list flex space-between flex-wrap mb30">
            {groupBox}
          </Box>

          <p className="delete-selected-groups-button" onClick={removeGroup}>
            {uiWords.deleteSelectedGroupsButton}
          </p>
        </Box>
      </Box>

      <AppLoading isActive={loading} />

      <AppDialog
        isActive={dialog}
        title={dialogUiWords.deleteMessage}
        choiceButtonLists={[
          dialogUiWords.yesButtonLabel,
          dialogUiWords.noButtonLabel
        ]}
        onClick={handleDialogClick}
        onDialogClosing={handleDialogClosing}
      />

      <AppDialog
        isActive={userRemovalDialog}
        title={dialogUiWords.deleteMessage}
        choiceButtonLists={[
          dialogUiWords.yesButtonLabel,
          dialogUiWords.noButtonLabel
        ]}
        onClick={handleUserRemovalDialogClick}
        onDialogClosing={handleUserRemovalDialogClosing}
      />
    </SettingLayout>
  )
}
