/* eslint-disable */
import { EventEmitter } from "events";
import dispatcher from "../dispatchers/Dispatcher";

class FinalDefecationTimeStore extends EventEmitter {
  handleActions(action) {
      switch (action.type) {
        case "FINAL_DEFECATION_TIME":
          this.emit("final_defecation_time",action.body);
          break;
        case "ERROR":
          let body = action.body ? action.body : "";
          this.emit("defecation_time_error",body);
          break;
        default:
          break;
      }
    }
}

const finalDefecationTimeStore = new FinalDefecationTimeStore();
dispatcher.register(finalDefecationTimeStore.handleActions.bind(finalDefecationTimeStore));

export default finalDefecationTimeStore;