import './SensorTable.scss'
import { localizedUiWords } from '@/features/locale'
import { LocaleContext } from '@/providers/LocaleProvider'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

class SensorTable extends Component {
  static contextType = LocaleContext

  constructor() {
    super()
    this.state = {
      sensors: null
    }
  }

  componentDidMount() {
    this.setState({
      sensors: this.props.sensors
    })
  }

  componentDidUpdate(prebProps) {
    if (this.props !== prebProps) {
      const state = {}
      if (this.state.sensors !== this.props.sensors) {
        state.sensors = this.props.sensors
      }

      if (Object.keys(state).length > 0) {
        this.setState(state)
      }
    }
  }

  handleDelete(e) {
    const data = {
      id: e.currentTarget.getAttribute('data-id')
    }

    this.props.onDeleteClick(data)
  }

  render() {
    const [locale] = this.context
    const uiWords = localizedUiWords(locale.lang).pages.settings

    return (
      <div className="sensor-table">
        <table>
          <tbody>
            <tr>
              <th className="sensor">{uiWords.sensorIdTableHeader}</th>
              <th className="action">{uiWords.actionTableHeader}</th>
            </tr>
          </tbody>
        </table>

        {/* eslint-disable-next-line */}
        <div ref="tableContent" className="sensor-table-content">
          <table>
            <tbody>
              {this.state.sensors === null ||
              this.state.sensors.length === 0 ? (
                <tr>
                  <td colSpan="2">{uiWords.sensorNotFoundMessage}</td>
                </tr>
              ) : (
                this.state.sensors.map((v, k) => (
                  <tr key={k} ref={v.id}>
                    <td className="sensor">{v.id}</td>
                    <td className="action">
                      <div
                        className="delete-btn"
                        data-id={v.id}
                        onClick={this.handleDelete.bind(this)}
                      >
                        <div className="icon icon-icons_delete"></div>
                        <div className="label">{uiWords.deleteButtonLabel}</div>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

SensorTable.propTypes = {
  sensors: PropTypes.array,
  onDeleteClick: PropTypes.func
}

export default SensorTable
