/* eslint-disable */
import * as Action from '../actions/CarePeopleAction'
import * as GroupAction from '../actions/GroupAction'
import AppLoading from '../components/App/AppLoading'
import GroupSelectField from '../components/common/GroupSelectField'
import UserBox from '../components/common/UserBox'
import UserSearchField from '../components/common/UserSearchField'
import carePeopleStore from '../stores/CarePeopleStore'
import facilityStore from '../stores/FacilityStore'
import groupStore from '../stores/GroupStore'
import { AppHeader } from '@/components/App/AppHeader'
import { localizedUiWords } from '@/features/locale'
import { LocaleContext } from '@/providers/LocaleProvider'
import _ from 'lodash'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class Users extends Component {
  static contextType = LocaleContext

  constructor() {
    super()
    this.state = {
      groupId: 0,
      carePeople: null,
      facilityDetail: null,
      loading: false,
      groups: null,
      sortKey: 'all'
    }
  }

  selectGroup(e) {
    let query = {
      group_id: e,
      sort: this.state.sortKey
    }
    Action.carePeopleSort(query)
    this.setState({
      groupId: e
    })
  }

  componentDidMount() {
    groupStore.on('group_all', (v) => {
      const groupIdList = v.map((g) => {
        return g.id
      })
      let peopleQuery = { sort: this.state.sortKey }
      if (groupIdList.indexOf(this.state.groupId) >= 0) {
        peopleQuery = { group_id: this.state.groupId, sort: this.state.sortKey }
      }
      Action.carePeopleSort(peopleQuery)
    })
    carePeopleStore.on('people_sort', (v) => {
      this.setState({
        carePeople: v,
        loading: false
      })
      if (this.state.sortKey === 'place') {
        this.sortPlace()
      }
    })
    facilityStore.on('facility_detail', (v) => {
      this.setState({
        facilityDetail: v
      })
    })

    const cookieData = this.getCookieData()
    this.setState({
      groupId: cookieData.groupVal,
      sortKey:
        cookieData.sortKey !== undefined
          ? cookieData.sortKey
          : this.state.sortKey
    })
    GroupAction.groups()
    if (facilityStore.getFacilityDetail() !== null) {
      this.setState({
        facilityDetail: facilityStore.getFacilityDetail()
      })
    }
  }

  componentWillUnmount() {
    carePeopleStore.removeAllListeners('people_all')
    carePeopleStore.removeAllListeners('people_sort')
    facilityStore.removeAllListeners('facility_detail')
    groupStore.removeAllListeners('group_all')
  }

  handleSearchUser(e) {
    let query = {
      group_id: this.state.groupId,
      sort: this.state.sortKey,
      keyword: e
    }
    Action.carePeopleSort(query)
    this.setState({
      loading: true
    })
  }

  date2sec(day) {
    return day * 24 * 60 * 60
  }

  setCookieSortKey(key) {
    document.cookie = `userSortKey=${key}; max-age=${this.date2sec(7)}"`
  }

  sortPlace() {
    const carePeople = JSON.parse(JSON.stringify(this.state.carePeople)).sort(
      (a, b) => {
        return a['place'] > b['place'] ? 1 : -1
      }
    )
    this.setState({
      carePeople: carePeople
    })
  }

  callSortAction(key) {
    // グループ選択状態の保持
    let query =
      this.state.groupId > 0
        ? { group_id: this.state.groupId, sort: key }
        : { sort: key }
    let isSortable = true
    switch (key) {
      case 'all':
        Action.carePeopleSort(query)
        break
      case 'kana':
        Action.carePeopleSort(query)
        break
      case 'alert':
        Action.carePeopleSort(query)
        break
      case 'place':
        this.sortPlace()
        break
      default:
        isSortable = false
        break
    }
    if (isSortable) {
      this.setState({
        sortKey: key
      })
    }
  }

  handleChangeSort(e) {
    const sortKey = e.target.value
    this.setCookieSortKey(sortKey)
    this.callSortAction(sortKey)
  }

  getCookieData() {
    const res = {}

    const cookieStr = document.cookie
    if (cookieStr !== '') {
      const cookies = cookieStr.split('; ')
      cookies.forEach((v) => {
        v = v.split('=')
        res[v[0]] = v[1]
      })
    }
    return {
      groupVal: isNaN(parseInt(res.groupValue)) ? 0 : parseInt(res.groupValue),
      sortKey: res.userSortKey
    }
  }

  // 読み込み中Dom取得
  getLoadingDom() {
    const [locale] = this.context
    const uiWords = localizedUiWords(locale.lang).pages.users
    return <div className="loadingView">{uiWords.loading}</div>
  }

  // データなしDom取得
  getNoDataDom() {
    const [locale] = this.context
    const uiWords = localizedUiWords(locale.lang).pages.users

    return (
      <div className="no-data">
        <p>{uiWords.residentsNotFoundLabel}</p>
        <Link to={'/user/edit/new'}>
          <div className="center_add_btn">
            <div>{uiWords.addResidentButtonLabel}</div>
          </div>
        </Link>
      </div>
    )
  }

  // ユーザボックス一覧Dom取得
  getUserBoxesDom() {
    let contents = _.map(this.state.carePeople, (v, k) => {
      return <UserBox key={k} carePeople={v} />
    })

    return <div className="flex flex-wrap users-box">{contents}</div>
  }

  render() {
    const [locale] = this.context
    const uiWords = localizedUiWords(locale.lang).pages.users

    // テーブル空の時のだしわけ
    let userBoxs
    if (this.state.carePeople === null) {
      // 読み込み中
      userBoxs = this.getLoadingDom()
    } else if (this.state.carePeople.length === 0) {
      // 利用者データなし
      userBoxs = this.getNoDataDom()
    } else {
      // ユーザボックス一覧
      userBoxs = this.getUserBoxesDom()
    }

    return (
      <div id="users">
        <AppHeader />
        <div className="flex">
          <div className="app_container">
            <div className="flex align-center mb20 mt20">
              <p className="title">
                {this.state.facilityDetail
                  ? this.state.facilityDetail.display_name
                  : ''}
              </p>
              <Link to={'/user/edit/new'}>
                <div className="add_btn">
                  <div className="icon icon-icons_add_person"></div>
                  <div>{uiWords.addNewResidentButtonLabel}</div>
                </div>
              </Link>
            </div>

            <div className="flex space-between">
              <div className="mb30">
                <GroupSelectField
                  onChange={this.selectGroup.bind(this)}
                  formData={this.state.groupId}
                />
              </div>

              <div className="flex">
                <div className="search-box mr20">
                  <UserSearchField
                    onKeyPress={this.handleSearchUser.bind(this)}
                    onSuggestionSelected={this.handleSearchUser.bind(this)}
                  >
                    <div className="icon icon-icons_search"></div>
                  </UserSearchField>
                </div>
                <div className="hpad-pulldown-1 mb30">
                  <select
                    value={this.state.sortKey}
                    onChange={this.handleChangeSort.bind(this)}
                  >
                    <option value="all">
                      {uiWords.registrationOrderLabel}
                    </option>
                    <option value="kana">
                      {uiWords.alphabeticalOrderLabel}
                    </option>
                    <option value="alert">
                      {uiWords.lastNotificationOrderLabel}
                    </option>
                    <option value="place">{uiWords.roomNameOrderLabel}</option>
                  </select>
                </div>
              </div>
            </div>

            {userBoxs}
          </div>
        </div>
        <AppLoading isActive={this.state.loading} />
      </div>
    )
  }
}

export default Users
