import * as AuthAction from './actions/AuthAction'
import ExcretionResults from './pages/ExcretionResults'
import IndivisualExcretionResults from './pages/IndivisualExcretionResults'
import UserDetail from './pages/UserDetail'
import UserEdit from './pages/UserEdit'
import Users from './pages/Users'
import {
  SettingAccount,
  SettingGroup,
  SettingLaxative,
  SettingPairing,
  SettingAccessibility,
  SettingSensor
} from '@/features/setting/routes'
import { App } from '@/pages/App'
import { Login } from '@/pages/Login'
import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'

const getCookies = () => {
  const res: Map<string, string> = new Map()

  const cookieStr: string = document.cookie
  if (cookieStr !== '') {
    const cookies = cookieStr.split('; ')
    cookies.forEach((v) => {
      const vv = v.split('=')
      res.set(vv[0], vv[1])
    })
  }
  return res
}

export const Routes = () => {
  const cookies = getCookies()
  const accessUrl = window.location.href
  let redirect2 = accessUrl.replace(/http[s]?:\/\/.*?\//, '/')

  if (redirect2 !== '/') {
    // ログインページに遷移する前に入力されたURLを格納
    document.cookie = 'redirect2=; max-age=0; path=/;' // リセット
    document.cookie = `redirect2=${redirect2}; path=/;`
  } else {
    redirect2 += 'home'
  }

  // ログインしていなければアクセス時のURLをcookieに保存
  if (cookies == null || cookies.get('token') == null) {
    AuthAction.logout()
    window.history.pushState(null, '', '/')
  } else {
    // 無効なtokenでログインに失敗する場合もこちらに入るので注意
    window.history.pushState(null, '', redirect2)
  }

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Login />
        </Route>

        <Route exact path="/home">
          <App />
        </Route>

        <Route exact path="/users">
          <Users />
        </Route>

        <Route exact path="/user/:id">
          <UserDetail />
        </Route>

        <Route exact path="/user/edit/:id">
          <UserEdit />
        </Route>

        <Route exact path="/setting/group">
          <SettingGroup />
        </Route>

        <Route exact path="/setting/laxative">
          <SettingLaxative />
        </Route>

        <Route exact path="/setting/sensor">
          <SettingSensor />
        </Route>

        <Route exact path="/setting/pairing">
          <SettingPairing />
        </Route>

        <Route exact path="/setting/accessibility">
          <SettingAccessibility />
        </Route>

        <Route exact path="/setting/account">
          <SettingAccount />
        </Route>

        <Route exact path="/excretionResults">
          <ExcretionResults />
        </Route>

        <Route exact path="/indivisualExcretionResults">
          <IndivisualExcretionResults />
        </Route>

        <Route render={() => <Redirect to="/home" />} />
      </Switch>
    </Router>
  )
}
