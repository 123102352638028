/**
 * EVENTに関わるシステム全体の定数定義
 */
export const EVENT = {
  action_type: {
    user_excretion_results: {
      current_date: 'CURRENT_DATE',
      care_person: 'CARE_PERSON',
      statistics: 'EXCRETION_STATISTICS',
      excretion_results: 'EXCRETION_RESULTS',
      amount_average: 'AMOUNT_AVERAGE'
    },
    excretion_setting: {
      excretion_results: 'DETAIL_EXCRETION_RESULTS',
      excretion_results_time: 'EXCRETION_RESULTS_TIME',
      alert_log: 'ALERT_LOG',
      laxation: 'LAXATION_LOGS'
    },
    delete_excretion_result: {
      excretion_result: 'DELETE_EXCRETION_RESULT',
      clear: 'CLEAR_EXCRETION_RESULT'
    },
    care_people: {
      people_all: 'people_all',
      people_group: 'people_group'
    },
    app: {
      app_loading: 'APP_LOADING'
    },
    error: {
      error_422: '422_error',
      error_500: '500_error',
      other_status_error: 'other_status_error',
      unexpected_error: 'unexpected_error'
    },
    amount_average: {
      average_list: 'AVERAGE_LIST'
    },
    laxative: {
      laxatives_all: 'laxatives_all',
      medicine_unit_master: 'medicine_unit_master'
    }
  }
}

export default EVENT
