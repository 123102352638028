import { Language } from '../types'

/**
 * 日本語環境かどうかを判定する
 *
 * 環境によってはChromeでja-JPが返ってくる場合があるので、その場合を考慮して判定する
 *
 * @param lang 言語情報
 * @returns boolean
 */
export const isJaJP = (lang: string): boolean => {
  return [Language.ja, Language.jaJP].includes(lang)
}
