/**
 * 全角は半角2文字分として、渡された文字列の文字数をカウントする
 *
 * @param s 文字数をカウントしたい文字列
 */
export const lengthConsideredCharacterWidth = (s: string): number => {
  let length = 0
  for (let i = 0; i < s.length; i++) {
    // 半角英数字記号なら1カウントアップ
    length += s[i].match(/[ -~]/) ? 1 : 2
  }
  return length
}
