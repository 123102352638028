import API from '@/constants/ApiConstants'
import dispatcher from '@/dispatchers/Dispatcher'
import request from 'superagent'

export function sensors(query) {
  request
    .get(API.Sensor.All)
    .query(query)
    .set('Accept', 'application/json')
    .end(function (err, res) {
      if (err === null) {
        const body = res.body
        dispatcher.dispatch({ type: 'SENSOR_ALL', body })
      } else {
        dispatcher.dispatch({ type: 'SENSOR_ALL_ERROR', res })
      }
    })
}

export function addSensor(data) {
  request
    .post(API.Sensor.Add)
    .set('Accept', 'application/json')
    .send(data)
    .end(function (err, res) {
      const body = res.body || null
      if (err === null) {
        dispatcher.dispatch({ type: 'SENSOR_ADD', body })
      } else {
        dispatcher.dispatch({ type: 'SENSOR_ADD_ERROR', body })
      }
    })
}

export function deleteSensor(id) {
  id = fixedEncodeURIComponent(id)
  request
    .del(API.Sensor.Delete.replace(':id', id))
    .set('Accept', 'application/json')
    .end(function (err, res) {
      const body = res.body || null
      if (err === null) {
        dispatcher.dispatch({ type: 'SENSOR_DELETE', body })
      } else {
        dispatcher.dispatch({ type: 'SENSOR_DELETE_ERROR', body })
      }
    })
}

export function editSensor(data, id) {
  id = fixedEncodeURIComponent(id)

  // 自分自身を重複チェックの対象から除外するためにセンサーIDをクエリパラメータとして渡す。
  const query = { sensor_id: id }

  request
    .put(API.Sensor.editAleartFreq.replace(':id', id))
    .send(data)
    .query(query)
    .set('Accept', 'application/json')
    .end(function (err, res) {
      const body = res.body || null
      if (err === null) {
        dispatcher.dispatch({ type: 'SENSOR_EDIT', body })
      } else {
        dispatcher.dispatch({ type: 'SENSOR_EDIT_ERROR', body })
      }
    })
}

export function sensorStatus() {
  request
    .get(API.SensorStatus.Get)
    .set('Accept', 'application/json')
    .end(function (err, res) {
      if (err === null) {
        const body = res.body
        dispatcher.dispatch({ type: 'SENSOR_STATUS', body })
      } else {
        dispatcher.dispatch({ type: 'SENSOR_STATUS_ERROR', res })
      }
    })
}

function fixedEncodeURIComponent(str) {
  return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
    return '%' + c.charCodeAt(0).toString(16)
  })
}
