import { Template } from '@/components/common/TemplateLiteral'

/**
 * UI 文言の共通管理用オブジェクト
 *
 * 画面別に管理 (キー名は、ファイル名を Lower camel case にしたもの)
 * ただし、ナビバーのような共通コンポーネントは components にて管理
 *
 * 略語にするなどして変数名に表記揺れを作らないように注意！
 * 例: Label → Lbl や Message → Msg など
 */
export const uiWords = {
  components: {
    appHeader: {
      homeTabLabel: {
        ja: 'ホーム',
        en: 'Home'
      },
      careNotesTabLabel: {
        ja: 'ケア記録',
        en: 'Care Notes'
      },
      residentsTabLabel: {
        ja: '利用者',
        en: 'Residents'
      },
      currentTimeLabel: {
        ja: '現在時刻',
        en: 'Current Time'
      },
      loginUserStatement: {
        ja: Template`${0} としてログイン中`,
        en: Template`You logged in as ${0}`
      },
      manualMenuItem: {
        ja: 'マニュアル',
        en: 'Manual'
      },
      logoutMenuItem: {
        ja: 'ログアウト',
        en: 'Logout'
      },
      webAppVersion: {
        ja: Template`web app version: ${0}`,
        en: Template`web app version: ${0}`
      }
    },
    appLoading: {
      loadingMessage: {
        ja: 'ローディング中です',
        en: 'Loading...'
      },
      justMomentMessage: {
        ja: '（しばらくお待ち下さい）',
        en: 'Just a moment...'
      },
      synchronizingAlt: {
        ja: '同期中です',
        en: 'Synchronizing'
      }
    },
    groupSelectField: {
      groupLabel: {
        ja: 'グループ',
        en: 'Group'
      }
    },
    userSearchField: {
      residentNamePlaceholder: {
        ja: '利用者名',
        en: 'Resident name'
      }
    },
    // ホーム画面とケア記録画面で利用されているケア記録入力用ダイアログ
    careNoteDialog: {
      excretionNoteTabLabel: {
        ja: '排泄記録',
        en: 'Excretion note'
      },
      laxativeNoteTabLabel: {
        ja: '下剤記録',
        en: 'Laxative note'
      },
      addNoteButtonLabel: {
        ja: '追加',
        en: 'Add'
      },
      deleteNoteButtonLabel: {
        ja: '削除',
        en: 'Delete'
      },
      cancelButtonLabel: {
        ja: 'キャンセル',
        en: 'Cancel'
      },
      saveButtonLabel: {
        ja: '保存',
        en: 'Save'
      },
      notifiedAtLabel: {
        ja: '通知日時',
        en: 'Notified at'
      },
      caredAtLabel: {
        ja: '対応日時',
        en: 'Cared at'
      },
      typeLabel: {
        ja: '種類',
        en: 'Excretion'
      },
      noneButtonLabel: {
        ja: '排泄なし',
        en: 'None'
      },
      urineButtonLabel: {
        ja: '尿',
        en: 'Urine'
      },
      fecesButtonLabel: {
        ja: '便',
        en: 'Feces'
      },
      urineAndFecesButtonLabel: {
        ja: '尿＆便',
        en: 'Urine & Feces'
      },
      urineAmountLabel: {
        ja: '尿',
        en: 'Urine'
      },
      fecesAmountLabel: {
        ja: '便',
        en: 'Feces'
      },
      spotAmountValue: {
        ja: '1: 付着',
        en: '1: Spot'
      },
      smallAmountValue: {
        ja: '2: 少',
        en: '2: Small'
      },
      middleAmountValue: {
        ja: '3: 中',
        en: '3: Middle'
      },
      largeAmountValue: {
        ja: '4: 多',
        en: '4: Large'
      },
      // ブリストルスケール
      rabitDroppingsScale: {
        ja: 'ころころ',
        en: 'Rabit Dropppings'
      },
      hardScale: {
        ja: '硬い',
        en: 'Hard'
      },
      littleHardScale: {
        ja: 'やや硬い',
        en: 'Little hard'
      },
      normalScale: {
        ja: '普通',
        en: 'Normal'
      },
      littleSoftScale: {
        ja: 'やや軟らかい',
        en: 'Little soft'
      },
      porridgeScale: {
        ja: '泥状便',
        en: 'Porridge'
      },
      diarrheaScale: {
        ja: '水様便',
        en: 'Diarrhea'
      },
      rabitDroppingsScaleDescription: {
        ja: '小塊が分離した木の実状の硬便・通過困難',
        en: 'Separate hard lumps'
      },
      hardScaleDescription: {
        ja: '小塊が融合したソーセージ状の硬便',
        en: 'Lumpy and sausage like'
      },
      littleHardScaleDescription: {
        ja: '表面に亀裂のあるソーセージ状の便',
        en: 'A sausage shape with cracks in the surface'
      },
      normalScaleDescription: {
        ja: '平滑で柔らかいソーセージ状の便',
        en: 'Like a smooth, soft sausage or snake'
      },
      littleSoftScaleDescription: {
        ja: '小塊の辺縁が鋭く切れた軟便・通過容易',
        en: 'Soft blobs with dear-cut edges'
      },
      porridgeScaleDescription: {
        ja: '不定形で辺縁不整の崩れた便',
        en: 'Mushy consistency with ragged edges'
      },
      diarrheaScaleDescription: {
        ja: '固形物を含まない水様便',
        en: 'Liquid consistency with no solid pieces'
      },
      leakageLabel: {
        ja: '漏れ出し',
        en: 'Leakage'
      },
      noLeakageAnswer: {
        ja: 'なし',
        en: 'No'
      },
      yesLeakageAnswer: {
        ja: 'あり',
        en: 'Yes'
      },
      placeLabel: {
        ja: '場所',
        en: 'Place'
      },
      diaperAnswer: {
        ja: 'おむつ',
        en: 'Diaper'
      },
      toiletAnswer: {
        ja: 'トイレ',
        en: 'Toilet'
      },
      notePlaceholder: {
        ja: '説明を追加',
        en: 'Add note'
      },
      noteLimitCharactersMessage: {
        ja: '1000文字以内で入力してください',
        en: 'No more than 1000 characters'
      },
      excretionNoteMaxDialogMessage: {
        ja: '1時間以内に登録できる排泄記録は5件までです。',
        en: 'Up to 5 excretion notes can be added within an hour.'
      },
      excretionNoteMaxDialogOkButtonLabel: {
        ja: 'OK',
        en: 'OK'
      },
      // 下剤記録タブ
      administeredAtLabel: {
        ja: '投与日時',
        en: 'Time'
      },
      laxativeNameLabel: {
        ja: '下剤名',
        en: 'Laxative'
      },
      laxativeAmountLabel: {
        ja: '投与量',
        en: 'Amount'
      },
      laxativeNoteMaxDialogMessage: {
        ja: '1時間以内に登録できる下剤記録は5件までです。',
        en: 'Up to 5 laxative notes can be added within an hour.'
      },
      laxativeNoteMaxDialogOkButtonLabel: {
        ja: 'OK',
        en: 'OK'
      }
    },
    // TODO: pagesにAppDialogの文言が散ってしまっているのでここに集約
    appDialog: {
      deleteMessage: {
        ja: '本当に削除しますか？',
        en: 'Are you sure to delete this?'
      },
      quitEditingMessage: {
        ja: '編集中のデータがあります。破棄してもよろしいですか？',
        en: 'Quit Editing? Changes you made so far will not be saved.'
      },
      okButtonLabel: {
        ja: 'OK',
        en: 'OK'
      },
      yesButtonLabel: {
        ja: 'はい',
        en: 'Yes'
      },
      noButtonLabel: {
        ja: 'いいえ',
        en: 'No'
      },
      cancelButtonLabel: {
        ja: 'キャンセル',
        en: 'Cancel'
      },
      closeButtonLabel: {
        ja: '閉じる',
        en: 'Close'
      }
    },
    menuDrawer: {
      homeMenuLabel: {
        ja: 'ホーム',
        en: 'Home'
      },
      careNotesMenuLabel: {
        ja: 'ケア記録',
        en: 'Care Notes'
      },
      residentsMenuLabel: {
        ja: '利用者',
        en: 'Residents'
      },
      settingsMenuLabel: {
        ja: '設定',
        en: 'Settings'
      },
      manualMenuLabel: {
        ja: 'マニュアル',
        en: 'Manual'
      },
      logoutMenuLabel: {
        ja: 'ログアウト',
        en: 'Logout'
      }
    }
  },
  pages: {
    login: {
      title: {
        ja: 'ログイン',
        en: 'Login'
      },
      usernamePlaceholder: {
        ja: 'ユーザーネーム',
        en: 'Username'
      },
      passwordPlaceholder: {
        ja: 'パスワード',
        en: 'Password'
      },
      loginButtonLabel: {
        ja: 'ログイン',
        en: 'Login'
      },
      failedLoginErrorMessage: {
        ja: [
          'ログインに失敗しました',
          'ユーザーネーム、パスワードを確認し',
          'もう一度やり直してください'
        ],
        en: [
          'Login failed.',
          'Invalid username or password.',
          'Please try again.'
        ]
      }
    },
    app: {
      careSupportRequiredLabel: {
        ja: '対応待ち',
        en: 'Care support required'
      },
      careSupportRequiredResidentUnit: {
        ja: '人',
        en: 'residents'
      },
      showResidentNameCheckboxLabel: {
        ja: '利用者の氏名を表示する',
        en: 'Show resident name'
      },
      emergencyNotificationLabel: {
        ja: '最優先',
        en: 'Emergency'
      },
      forecastedNotificationLabel: {
        ja: '排泄予報',
        en: 'Forecasted'
      },
      priorityNotificationLabel: {
        ja: '優先',
        en: 'Priority'
      },
      normalNotificationLabel: {
        ja: '通常',
        en: 'Normal'
      },
      emergencyNotificationTooltip: {
        ja: '下剤投与の記録をしたおむつ漏れリスクのとても高い方の通知です。',
        en: 'Excretion notification of a resident with laxative notes who has very high diaper leakage risk.'
      },
      forecastedNotificationTooltip: {
        ja: '排泄予報の時間になったことの通知です。',
        en: 'Excretion notification of forecasted time.'
      },
      priorityNotificationTooltip: {
        ja: '3日以上便秘 または 検知から3時間以上経過した、おむつ漏れリスクの高い方の通知です。',
        en: 'Notification of constipation for more than 3 days or that 3 hours has passed since high probability of diaper leakage was known.'
      },
      normalNotificationTooltip: {
        ja: 'おむつ漏れリスクが少ない通知です。',
        en: 'Excretion notification with low risk for diaper leakage.'
      },
      showEmergencyDropdownItem: {
        ja: '最優先の通知を表示',
        en: 'Show emergency notifications'
      },
      showEmergencyAndPriorityDropdownItem: {
        ja: '最優先と優先の通知を表示',
        en: 'Show emergency and priority notifications'
      },
      showAllDropdownItem: {
        ja: '全ての通知を表示',
        en: 'Show all notifications'
      },
      notificationFilterDropdownTooltip: {
        ja: ['表示する通知を選択できます', '※排泄予報は常に表示します'],
        en: [
          'You can select the notifications you want to show.',
          '* Notification of excretion forecast is always showed.'
        ]
      },
      noteRequestMessage: {
        ja: '通知をクリックして何が出ていたかを記録しましょう！',
        en: 'Click a notification and note what did you find in diaper!'
      },
      userColumnLabel: {
        ja: '部屋 / 利用者',
        en: 'Room / Resident'
      },
      notifiedAtColumnLabel: {
        ja: '通知時刻',
        en: 'When'
      },
      noNotificationMessage: {
        ja: '現在、対応が必要な利用者はいません。',
        en: 'No notifications'
      },
      loadingMessage: {
        ja: '読み込み中',
        en: 'Loading'
      },
      residentNotFoundMessage: {
        ja: '利用者データがありません',
        en: 'Residents not found'
      },
      addResidentButtonLabel: {
        ja: '利用者を追加',
        en: 'Add a resident'
      },
      hardwareErrorMessage: {
        ja: [
          'コンセントを抜き挿ししてください。',
          'このエラーが解消しない場合は',
          'お問い合わせください。'
        ],
        en: [
          'Unplug and plug the outlet.',
          'If you cannot solve this error,',
          'please contact us.'
        ]
      },
      gyroErrorMessage: {
        ja: ['センサーが傾いています。', 'センサーを水平に設置してください。'],
        en: ['Sensor is tilted.', 'Please put the sensor horizontally.']
      },
      floodErrorMessage: {
        ja: [
          'センサー内のタンクに',
          '浸水している可能性があります。',
          'タンクとチューブを確認してください。'
        ],
        en: [
          'The tank in the sensor may be flooded.',
          'Please check tank and tube.'
        ]
      },
      noSignalErrorMessage: {
        ja: [
          'センサーから電波が届いていません。',
          'センサーのコンセントを挿してください。'
        ],
        en: [
          'No signal is received from the sensor.',
          'Please plug in the sensor.'
        ]
      },
      notPairingErrorMessage: {
        ja: ['排泄通知の利用には', 'ペアリング', 'が必要です。'],
        en: ['Pairing', ' is required', 'to use excretion notification.']
      },
      minutesBeforeLabel: {
        ja: Template`${0}分前`,
        en: Template`${0} minutes before`
      },
      hoursBeforeLabel: {
        ja: Template`${0}時間前`,
        en: Template`${0} hours before`
      },
      hoursPassedLabel: {
        ja: Template`${0}時間経過`,
        en: Template`${0} hours passed`
      },
      daysPassedLabel: {
        ja: Template`${0}日経過`,
        en: Template`${0} days passed`
      },
      greenForecastNotificationTooltip: {
        ja: '排泄予報の時間になるとこのアイコンが表示され、通知が緑色になります。',
        en: 'When it is time for the excretion forecast, this icon appears and the notification turns green.'
      },
      yellow72HoursPassedNotificationTooltip: {
        ja: '最後の排便記録から72時間以上経過していると、このアイコンが表示され、通知が黄色になります。',
        en: 'If more than 72 hours have passed since the last feces, this icon shows and the notification turns yellow.'
      },
      yellow3HoursPassedNotificationTooltip: {
        ja: '排泄通知から3時間以上経過していると、このアイコンが表示され、通知が黄色になります。',
        en: 'If more than 3 hours have passed since the last notification, this icon shows and the notification turns yellow.'
      },
      redLaxativeNotificationTooltip: {
        ja: Template`下剤記録後、最初の排便から ${0} 時間の間、このアイコンが表示され、通知が赤色になります。`,
        en: Template`This icon will appear for ${0} hours after the first excretion after laxative recording and the notification will be red.`
      }
    },
    excretionResults: {
      explanation: {
        ja: [
          '排泄記録の確認や入力をするには、',
          '表の中のマークや空いているところをクリックしてください。'
        ],
        en: [
          'To check or input a care note,',
          'please click an icon or a blank.'
        ]
      },
      noneColorLabel: {
        ja: '何もなかった',
        en: 'None'
      },
      urineColorLabel: {
        ja: '尿',
        en: 'Urine'
      },
      diarrheaColorLabel: {
        ja: '水様便',
        en: 'Diarrhea'
      },
      fecesColorLabel: {
        ja: 'その他の便',
        en: 'Feces'
      },
      laxativeColorLabel: {
        ja: '下剤記録',
        en: 'Laxative'
      },
      todayButtonLabel: {
        ja: '今日',
        en: 'Today'
      },
      dayOfWeeks: {
        ja: ['日', '月', '火', '水', '木', '金', '土'],
        en: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
      },
      lastFecesLabel: {
        ja: '最終排便',
        en: 'Last Feces'
      },
      hoursAgoLabel: {
        ja: '時間前',
        en: 'hours ago'
      },
      daysAgoLabel: {
        ja: '時間前',
        en: 'days ago'
      },
      noRecordsLabel: {
        ja: '記録なし',
        en: 'No records'
      },
      laxativeFollowupNotice: {
        ja: ['下剤記録後', 'の経過観察'],
        en: ['Laxative', 'follow-up']
      },
      residentNotFoundTitle: {
        ja: '利用者データがありません',
        en: 'Resident not found'
      },
      addNewResidentButtonLabel: {
        ja: '利用者を追加する',
        en: 'Add new resident'
      },
      livingResidentNotFoundTitle: {
        ja: '入居中の利用者がいません',
        en: 'Living resident not found'
      },
      deleteExcretionRecordDialog: {
        ja: 'この排泄記録を削除してよろしいですか？（この操作は元に戻せません）',
        en: 'Are you sure want to permanently delete this excretion record?'
      },
      deleteLaxativeRecordDialog: {
        ja: 'この下剤記録を削除してよろしいですか？（この操作は元に戻せません）',
        en: 'Are you sure want to permanently delete this laxative record?'
      },
      deleteDialogOkButtonLabel: {
        ja: 'OK',
        en: 'OK'
      },
      deleteDialogCancelButtonLabel: {
        ja: 'キャンセル',
        en: 'Cancel'
      },
      timelineOptionFormatMajorLabelsMinute: {
        ja: 'YYYY/M/D (ddd)',
        en: 'ddd, M/D/YYYY'
      },
      timelineOptionFormatMajorLabelsHour: {
        ja: 'YYYY/M/D (ddd)',
        en: 'ddd, M/D/YYYY'
      },
      // 利用者別1ヶ月ビュー
      backButtonLabel: {
        ja: '戻る',
        en: 'Back'
      },
      showAverageAmountOptionTemplate: {
        ja: Template`${0}時間ごとの量平均を表示`,
        en: Template`Average amount per ${0} hour`
      },
      sortByDateDescOption: {
        ja: '日付 新しい順',
        en: 'Date (descending)'
      },
      sortByDateAscOption: {
        ja: '日付 古い順',
        en: 'Date (ascending)'
      },
      noMissingNoLeakageSummary: {
        ja: Template`この日までの31日間で${0}様は、空振りもおむつ漏れもありませんでした。`,
        en: Template`31 days so far, missing and leakage did not happen to Mr./Ms. ${0}.`
      },
      leakageSummary: {
        ja: Template`この日までの31日間で${0}様は、空振りはなく、${1} 回のおむつ漏れがありました。`,
        en: Template`31 days so far, missing did not happen to Mr./Ms. ${0}, but leakage happend ${1} times.`
      },
      missingSummary: {
        ja: Template`この日までの31日間で${0}様は、${1} 回の空振りがあり、おむつ漏れはありませんでした。`,
        en: Template`31 days so far, leakage did not happen to Mr./Ms. ${0}, but missing happend ${1} times.`
      },
      missingLeakageSummary: {
        ja: Template`この日までの31日間で${0}様は、${0}回の空振りと${1}回のおむつ漏れがありました。`,
        en: Template`31 days so far, ${1} times missing and ${2} times leakage happened to Mr./Ms. ${0}.`
      },
      averageHeaderLabel: {
        ja: '平均',
        en: 'Average'
      },
      totalHeaderLabel: {
        ja: '合計',
        en: 'Total'
      },
      leakageCountLabel: {
        ja: 'おむつ漏れ',
        en: 'Leakage'
      },
      averageExcretionAmountSelectLabel: {
        ja: '平均排泄量の表示方法',
        en: 'Average amount'
      },
      sortSelectLabel: {
        ja: '並び替え',
        en: 'Sort by'
      }
    },
    users: {
      // UserBox
      maleLabel: {
        ja: '男性',
        en: 'Male'
      },
      femaleLabel: {
        ja: '女性',
        en: 'Female'
      },
      detailsLabel: {
        ja: '詳細',
        en: 'Details'
      },
      noDataLabel: {
        ja: 'データ無し',
        en: 'No data'
      },
      // users
      addNewResidentButtonLabel: {
        ja: '新規追加',
        en: 'Add new resident'
      },
      registrationOrderLabel: {
        ja: '登録順',
        en: 'Registration order'
      },
      alphabeticalOrderLabel: {
        ja: '五十音順',
        en: 'Alphabetical order'
      },
      lastNotificationOrderLabel: {
        ja: '通知順',
        en: 'Last notification order'
      },
      roomNameOrderLabel: {
        ja: '部屋順',
        en: 'Room name order'
      },
      residentsNotFoundLabel: {
        ja: '利用者データがありません',
        en: 'Residents not found'
      },
      addResidentButtonLabel: {
        ja: '利用者を追加する',
        en: 'Add a resident'
      },
      loadingLabel: {
        ja: '読み込み中',
        en: 'Loading...'
      }
    },
    userEdit: {
      editLabel: {
        ja: '編集',
        en: 'Edit'
      },
      addNewResidentLabel: {
        ja: '新規追加',
        en: 'Add new resident'
      },
      fullNameLabel: {
        ja: '氏名',
        en: 'Full name'
      },
      furiganaLabel: {
        ja: 'ふりがな',
        en: 'Furigana'
      },
      roomNameLabel: {
        ja: '部屋名',
        en: 'Room name'
      },
      birthDateLabel: {
        ja: '生年月日',
        en: 'Birth date'
      },
      genderLabel: {
        ja: '性別',
        en: 'Gender'
      },
      groupLabel: {
        ja: 'グループ',
        en: 'Group'
      },
      createGroupLabel: {
        ja: 'グループを作成する',
        en: 'Create new group'
      },
      statusLabel: {
        ja: 'ステータス',
        en: 'Status'
      },
      maleLabel: {
        ja: '男性',
        en: 'Male'
      },
      femaleLabel: {
        ja: '女性',
        en: 'Female'
      },
      livingLabel: {
        ja: '入居中',
        en: 'Living'
      },
      hospitalizedLabel: {
        ja: '入院中',
        en: 'Hospitalized'
      },
      outingLabel: {
        ja: '外泊中',
        en: 'Outing'
      },
      saveButtonLabel: {
        ja: 'この内容で保存する',
        en: 'Save'
      },
      inputCharacterLimitErrorMessage: {
        ja: Template`全角で${0}文字、半角で${1}文字までで入力してください。`,
        en: Template`No more than ${1} characters.`
      },
      futureDateHasBeenEnteredErrorMessage: {
        ja: '未来の日付が入力されています。',
        en: 'A future date has been entered.'
      },
      statusCannotBeChangedErrorMessage: {
        ja: '送信機ペアリングを外さないとステータスを変更できません。',
        en: 'Status cannot be changed without removing transmitter pairing.'
      },

      // AddGroupDialog コンポーネント
      createNewGroupLabel: {
        ja: 'グループ新規作成',
        en: 'Create new Group'
      },
      addGroupNameLabel: {
        ja: 'グループを追加する',
        en: 'Add group name'
      },
      newGroupNamePlaceholder: {
        ja: '新規グループ名',
        en: 'New group name'
      },
      createButtonLabel: {
        ja: '追加',
        en: 'Create'
      },
      groupListLabel: {
        ja: '作成済みグループ一覧',
        en: 'Group list'
      },
      thereIsNoGroup: {
        ja: 'グループがありません。',
        en: 'There is no group.'
      },
      groupAlreadyExistsErrorMessage: {
        ja: '既に存在しているグループです。',
        en: 'This is a group that already exists.'
      }
    },
    userDetail: {
      title: {
        ja: '利用者情報',
        en: 'Resident'
      },
      userTableGroupLabel: {
        ja: 'グループ',
        en: 'Group'
      },
      userTableNoGroupValue: {
        ja: 'グループなし',
        en: 'No affiliation'
      },
      userTableNoDataMessage: {
        ja: 'データなし',
        en: 'No data'
      },
      userTableRoomLabel: {
        ja: '部屋',
        en: 'Room'
      },
      editButtonLabel: {
        ja: '編集',
        en: 'Edit'
      },
      removeButtonLabel: {
        ja: '削除',
        en: 'Remove'
      },
      excretionTrendGraphHeading: {
        ja: '排泄傾向グラフ',
        en: 'Excretion trend graph'
      },
      excretionTrendGraphAnnotation: {
        ja: '※ 最大5年間の期間を指定して検索できます。',
        en: '* Select a specific period of up to 5 years.'
      },
      modeDropdownLabel: {
        ja: 'モード',
        en: 'mode'
      },
      histogramAlertLogModeOption: {
        ja: '排泄パターンと排泄予報の計算',
        en: 'Excretion pattern and forecast'
      },
      histogramExcretionResultModeOption: {
        ja: '排泄ケアの実績確認',
        en: 'Excretion care records'
      },
      histogramCheckForecastModeOption: {
        ja: '登録済み排泄予報の確認',
        en: 'Registerd excretion forecast'
      },
      histogramPeriodSelectorLabel: {
        ja: '期間',
        en: 'Period'
      },
      histogramPeriodSelectorButtonLabel: {
        ja: '検索',
        en: 'Select'
      },
      invalidPeriodErrorMessage: {
        ja: '無効な期間です。再度入力してください。',
        en: 'Invalid period. Please, select again.'
      },
      over5YearsPeriodErrorMessage: {
        ja: '期間が5年を超えています。再度入力してください。',
        en: 'The selected period is over 5 years. Please, select again.'
      },
      futurePeriodErrorMessage: {
        ja: '未来の期間を含んでいます。再度入力してください。',
        en: 'The selected period includes future dates. Please, select again.'
      },
      lastWeekButtonLabel: {
        ja: '前週',
        en: 'Last week'
      },
      nextWeekButtonLabel: {
        ja: '翌週',
        en: 'Next week'
      },
      syncPeriodBelowButtonLabel: {
        ja: '下のグラフと同じ期間にする',
        en: 'Sync the period with the graph below'
      },
      syncPeriodAboveButtonLabel: {
        ja: '上のグラフと同じ期間にする',
        en: 'Sync the period with the graph below'
      },
      forecastSettingsToggleLabel: {
        ja: '排泄予報の設定',
        en: 'Excretion forecast settings'
      },
      forecastStartTimeLabel: {
        ja: '開始',
        en: 'Start'
      },
      forecastEndTimeLabel: {
        ja: '終了',
        en: 'End'
      },
      timeUnitLabel: {
        ja: '時',
        en: ':00'
      },
      diaperChangesAtStartCheckboxLabel: {
        ja: '開始時におむつ交換する',
        en: 'Change a diaper at start time'
      },
      diaperChangesAtEndCheckboxLabel: {
        ja: '終了時におむつ交換する',
        en: 'Change a diaper at end time'
      },
      diaperChangesThresholdLabel: {
        ja: '交換回数',
        en: 'Diaper changes'
      },
      diaperChangesTimesUnitLabel: {
        ja: '回',
        en: 'times'
      },
      forecastCalculateButtonLabel: {
        ja: '計算',
        en: 'Calculate'
      },
      forecastRegisterButtonLabel: {
        ja: '排泄予報に登録',
        en: 'Register'
      },
      histogramXAxisUnitLabel: {
        ja: '(時)',
        en: '(Hr)'
      },
      histogramYAxisUnitLabel: {
        ja: '(回)',
        en: '(Times)'
      },
      histogramUrineLegend: {
        ja: '尿',
        en: 'Urine'
      },
      histogramUrineAndFecesLegend: {
        ja: '尿&便',
        en: 'Urine & Feces'
      },
      histogramFecesLegend: {
        ja: '便',
        en: 'Feces'
      },
      histogramNoneLegend: {
        ja: 'なし',
        en: 'None'
      },
      noteTitle: {
        ja: 'カルテ (自由記述欄)',
        en: 'Note'
      },
      noteSaveButtonLabel: {
        ja: '保存',
        en: 'Save'
      },
      registeredForecastDialogMessage: {
        ja: '排泄予報を登録しました。',
        en: 'Excretion forecast is registered'
      },
      failedRegisterForecastDialogMessage: {
        ja: '排泄予報の登録に失敗しました。',
        en: 'Excretion forecast registration failed.'
      },
      histogramConfirmedBarLabel: {
        ja: '件数',
        en: 'Number of data'
      },
      histogramUnconfirmedBarLabel: {
        ja: '未確認',
        en: 'Unconfirmed'
      },
      histogramUrineBarLabel: {
        ja: '尿',
        en: 'Urine'
      },
      histogramFecesBarLabel: {
        ja: '便',
        en: 'Feces'
      },
      histogramUrineAndFecesBarLabel: {
        ja: '尿＆便',
        en: 'Urine & Feces'
      },
      histogramNoneBarLabel: {
        ja: 'なし',
        en: 'None'
      }
    },
    settings: {
      groupTabLabel: {
        ja: 'グループ',
        en: 'Group'
      },
      laxativeTabLabel: {
        ja: '下剤',
        en: 'Laxative'
      },
      sensorTabLabel: {
        ja: '送信機',
        en: 'Sensor'
      },
      pairingTabLabel: {
        ja: 'ペアリング',
        en: 'Pairing'
      },
      accessibilityTabLabel: {
        ja: 'アクセシビリティ',
        en: 'Accessibility'
      },
      accountTabLabel: {
        ja: 'アカウント',
        en: 'Account'
      },
      // 共通
      updateButtonLabel: {
        ja: '更新',
        en: 'Update'
      },
      addButtonLabel: {
        ja: '追加',
        en: 'Add'
      },
      registerButtonLabel: {
        ja: '登録',
        en: 'Register'
      },
      createButtonLabel: {
        ja: '作成',
        en: 'Create'
      },
      deleteButtonLabel: {
        ja: '削除',
        en: 'Delete'
      },
      cancelButtonLabel: {
        ja: 'キャンセル',
        en: 'Cancel'
      },

      // グループ設定タブ
      groupTabTitle: {
        ja: 'グループ設定',
        en: 'Group Setting'
      },
      createNewGroupTitle: {
        ja: '新規グループ作成',
        en: 'Create new group'
      },
      manageGroupMemberTitle: {
        ja: 'グループへの利用者追加',
        en: 'Manage group member'
      },
      newGroupInputLabel: {
        ja: '新規グループ名',
        en: 'New group name'
      },
      newGroupInputPlaceholder: {
        ja: '新規グループ名',
        en: 'New group name'
      },
      newResidentInputLabel: {
        ja: '利用者の追加',
        en: 'Add a resident'
      },
      groupNamePlaceholder: {
        ja: 'グループ名',
        en: 'Group name'
      },
      deleteSelectedGroupsButton: {
        ja: '選択したグループを削除',
        en: 'Delete selected groups'
      },
      notFoundResidentErrorMessage: {
        ja: '指定した利用者が見つかりませんでした',
        en: 'Not found selected resident'
      },
      alreadyExistGroupErrorMessage: {
        ja: 'すでに存在しているグループ名です',
        en: 'This group already exists'
      },

      // 下剤設定タブ
      laxativeTabTitle: {
        ja: '下剤設定',
        en: 'Laxative Setting'
      },
      durationRaisingPriorityTitle: {
        ja: '通知優先度を引き上げる時間',
        en: 'Duration raising priority of notification'
      },
      durationRaisingPriorityLabel: {
        ja: '時間',
        en: 'Duration'
      },
      durationRaisingPriorityDescription: {
        ja: '下剤記録後、最初の排便記録を作成してから指定の時間の間、排泄通知は赤色で強調表示されます。',
        en: 'Excretion notification is highlighted in red for specific hours after first excretion since laxative administration.'
      },
      hoursUnit: {
        ja: '時間',
        en: 'hours'
      },
      addLaxativeTitle: {
        ja: '下剤追加',
        en: 'Register laxative'
      },
      registerLaxativeDescription: {
        ja: '下剤記録で利用する下剤を登録できます。',
        en: 'You can register the laxative to be used in laxative note.'
      },
      laxativeNameLabel: {
        ja: '下剤名',
        en: 'Laxative name'
      },
      registeredLaxativesLabel: {
        ja: '登録済み下剤',
        en: 'Registered laxatives'
      },
      overLimitLaxativeErrorMessage: {
        ja: '下剤の登録は20個までです',
        en: 'The maximum number of laxatives registered is 20'
      },
      overLimitLaxativeNameErrorMessage: {
        ja: '全角で10文字、半角で20文字までです。',
        en: 'Max length of laxative name is 20 characters.'
      },
      alreadyRegisteredLaxativeErrorMessage: {
        ja: '既に登録されている下剤名です。',
        en: 'This laxative is already registered.'
      },

      // 送信機設定タブ
      sensorTabTitle: {
        ja: '送信機設定',
        en: 'Sensor Setting'
      },
      sensorIdInputLabel: {
        ja: '送信機ID',
        en: 'Sensor ID'
      },
      sensorIdInputPlaceholder: {
        ja: '新規送信機ID',
        en: 'New sensor ID'
      },
      // 送信機設定、ペアリング設定画面で利用
      sensorIdTableHeader: {
        ja: '送信機ID',
        en: 'Sensor ID'
      },
      actionTableHeader: {
        ja: '操作',
        en: 'Action'
      },
      sensorNotFoundMessage: {
        ja: '登録済み送信機が見つかりませんでした',
        en: 'Registered sensor not found'
      },
      otherFacilityUsingSensorErrorMessage: {
        ja: '既に他の施設で使用されている送信機IDです。',
        en: 'This sensor ID has been used in other facility.'
      },
      // 送信機設定、ペアリング設定画面で利用
      alreadyRegisteredSensorErrorMessage: {
        ja: '既に登録されている送信機IDです。',
        en: 'This sensor ID is already registered.'
      },

      // ペアリング設定画面
      pairingTabTitle: {
        ja: 'ペアリング設定',
        en: 'Pairing Setting'
      },
      roomTableHeader: {
        ja: '部屋',
        en: 'Room'
      },
      residentTableHeader: {
        ja: '利用者',
        en: 'Resident'
      },
      pairingTableHeader: {
        ja: 'ペアリング',
        en: 'Pairing'
      },
      loadingMessage: {
        ja: '読み込み中',
        en: 'Loading'
      },
      noDataMessage: {
        ja: 'データがありません',
        en: 'No data'
      },
      leaveEnsureMessage: {
        ja: '変更が保存されていないものがあります。移動してもよろしいですか？',
        en: 'Some changes are not saved. Are you sure you want to leave?'
      },

      // アクセシビリティ設定画面
      accessibilityTabHeading: {
        ja: 'アクセシビリティ設定',
        en: 'Accessibility Setting'
      },
      languageSettingSubHeading: {
        ja: '表示言語',
        en: 'Language'
      },
      languageSettingDescription: {
        ja: 'Helppad Web アプリで使用する言語を選択します。',
        en: 'Change the language used in the user interface.'
      },
      languageSelectOptionJaJP: {
        ja: '日本語',
        en: '日本語'
      },
      languageSelectOptionEnUS: {
        ja: 'English (US)',
        en: 'English (US)'
      },

      // アカウント設定画面
      accountTabTitle: {
        ja: 'アカウント設定',
        en: 'Account Setting'
      },
      invalidCharacterErrorMessage: {
        ja: '無効な文字が含まれています',
        en: 'Invalid character is found'
      },
      overLimitDisplayNameErrorMessage: {
        ja: '全角で30文字、半角で60文字までです',
        en: 'Max length of display name is 60 characters.'
      },
      overLimitKanaErrorMessage: {
        ja: '全角で60文字、半角で120文字までです',
        // 英語ではカナ項目自体を表示しないため
        en: ''
      },
      overLimitAddressErrorMessage: {
        ja: '全角で50文字、半角で100文字までです',
        en: 'Max length of display name is 100 characters.'
      },
      overLimitEmailErrorMessage: {
        ja: '半角で50文字までです',
        en: 'Max length of email address is 50 characters.'
      },
      overLimitPasswordErrorMessage: {
        ja: '半角で20文字までです',
        en: 'Max length of password is 20 characters.'
      },
      invalidTelErrorMessage: {
        ja: '電話番号の形式が不正です',
        en: 'Invalid telephone number'
      },
      invalidPostalErrorMessage: {
        ja: '郵便番号の形式が不正です',
        en: 'Invalid postal code'
      },
      inputFullWidthErrorMessage: {
        ja: '半角で入力してください',
        en: 'Please, input half-width characters'
      },
      confirmNewPasswordErrorMessage: {
        ja: '確認用パスワードが新しいパスワードと一致しません',
        en: 'Failed to confirm new password'
      },
      changedPasswordMessage: {
        ja: 'パスワードを変更しました',
        en: 'Changed password'
      },
      usernameInputLabel: {
        ja: 'ユーザーネーム',
        en: 'Username'
      },
      displayNameInputLabel: {
        ja: '表示名',
        en: 'Display name'
      },
      kanaInputLabel: {
        ja: 'ふりがな',
        // 英語ではカナ項目自体を表示しないため
        en: ''
      },
      telInputLabel: {
        ja: '電話番号',
        en: 'Telephone number'
      },
      postalInputLabel: {
        ja: '郵便番号',
        en: 'Postal code'
      },
      addressInputLabel: {
        ja: '住所',
        en: 'Address'
      },
      mailInputLabel: {
        ja: 'メールアドレス',
        en: 'Mail address'
      },
      newPasswordInputLabel: {
        ja: '新しいパスワード',
        en: 'New password'
      },
      confirmPasswordInputLabel: {
        ja: '確認用パスワード',
        en: 'Confirm password'
      },
      saveButtonLabel: {
        ja: '保存',
        en: 'Save'
      },
      autocompleteAddressButtonLabel: {
        ja: '郵便番号から自動入力する',
        en: 'Autocomplete address'
      }
    }
  }
}
