/* eslint-disable */
import './CareNoteDialog.scss'
import { FirmnessField } from './FirmnessField'
import * as excretionResultAction from '@/actions/ExcretionResultAction'
import * as laxationLogsAction from '@/actions/LaxationLogsAction'
import AppDialog from '@/components/App/AppDialog'
import AppLoading from '@/components/App/AppLoading'
import DatetimeField from '@/components/common/DatetimeField'
import ListBoxField from '@/components/common/ListBoxField'
import UserSearchField from '@/components/common/UserSearchField'
import { localizedUiWords } from '@/features/locale'
import { getUtcOffsetAtHour, getDiffLocalTimeAndJST } from '@/features/locale'
import { LocaleContext } from '@/providers/LocaleProvider'
import carePeopleStore from '@/stores/CarePeopleStore'
import excretionResultStore from '@/stores/ExcretionResultStore'
import laxationLogsStore from '@/stores/LaxationLogsStore'
import _ from 'lodash'
import * as moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Form from 'react-jsonschema-form'

export class CareNoteDialog extends Component {
  static contextType = LocaleContext

  _isMounted = false

  constructor() {
    super()
    this.state = {
      convertedExcretionDetailDatas: [],
      convertedLaxationLogsDetailDatas: [],
      isActive: false,
      excretionCurrentIndex: 0,
      laxationLogsCurrentIndex: 0,
      excretionDataSelectTab: 1,
      laxationLogsDataSelectTab: 1,
      errors: {},
      loading: false,
      addErrorDialog: false,
      confirm: false,
      isRegisterCompleted: {
        excretionResults: false,
        laxationLogs: false
      }
    }

    this.submitButtonRef = React.createRef()
  }

  disableSubmitButton() {
    if (
      this.submitButtonRef.current === null ||
      this.submitButtonRef.current === undefined
    ) {
      return
    }
    this.submitButtonRef.current.setAttribute('disabled', 'disabled')
  }

  enableSubmitButton() {
    if (
      this.submitButtonRef.current === null ||
      this.submitButtonRef.current === undefined
    ) {
      return
    }
    this.submitButtonRef.current.removeAttribute('disabled')
  }

  componentDidMount() {
    this._isMounted = true

    carePeopleStore.on('people_all', (v) => {
      if (!this._isMounted) return
      if (v.length > 0) {
        this.setState({
          carePeople: v
        })
      }
    })

    carePeopleStore.on('person_detail', (v) => {
      if (!this._isMounted) return
      this.setState({
        name: v.name,
        loading: false,
        isActive: true
      })
    })

    // eslint-disable-next-line
    excretionResultStore.on('excretion_result_add', (v) => {
      if (!this._isMounted) {
        return
      }

      if (this.props.where === 'home') {
        // 「ホーム」画面の通知からの排泄記録時に必要な API 呼び出しは handleSubmit() で実行している
      } else {
        // this.props.onComplete(v);
      }
    })

    // eslint-disable-next-line
    excretionResultStore.on('excretion_result_edit', (v) => {
      // this.props.onComplete(v);
    })

    // eslint-disable-next-line
    excretionResultStore.on('excretion_result_register', (v) => {
      if (!this._isMounted) return
      this.checkRegisterCompleted('excretionResults')
    })

    // eslint-disable-next-line
    excretionResultStore.on('excretion_result_delete', (v) => {
      // ここで更新かければよい？
    })

    // eslint-disable-next-line
    excretionResultStore.on('excretion_result_error', (v) => {})

    // eslint-disable-next-line
    laxationLogsStore.on('laxation_logs_register', (v) => {
      if (!this._isMounted) return
      this.checkRegisterCompleted('laxationLogs')
    })

    // eslint-disable-next-line
    excretionResultStore.on('laxation_logs_error', (v) => {
      if (!this._isMounted) return
      this.setState({
        loading: false
      })
    })

    if (!this._isMounted) return
    if (
      (this.props.isActive && this.props.excretionResultId) ||
      // eslint-disable-next-line
      (this.props.isActive && this.props.laxationLogsId)
    ) {
      this.setState({
        isActive: this.props.isActive,
        loading: true
      })
    }
  }

  /**
   * データを登録する通信を監視して、監視対象すべての通信が完了した場合のみロード画面とダイアログを閉じる
   * @param {String} dataName 監視対象のデータ名。this.state.isRegisterCompleteに記載されているものを使用。
   */
  checkRegisterCompleted = (dataName) => {
    const isRegisterCompletedCopy = { ...this.state.isRegisterCompleted }
    isRegisterCompletedCopy[dataName] = false
    const flags = Object.values(isRegisterCompletedCopy)
    if (!flags.includes(true)) {
      this.handleDialogClosing()
      this.props.onComplete()
    }
    this.setState({
      isRegisterCompleted: isRegisterCompletedCopy
    })
  }

  componentWillUnmount() {
    this._isMounted = false
    carePeopleStore.removeAllListeners('people_all')
    carePeopleStore.removeAllListeners('person_detail')
    excretionResultStore.removeAllListeners('excretion_result_detail')
    excretionResultStore.removeAllListeners('excretion_result_add')
    excretionResultStore.removeAllListeners('excretion_result_edit')
    excretionResultStore.removeAllListeners('excretion_result_register')
    excretionResultStore.removeAllListeners('excretion_result_delete')
    excretionResultStore.removeAllListeners('excretion_result_error')
    laxationLogsStore.removeAllListeners('laxation_logs_register')
    laxationLogsStore.removeAllListeners('laxation_logs_error')
  }

  componentDidUpdate(prebProps) {
    if (prebProps !== this.props) {
      if (this.props.isAsync) {
        return
      }

      if (!this.props.isActive) {
        // ダイアログは開かないので、残存しているデータを初期化する。
        this.setState({
          convertedExcretionDetailDatas: [],
          convertedLaxationLogsDetailDatas: [],
          excretionCurrentIndex: 0,
          laxationLogsCurrentIndex: 0,
          excretionDataSelectTab: 1,
          laxationLogsDataSelectTab: 1
        })
        return
      }

      // アクティブなタブで必要な処理を判断する
      // ここでコンバート
      // eslint-disable-next-line
      switch (this.props.activeTab) {
        // 排泄記録
        case 1:
          this.convertProcessExcretionForm()
          break
        // 下剤記録
        case 2:
          this.convertProcessLaxationLogsForm()
          break
        default:
          this.convertProcessExcretionForm()
          break
      }

      // いらない表記を消す。
      this.removeConfirmedDateOption()
    }
  }

  convertProcessExcretionForm() {
    const convertedExcretionDetailDatas = []

    const unsavedExcretionDetailDatas = this.props.unsavedExcretionDetailDatas
    let carePerson

    if (this.props.where === 'home' && this.props.carePerson) {
      carePerson = this.props.carePerson
        ? _.filter(this.props.carePeople, {
            id: this.props.carePerson.subject_id
          })[0]
        : null
    }

    // 編集中の未保存データがあるならそちらを優先する
    // eslint-disable-next-line
    if (unsavedExcretionDetailDatas && unsavedExcretionDetailDatas.length > 0) {
      // eslint-disable-next-line
      _.each(unsavedExcretionDetailDatas, (data, index) => {
        convertedExcretionDetailDatas.push(
          this.convertPropsToExcretionFormData(data)
        )
      })

      this.setState({
        alertDate:
          this.props.where === 'home' && carePerson && carePerson.alert_log
            ? moment(carePerson.alert_log.created).format('YYYY/MM/DD HH:mm')
            : '',
        convertedExcretionDetailDatas,
        place: carePerson ? carePerson.place : '',
        name: carePerson ? carePerson.name : '',
        isActive: this.props.isActive
      })
    } else {
      // eslint-disable-next-line
      _.each(this.props.excretionDetailDatas, (data, index) => {
        convertedExcretionDetailDatas.push(
          this.convertPropsToExcretionFormData(data)
        )
      })

      this.setState({
        alertDate:
          this.props.where === 'home' && carePerson && carePerson.alert_log
            ? moment(carePerson.alert_log.created).format('YYYY/MM/DD HH:mm')
            : '',
        convertedExcretionDetailDatas,
        place: carePerson ? carePerson.place : '',
        name: carePerson ? carePerson.name : '',
        isActive: this.props.isActive,
        excretionCurrentIndex: 0,
        excretionDataSelectTab: 1
      })
    }
  }

  convertProcessLaxationLogsForm() {
    const convertedLaxationLogsDetailDatas = []
    const unsavedLaxationLogsDetailDatas =
      this.props.unsavedLaxationLogsDetailDatas

    let carePerson
    if (this.props.where === 'home' && this.props.carePerson) {
      carePerson = this.props.carePerson
        ? _.filter(this.props.carePeople, {
            id: this.props.carePerson.subject_id
          })[0]
        : null
    }
    // 編集中の未保存データがあるならそちらを優先する
    if (
      unsavedLaxationLogsDetailDatas &&
      unsavedLaxationLogsDetailDatas.length > 0
    ) {
      _.each(unsavedLaxationLogsDetailDatas, (data, index) => {
        convertedLaxationLogsDetailDatas.push(
          this.convertPropsToLaxativeFormData(data)
        )
      })
      this.setState({
        alertDate:
          this.props.where === 'home' && carePerson && carePerson.alert_log
            ? moment(carePerson.alert_log.created).format('YYYY/MM/DD HH:mm')
            : '',
        convertedLaxationLogsDetailDatas,
        place: carePerson ? carePerson.place : '',
        name: carePerson ? carePerson.name : '',
        isActive: this.props.isActive
      })
    } else {
      _.each(this.props.laxationLogsDetailDatas, (data, index) => {
        convertedLaxationLogsDetailDatas.push(
          this.convertPropsToLaxativeFormData(data)
        )
      })
      this.setState({
        alertDate:
          this.props.where === 'home' && carePerson && carePerson.alert_log
            ? moment(carePerson.alert_log.created).format('YYYY/MM/DD HH:mm')
            : '',
        convertedLaxationLogsDetailDatas,
        place: carePerson ? carePerson.place : '',
        name: carePerson ? carePerson.name : '',
        isActive: this.props.isActive,
        laxationLogsCurrentIndex: 0,
        laxationLogsDataSelectTab: 1
      })
    }
  }

  // thisを参照したいのでアロー化
  convertPropsToExcretionFormData = (detailData) => {
    const dataFields = [
      'id',
      'place',
      'name',
      'alert',
      'confirmed',
      'result',
      'amount',
      'urine_amount',
      'firmness',
      'leaked',
      'type',
      'description',
      'isEdit'
    ]

    const selectNoneOrValue = (value) => {
      return value == null ? 'none' : value
    }

    const setData = {}
    setData.subject_id = detailData.subject_id
    _.each(dataFields, (v) => {
      const carePeople = this.props.carePeople
      const carePerson =
        detailData.subject_id && carePeople
          ? _.filter(carePeople, { id: detailData.subject_id })[0]
          : null
      const alertLogs = this.props.alertLogs

      switch (v) {
        case 'id':
          setData[v] = detailData.id ? detailData.id : null
          break
        case 'place':
          setData[v] = carePerson ? carePerson.place : ''
          break
        case 'name':
          setData[v] = carePerson ? carePerson.name : ''
          break
        case 'leaked':
          if (detailData[v] || detailData[v] != null) {
            setData[v] =
              detailData[v] === 'exist' || detailData[v] === true
                ? 'exist'
                : 'none'
          }
          break
        case 'alert':
          setData[v] =
            alertLogs && detailData.alert_log_id
              ? moment(alertLogs[detailData.alert_log_id]).format(
                  'YYYY/MM/DD HH:mm'
                )
              : detailData.alert
          break
        case 'confirmed':
          setData[v] = detailData.confirmed
            ? typeof detailData.confirmed === 'string'
              ? detailData.confirmed
              : // rjsf対応
                detailData.confirmed
                  .clone()
                  .add(getUtcOffsetAtHour(), 'hours')
                  .format()
            : ''

          break
        case 'description':
          setData[v] = detailData.description ? detailData.description : ''
          break
        case 'type':
          if (detailData[v]) {
            setData[v] = detailData.type
          }
          break
        case 'result':
          setData[v] = detailData.result ? detailData.result : 'none'
          switch (detailData[v]) {
            case 'none':
              setData.isResultNone = true
              setData.isUrineDisabled = true
              setData.isFecesDisabled = true
              break
            case 'urine':
              setData.isResultNone = false
              setData.isUrineDisabled = false
              setData.isFecesDisabled = true
              break
            case 'feces':
              setData.isResultNone = false
              setData.isUrineDisabled = true
              setData.isFecesDisabled = false
              break
            default:
              setData.isResultNone = false
              setData.isFecesDisabled = false
              setData.isUrineDisabled = false
              break
          }
          break
        case 'isEdit':
          setData[v] = detailData.isEdit ? detailData.isEdit : false
          break
        default:
          setData[v] = selectNoneOrValue(detailData[v])
          break
      }
    })

    return setData
  }

  convertPropsToLaxativeFormData = (detailData) => {
    const dataFields = [
      'id',
      'place',
      'name',
      'dosage_date',
      'laxative_id',
      'amount',
      'unit_id',
      'isEdit'
    ]

    const selectNoneOrValue = (value) => {
      return value == null ? 'none' : value
    }
    const setData = {}

    setData.subject_id = detailData.subject_id
    _.each(dataFields, (v) => {
      const carePeople = this.props.carePeople
      const carePerson =
        detailData.subject_id && carePeople
          ? _.filter(carePeople, { id: detailData.subject_id })[0]
          : null
      switch (v) {
        case 'id':
          setData[v] = detailData.id ? detailData.id : null
          break
        case 'place':
          setData[v] = carePerson ? carePerson.place : ''
          break
        case 'name':
          setData[v] = carePerson ? carePerson.name : ''
          break
        case 'dosage_date':
          setData[v] = detailData.dosage_date
            ? typeof detailData.dosage_date === 'string'
              ? detailData.dosage_date
              : detailData.dosage_date
                  .clone()
                  // rjsf対応
                  .add(getUtcOffsetAtHour(), 'hours')
                  .format()
            : ''
          break
        case 'laxative_id':
          setData[v] = detailData.laxative_id
            ? detailData.laxative_id
            : this.props.laxativeDatas
            ? this.props.laxativeDatas[0].id
            : 0
          break
        case 'amount':
          setData[v] = detailData.amount ? detailData.amount : 1
          break
        case 'unit_id':
          setData[v] = detailData.unit_id
            ? detailData.unit_id
            : this.props.unitList
            ? this.props.unitList[0].id
            : ''
          break
        case 'isEdit':
          setData[v] = detailData.isEdit ? detailData.isEdit : false
          break
        default:
          setData[v] = selectNoneOrValue(detailData[v])
          break
      }
    })
    return setData
  }

  setExcretionFormDataEmpty() {
    return {
      id: null,
      place: null,
      name: null,
      // 何もなかった
      result: 'none',
      // 量を入力
      urine_amount: 'none',
      // 量を入力
      amount: 'none',
      // 固さを入力
      firmness: 'none',
      description: '',
      isResultNone: true,
      isUrineDisabled: true,
      isFecesDisabled: true
    }
  }

  setLaxationLogsFormDataEmpty() {
    const laxativeDatas = this.state.laxativeDatas
    const unitList = this.state.unitList
    const laxativeIds = _.map(laxativeDatas, 'id')
    const unitIds = _.map(unitList, 'id')

    const obj = {
      id: null,
      place: null,
      name: null,
      // laxative_id: null,
      laxative_id: laxativeIds[0],
      amount: 1,
      // unit_id: null
      unit_id: unitIds[0]
    }
    return obj
  }

  handleFormClick(e) {
    // 背景のonClickに伝播しないようにする。
    e.stopPropagation()
  }

  /**
   * タブが切り替わったときのハンドラ
   */
  handleRecordChange = (e) => {
    if (this.props.where === 'home') {
      return
    }

    this.props.onRecordChange({
      activeTab: e.formData.recordTab,
      unsavedExcretionDatas: this.state.convertedExcretionDetailDatas,
      unsavedLaxationLogsDatas: this.state.convertedLaxationLogsDetailDatas
    })
  }

  handleFormChange(e) {
    switch (this.props.activeTab) {
      case 1:
        this.changeProcessExcretionForm(e)
        break
      case 2:
        this.changeProcessLaxationLogsForm(e)
        break
      default:
        this.changeProcessExcretionForm(e)
        break
    }
  }

  changeProcessExcretionForm(e) {
    const index = e.formData.excretionDataSelectTab - 1
    if (index === this.state.excretionCurrentIndex) {
      // 1. this.state.converteedExcretionDatailDatasのコピーを作成
      const copyData = this.state.convertedExcretionDetailDatas.concat()

      // 2. コピーの現在のインデックスの要素を上書き編集
      const changedData = {}
      let isEdit = false
      _.each(copyData[index], (v, k, o) => {
        changedData[k] = e.formData[k]
      })

      // 3.result=="none"(何もなかった)の場合は、descriptionのみを残してまっさらな状態に上書き編集
      if (e.formData.result === 'none') {
        changedData.result = 'none'
        changedData.urine_amount = 'none'
        changedData.amount = 'none'
        changedData.firmness = 'none'
        if (e.formData.leaked) {
          delete changedData.leaked
        }

        if (e.formData.type) {
          delete changedData.type
        }
      } else if (e.formData.result === 'urine') {
        changedData.result = e.formData.result
        changedData.amount = 'none'
        changedData.firmness = 'none'
        changedData.leaked = e.formData.leaked
        changedData.type = e.formData.type
      } else if (e.formData.result === 'feces') {
        changedData.result = e.formData.result
        changedData.urine_amount = 'none'
        changedData.amount = e.formData.amount
        changedData.firmness = e.formData.firmness
        changedData.leaked = e.formData.leaked
        changedData.type = e.formData.type
      } else {
        // leaked, typeを未入力にしたい場合、keyごと削除する必要がある
        if (e.formData.leaked) {
          changedData.leaked = e.formData.leaked
        }

        if (e.formData.type) {
          changedData.type = e.formData.type
        }
      }

      if (copyData.length > 0 && typeof copyData[index] !== 'undefined') {
        _.each(changedData, (v, k) => {
          // 既存データから編集をしたか判定する
          if (k in copyData[index]) {
            if (v !== copyData[index][k]) isEdit = true
          } else {
            isEdit = true
          }
        })
      }

      if (isEdit) changedData.isEdit = isEdit
      // 3. setStateで返す
      copyData[index] = changedData
      this.setState({
        convertedExcretionDetailDatas: copyData
      })
    } else {
      this.setState({
        excretionCurrentIndex: index,
        excretionDataSelectTab: e.formData.excretionDataSelectTab
      })
    }
  }

  changeProcessLaxationLogsForm(e) {
    const index = e.formData.laxationLogsDataSelectTab - 1
    if (index === this.state.laxationLogsCurrentIndex) {
      // 1. this.state.converteedLaxationLogsDatailDatasのコピーを作成
      const copyData = this.state.convertedLaxationLogsDetailDatas.concat()

      // 2. コピーの現在のインデックスの要素を上書き編集
      const changedData = {}
      let isEdit = false
      _.each(copyData[index], (v, k, o) => {
        changedData[k] = e.formData[k]

        // 既存データから編集をしたか判定する
        if (e.formData[k] !== o[k]) {
          isEdit = true
        }
      })

      if (isEdit) {
        changedData.isEdit = isEdit
      }

      // 3. setStateで返す
      copyData[index] = changedData
      this.setState({
        convertedLaxationLogsDetailDatas: copyData
      })
    } else {
      this.setState({
        laxationLogsCurrentIndex: index,
        laxationLogsDataSelectTab: e.formData.laxationLogsDataSelectTab
      })
    }
  }

  // データ一括取得
  handleSubmit(e) {
    this.disableSubmitButton()

    if (this.props.where === 'excretionResults') {
      this.props.onLoading(e)
    }

    this.submitProcessExcretionForm(e)
    this.submitProcessLaxationLogsForm(e)

    // isRegisterCompletedをtrueに更新して取得中を表現
    const isRegisterCompletedCopy = { ...this.state.isRegisterCompleted }
    isRegisterCompletedCopy.excretionResults = true
    isRegisterCompletedCopy.laxationLogs = true
    this.setState({
      isRegisterCompleted: isRegisterCompletedCopy
    })
  }

  submitProcessExcretionForm(e) {
    const sendDatas = []
    let convertedExcretionDetailDatas = null
    if (this.props.where === 'home') {
      convertedExcretionDetailDatas = this.state.convertedExcretionDetailDatas
    } else {
      convertedExcretionDetailDatas =
        this.props.activeTab === 1
          ? this.state.convertedExcretionDetailDatas
          : this.props.unsavedExcretionDetailDatas
    }

    // convertedExcretionDetailDatasの中身すべてを保存する
    _.each(convertedExcretionDetailDatas, (data) => {
      // 別タブを開いているなら未登録の未編集データは保存しない
      if (
        this.props.where === 'excretionResults' &&
        this.props.activeTab !== 1 &&
        !data.isEdit &&
        data.id === null
      ) {
        return
      }

      // 登録済み未編集データはスキップする
      if (!data.isEdit && data.id !== null) {
        return
      }

      let leaked
      if (data.leaked) {
        if (data.leaked === 'none') {
          leaked = false
        } else if (data.leaked === 'exist') {
          leaked = true
        } else {
          leaked = null
        }
      } else {
        leaked = null
      }

      const sendData = {
        subject_id: data.subject_id,
        // rjsf対応
        confirmed: moment(data.confirmed)
          .clone()
          .add(getDiffLocalTimeAndJST() - getUtcOffsetAtHour(), 'hours')
          .format(),
        result: data.result,
        amount: data.amount !== 'none' ? data.amount : null,
        urine_amount: data.urine_amount !== 'none' ? data.urine_amount : null,
        firmness: data.firmness !== 'none' ? data.firmness : null,
        leaked,
        type: data.type ? data.type : null,
        description:
          data.description === '' || data.description === undefined
            ? null
            : data.description
      }
      sendDatas.push({ sendData, id: data.id })
    })

    if (this.props.where === 'excretionResults') {
      excretionResultAction.registerExcretionResults(sendDatas)
    } else {
      // 「ホーム」画面から通知からの排泄記録時は、排泄記録登録 API は呼ばない。
      // 下記の通知の非アクティブ化の API（Alert.Edit）内で作られるため。
      this.props.onComplete(e, sendDatas[0].sendData)
      this.enableSubmitButton()
    }
  }

  submitProcessLaxationLogsForm(e) {
    if (this.props.where === 'home') {
      return
    }

    const laxativeIds = _.map(this.state.laxativeDatas, 'id')
    const unitIds = _.map(this.state.unitList, 'id')
    const sendDatas = []
    const convertedLaxationLogsDetailDatas =
      this.props.activeTab === 2
        ? this.state.convertedLaxationLogsDetailDatas
        : this.props.unsavedLaxationLogsDetailDatas

    // convertedExcretionDetailDatasの中身すべてを保存する
    _.each(convertedLaxationLogsDetailDatas, (data) => {
      // 別タブを開いているなら未登録の未編集データは保存しない
      if (this.props.activeTab !== 2 && !data.isEdit && data.id === null) return

      // 登録済み未編集データはスキップする
      if (!data.isEdit && data.id !== null) return

      const sendData = {
        subject_id: data.subject_id,
        administered: moment(data.dosage_date)
          .clone()
          .add(getDiffLocalTimeAndJST() - getUtcOffsetAtHour(), 'hours')
          .format(),
        laxative_id:
          data.laxative_id === '' ? laxativeIds[0] : data.laxative_id,
        amount: data.amount,
        unit_id: data.unit_id === '' ? unitIds[0] : data.unit_id
      }

      sendDatas.push({ sendData, id: data.id })
    })

    laxationLogsAction.registerLaxationLogs(sendDatas)
  }

  handleDialogClosing(e) {
    this.props.onDialogClosing(e)
    this.setState({
      convertedExcretionDetailDatas: [],
      convertedLaxationLogsDetailDatas: [],
      confirm: false
    })
    this.enableSubmitButton()
  }

  excretionTemplate(props) {
    return (
      <div className={props.classNames}>
        <label htmlFor={props.id}>
          {props.label}
          {props.required ? <span>*</span> : null}
        </label>
        {props.children}
        {/* {props.help} */}
      </div>
    )
  }

  handleDeleteButtonClick(e) {
    switch (this.props.activeTab) {
      case 1:
        this.deleteProcessExcretionForm(e)
        break
      case 2:
        this.deleteProcessLaxationLogsForm(e)
        break
      default:
        break
    }
  }

  deleteProcessExcretionForm(e) {
    let currentIndex
    let excretionDataSelectTab
    const currentData =
      this.state.convertedExcretionDetailDatas[this.state.excretionCurrentIndex]

    let editingData = []
    if (currentData !== null) {
      const nextDetailDatas = this.state.convertedExcretionDetailDatas.slice()

      // 0番目を考慮する
      if (this.state.excretionCurrentIndex > 0) {
        nextDetailDatas.splice(this.state.excretionCurrentIndex, 1)
      } else {
        nextDetailDatas.shift()
      }

      // インデックスが0以下にならないようにする。
      if (nextDetailDatas.length === this.state.excretionCurrentIndex) {
        currentIndex =
          this.state.excretionCurrentIndex > 0
            ? this.state.excretionCurrentIndex - 1
            : 0
      } else {
        currentIndex =
          this.state.excretionCurrentIndex > 0
            ? this.state.excretionCurrentIndex
            : 0
      }

      excretionDataSelectTab = currentIndex + 1
      this.setState({
        convertedExcretionDetailDatas: nextDetailDatas,
        excretionCurrentIndex: currentIndex,
        excretionDataSelectTab
      })

      if (currentData.id === null) {
        // 排泄記録が1件
        if (nextDetailDatas.length === 1) {
          // 排泄記録に編集中のデータはあるか
          if (_.filter(nextDetailDatas, { isEdit: true })) {
            // あるならウィンドウは閉じない。
          } else {
            // 下剤記録が1件以上
            if (this.state.convertedLaxationLogsDetailDatas.length > 0) {
              // 下剤記録の未保存編集中データ
              editingData = _.filter(
                this.state.convertedLaxationLogsDetailDatas,
                { isEdit: true }
              )

              const unsavedIndex = _.findIndex(
                this.state.convertedLaxationLogsDetailDatas,
                { isEdit: true }
              )

              if (editingData.length > 0) {
                this.props.onRecordChange({
                  activeTab: 2,
                  unsavedExcretionDatas: nextDetailDatas,
                  unsavedLaxationLogsDatas:
                    this.state.convertedLaxationLogsDetailDatas
                })
                this.setState({
                  laxationLogsCurrentIndex: unsavedIndex,
                  laxationLogsDataSelectTab: unsavedIndex + 1
                })
              } else {
                this.handleDialogClosing(e)
              }
            } else {
              // 残っているデータがなければ閉じる。
              if (nextDetailDatas.length === 0) {
                this.handleDialogClosing(e)
              }
            }
          }
        } else if (nextDetailDatas.length === 0) {
          // 下剤記録が1件以上
          if (this.state.convertedLaxationLogsDetailDatas.length > 0) {
            // 下剤記録の未保存編集中データ
            editingData = _.filter(
              this.state.convertedLaxationLogsDetailDatas,
              { isEdit: true }
            )
            const unsavedIndex = _.findIndex(
              this.state.convertedLaxationLogsDetailDatas,
              { isEdit: true }
            )
            if (editingData.length > 0) {
              this.props.onRecordChange({
                activeTab: 2,
                unsavedExcretionDatas: nextDetailDatas,
                unsavedLaxationLogsDatas:
                  this.state.convertedLaxationLogsDetailDatas
              })
              this.setState({
                laxationLogsCurrentIndex: unsavedIndex,
                laxationLogsDataSelectTab: unsavedIndex + 1
              })
            } else {
              this.handleDialogClosing(e)
            }
          } else {
            // 残っているデータがなければ閉じる。
            if (nextDetailDatas.length === 0) {
              this.handleDialogClosing(e)
            }
          }
        }
      } else {
        const startHour =
          this.state.convertedExcretionDetailDatas[
            this.state.excretionCurrentIndex
          ].confirmed
        const subjectId =
          this.state.convertedExcretionDetailDatas[
            this.state.excretionCurrentIndex
          ].subject_id
        let unsavedIndex = 0

        // 排泄記録が1件
        if (nextDetailDatas.length === 1) {
          // 排泄記録に編集中のデータはあるか
          if (_.filter(nextDetailDatas, { isEdit: true }).length > 0) {
            // あるならウィンドウは閉じない。
          } else {
            // 下剤記録が1件以上
            if (this.state.convertedLaxationLogsDetailDatas.length > 0) {
              // 下剤記録の未保存編集中データ
              editingData = _.filter(
                this.state.convertedLaxationLogsDetailDatas,
                { isEdit: true }
              )
              if (editingData.length > 0) {
                unsavedIndex = _.findIndex(
                  this.state.convertedLaxationLogsDetailDatas,
                  { isEdit: true }
                )
              }
            } else {
              // 下剤記録が0件なら閉じる。
              // this.handleDialogClosing(e);
            }
          }
        } else if (nextDetailDatas.length === 0) {
          // 下剤記録が1件以上
          if (this.state.convertedLaxationLogsDetailDatas.length > 0) {
            // 下剤記録の未保存編集中データ
            editingData = _.filter(
              this.state.convertedLaxationLogsDetailDatas,
              { isEdit: true }
            )
            if (editingData.length > 0) {
              unsavedIndex = _.findIndex(
                this.state.convertedLaxationLogsDetailDatas,
                { isEdit: true }
              )
            }
          } else {
            // 下剤記録が0件なら閉じる。
            // this.handleDialogClosing(e);
          }
        }

        this.props.onRemove({
          excretionResultId: currentData.id,
          startHour,
          subjectId,
          unsavedExcretionDatas: this.state.convertedExcretionDetailDatas,
          unsavedLaxationLogsDatas:
            editingData.length > 0
              ? this.state.convertedLaxationLogsDetailDatas
              : [],
          unsavedLaxationLogsIndex: unsavedIndex
        })
      }
    }
  }

  deleteProcessLaxationLogsForm(e) {
    let currentIndex
    let laxationLogsDataSelectTab
    const currentData =
      this.state.convertedLaxationLogsDetailDatas[
        this.state.laxationLogsCurrentIndex
      ]

    let editingData = []
    if (currentData !== null) {
      const nextDetailDatas =
        this.state.convertedLaxationLogsDetailDatas.slice()

      // 0番目を考慮する
      if (this.state.laxationLogsCurrentIndex > 0) {
        nextDetailDatas.splice(this.state.laxationLogsCurrentIndex, 1)
      } else {
        nextDetailDatas.shift()
      }

      // インデックスが0以下にならないようにする。
      if (nextDetailDatas.length === this.state.laxationLogsCurrentIndex) {
        currentIndex =
          this.state.laxationLogsCurrentIndex > 0
            ? this.state.laxationLogsCurrentIndex - 1
            : 0
      } else {
        currentIndex =
          this.state.laxationLogsCurrentIndex > 0
            ? this.state.laxationLogsCurrentIndex
            : 0
      }

      laxationLogsDataSelectTab = currentIndex + 1
      this.setState({
        convertedLaxationLogsDetailDatas: nextDetailDatas,
        laxationLogsCurrentIndex: currentIndex,
        laxationLogsDataSelectTab
      })

      if (currentData.id === null) {
        // 下剤記録が1件
        if (nextDetailDatas.length === 1) {
          // 下剤記録に編集中のデータはあるか
          if (_.filter(nextDetailDatas, { isEdit: true })) {
            // あるならウィンドウは閉じない。
          } else {
            // 排泄記録が1件以上
            if (this.state.convertedExcretionDetailDatas.length > 0) {
              // 排泄記録の未保存編集中データ
              editingData = _.filter(this.state.convertedExcretionDetailDatas, {
                isEdit: true
              })
              const unsavedIndex = _.findIndex(
                this.state.convertedExcretionDetailDatas,
                { isEdit: true }
              )
              if (editingData.length > 0) {
                this.props.onRecordChange({
                  activeTab: 1,
                  unsavedExcretionDatas: nextDetailDatas,
                  unsavedLaxationLogsDatas:
                    this.state.convertedLaxationLogsDetailDatas
                })
                this.setState({
                  excretionCurrentIndex: unsavedIndex,
                  excretionDataSelectTab: unsavedIndex + 1
                })
              } else {
                this.handleDialogClosing(e)
              }
            } else {
              // 残っているデータがなければ閉じる。
              if (nextDetailDatas.length === 0) {
                this.handleDialogClosing(e)
              }
            }
          }
        } else if (nextDetailDatas.length === 0) {
          // 排泄記録が1件以上
          if (this.state.convertedExcretionDetailDatas.length > 0) {
            // 排泄記録の未保存編集中データ
            editingData = _.filter(this.state.convertedExcretionDetailDatas, {
              isEdit: true
            })
            const unsavedIndex = _.findIndex(
              this.state.convertedExcretionDetailDatas,
              { isEdit: true }
            )
            if (editingData.length > 0) {
              this.props.onRecordChange({
                activeTab: 1,
                unsavedExcretionDatas: this.state.convertedExcretionDetailDatas,
                unsavedLaxationLogsDatas: nextDetailDatas
              })
              this.setState({
                excretionCurrentIndex: unsavedIndex,
                excretionDataSelectTab: unsavedIndex + 1
              })
            } else {
              this.handleDialogClosing(e)
            }
          } else {
            // 残っているデータがなければ閉じる。
            if (nextDetailDatas.length === 0) {
              this.handleDialogClosing(e)
            }
          }
        }
      } else {
        const startHour =
          this.state.convertedLaxationLogsDetailDatas[
            this.state.laxationLogsCurrentIndex
          ].dosage_date
        const subjectId =
          this.state.convertedLaxationLogsDetailDatas[
            this.state.laxationLogsCurrentIndex
          ].subject_id
        let unsavedIndex = 0

        // 下剤記録が1件
        if (nextDetailDatas.length === 1) {
          if (_.filter(nextDetailDatas, { isEdit: true }).length > 0) {
            // あるならウィンドウは閉じない。
          } else {
            // 排泄記録が1件以上
            if (this.state.convertedExcretionDetailDatas.length > 0) {
              // 排泄記録の未保存編集中データ
              editingData = _.filter(this.state.convertedExcretionDetailDatas, {
                isEdit: true
              })
              if (editingData.length > 0) {
                unsavedIndex = _.findIndex(
                  this.state.convertedExcretionDetailDatas,
                  { isEdit: true }
                )
              }
            }
          }
        } else if (nextDetailDatas.length === 0) {
          // 排泄記録が1件以上
          if (this.state.convertedExcretionDetailDatas.length > 0) {
            // 排泄記録の未保存編集中データ
            editingData = _.filter(this.state.convertedExcretionDetailDatas, {
              isEdit: true
            })
            if (editingData.length > 0) {
              unsavedIndex = _.findIndex(
                this.state.convertedExcretionDetailDatas,
                { isEdit: true }
              )
            }
          }
        }

        this.props.onRemove({
          laxationLogsId: currentData.id,
          startHour,
          subjectId,
          unsavedExcretionDatas:
            editingData.length > 0
              ? this.state.convertedExcretionDetailDatas
              : [],
          unsavedLaxationLogsDatas: this.state.convertedLaxationLogsDetailDatas,
          unsavedExcretionIndex: unsavedIndex
        })
      }
    }
  }

  handleAddButtonClick(e) {
    switch (this.props.activeTab) {
      case 1:
        this.excretionNewDataFactory()
        break
      case 2:
        this.laxationLogsNewDataFactory()
        break
      default:
        this.excretionNewDataFactory()
        break
    }
  }

  excretionNewDataFactory() {
    if (this.state.convertedExcretionDetailDatas.slice().length >= 5) {
      this.setState({
        addErrorDialog: true
      })
      return
    }

    const nextDetailDatas = this.state.convertedExcretionDetailDatas.slice()
    const newTabData = this.setExcretionFormDataEmpty()
    // rjsf対応
    newTabData.confirmed = moment(this.props.confirmed)
      .clone()
      .add(getUtcOffsetAtHour(), 'hours')
      .format()
    newTabData.subject_id =
      this.state.convertedExcretionDetailDatas[0].subject_id
    newTabData.place = this.state.convertedExcretionDetailDatas[0].place
    newTabData.name = this.state.convertedExcretionDetailDatas[0].name
    newTabData.isEdit = false
    nextDetailDatas.push(newTabData)
    this.setState({
      convertedExcretionDetailDatas: nextDetailDatas,
      excretionDataSelectTab: nextDetailDatas.length,
      excretionCurrentIndex: nextDetailDatas.length - 1
    })
  }

  laxationLogsNewDataFactory() {
    if (this.state.convertedLaxationLogsDetailDatas.slice().length >= 5) {
      this.setState({
        addErrorDialog: true
      })
      return
    }
    const nextDetailDatas = this.state.convertedLaxationLogsDetailDatas.slice()
    let newTabData = this.setLaxationLogsFormDataEmpty()

    // rjsf対応
    newTabData.dosage_date = moment(this.props.confirmed)
      .clone()
      .add(getUtcOffsetAtHour(), 'hours')
      .format()
    newTabData.subject_id =
      this.state.convertedLaxationLogsDetailDatas[0].subject_id
    newTabData.place = this.state.convertedLaxationLogsDetailDatas[0].place
    newTabData.name = this.state.convertedLaxationLogsDetailDatas[0].name
    newTabData.laxative_id =
      this.props.laxativeDatas.length > 0 ? this.props.laxativeDatas[0].id : 0
    newTabData.unit_id =
      this.props.unitList.length > 0 ? this.props.unitList[0].id : 0
    newTabData.isEdit = false
    nextDetailDatas.push(newTabData)
    this.setState({
      convertedLaxationLogsDetailDatas: nextDetailDatas,
      laxationLogsDataSelectTab: nextDetailDatas.length,
      laxationLogsCurrentIndex: nextDetailDatas.length - 1
    })
  }

  handleAddErrorOkClick(e) {
    this.setState({
      addErrorDialog: false
    })
  }

  handleAddErrorDialogClosing(e) {
    this.setState({
      addErrorDialog: false
    })
  }

  handleCancelButtonClick(e) {
    let isEdit = false
    if (this.props.activeTab === 1) {
      isEdit =
        _.filter(this.state.convertedExcretionDetailDatas, { isEdit: true })
          .length > 0 ||
        _.filter(this.props.unsavedLaxationLogsDetailDatas, { isEdit: true })
          .length > 0
    } else {
      isEdit =
        _.filter(this.state.convertedLaxationLogsDetailDatas, { isEdit: true })
          .length > 0 ||
        _.filter(this.props.unsavedExcretionDetailDatas, { isEdit: true })
          .length > 0
    }
    if (isEdit) {
      this.setState({
        confirm: true
      })
    } else {
      this.handleDialogClosing(e)
    }
  }

  handleConfirmDialog(e) {
    const [locale] = this.context
    const uiWords = localizedUiWords(locale.lang).components.appDialog

    switch (e) {
      case uiWords.okButtonLabel:
        this.handleDialogClosing(e)
        break
      case uiWords.cancelButtonLabel:
        this.handleConfirmDialogClosing(e)
        break
      default:
        break
    }
  }

  handleConfirmDialogClosing(e) {
    this.setState({
      confirm: false
    })
  }

  // year month day hour minute の表記はいらないので消す。
  removeConfirmedDateOption() {
    const yearDOM = document.getElementById('root_confirmed_year')
    const monthDOM = document.getElementById('root_confirmed_month')
    const dayDOM = document.getElementById('root_confirmed_day')
    const hourDOM = document.getElementById('root_confirmed_hour')
    const minuteDOM = document.getElementById('root_confirmed_minute')

    if (yearDOM && yearDOM[0].innerText === 'year') {
      yearDOM.remove(0)
    }

    if (monthDOM && monthDOM[0].innerText === 'month') {
      monthDOM.remove(0)
    }

    if (dayDOM && dayDOM[0].innerText === 'day') {
      dayDOM.remove(0)
    }

    if (hourDOM && hourDOM[0].innerText === 'hour') {
      hourDOM.remove(0)
    }

    if (minuteDOM && minuteDOM[0].innerText === 'minute') {
      minuteDOM.remove(0)
    }
  }

  buildDescriptionCounterDom = (description) => {
    const [locale] = this.context
    const uiWords = localizedUiWords(locale.lang).components.careNoteDialog

    if (description === undefined || description === '') {
      return <div style={{ textAlign: 'right' }}>0/1000</div>
    }

    const lenDescription = description.length
    if (lenDescription > 1000) {
      return (
        <div className="error" style={{ textAlign: 'right' }}>
          {uiWords.noteLimitCharactersMessage} {lenDescription}/1000
        </div>
      )
    } else {
      return <div style={{ textAlign: 'right' }}>{lenDescription}/1000</div>
    }
  }

  switchSubmitBtnStatus = (description) => {
    if (description === undefined || description === '') {
      return ''
    }
    return description.length > 1000 ? 'disabled' : ''
  }

  /**
   * 排泄記録と下剤記録用のタブを作成
   */
  getRecordTab = () => {
    const [locale] = this.context
    const uiWords = localizedUiWords(locale.lang).components.careNoteDialog

    const recordTab =
      this.props.where === 'home'
        ? {
            enum: [1],
            enumNames: [uiWords.excretionNoteTabLabel],
            title: ' ',
            type: 'number',
            default: 1
          }
        : {
            enum: [1, 2],
            enumNames: [
              uiWords.excretionNoteTabLabel,
              uiWords.laxativeNoteTabLabel
            ],
            title: ' ',
            type: 'number',
            default: 1
          }

    const schema = {
      type: 'object',
      properties: { recordTab }
    }

    const uiSchema = {
      recordTab: {
        classNames: 'form-record-tab',
        'ui:widget': 'radio',
        'ui:options': {
          inline: true
        }
      }
    }

    const formData = {}
    formData.recordTab = this.props.activeTab
    return (
      <Form
        schema={schema}
        uiSchema={uiSchema}
        formData={formData}
        onChange={this.handleRecordChange}
        showErrorList={false}
        noValidate={true}
        className="record-tab-container rjsf"
      >
        <div className="horizontal-bar">&nbsp;</div>
        <div className="btn-set" style={{ display: 'none' }}>
          <button type="submit">{uiWords.saveButtonLabel}</button>
        </div>
      </Form>
    )
  }

  /**
   * 排泄記録タブ
   *
   * TODO: コンポーネント化, 別ファイルに分離
   */
  getExcretionTab = () => {
    let currentData = null
    if (this.state.convertedExcretionDetailDatas) {
      currentData =
        this.state.convertedExcretionDetailDatas[
          this.state.excretionCurrentIndex
        ]
    }

    const customFields = {
      userSearch: UserSearchField,
      datetime: DatetimeField,
      firmness: FirmnessField
    }

    // 排泄記録タブ
    const excretionDataSelectTab = {}
    const excretionDataSelectTabEnum = []
    const excretionDataSelectTabEnumNames = []
    let excretionDataCount = 1

    // 改修
    _.each(this.state.convertedExcretionDetailDatas, (v) => {
      if (v && v.isEdit) {
        excretionDataSelectTabEnum.push(excretionDataCount)
        excretionDataSelectTabEnumNames.push(
          moment(v.confirmed)
            .clone()
            // rjsf対応
            .subtract(getUtcOffsetAtHour(), 'hours')
            .format('HH:mm') + ' *'
        )
        excretionDataCount++
      } else {
        excretionDataSelectTabEnum.push(excretionDataCount)
        excretionDataSelectTabEnumNames.push(
          moment(v.confirmed)
            .clone()
            // rjsf対応
            .subtract(getUtcOffsetAtHour(), 'hours')
            .format('HH:mm')
        )
        excretionDataCount++
      }
    })

    // 排泄記録タブ
    excretionDataSelectTab.enum = excretionDataSelectTabEnum
    excretionDataSelectTab.enumNames = excretionDataSelectTabEnumNames
    excretionDataSelectTab.title = ' '
    excretionDataSelectTab.type = 'number'
    excretionDataSelectTab.default = 1

    const [locale] = this.context
    const uiWords = localizedUiWords(locale.lang).components.careNoteDialog

    // フォームの設定
    const schema = {
      type: 'object',
      properties: {
        excretionDataSelectTab,
        alert: {
          type: 'string',
          title: uiWords.notifiedAtLabel,
          enum: [0],
          enumNames: [
            this.state.alertDate
              ? this.state.alertDate
              : currentData && currentData.alert
              ? currentData.alert
              : ''
          ]
        },
        confirmed: {
          type: 'string',
          title: uiWords.caredAtLabel
        },
        result: {
          type: 'string',
          title: uiWords.typeLabel,
          enum: ['none', 'urine', 'feces', 'urine_and_feces'],
          enumNames: [
            uiWords.noneButtonLabel,
            uiWords.urineButtonLabel,
            uiWords.fecesButtonLabel,
            uiWords.urineAndFecesButtonLabel
          ],
          default: 'none'
        },
        // TODO: キャメルケースにする
        urine_amount: {
          type: 'string',
          title: uiWords.urineAmountLabel,
          enum: ['adhesion', 'little', 'medium', 'many'],
          enumNames: [
            uiWords.spotAmountValue,
            uiWords.smallAmountValue,
            uiWords.middleAmountValue,
            uiWords.largeAmountValue
          ]
        },
        // TODO: fecesAmount にする
        amount: {
          type: 'string',
          title: uiWords.fecesAmountLabel,
          enum: ['adhesion', 'little', 'medium', 'many'],
          enumNames: [
            uiWords.spotAmountValue,
            uiWords.smallAmountValue,
            uiWords.middleAmountValue,
            uiWords.largeAmountValue
          ]
        },
        firmness: {
          type: 'string',
          // 空文字にすると firmness が表示されるので空白文字を入れる
          title: ' ',
          // excretion_resultsテーブルのfirmness項目と対応している必要があるので注意
          enum: [
            'very_hard',
            'hard',
            'somewhat_hard',
            'normal',
            'somewhat_soft',
            'turbid',
            'watery'
          ],
          enumNames: [
            uiWords.rabitDroppingsScale,
            uiWords.hardScale,
            uiWords.littleHardScale,
            uiWords.normalScale,
            uiWords.littleSoftScale,
            uiWords.porridgeScale,
            uiWords.diarrheaScale
          ],
          hasAlert: false
        },

        leaked: {
          type: 'string',
          title: uiWords.leakageLabel,
          enum: ['none', 'exist'],
          enumNames: [uiWords.noLeakageAnswer, uiWords.yesLeakageAnswer]
        },
        type: {
          type: 'string',
          title: uiWords.placeLabel,
          enum: ['diaper', 'toilet'],
          enumNames: [uiWords.diaperAnswer, uiWords.toiletAnswer]
        },
        description: {
          type: 'string',
          title: ' '
        },
        isEdit: {
          type: 'boolean',
          title: ' '
        }
      }
    }

    const uiSchema = {
      excretionDataSelectTab: {
        classNames: 'form-excretionDataSelectTab',
        'ui:widget': 'radio',
        'ui:options': {
          inline: true
        }
      },
      alert: {
        classNames: 'form-alert',
        'ui:widget': 'radio',
        'ui:options': {
          inline: true
        }
      },
      confirmed: {
        classNames: 'form-confirmed',
        'ui:disabled':
          currentData && currentData.id === null
            ? false
            : this.props.scopCooperation,
        'ui:widget': 'alt-datetime',
        'ui:options': {
          yearsRange: [2018, 2030],
          hideClearButton: true,
          hideNowButton: true
        }
      },
      result: {
        'ui:widget': 'radio',
        'ui:options': {
          inline: true
        },
        classNames: 'form-radio form-result'
      },
      urine_amount: {
        'ui:widget': 'radio',
        'ui:options': {
          inline: true
        },
        classNames: 'form-radio form-urine_amount',
        'ui:disabled':
          currentData &&
          (currentData.result === 'feces' || currentData.result === 'none')
      },
      amount: {
        'ui:widget': 'radio',
        'ui:options': {
          inline: true
        },
        classNames: 'form-radio form-amount',
        'ui:disabled':
          currentData &&
          (currentData.result === 'urine' || currentData.result === 'none')
      },
      firmness: {
        'ui:field': 'firmness',
        'ui:options': {
          inline: true
        },
        classNames: 'form-radio form-firmness',
        'ui:disabled':
          currentData &&
          (currentData.result === 'urine' || currentData.result === 'none')
      },
      leaked: {
        'ui:widget': 'radio',
        'ui:options': {
          inline: true
        },
        classNames: 'form-radio form-leaked',
        'ui:disabled': currentData && currentData.result === 'none'
      },
      type: {
        'ui:widget': 'radio',
        'ui:options': {
          inline: true
        },
        classNames: 'form-radio form-type',
        'ui:disabled': currentData && currentData.result === 'none'
      },
      description: {
        'ui:widget': 'textarea',
        'ui:placeholder': uiWords.notePlaceholder,
        classNames: 'form-description'
      },
      isEdit: {
        classNames: 'form-hidden',
        'ui:widget': 'radio'
      }
    }

    // where = excretionResults, 新規追加または通知記録との紐付けがない場合、通知日時を非表示にする
    if (
      this.props.where === 'excretionResults' &&
      this.state.convertedExcretionDetailDatas &&
      ((this.state.convertedExcretionDetailDatas.length === 1 &&
        this.state.convertedExcretionDetailDatas[0].id === null) ||
        (currentData && !currentData.alert))
    ) {
      delete schema.properties.alert
      delete uiSchema.alert
      // 固さホバー文言の位置を通知日時の有無で調整する
      schema.properties.firmness.hasAlert = true
    }

    // メッセージ（エラー、通知）はhelpフィールドを利用して出力する
    if (!this.state.loading) {
      for (const param in schema.properties) {
        if (this.state.errors[param]) {
          if (!uiSchema[param]) {
            uiSchema[param] = {}
          }

          uiSchema[param]['ui:help'] = (
            <div className="error">{this.state.errors[param]}</div>
          )
        }
      }
    }

    const formData = {}
    // 改修
    if (currentData) {
      _.each(currentData, (v, k) => {
        formData[k] = v
      })
    }

    formData.excretionDataSelectTab = this.state.excretionDataSelectTab
    formData.currentIndex = this.state.excretionCurrentIndex
    let addButtonDOM
    let deleteButonDOM

    // ホーム画面からの表示の場合、追加ボタン、削除ボタンを非表示にする
    // 通知日時を表示する
    if (this.props.where === 'home') {
      formData.alert = this.state.alertDate
      addButtonDOM = ''
      deleteButonDOM = ''
    } else {
      addButtonDOM = (
        <div className="add-field">
          <div
            className={
              this.state.convertedExcretionDetailDatas.slice().length >= 5
                ? 'disabled-color add-icon'
                : 'add-icon'
            }
            onClick={this.handleAddButtonClick.bind(this)}
          >
            ＋
          </div>

          <button
            type="button"
            className="add-btn"
            onClick={this.handleAddButtonClick.bind(this)}
          >
            {uiWords.addNoteButtonLabel}
          </button>
        </div>
      )
      deleteButonDOM = (
        <button
          className="delete-btn"
          type="button"
          onClick={this.handleDeleteButtonClick.bind(this)}
        >
          {uiWords.deleteNoteButtonLabel}
        </button>
      )
    }

    const descriptionCounterDom = this.buildDescriptionCounterDom(
      currentData ? currentData.description : ''
    )

    const submitBtnStatus = this.switchSubmitBtnStatus(
      currentData ? currentData.description : ''
    )

    return (
      <div
        className="content excretion-tab"
        onClick={this.handleFormClick.bind(this)}
      >
        <div className="care-subject-info">
          <div>{this.state.place ? this.state.place : formData.place}</div>
          <div>{this.state.name ? this.state.name : formData.name}</div>
        </div>
        {this.getRecordTab()}
        {addButtonDOM}
        {/* 排泄記録タブ */}
        <Form
          schema={schema}
          uiSchema={uiSchema}
          fields={customFields}
          FieldTemplate={this.excretionTemplate}
          formData={formData}
          onSubmit={this.handleSubmit.bind(this)}
          onChange={this.handleFormChange.bind(this)}
          showErrorList={false}
          noValidate={true}
          className="excretion-note-container rjsf"
        >
          <div className="description-counter">{descriptionCounterDom}</div>
          <div className="btn-set">
            {deleteButonDOM}
            <button
              ref={this.submitButtonRef}
              type="submit"
              disabled={submitBtnStatus}
            >
              {uiWords.saveButtonLabel}
            </button>
            <button
              className="cancel-btn"
              type="button"
              onClick={this.handleCancelButtonClick.bind(this)}
            >
              {uiWords.cancelButtonLabel}
            </button>
          </div>
        </Form>
        <AppDialog
          isActive={this.state.addErrorDialog}
          title={uiWords.excretionNoteMaxDialogMessage}
          choiceButtonLists={[uiWords.excretionNoteMaxDialogOkButtonLabel]}
          onClick={this.handleAddErrorOkClick.bind(this)}
          onDialogClosing={this.handleAddErrorDialogClosing.bind(this)}
        />
      </div>
    )
  }

  /**
   * 下剤記録タブ
   *
   * TODO: コンポーネント化, 別ファイルに分離
   */
  getLaxationLogsTab = () => {
    const [locale] = this.context
    const uiWords = localizedUiWords(locale.lang).components.careNoteDialog

    const laxativeDatas = this.props.laxativeDatas
    const unitList = this.props.unitList

    const laxativeIds = _.map(laxativeDatas, 'id')
    const laxativeNames = _.map(laxativeDatas, 'name')

    // 投与単位
    const unitIds = _.map(unitList, 'id')
    const unitNames = _.map(unitList, 'name_jp')

    let currentData = null
    if (this.state.convertedLaxationLogsDetailDatas) {
      currentData =
        this.state.convertedLaxationLogsDetailDatas[
          this.state.laxationLogsCurrentIndex
        ]
    }

    // 下剤記録タブ
    const laxationLogsDataSelectTab = {}
    const laxationLogsDataSelectTabEnum = []
    const laxationLogsDataSelectTabEnumNames = []
    let laxationLogsDataCount = 1

    _.each(this.state.convertedLaxationLogsDetailDatas, (v) => {
      if (v && v.isEdit) {
        laxationLogsDataSelectTabEnum.push(laxationLogsDataCount)
        laxationLogsDataSelectTabEnumNames.push(
          moment(v.dosage_date)
            .clone()
            .subtract(getUtcOffsetAtHour(), 'hours')
            .format('HH:mm') + ' *'
        )
        laxationLogsDataCount++
      } else {
        laxationLogsDataSelectTabEnum.push(laxationLogsDataCount)
        laxationLogsDataSelectTabEnumNames.push(
          moment(v.dosage_date)
            .clone()
            // rjsf対応
            .subtract(getUtcOffsetAtHour(), 'hours')
            .format('HH:mm')
        )
        laxationLogsDataCount++
      }
    })

    // 下剤記録タブ
    laxationLogsDataSelectTab.enum = laxationLogsDataSelectTabEnum
    laxationLogsDataSelectTab.enumNames = laxationLogsDataSelectTabEnumNames
    laxationLogsDataSelectTab.title = ' '
    laxationLogsDataSelectTab.type = 'number'
    laxationLogsDataSelectTab.default = 1

    const customFields = {
      laxativeNamesField: ListBoxField,
      UnitIdField: ListBoxField
    }

    // フォームの設定
    const schema = {
      type: 'object',
      properties: {
        laxationLogsDataSelectTab,
        dosage_date: {
          type: 'string',
          title: uiWords.administeredAtLabel
        },
        laxative_id: {
          type: 'string',
          title: uiWords.laxativeNameLabel,
          enum: laxativeIds,
          enumNames: laxativeNames
        },
        amount: {
          type: 'number',
          title: uiWords.laxativeAmountLabel,
          multipleOf: 0.1,
          minimum: 0
        },
        unit_id: {
          title: ' ',
          type: 'string',
          enum: unitIds,
          enumNames: unitNames,
          default: unitIds[0]
        },
        isEdit: {
          type: 'boolean',
          title: ' '
        }
      }
    }

    const uiSchema = {
      laxationLogsDataSelectTab: {
        classNames: 'form-laxationLogsDataSelectTab',
        'ui:widget': 'radio',
        'ui:options': {
          inline: true
        }
      },
      dosage_date: {
        classNames: 'form-dosage_date',
        'ui:widget': 'alt-datetime',
        'ui:options': {
          yearsRange: [2018, 2030],
          hideClearButton: true,
          hideNowButton: true
        }
      },
      laxative_id: {
        'ui:field': 'laxativeNamesField',
        classNames: 'form-laxativeName',
        'ui:options': {
          size: 6
        }
      },
      amount: {
        classNames: 'form-medicine-amount'
      },
      unit_id: {
        classNames: 'form-unit_id',
        'ui:field': 'UnitIdField',
        'ui:options': {
          size: 7
        }
      },
      isEdit: {
        classNames: 'form-hidden',
        'ui:widget': 'radio'
      }
    }

    // メッセージ（エラー、通知）はhelpフィールドを利用して出力する
    if (!this.state.loading) {
      for (const param in schema.properties) {
        if (this.state.errors[param]) {
          if (!uiSchema[param]) {
            uiSchema[param] = {}
          }
          uiSchema[param]['ui:help'] = (
            <div className="error">{this.state.errors[param]}</div>
          )
        }
      }
    }

    const formData = {}

    if (currentData) {
      _.each(currentData, (v, k) => {
        formData[k] = v
      })
    }
    if (formData.laxative_id === '') {
      formData.laxative_id = laxativeIds[0]
    }
    if (formData.unit_id === '') {
      formData.unit_id = unitIds[0]
    }

    formData.laxationLogsDataSelectTab = this.state.laxationLogsDataSelectTab

    const addButtonDOM = (
      <div className="add-field">
        <div
          className={
            this.state.convertedLaxationLogsDetailDatas.slice().length >= 5
              ? 'disabled-color add-icon'
              : 'add-icon'
          }
          onClick={this.handleAddButtonClick.bind(this)}
        >
          ＋
        </div>
        <button
          type="button"
          className="add-btn"
          onClick={this.handleAddButtonClick.bind(this)}
        >
          {uiWords.addNoteButtonLabel}
        </button>
      </div>
    )

    const deleteButonDOM = (
      <button
        className="delete-btn"
        type="button"
        onClick={this.handleDeleteButtonClick.bind(this)}
      >
        {uiWords.deleteNoteButtonLabel}
      </button>
    )

    const submitBtnStatus = this.switchSubmitBtnStatus(
      currentData ? currentData.description : ''
    )
    return (
      <div
        className="content laxative-tab"
        onClick={this.handleFormClick.bind(this)}
      >
        <div className="care-subject-info">
          <div>{this.state.place ? this.state.place : formData.place}</div>
          <div>{this.state.name ? this.state.name : formData.name}</div>
        </div>
        {this.getRecordTab()}
        {addButtonDOM}
        {/* 下剤記録タブ */}
        <Form
          schema={schema}
          uiSchema={uiSchema}
          fields={customFields}
          formData={formData}
          onSubmit={this.handleSubmit.bind(this)}
          onChange={this.handleFormChange.bind(this)}
          showErrorList={false}
          noValidate={true}
          className="laxative-note-container rjsf"
        >
          <div className="btn-set">
            {deleteButonDOM}
            <button
              ref={this.submitButtonRef}
              type="submit"
              disabled={submitBtnStatus}
            >
              {uiWords.saveButtonLabel}
            </button>
            <button
              className="cancel-btn"
              type="button"
              onClick={this.handleCancelButtonClick.bind(this)}
            >
              {uiWords.cancelButtonLabel}
            </button>
          </div>
        </Form>
        <AppDialog
          isActive={this.state.addErrorDialog}
          title={uiWords.laxativeNoteMaxDialogMessage}
          choiceButtonLists={[uiWords.laxativeNoteMaxDialogOkButtonLabel]}
          onClick={this.handleAddErrorOkClick.bind(this)}
          onDialogClosing={this.handleAddErrorDialogClosing.bind(this)}
        />
      </div>
    )
  }

  render() {
    const isRender =
      this.state.isActive && this.props.isActive
        ? 'excretion-dialog'
        : 'excretion-dialog hide'
    let tabView = null

    switch (this.props.activeTab) {
      case 1:
        // 排泄記録
        tabView = this.getExcretionTab()
        break
      case 2:
        // 下剤記録
        tabView = this.getLaxationLogsTab()
        break
      default:
        tabView = this.getExcretionTab()
        break
    }

    const [locale] = this.context
    const uiWords = localizedUiWords(locale.lang).components.appDialog

    return (
      <div className={isRender}>
        {tabView}
        <AppLoading isActive={this.state.loading} />
        <AppDialog
          isActive={this.state.confirm}
          title={uiWords.quitEditingMessage}
          choiceButtonLists={[uiWords.okButtonLabel, uiWords.cancelButtonLabel]}
          onClick={this.handleConfirmDialog.bind(this)}
          onDialogClosing={this.handleConfirmDialogClosing.bind(this)}
        />
      </div>
    )
  }
}

CareNoteDialog.propTypes = {
  isActive: PropTypes.bool,
  excretionResultId: PropTypes.number,
  onDialogClosing: PropTypes.func,
  onComplete: PropTypes.func,
  isEditDisable: PropTypes.bool,
  where: PropTypes.string
}
