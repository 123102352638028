import TLaxative, { initialLaxative } from "./TLaxative";
type TLaxationLog = {
  id: 0;
  laxative_id: 0;
  subject_id: 0;
  unit_id: 0;
  staff_id: 0;
  administered: "";
  created: "";
  modified: "";
  laxative: TLaxative;
};

export const initialLaxationLog: TLaxationLog = {
  id: 0,
  laxative_id: 0,
  subject_id: 0,
  unit_id: 0,
  staff_id: 0,
  administered: "",
  created: "",
  modified: "",
  laxative: initialLaxative,
};
export default TLaxationLog;
