import EventConst from '@/constants/EventConstants'
import dispatcher from '@/dispatchers/Dispatcher'

/**
 * ローディング開始
 */
export function startLoaded() {
  dispatch(true)
}

/**
 * ローディング終了
 */
export function finishLoaded() {
  dispatch(false)
}

/**
 * dispatch
 */
function dispatch(value) {
  dispatcher.dispatch({
    type: EventConst.action_type.app.app_loading,
    value
  })
}
