/* eslint-disable */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import request, { AxiosError } from "axios";
import { AsyncThunkConfig } from "../../store";
import API from "../../constants/ApiConstants";
import ValidationErrors from "../ValidationErrors";

// createAsyncThunk< 返り値の型, 引数の型, thunkAPIの型 >( "originalname", async( 引数, thunkAPI ) => {
//   const { xxx } = thunkAPI.getState().xxx;
//   return request.
//  } );
export type LogoutState = {
  success: boolean;
  message: string;
  isLoading: boolean;
};
const initialState: LogoutState = {
  success: false,
  message: "",
  isLoading: false,
};
export const fetchLogout = createAsyncThunk<
  boolean,
  undefined,
  AsyncThunkConfig<ValidationErrors>
>("auth/logout", async (arg, thunkAPI) => {
  let response: any = [];
  try {
    response = await request.post(API.Auth.Logout);
  } catch (err: any) {
    let error: AxiosError<ValidationErrors> = err;
    if (!error.response) {
      throw err;
    }
    return thunkAPI.rejectWithValue(error.response.data);
  }

  return response;
});

const fetchSlice = createSlice({
  name: "fetch/logout",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(fetchLogout.fulfilled, (state: any, action: any) => {
      console.log("logout fulfilled");
      state.success = action.payload;
      state.isLoading = false;
      window.location.href = "/";
    });
    builder.addCase(fetchLogout.pending, (state: any, action: any) => {
      console.log("logout pending");
      state.isLoading = true;
    });
    builder.addCase(fetchLogout.rejected, (state: any, action: any) => {
      console.log("logout rejected");
      state.status = "error";
      state.message = action.error.message;
      state.isLoading = false;
    });
  },
});

export default fetchSlice.reducer;
