import './Timeline.scss'
import { localizedUiWords } from '@/features/locale'
import { LocaleContext } from '@/providers/LocaleProvider'
import activeAlertLogsStore from '@/stores/ActiveAlertLogsStore'
import excretionResultStore from '@/stores/ExcretionResultStore'
import facilityStore from '@/stores/FacilityStore'
import finalDefecationTimeStore from '@/stores/FinalDefecationTimeStore'
import laxationLogsStore from '@/stores/LaxationLogsStore'
import _ from 'lodash'
import * as moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import VisjsTimeline from 'react-visjs-timeline'

// massiveは後で削除
const amountTemplate = {
  adhesion: 1,
  little: 2,
  medium: 3,
  many: 4,
  massive: 5
}
const DEFECATION_ALERT_HOUR = 72
const WIDTH_HOUR = 24

class Timeline extends Component {
  static contextType = LocaleContext

  state = {
    items: [],
    groups: [],
    options: {
      /*
       * timeline のオプションを定義
       *
       * add: add new items by double tapping
       * updateTime: drag items horizontally
       * updateGroup: drag items from one group to another
       * overrideItems: allow these options to override item.editable
       */
      editable: {
        add: false,
        updateTime: false,
        updateGroup: false,
        overrideItems: false
      },
      width: '100%',

      // 時刻が近くて表示が重なるようなケースにどう扱うかの指定(trueにすると冒頭の例のようにずらして表示)
      stack: false,

      // 日付ラベルの非表示
      showMajorLabels: true,

      // 現在の時刻のポイントにラインを表示
      showCurrentTime: true,

      // デフォルトのアイテムのタイプ指定
      type: 'range',

      // timelineの表示の最大幅
      maxHeight: '800px',
      moveable: false,

      // タイムライン拡大縮小の禁止
      zoomable: true,

      // 最小時間軸を指定
      zoomMin: 1000 * 60 * 60 * WIDTH_HOUR,

      // 最大時間軸を指定
      zoomMax: 1000 * 60 * 60 * WIDTH_HOUR,
      zoomKey: 'ctrlKey',

      // 時間軸を指定
      timeAxis: { scale: 'hour', step: 1 },
      start: moment().format('YYYY-MM-DDT00:00:00'),
      end: moment().add(WIDTH_HOUR, 'hours').format('YYYY-MM-DDT00:00:00'),
      orientation: 'top',
      align: 'auto',
      verticalScroll: true,
      horizontalScroll: true,
      margin: 0,
      format: {
        minorLabels: {
          millisecond: 'SSS',
          second: 's',
          minute: 'HH:mm',
          hour: 'HH:mm',
          weekday: 'M/D(ddd)',
          day: 'M/D(ddd)',
          week: 'w',
          month: 'MMM',
          year: 'YYYY'
        },
        majorLabels: {
          millisecond: 'HH:mm:ss',
          second: 'YYYY/M/D HH:mm',
          minute: 'YYYY/M/D (ddd)',
          hour: 'YYYY/M/D (ddd)',
          weekday: 'YYYY年',
          day: 'YYYY年M月',
          week: 'YYYY年M月',
          month: 'YYYY年',
          year: ''
        }
      }
    }
  }

  componentDidMount() {
    // eslint-disable-next-line
    finalDefecationTimeStore.on('final_defecation_time', (v) => {
      this.setGroup(this.props.carePeople)
    })

    // eslint-disable-next-line
    activeAlertLogsStore.on('active_alert_logs', (v) => {
      this.setGroup(this.props.carePeople)
    })

    // eslint-disable-next-line
    excretionResultStore.on('excretion_result_add', (v) => {
      this.setGroup(this.props.carePeople)
    })

    // eslint-disable-next-line
    excretionResultStore.on('excretion_result_detail', (v) => {
      this.setGroup(this.props.carePeople)
    })

    // eslint-disable-next-line
    excretionResultStore.on('excretion_result_delete', (v) => {
      this.setGroup(this.props.carePeople)
    })

    // eslint-disable-next-line
    laxationLogsStore.on('laxation_logs_add', (v) => {
      this.setGroup(this.props.carePeople)
    })

    // eslint-disable-next-line
    laxationLogsStore.on('laxation_logs_detail', (v) => {
      this.setGroup(this.props.carePeople)
    })

    // eslint-disable-next-line
    laxationLogsStore.on('laxation_logs_delete', (v) => {
      this.setGroup(this.props.carePeople)
    })

    this.setItem(this.props.excretionResults, this.props.laxationLogs)
    this.setGroup(this.props.carePeople)
    this.setDatetime(this.props.datetime)
    this.localizeDateFormat()
  }

  componentWillUnmount() {
    finalDefecationTimeStore.removeAllListeners('final_defecation_time')
    activeAlertLogsStore.removeAllListeners('active_alert_logs')
    excretionResultStore.removeAllListeners('excretion_result_add')
    excretionResultStore.removeAllListeners('excretion_result_detail')
    excretionResultStore.removeAllListeners('excretion_result_delete')
    laxationLogsStore.removeAllListeners('laxation_logs_add')
    laxationLogsStore.removeAllListeners('laxation_logs_detail')
    laxationLogsStore.removeAllListeners('laxation_logs_delete')
    // finalDefecationTimeStore.removeAllListeners("final_defecation_time");
  }

  setExcretionDataToList(excretionDataList, excretionData, hourKey) {
    excretionDataList[excretionData.subject_id][hourKey].push({
      subject_id: excretionData.subject_id,
      result: excretionData.result,
      urine_amount:
        excretionData.urine_amount === null
          ? null
          : amountTemplate[excretionData.urine_amount],
      amount:
        excretionData.amount === null
          ? null
          : amountTemplate[excretionData.amount],
      firmness: excretionData.firmness,
      leaked: excretionData.leaked,
      alert_log_id: excretionData.alert_log_id
    })
  }

  setLaxationLogsDataToList(laxationLogsDataList, laxationLogsData, hourKey) {
    laxationLogsDataList[laxationLogsData.subject_id][hourKey].push({
      subject_id: laxationLogsData.subject_id,
      administered: laxationLogsData.administered,
      laxative_id:
        laxationLogsData.laxative_id === null
          ? 0
          : laxationLogsData.laxative_id,
      amount: laxationLogsData.amount === null ? 0 : laxationLogsData.amount,
      unit_id: laxationLogsData.unit_id
    })
  }

  setItem(excretionResults, laxationLogs) {
    // 各時間帯に含まれる排泄記録データを、利用者ごとにまとめる。
    // {
    //   subject_id:{
    //     unixDatatime:[
    //       {subject_id, result, ...},
    //       {subject_id, result, ...},
    //       ...
    //     ],
    //     unixDatetime:[
    //       ...
    //     ]
    //   },
    //   subject_id:{
    //   ...
    // }

    const excretionDataByGroup = {}
    if (excretionResults !== null) {
      _.each(excretionResults, (excretionData) => {
        const hourKey = moment(excretionData.confirmed)
          .minute(0)
          .second(0)
          .unix()

        if (!excretionDataByGroup[excretionData.subject_id]) {
          excretionDataByGroup[excretionData.subject_id] = {}
        }

        if (!excretionDataByGroup[excretionData.subject_id][hourKey]) {
          excretionDataByGroup[excretionData.subject_id][hourKey] = []
        }

        this.setExcretionDataToList(
          excretionDataByGroup,
          excretionData,
          hourKey
        )
      })
    }

    const laxationLogsDataByGroup = {}
    if (laxationLogs !== null) {
      _.each(laxationLogs, (laxationLogsData) => {
        const hourKey = moment(laxationLogsData.administered)
          .minute(0)
          .second(0)
          .unix()

        if (!laxationLogsDataByGroup[laxationLogsData.subject_id]) {
          laxationLogsDataByGroup[laxationLogsData.subject_id] = {}
        }

        if (!laxationLogsDataByGroup[laxationLogsData.subject_id][hourKey]) {
          laxationLogsDataByGroup[laxationLogsData.subject_id][hourKey] = []
        }

        this.setLaxationLogsDataToList(
          laxationLogsDataByGroup,
          laxationLogsData,
          hourKey
        )
      })
    }

    // vis-timelineのsetItemに渡すためのデータを作成する
    const makeExcretionItems = (detailData, hourKey, groupId) => {
      const items = []
      const totalFecesAmount = calcTotalFieldValue(detailData, 'amount')
      const totalUrineAmount = calcTotalFieldValue(detailData, 'urine_amount')

      const urineExist = judgeResultExist(detailData, 'urine')
      const fecesExist = judgeResultExist(detailData, 'feces')
      const urineAndFecesExist = judgeResultExist(detailData, 'urine_and_feces')

      const isUrineLeaked = judgeResultLeaked(detailData, 'urine')
      const isFecesLeaked = judgeResultLeaked(detailData, 'feces')

      const isUrineAndFecesLeaked = judgeResultLeaked(
        detailData,
        'urine_and_feces'
      )
      const isWatery =
        detailData.filter((data) => data.firmness === 'watery').length > 0

      const startHour = moment.unix(hourKey, 'YYYY-MM-DD HH:mm')
      const halfHour = startHour.clone().add(30, 'minutes')
      const endHour = startHour.clone().add(1, 'hours')

      const urineStyleClass = isUrineLeaked
        ? 'item-urine item-leaked'
        : 'item-urine'
      let fecesStyleClass = isWatery ? 'item-watery' : 'item-feces'
      fecesStyleClass += isFecesLeaked ? ' item-leaked' : ''

      // 何もない
      if ((urineExist || fecesExist || urineAndFecesExist) === false) {
        const item = buildItem('item-none', startHour, endHour, 0, groupId)
        items.push(item)
        return items
      }

      // 便尿２つマークをつける場合
      if ((urineExist && fecesExist) || urineAndFecesExist) {
        const urineClassName = isUrineAndFecesLeaked
          ? urineStyleClass + ' item-leaked'
          : urineStyleClass
        const urineContent = makeContent(totalUrineAmount)
        const urineItem = buildItem(
          urineClassName,
          startHour,
          halfHour,
          urineContent,
          groupId
        )
        items.push(urineItem)

        const fecesClassName = isUrineAndFecesLeaked
          ? fecesStyleClass + ' item-leaked'
          : fecesStyleClass
        const fecesContent = makeContent(totalFecesAmount)
        const fecesItem = buildItem(
          fecesClassName,
          halfHour,
          endHour,
          fecesContent,
          groupId
        )
        items.push(fecesItem)
        return items
      }

      if (urineExist) {
        const urineContent = makeContent(totalUrineAmount)
        const leftItem = buildItem(
          urineStyleClass,
          startHour,
          halfHour,
          urineContent,
          groupId
        )
        items.push(leftItem)
        const rightItem = buildItem(
          'item-blank',
          halfHour,
          endHour,
          '',
          groupId
        )
        items.push(rightItem)
        return items
      }

      if (fecesExist) {
        const leftItem = buildItem(
          'item-blank',
          startHour,
          halfHour,
          '',
          groupId
        )
        items.push(leftItem)
        const fecesContent = makeContent(totalFecesAmount)
        const rightItem = buildItem(
          fecesStyleClass,
          halfHour,
          endHour,
          fecesContent,
          groupId
        )
        items.push(rightItem)
        return items
      }
    }

    // vis-timelineのsetItemに渡すためのデータを作成する
    const makeLaxationLogsItems = (hourKey, groupId) => {
      const items = []

      const startHour = moment.unix(hourKey, 'YYYY-MM-DD HH:mm')
      const endHour = startHour.clone().add(1, 'hours')
      items.push({
        className: 'item-laxative',
        start: startHour,
        end: endHour,
        content: '',
        type: 'range',
        group: groupId,
        editable: { updateTime: false, updateGroup: false }
      })
      return items
    }

    const calcTotalFieldValue = (detailData, fieldName) => {
      const initialValue = 0
      return detailData.reduce(
        (accumulator, currentValue) => accumulator + currentValue[fieldName],
        initialValue
      )
    }

    const judgeResultExist = (detailData, result) => {
      return detailData.filter((data) => data.result === result).length > 0
    }

    const judgeResultLeaked = (detailData, result) => {
      return (
        detailData.filter((data) => data.result === result && data.leaked)
          .length > 0
      )
    }

    const makeContent = (totalAmount) => {
      if (totalAmount === 0) return ''
      const amount = totalAmount > 4 ? 4 : totalAmount
      return '' + amount
    }

    const buildItem = (className, start, end, content, groupId) => {
      const item = {
        className,
        start,
        end,
        content,
        type: 'range',
        group: groupId,
        editable: { updateTime: false, updateGroup: false }
      }
      return item
    }

    let items = []
    if (_.size(excretionDataByGroup) > 0) {
      _.each(excretionDataByGroup, (userData, groupId) => {
        _.each(userData, (detailData, hourKey) => {
          items = items.concat(makeExcretionItems(detailData, hourKey, groupId))
        })
      })
    }
    if (_.size(laxationLogsDataByGroup) > 0) {
      _.each(laxationLogsDataByGroup, (userData, groupId) => {
        _.each(userData, (v, hourKey) => {
          items = items.concat(makeLaxationLogsItems(hourKey, groupId))
        })
      })
    }

    this.setState({
      items
    })
  }

  setGroup(carePeople) {
    const [locale] = this.context
    const uiWords = localizedUiWords(locale.lang).pages.excretionResults

    let groups = []

    if (carePeople !== null && this.props.finalDefecationTime) {
      groups = _.map(_.filter(carePeople, { status_code: '0' }), (v) => {
        // 対象利用者の最新の排便時間
        const peopleFinalDefecationTime = _.find(
          this.props.finalDefecationTime,
          (finaleDefecation) => {
            return v.id === finaleDefecation.subject_id
          }
        )

        // 対象利用者の下剤アラート
        const peopleLaxativeAlerts = _.find(
          this.props.laxativeAlerts,
          (laxativeAlerts) => {
            return v.id === laxativeAlerts.subject_id
          }
        )

        let defecationTime = null
        let defecationTimeStr = ''
        let defecationTimeColor = ''
        if (peopleFinalDefecationTime && peopleFinalDefecationTime.time) {
          defecationTime = moment().diff(
            moment(peopleFinalDefecationTime.time),
            'hours'
          )
          defecationTimeStr =
            defecationTime > 24
              ? moment().diff(moment(peopleFinalDefecationTime.time), 'days') +
                uiWords.daysAgoLabel
              : defecationTime + uiWords.hoursAgoLabel
          defecationTimeColor =
            defecationTime > DEFECATION_ALERT_HOUR ? 'red' : 'black'
        } else {
          defecationTimeStr = uiWords.noRecordsLabel
        }

        let laxativeAlertStr = '<div></div>'
        if (peopleLaxativeAlerts && peopleLaxativeAlerts.confirmed) {
          const facility = facilityStore.getFacilityDetail()
          if (
            moment().diff(peopleLaxativeAlerts.confirmed, 'hours') <
            facility.medicine_alert_prioritize_hour
          ) {
            laxativeAlertStr = `<div style="padding: 3px; color: #FF0000; white-space: nowrap;"><div>${uiWords.laxativeFollowupNotice[0]}</div><div>${uiWords.laxativeFollowupNotice[1]}</div></div>`
          }
        }

        const groupLeft =
          '<div class="careperson-group">' +
          `<div class="place">${v.place}</div>` +
          `<div class="name">${v.name}</div>` +
          '</div>'

        const groupCenter =
          `<div style="border-left: 1px solid gray; padding: 3px; white-space: nowrap;">${uiWords.lastFecesLabel}<p style="color:` +
          defecationTimeColor +
          ';margin-top:5px;">' +
          defecationTimeStr +
          '</p></div>'
        const groupRight = laxativeAlertStr

        return {
          id: v.id,
          content: groupLeft + groupCenter + groupRight
        }
      })
    }

    this.setState({
      groups
    })
  }

  setDatetime(datetime) {
    const options = this.state.options
    options.start = moment(datetime.clone().format('YYYY-MM-DDT00:00:00'))
    options.end = moment(datetime.clone().format('YYYY-MM-DDT00:00:00')).add(
      WIDTH_HOUR,
      'hours'
    )
    this.setState({
      options
    })
  }

  // eslint-disable-next-line
  componentDidUpdate(prebProps, prebState) {
    if (
      prebProps.excretionResults !== this.props.excretionResults ||
      prebProps.laxationLogs !== this.props.laxationLogs
    ) {
      this.setItem(this.props.excretionResults, this.props.laxationLogs)
    }

    if (prebProps.carePeople !== this.props.carePeople) {
      this.setGroup(this.props.carePeople)
    }

    if (prebProps.datetime !== this.props.datetime) {
      this.setDatetime(this.props.datetime)
    }
  }

  onClick(e) {
    if (e.what === 'group-label') {
      // 部屋番号・利用者名をクリック
      const className = e.event.srcElement.className
      if (className === 'place' || className === 'name') {
        this.props.onGroupLabelClick(e)
      }
    }
    if (e.group === null) return
    if (e.what === 'background' || e.what === 'item') {
      if (this.isExistItem(e.group, moment(e.time))) {
        this.props.onUpdate(e)
      } else {
        this.props.onAdd(e)
      }
    }
  }

  isExistItem(groupId, time) {
    let isExist = false
    _.each(this.state.items, (item) => {
      // 利用者とアイテムの時刻まで同じデータがある場合は、
      // アイテムが存在したとする。
      if (
        parseInt(item.group, 10) === groupId &&
        item.start.isSame(time, 'hour')
      ) {
        isExist = true
        return false
      }
    })
    return isExist
  }

  /**
   * タイムラインのヘッダー上の日付フォーマットをローカライズ
   */
  localizeDateFormat = () => {
    const [locale] = this.context
    const uiWords = localizedUiWords(locale.lang).pages.excretionResults

    // format.majorLabels の minute と second を切り替え
    const options = this.state.options
    options.format.majorLabels = {
      ...this.state.options.format.majorLabels,
      minute: uiWords.timelineOptionFormatMajorLabelsMinute,
      hour: uiWords.timelineOptionFormatMajorLabelsHour
    }

    this.setState({ options })
  }

  render() {
    return (
      <div className="timeline">
        <VisjsTimeline
          options={this.state.options}
          items={this.state.items}
          groups={this.state.groups}
          clickHandler={this.onClick.bind(this)}
        />
      </div>
    )
  }
}

Timeline.propTypes = {
  carePeople: PropTypes.array,
  finalDefecationTime: PropTypes.array,
  excretionResults: PropTypes.array,
  laxationLogs: PropTypes.array,
  datetime: PropTypes.instanceOf(moment),
  finalFefecationTime: PropTypes.array,
  laxativeAlerts: PropTypes.array,
  onAdd: PropTypes.func,
  onUpdate: PropTypes.func,
  onMove: PropTypes.func,
  onRemove: PropTypes.func,
  onGroupLabelClick: PropTypes.func
}

export default Timeline
