import API from '../constants/ApiConstants'
import dispatcher from '../dispatchers/Dispatcher'
import request from 'superagent'

export function getDefecationTime(query) {
  request
    .get(API.FinalDefecationTime.Get)
    .query(query)
    .set('Accept', 'application/json')
    .end(function (err, res) {
      if (err === null) {
        const body = res.body ? res.body : JSON.parse(res.text)
        dispatcher.dispatch({ type: 'FINAL_DEFECATION_TIME', body })
      } else {
        dispatcher.dispatch({ type: 'ERROR', res })
      }
    })
}
