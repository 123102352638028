/* eslint-disable */
import { EventEmitter } from "events";
import dispatcher from "../dispatchers/Dispatcher";

class CarePeopleStore extends EventEmitter {
  constructor() {
    super();
    this.peopleList = null;
    this.personDetail = null;
  }
  getPeopleList() {
    return this.peopleList;
  }
  handleActions(action) {
    switch (action.type) {
      case "PEOPLE_ALL":
        this.emit("people_all", action.body);
        this.peopleList = action.body;
        break;
      case "PEOPLE_SORT":
        this.emit("people_sort", action.body);
        break;
      case "PEOPLE_ASYNC_SORT":
        this.emit("people_async_sort", action.body);
        break;
      case "PEOPLE_GROUP":
        this.emit("people_group", action.body);
        break;
      case "PERSON_DETAIL":
        this.emit("person_detail", action.body);
        this.personDetail = action.body;
        break;
      case "PERSON_EDIT":
        this.emit("person_edit", action.body);
        break;
      case "PERSON_ADD":
        this.emit("person_add", action.body);
        break;
      case "PERSON_DELETE":
        this.emit("person_delete", action.body);
        break;
      case "ERROR":
        this.emit("people_error", action.res);
        break;
      default:
        break;
    }
  }
}

const carePeopleStore = new CarePeopleStore();
dispatcher.register(carePeopleStore.handleActions.bind(carePeopleStore));

export default carePeopleStore;