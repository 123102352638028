import BaseStyles from '../../features/styles/BaseStyles'
import PropTypes from 'prop-types'
import React from 'react'

const AppDialog = ({
  isActive,
  title,
  choiceButtonLists,
  onClick,
  onDialogClosing
}) => {
  const classes = BaseStyles()

  return (
    <div
      className={isActive ? classes.appDialog : classes.appDialog + ' hide'}
      onClick={(e) => onDialogClosing(e)}
    >
      <div className="closeButton"></div>
      <div className="content">
        <p>{title}</p>

        <div className="flex choiceButtons">
          {choiceButtonLists.map((choiceButtonLabel, index) => (
            <div onClick={(e) => onClick(e.target.innerHTML)} key={index}>
              {choiceButtonLabel}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

AppDialog.propTypes = {
  isActive: PropTypes.bool,
  title: PropTypes.string,
  choiceButtonLists: PropTypes.arrayOf(PropTypes.string),
  onClick: PropTypes.func,
  onDialogClosing: PropTypes.func
}

export default AppDialog
