import { Typography, TypographyProps } from '@material-ui/core'
import React from 'react'

/**
 * 腐敗防止用 MUI Typography ラッパーコンポーネント
 *
 * MUI が後方互換性を破壊するような仕様変更をした場合に影響を抑える
 */
export const SubHeading = ({
  className,
  children,
  ...props
}: TypographyProps) => {
  return (
    <Typography variant="h2" className={`.sub-heading ${className}`} {...props}>
      {children}
    </Typography>
  )
}
