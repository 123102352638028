import { isJaJP, localizedUiWords } from '@/features/locale'
import { LocaleContext } from '@/providers/LocaleProvider'
import React, { ReactNode, useContext } from 'react'
import { Link } from 'react-router-dom'

const HardwareError = () => {
  const [locale] = useContext(LocaleContext)
  const uiWords = localizedUiWords(locale.lang).pages.app

  return (
    <>
      <div className="path1"></div>
      <div className="path2"></div>
      <div className="path3"></div>
      <div className="path4"></div>
      <div className="path5"></div>
      <div className="path6"></div>
      <p className="error_msg hw_error">
        {(uiWords.hardwareErrorMessage as Array<string>).map((message) => (
          <>
            {message}
            <br />
          </>
        ))}
      </p>
    </>
  )
}

const GyroError = () => {
  const [locale] = useContext(LocaleContext)
  const uiWords = localizedUiWords(locale.lang).pages.app

  return (
    <>
      <div className="path1"></div>
      <div className="path2"></div>
      <div className="path3"></div>
      <div className="path4"></div>
      <div className="path5"></div>
      <div className="path6"></div>
      <p className="error_msg">
        {(uiWords.gyroErrorMessage as Array<string>).map((message) => (
          <>
            {message}
            <br />
          </>
        ))}
      </p>
    </>
  )
}

const FloodError = () => {
  const [locale] = useContext(LocaleContext)
  const uiWords = localizedUiWords(locale.lang).pages.app

  return (
    <>
      <div className="path1"></div>
      <div className="path2"></div>
      <div className="path3"></div>
      <div className="path4"></div>
      <div className="path5"></div>
      <div className="path6"></div>
      <p className="error_msg water_error">
        {(uiWords.floodErrorMessage as Array<string>).map((message) => (
          <>
            {message}
            <br />
          </>
        ))}
      </p>
    </>
  )
}

const NoSignalError = () => {
  const [locale] = useContext(LocaleContext)
  const uiWords = localizedUiWords(locale.lang).pages.app

  return (
    <>
      <div className="path1"></div>
      <div className="path2"></div>
      <div className="path3"></div>
      <div className="path4"></div>
      <div className="path5"></div>
      <div className="path6"></div>
      <p className="error_msg connection_error">
        {(uiWords.noSignalErrorMessage as Array<string>).map(
          (message, index) => (
            <React.Fragment key={index}>
              {message}
              <br />
            </React.Fragment>
          )
        )}
      </p>
    </>
  )
}

/**
 * ペアリング未実施エラー
 *
 * TODO: NotificationTable での扱いが別のエラーと異なるので一旦個別にexportした
 */
export const NotPairingError = () => {
  const [locale] = useContext(LocaleContext)
  const uiWords = localizedUiWords(locale.lang).pages.app

  const notPairingErrorMessage = uiWords.notPairingErrorMessage as Array<string>

  const NotPairingErrorMessageJa = () => (
    <>
      {notPairingErrorMessage[0]}
      <br />
      <Link to="setting/pairing">{notPairingErrorMessage[1]}</Link>
      {notPairingErrorMessage[2]}
    </>
  )

  const NotPairingErrorMessageEn = () => (
    <>
      <Link to="setting/pairing">{notPairingErrorMessage[0]}</Link>
      {notPairingErrorMessage[1]}
      <br />
      {notPairingErrorMessage[2]}
    </>
  )

  return (
    <>
      <div className="path1"></div>
      <p className="error_msg connection_error">
        {isJaJP(locale.lang) ? (
          <NotPairingErrorMessageJa />
        ) : (
          <NotPairingErrorMessageEn />
        )}
      </p>
    </>
  )
}

export type SensorError = {
  type: string
  message: ReactNode
}

export const sensorErrors = {
  1: {
    type: 'HW_error',
    message: <HardwareError />
  },
  3: {
    type: 'HW_error',
    message: <HardwareError />
  },
  4: {
    type: 'HW_error',
    message: <HardwareError />
  },
  6: {
    type: 'HW_error',
    message: <HardwareError />
  },
  24: {
    type: 'HW_error',
    message: <HardwareError />
  },
  25: {
    type: 'gyro_error',
    message: <GyroError />
  },
  26: {
    type: 'water_error',
    message: <FloodError />
  },
  27: {
    type: 'connection_error',
    message: <NoSignalError />
  }
}
