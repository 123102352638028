import CareNoteCalendar from './CareNoteCalendar'
import './CareNoteDatePicker.scss'
import SystemConst from '@/constants/SystemConstants'
import { localizedUiWords } from '@/features/locale'
import { LocaleContext } from '@/providers/LocaleProvider'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import Form from 'react-jsonschema-form'

// フォームの設定
const schema = {
  type: 'object',
  required: ['confirmed'],
  properties: {
    confirmed: {
      type: 'string',
      title: ''
    }
  }
}

const uiSchema = {
  confirmed: {
    type: 'date',
    'ui:field': 'datetime'
  }
}

export const CareNoteDatePicker = ({ currentDatetime, onClick, onChange }) => {
  const [locale] = useContext(LocaleContext)
  const uiWords = localizedUiWords(locale.lang).pages.excretionResults

  return (
    <div className="timeline-container">
      <div className="calendar-date-picker">
        <button className="today-select-button" onClick={onClick}>
          {uiWords.todayButtonLabel}
        </button>

        <Form
          schema={schema}
          uiSchema={uiSchema}
          fields={{ datetime: CareNoteCalendar }}
          formData={{
            confirmed: currentDatetime.format(SystemConst.DateFormat.Calendar)
          }}
          showErrorList={false}
          onChange={onChange}
        ></Form>
      </div>
    </div>
  )
}

CareNoteDatePicker.propTypes = {
  // moment
  currentDatetime: PropTypes.object,
  onClick: PropTypes.func,
  onChange: PropTypes.func
}
