import * as activeAlertLogsAction from '../actions/ActiveAlertLogsAction'
import * as alertAction from '../actions/AlertAction'
import * as carePeopleAction from '../actions/CarePeopleAction'
import * as excretionResultAction from '../actions/ExcretionResultAction'
import * as facilityAction from '../actions/FacilityAction'
import * as finalDefecationTimeAction from '../actions/FinalDefecationTimeAction'
import * as laxationLogsAction from '../actions/LaxationLogsAction'
import * as laxativeAction from '../actions/LaxativeAction'
import AppDialog from '../components/App/AppDialog'
import AppLoading from '../components/App/AppLoading'
import GroupSelectField from '../components/common/GroupSelectField'
import SystemConst from '../constants/SystemConstants'
import refreshIcon from '../images/redo-alt-solid.svg'
import activeAlertLogsStore from '../stores/ActiveAlertLogsStore'
import alertStore from '../stores/AlertStore'
import authStore from '../stores/AuthStore'
import carePeopleStore from '../stores/CarePeopleStore'
import errorStore from '../stores/ErrorStore'
import excretionResultStore from '../stores/ExcretionResultStore'
import facilityStore from '../stores/FacilityStore'
import finalDefecationTimeStore from '../stores/FinalDefecationTimeStore'
import groupStore from '../stores/GroupStore'
import laxationLogsStore from '../stores/LaxationLogsStore'
import laxativeStore from '../stores/LaxativeStore'
import './ExcretionResults.scss'
import { AppHeader } from '@/components/App/AppHeader'
import {
  CareNoteDialog,
  CareNoteExplanation,
  CareNoteLegends
} from '@/features/careNotes/components'
import CareNoteCalendar from '@/features/careNotes/components/CareNoteDatePicker/CareNoteCalendar'
import Timeline from '@/features/careNotes/components/CareNoteTimeline/Timeline'
import { getDiffLocalTimeAndJST, localizedUiWords } from '@/features/locale'
import { LocaleContext } from '@/providers/LocaleProvider'
import _ from 'lodash'
import * as moment from 'moment'
import React, { Component } from 'react'
import Form from 'react-jsonschema-form'
import { Link, withRouter } from 'react-router-dom'

class ExcretionResults extends Component {
  static contextType = LocaleContext

  constructor() {
    super()
    this.state = {
      groupId: 0,
      excretionResults: null,
      excretionDetailDatas: [],
      unsavedExcretionDetailDatas: [],
      laxationLogs: null,
      laxationLogsDetailDatas: [],
      unsavedLaxationLogsDetailDatas: [],
      alertLogs: {},
      carePeople: null,
      loading: true,
      totalCount: 0,
      currentPage: 1,
      perPage: 1000,
      excretionResultId: null,
      deletedExcretionResultId: null,
      laxationLogsId: null,
      deletedLaxationLogsId: null,
      finalDefecationTime: null,
      laxativeAlerts: null,
      dialogExcretion: false,
      dialogLaxation: false,
      deletingDialog: false,
      // 排泄記録削除時に必要
      startHour: null,
      removedId: null,
      confirmed: moment().hour(0).minute(0).second(0).millisecond(0),
      excretionDetailData: {
        subject_id: null,
        name: '',
        confirmed: '',
        result: 'none',
        urine_amount: 'none',
        amount: 'none',
        firmness: 'none',
        description: '',
        isEdit: false
      },
      laxationLogsDetailData: {
        subject_id: null,
        name: '',
        dosage_date: '',
        laxative_id: null,
        amount: '',
        unit_id: null,
        isEdit: false
      },
      laxativeDatas: null,
      unitList: null,

      // 1: 排泄記録, 2: 下剤記録
      activeTab: 1,

      // 0 のときは使用しない
      reservedActiveTab: 0,

      unsavedExcretionIndex: 0,
      unsavedLaxationLogsIndex: 0,
      isGettingTimelineCompleted: {
        excretionResults: false,
        laxationLogs: false
      },

      // SCOP連携フラグ
      scopCooperation: null
    }
    this.perPage = 1000
  }

  refresh() {
    finalDefecationTimeAction.getDefecationTime()
    activeAlertLogsAction.getActiveAlertLogs()
    laxativeAction.laxatives()
    laxativeAction.medicineUnitMaster()
    let groupId = this.getGroupFromCookie()
    if (!this.isEmptyGroup(groupId)) {
      groupId = 0
    }
    carePeopleAction.carePeopleGroupFilter(
      groupId > 0 ? { group_id: groupId } : ''
    )
    this.setState({
      loading: true
    })
  }

  componentDidMount() {
    // ページ遷移による状態の引継ぎ
    this.setPageTransitionState()

    // SCOP連携確認のため施設データを参照する
    if (facilityStore.getFacilityDetail() !== null) {
      this.setState({
        scopCooperation:
          facilityStore.getFacilityDetail().scop_cooperation !== null,
        loading: true
      })
    } else if (authStore.getFacilityId()) {
      facilityAction.facility(authStore.getFacilityId())
    }

    facilityStore.on('facility_detail', (v) => {
      this.setState({
        scopCooperation: v.scop_cooperation !== null,
        loading: false
      })
    })

    groupStore.on('group_all', (v) => {
      const groupIdList = v.map((g) => {
        return g.id
      })
      const cookieData = this.getGroupFromCookie()
      if (cookieData !== null && groupIdList.indexOf(cookieData) >= 0) {
        this.selectGroup(cookieData)
      }
      this.setState({
        groups: v
      })
    })

    finalDefecationTimeStore.on('final_defecation_time', (v) => {
      this.setState({
        finalDefecationTime: v,
        loading: false
      })
    })

    activeAlertLogsStore.on('active_alert_logs', (v) => {
      this.setState({
        laxativeAlerts: v.laxative_alerts,
        loading: false
      })
    })

    // 指定時刻＋前後日のデータ取得
    excretionResultStore.on('excretion_result_all', (v) => {
      this.setState({
        excretionResults: v.data,
        currentPage: v.pagination.current_page,
        totalCount: v.pagination.count,
        loading: false
      })
    })

    excretionResultStore.on('excretion_result_pagination', (v) => {
      this.setState({
        excretionResults: v.data,
        currentPage: v.pagination.current_page,
        totalCount: v.pagination.count
      })
    })

    // ExcretionDialogに流す
    excretionResultStore.on('excretion_result_for_dialog', (v) => {
      const excretionDetailDatas = []
      _.each(v.data, (excretionDetailData) => {
        if (excretionDetailData.alert_log_id !== null) {
          // 通知時刻を取るためにAPIを更に呼ぶ
          alertAction.alert(excretionDetailData.alert_log_id)
        }
        excretionDetailDatas.push({
          id: excretionDetailData.id,
          name: excretionDetailData.name,
          subject_id: excretionDetailData.subject_id,
          alert_log_id: excretionDetailData.alert_log_id,
          confirmed: moment(excretionDetailData.confirmed),
          result: excretionDetailData.result,
          amount: excretionDetailData.amount,
          urine_amount: excretionDetailData.urine_amount,
          leaked: excretionDetailData.leaked,
          type: excretionDetailData.type,
          firmness: excretionDetailData.firmness,
          description: excretionDetailData.description,
          isEdit: false
        })
      })

      // 既存データがある場合ダイアログを表示
      // let showDialog = excretionDetailDatas.length > 0;

      if (excretionDetailDatas.length === 0) {
        const subjectId = parseInt(
          v.url
            .substring(v.url.search('subject_id'), v.url.indexOf('&'))
            .split('=')[1]
        )
        const excretionDetailData = this.state.excretionDetailData
        excretionDetailData.subject_id = subjectId
        excretionDetailData.confirmed = moment(this.state.confirmed)
        excretionDetailDatas.push(excretionDetailData)
      }

      excretionDetailDatas.sort((currentData, nextData) => {
        return currentData.confirmed.format() > nextData.confirmed.format()
          ? 1
          : -1
      })

      this.setState({
        excretionDetailDatas,
        // 下剤投与記録の取得が完了していた場合のみロード画面を外す
        loading: !this.state.dialogLaxation,
        dialogExcretion: true
      })
    })

    // ExcretionDialogに流す
    excretionResultStore.on('excretion_result_for_dialog_from_delete', (v) => {
      let excretionDetailDatas = []
      _.each(v.data, (excretionDetailData) => {
        if (excretionDetailData.alert_log_id !== null) {
          // 通知時刻を取るためにAPIを更に呼ぶ
          alertAction.alert(excretionDetailData.alert_log_id)
        }
        excretionDetailDatas.push({
          id: excretionDetailData.id,
          name: excretionDetailData.name,
          subject_id: excretionDetailData.subject_id,
          alert_log_id: excretionDetailData.alert_log_id,
          confirmed: moment(excretionDetailData.confirmed),
          result: excretionDetailData.result,
          amount: excretionDetailData.amount,
          urine_amount: excretionDetailData.urine_amount,
          leaked: excretionDetailData.leaked,
          type: excretionDetailData.type,
          firmness: excretionDetailData.firmness,
          description: excretionDetailData.description,
          isEdit: false
        })
      })

      // 既存データがある場合ダイアログを表示
      let showDialog = false
      let activeTab = 1
      let unsavedDatas = []
      let currentIndex = 0

      // 未保存データと結合する。
      const unsavedExcretionDetailDatas =
        this.state.unsavedExcretionDetailDatas.concat()
      excretionDetailDatas = _.union(
        excretionDetailDatas,
        _.filter(unsavedExcretionDetailDatas, { id: null })
      )

      // 既存データがある場合、データの整合性を保つ。ない場合は初期値を準備する。
      if (excretionDetailDatas.length > 0) {
        showDialog = true

        unsavedDatas = this.state.unsavedExcretionDetailDatas.concat()
        currentIndex = _.findIndex(unsavedDatas, {
          id: this.state.deletedExcretionResultId
        })

        currentIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex
        _.remove(unsavedDatas, { id: this.state.deletedExcretionResultId })
        unsavedDatas.sort((currentData, nextData) => {
          const currentDate =
            Object.prototype.toString.call(currentData.confirmed) ===
            '[object Date]'
              ? currentData.confirmed.format()
              : currentData.confirmed
          const nextDate =
            Object.prototype.toString.call(nextData.confirmed) ===
            '[object Date]'
              ? nextData.confirmed.format()
              : nextData.confirmed

          return currentDate > nextDate ? 1 : -1
        })
      } else {
        const subjectId = parseInt(
          v.url
            .substring(v.url.search('subject_id'), v.url.indexOf('&'))
            .split('=')[1]
        )
        const person = _.filter(this.state.carePeople, { id: subjectId })[0]
        const excretionDetailData = this.state.excretionDetailData
        excretionDetailData.subject_id = subjectId
        excretionDetailData.confirmed = moment(this.state.confirmed)
        excretionDetailData.name = person.name
        excretionDetailData.place = person.place
        excretionDetailDatas.push(excretionDetailData)
        excretionDetailDatas.sort((currentData, nextData) => {
          return currentData.confirmed.format() > nextData.confirmed.format()
            ? 1
            : -1
        })

        // 未保存の編集中下剤記録があるか
        if (this.state.unsavedLaxationLogsDetailDatas.length > 0) {
          showDialog = true

          const editingData = _.filter(
            this.state.unsavedLaxationLogsDetailDatas,
            { isEdit: true }
          )
          // あれば下剤記録タブを表示する
          if (editingData.length > 0) {
            activeTab = 2
          }
        }
      }

      this.setState({
        excretionDetailDatas,
        unsavedExcretionDetailDatas: unsavedDatas,
        unsavedExcretionIndex: currentIndex,
        // loading: false,
        dialogExcretion: showDialog,
        activeTab
      })
    })

    // eslint-disable-next-line
    excretionResultStore.on('excretion_result_delete', (v) => {
      this.getExcretionResultsForDialog(
        this.state.startHour,
        this.state.removedId
      )
      const datetime = this.state.confirmed
      this.getExcretionResultsForTimeline(datetime, this.state.groupId)
      this.getLaxationLogsForTimeline(datetime, this.state.groupId)
      this.setState({
        loading: true
        // activeTab: this.state.reservedActiveTab
      })
    })

    // eslint-disable-next-line
    excretionResultStore.on('excretion_result_add', (v) => {
      // this.handleDialogComplete(v);
    })

    // eslint-disable-next-line
    excretionResultStore.on('excretion_result_edit', (v) => {
      // this.handleDialogComplete(v);
    })

    carePeopleStore.on('people_all', (v) => {
      this.setState({
        carePeople: v,
        loading: this.state.excretionResults === null
      })
      // 利用者が登録されていれば排泄記録をとってくる．
      if (this.isEmptyGroup(0)) {
        this.selectGroup(this.state.groupId)
        this.getExcretionResultsForTimeline(
          this.state.confirmed,
          this.state.groupId
        )
        this.getLaxationLogsForTimeline(
          this.state.confirmed,
          this.state.groupId
        )
      } else {
        this.setState({
          loading: false
        })
      }
    })

    // loadingへのsetStateを集約
    carePeopleStore.on('people_group', (v) => {
      this.setState({
        carePeople: v
        // loading: this.state.excretionResults !== null
      })

      // 利用者が登録されていれば排泄記録をとってくる．
      if (this.isEmptyGroup(0)) {
        this.getExcretionResultsForTimeline(
          this.state.confirmed,
          this.state.groupId
        )
        this.getLaxationLogsForTimeline(
          this.state.confirmed,
          this.state.groupId
        )
      }

      this.setState({
        loading: false
      })
    })

    alertStore.on('alert_detail', (v) => {
      const alertLogs = this.state.alertLogs
      alertLogs[v.data.id] = moment(v.data.created).format('YYYY-MM-DD HH:mm')
      this.setState({
        alertLogs
      })
    })

    laxationLogsStore.on('laxation_logs_all', (v) => {
      this.setState({
        laxationLogs: v.data,
        currentPage: v.pagination.current_page,
        totalCount: v.pagination.count,
        loading: false
      })
    })

    laxationLogsStore.on('laxation_logs_pagination', (v) => {
      this.setState({
        laxationLogs: v.data,
        currentPage: v.pagination.current_page,
        totalCount: v.pagination.count
        // loading: false
      })
    })

    laxationLogsStore.on('laxation_logs_detail', (v) => {
      const laxationLogsDetailDatas = []
      _.each(v.data, (laxationLogsDetailData) => {
        laxationLogsDetailDatas.push({
          id: laxationLogsDetailData.id,
          subject_id: laxationLogsDetailData.subject_id,
          dosage_date: moment(laxationLogsDetailData.administered),
          laxative_id: laxationLogsDetailData.laxative_id,
          amount: laxationLogsDetailData.amount,
          unit_id: laxationLogsDetailData.unit_id,
          isEdit: false
        })
      })

      // 既存データがある場合ダイアログを表示
      // let showDialog = laxationLogsDetailDatas.length > 0;

      // 既存データ0件、初期値準備
      if (laxationLogsDetailDatas.length === 0) {
        const subjectId = parseInt(
          v.url
            .substring(v.url.search('subject_id'), v.url.indexOf('&'))
            .split('=')[1]
        )
        const laxationLogsDetailData = this.state.laxationLogsDetailData
        laxationLogsDetailData.dosage_date = moment(this.state.confirmed)
        laxationLogsDetailData.subject_id = subjectId
        laxationLogsDetailDatas.push(laxationLogsDetailData)
      }

      laxationLogsDetailDatas.sort((currentData, nextData) => {
        return currentData.dosage_date.format() > nextData.dosage_date.format()
          ? 1
          : -1
      })

      this.setState({
        laxationLogsDetailDatas,
        // 排泄記録の取得が完了していた場合のみロード画面を外す
        loading: !this.state.dialogExcretion,
        dialogLaxation: true
      })
    })

    laxationLogsStore.on('laxation_logs_detail_from_delete', (v) => {
      let laxationLogsDetailDatas = []
      _.each(v.data, (laxationLogsDetailData) => {
        laxationLogsDetailDatas.push({
          id: laxationLogsDetailData.id,
          subject_id: laxationLogsDetailData.subject_id,
          dosage_date: moment(laxationLogsDetailData.administered),
          laxative_id: laxationLogsDetailData.laxative_id,
          amount: laxationLogsDetailData.amount,
          unit_id: laxationLogsDetailData.unit_id,
          isEdit: false
        })
      })

      let showDialog = false
      let activeTab = 2
      let unsavedDatas = []
      // 未保存データと結合する。
      const unsavedLaxationLogsDetailDatas =
        this.state.unsavedLaxationLogsDetailDatas.concat()
      laxationLogsDetailDatas = _.union(
        laxationLogsDetailDatas,
        _.filter(unsavedLaxationLogsDetailDatas, { id: null })
      )

      // 既存データがある場合、データの整合性を保つ。ない場合は初期値を準備する。
      if (laxationLogsDetailDatas.length > 0) {
        showDialog = true

        unsavedDatas = this.state.unsavedLaxationLogsDetailDatas.concat()
        _.remove(unsavedDatas, { id: this.state.deletedLaxationLogsId })
      } else {
        const subjectId = parseInt(
          v.url
            .substring(v.url.search('subject_id'), v.url.indexOf('&'))
            .split('=')[1]
        )
        const person = _.filter(this.state.carePeople, { id: subjectId })[0]
        const laxationLogsDetailData = this.state.laxationLogsDetailData
        laxationLogsDetailData.dosage_date = moment(this.state.confirmed)
        laxationLogsDetailData.subject_id = subjectId
        laxationLogsDetailData.name = person.name
        laxationLogsDetailData.place = person.place
        laxationLogsDetailDatas.push(laxationLogsDetailData)
        laxationLogsDetailDatas.sort((currentData, nextData) => {
          return currentData.dosage_date.format() >
            nextData.dosage_date.format()
            ? 1
            : -1
        })

        // 未保存の編集中排泄記録があるか
        if (this.state.unsavedExcretionDetailDatas.length > 0) {
          showDialog = true

          const editingData = _.filter(this.state.unsavedExcretionDetailDatas, {
            isEdit: true
          })
          // あれば排泄記録タブを表示する
          if (editingData.length > 0) {
            activeTab = 1
          }
        }
      }

      this.setState({
        laxationLogsDetailDatas,
        unsavedLaxationLogsDetailDatas: unsavedDatas,
        dialogLaxation: showDialog,
        activeTab
      })
    })

    // eslint-disable-next-line
    laxationLogsStore.on('laxation_logs_delete', (v) => {
      this.getLaxationLogsForDialog(this.state.startHour, this.state.removedId)
      const datetime = this.state.confirmed
      this.getLaxationLogsForTimeline(datetime, this.state.groupId)
      this.setState({
        loading: true
      })
    })

    laxativeStore.on('laxatives_all', (v) => {
      this.setState({
        laxativeDatas: v
      })
    })

    laxativeStore.on('medicine_unit_master', (v) => {
      this.setState({
        unitList: v
      })
    })

    // eslint-disable-next-line
    errorStore.on('422_error', (v) => {
      // TODO: コンソールログ出す？
      console.log('更新に失敗しました。(422)')
      this.setState({
        loading: false
      })
    })

    // eslint-disable-next-line
    errorStore.on('500_error', (v) => {
      // TODO: コンソールログ出す？
      console.log('更新に失敗しました。(500)')
      this.setState({
        loading: false
      })
    })

    errorStore.on('other_status_error', (v) => {
      // TODO: コンソールログ出す？
      console.log('更新に失敗しました。(%d)', v.status)
      this.setState({
        loading: false
      })
    })

    errorStore.on('unexpected_error', (v) => {
      console.log('unexpected_error', v)
      this.setState({
        loading: false
      })
    })

    this.refresh()
  }

  componentDidUpdate(prebProps, prebState) {
    if (
      prebState.excretionResults !== this.state.excretionResults ||
      prebState.laxationLogs !== this.state.laxationLogs
    ) {
      finalDefecationTimeAction.getDefecationTime()
      activeAlertLogsAction.getActiveAlertLogs()
    }
  }

  componentWillUnmount() {
    excretionResultStore.removeAllListeners('excretion_result_all')
    excretionResultStore.removeAllListeners('excretion_result_pagination')
    excretionResultStore.removeAllListeners('excretion_result_for_dialog')
    excretionResultStore.removeAllListeners(
      'excretion_result_for_dialog_from_delete'
    )
    excretionResultStore.removeAllListeners('excretion_result_query')
    excretionResultStore.removeAllListeners('excretion_result_detail')
    excretionResultStore.removeAllListeners('excretion_result_edit')
    excretionResultStore.removeAllListeners('excretion_result_add')
    excretionResultStore.removeAllListeners('excretion_result_delete')
    laxationLogsStore.removeAllListeners('laxation_logs_all')
    laxationLogsStore.removeAllListeners('laxation_logs_pagination')
    laxationLogsStore.removeAllListeners('laxation_logs_detail')
    laxationLogsStore.removeAllListeners('laxation_logs_detail_from_delete')
    laxationLogsStore.removeAllListeners('laxation_logs_delete')
    finalDefecationTimeStore.removeAllListeners('final_defecation_time')
    activeAlertLogsStore.removeAllListeners('active_alert_logs')
    carePeopleStore.removeAllListeners('people_all')
    carePeopleStore.removeAllListeners('people_group')
    alertStore.removeAllListeners('alert_detail')
    errorStore.removeAllListeners('422_error')
    errorStore.removeAllListeners('500_error')
    errorStore.removeAllListeners('other_status_error')
    errorStore.removeAllListeners('unexpected_error')
    groupStore.removeAllListeners('group_all')
    laxativeStore.removeAllListeners('laxatives_all')
    laxativeStore.removeAllListeners('medicine_unit_master')
    facilityStore.removeAllListeners('facility_detail')
  }

  // ページ遷移による状態の引継ぎ
  setPageTransitionState() {
    if (
      // eslint-disable-next-line react/prop-types
      typeof this.props.location.state !== 'undefined' &&
      // eslint-disable-next-line react/prop-types
      this.props.location.state !== null
    ) {
      // eslint-disable-next-line react/prop-types
      if (this.props.location.state.selectDatetime !== null) {
        this.setState({
          confirmed: moment(
            // eslint-disable-next-line react/prop-types
            this.props.location.state.selectDatetime,
            SystemConst.DateFormat.Calendar
          ),
          loading: true
        })
      }
    }
  }

  getGroupFromCookie() {
    const res = {}

    const cookieStr = document.cookie
    if (cookieStr !== '') {
      const cookies = cookieStr.split('; ')
      cookies.forEach((v) => {
        v = v.split('=')
        res[v[0]] = v[1]
      })
    }
    return isNaN(parseInt(res.groupValue)) ? null : parseInt(res.groupValue)
  }

  // 指定したグループにユーザーが存在するかの確認
  isEmptyGroup(gId) {
    if (this.state.carePeople) {
      if (gId > 0) {
        const pickList = this.state.carePeople.filter((elem) => {
          return elem.group_id === gId
        })
        return pickList.length > 0
      } else {
        return this.state.carePeople.length > 0
      }
    } else {
      return false
    }
  }

  getExcretionResultsForTimeline(datetime, groupId) {
    const startDate = moment(datetime)
      .subtract(1, 'days')
      .format('YYYY-MM-DDT00:00:00')
    const endDate = moment(datetime)
      .add(1, 'days')
      .format('YYYY-MM-DDT23:59:59')
    const query = groupId > 0 ? { group_id: groupId } : {}
    query.start = startDate
    query.end = endDate
    excretionResultAction.excretionResultsPagination(query)
  }

  getExcretionResultsForDialog(datetime, subjectId) {
    const startHour = moment(datetime).format('YYYY-MM-DDTHH:00:00')
    const endHour = moment(datetime).format('YYYY-MM-DDTHH:59:59')
    const query = {
      subject_id: subjectId,
      start: startHour,
      end: endHour
    }
    excretionResultAction.excretionResultsForDialogFromDelete(query)
  }

  getLaxationLogsForTimeline(datetime, groupId) {
    const startDate = moment(datetime)
      .subtract(1, 'days')
      .format('YYYY-MM-DDT00:00:00')
    const endDate = moment(datetime)
      .add(1, 'days')
      .format('YYYY-MM-DDT23:59:59')
    const query = groupId > 0 ? { group_id: groupId } : {}
    query.start = startDate
    query.end = endDate
    laxationLogsAction.laxationLogsPagination(query)
  }

  getLaxationLogsForDialog(datetime, subjectId) {
    const startHour = moment(datetime).format('YYYY-MM-DDTHH:00:00')
    const endHour = moment(datetime).format('YYYY-MM-DDTHH:59:59')
    const query = {
      subject_id: subjectId,
      start: startHour,
      end: endHour
    }
    laxationLogsAction.laxationLogsDetailFromDelete(query)
  }

  // TODO?: (改修)selectGroup内carePeopleGroupFilterの引数にパラメータを直接挿入
  // グループ選択
  selectGroup(e) {
    if (e > 0) {
      // グループ選択時用のActionを呼び出して、ヘッダーの通知件数に干渉しないようにする。
      carePeopleAction.carePeopleGroupFilter({ group_id: e })
    } else {
      carePeopleAction.carePeopleGroupFilter()
    }

    // グループ選択時の排泄記録の取得はcarePeopleGroupFilterのリスナーで行うためコメントアウト
    // this.getExcretionResultsForTimeline(datetime, e);
    this.setState({
      groupId: e,
      loading: true
    })
  }

  // 排泄記録ダイアログで処理完了
  handleDialogComplete() {
    // グループ選択状態の保持
    const datetime = this.state.confirmed
    this.getExcretionResultsForTimeline(datetime, this.state.groupId)
    this.getLaxationLogsForTimeline(datetime, this.state.groupId)
    this.setState({
      dialogExcretion: false,
      dialogLaxation: false,
      loading: true,
      unsavedExcretionDetailDatas: [],
      unsavedLaxationLogsDetailDatas: []
    })
  }

  // 排泄記録ダイアログで「×」ボタンクリック
  // eslint-disable-next-line
  handleDialogClosing(e) {
    this.setState({
      activeTab: 1,
      dialogExcretion: false,
      dialogLaxation: false,
      excretionDetailDatas: [],
      laxationLogsDetailDatas: [],
      unsavedExcretionDetailDatas: [],
      unsavedLaxationLogsDetailDatas: [],
      unsavedExcretionIndex: 0,
      unsavedLaxationLogsIndex: 0
      // loading: true
    })
  }

  // eslint-disable-next-line
  handleLoading(e) {
    this.setState({
      loading: true
    })
  }

  // 削除確認ダイアログで「OK」をクリック
  handleExcretionDeletingDialogClick(e) {
    const [locale] = this.context
    const uiWords = localizedUiWords(locale.lang).pages.excretionResults

    switch (e) {
      case uiWords.deleteDialogOkButtonLabel:
        excretionResultAction.deleteExcretionResult(
          this.state.excretionResultId
        )

        // eslint-disable-next-line no-case-declarations
        const unsavedExcretionDetailDatas =
          this.state.unsavedExcretionDetailDatas.concat()

        if (unsavedExcretionDetailDatas.length < 2) {
          // 排泄記録に編集中のデータはあるか
          if (
            _.filter(unsavedExcretionDetailDatas, { isEdit: true }).length > 0
          ) {
            // 下剤記録タブにデータはあるか。
            if (this.state.unsavedLaxationLogsDetailDatas.length > 0) {
              // 編集中のデータはあるか。
              if (
                _.filter(this.state.unsavedLaxationLogsDetailDatas, {
                  isEdit: true
                }).length > 0
              ) {
                // 下剤記録タブに編集中のデータがあるので閉じない。
              } else {
                // 下剤記録タブに編集中のデータはないので閉じてよい。
                this.handleDialogClosing(e)
              }
            } else {
              // 下剤記録タブにデータはないので閉じてよい。
              this.handleDialogClosing(e)
            }
          } else {
            // 下剤記録タブにデータはあるか。
            if (this.state.unsavedLaxationLogsDetailDatas.length > 0) {
              // 編集中のデータはあるか。
              if (
                _.filter(this.state.unsavedLaxationLogsDetailDatas, {
                  isEdit: true
                }).length > 0
              ) {
                // 下剤記録タブに編集中のデータがあるので閉じない。
              } else {
                // 下剤記録タブに編集中のデータはないので閉じてよい。
                this.handleDialogClosing(e)
              }
            } else {
              // 下剤記録タブにデータはない。排泄記録にも編集中のデータはないので閉じてよい
              this.handleDialogClosing(e)
            }
          }

          // データの整合性を合わせる。
          _.remove(unsavedExcretionDetailDatas, {
            id: this.state.excretionResultId
          })

          // データがない場合の初期値準備
          if (unsavedExcretionDetailDatas.length === 0) {
            const excretionDetailData = { ...this.state.excretionDetailData }
            const person = _.filter(this.state.carePeople, {
              id: this.state.removedId
            })[0]

            excretionDetailData.subject_id = person.id
            excretionDetailData.name = person.name
            excretionDetailData.place = person.place
            excretionDetailData.confirmed = this.state.confirmed
            this.setState({
              excretionDetailDatas: [excretionDetailData],
              unsavedExcretionDetailDatas: []
            })
          }
        }

        this.setState({
          excretionResultId: null,
          deletedExcretionResultId: this.state.excretionResultId,
          deletingDialog: false,
          loading: true
        })
        break

      case uiWords.deleteDialogCancelButtonLabel:
        this.setState({
          excretionResultId: null,
          deletingDialog: false
        })
        break
      default:
        break
    }
  }

  // 削除確認ダイアログで「OK」をクリック
  handleLaxationLogsDeletingDialogClick(e) {
    const [locale] = this.context
    const uiWords = localizedUiWords(locale.lang).pages.excretionResults

    switch (e) {
      case uiWords.deleteDialogOkButtonLabel:
        laxationLogsAction.deleteLaxationLogs(this.state.laxationLogsId)

        // eslint-disable-next-line no-case-declarations
        const unsavedLaxationLogsDetailDatas =
          this.state.unsavedLaxationLogsDetailDatas.concat()

        if (unsavedLaxationLogsDetailDatas.length < 2) {
          // 下剤記録に編集中のデータはあるか。
          if (
            _.filter(unsavedLaxationLogsDetailDatas, { isEdit: true }).length >
            0
          ) {
            // 排泄記録タブにデータはあるか。
            if (this.state.unsavedExcretionDetailDatas.length > 0) {
              // 編集中のデータはあるか。
              if (
                _.filter(this.state.unsavedExcretionDetailDatas, {
                  isEdit: true
                }).length > 0
              ) {
                // 排泄記録タブに編集中のデータがあるので閉じない。
              } else {
                // 排泄記録タブに編集中のデータはないので閉じてよい。
                this.handleDialogClosing(e)
              }
            } else {
              // 排泄記録タブにデータはないので閉じてよい。
              this.handleDialogClosing(e)
            }
          } else {
            // 排泄記録タブにデータはあるか。
            if (this.state.unsavedExcretionDetailDatas.length > 0) {
              // 編集中のデータはあるか。
              if (
                _.filter(this.state.unsavedExcretionDetailDatas, {
                  isEdit: true
                }).length > 0
              ) {
                // 排泄記録タブに編集中のデータがあるので閉じない。
              } else {
                // 排泄記録タブに編集中のデータはないので閉じてよい。
                this.handleDialogClosing(e)
              }
            } else {
              // 排泄記録タブにデータはないので閉じてよい。
              this.handleDialogClosing(e)
            }
          }

          // データの整合性を合わせる。
          _.remove(unsavedLaxationLogsDetailDatas, {
            id: this.state.laxationLogsId
          })

          // データがない場合の初期値準備
          if (unsavedLaxationLogsDetailDatas.length === 0) {
            const laxationLogsDetailData = {
              ...this.state.laxationLogsDetailData
            }

            const person = _.filter(this.state.carePeople, {
              id: this.state.removedId
            })[0]

            laxationLogsDetailData.subject_id = person.id
            laxationLogsDetailData.name = person.name
            laxationLogsDetailData.place = person.place
            laxationLogsDetailData.dosage_date = this.state.confirmed
            this.setState({
              laxationLogsDetailDatas: [laxationLogsDetailData],
              unsavedLaxationLogsDetailDatas: []
            })
          }
        }

        this.setState({
          laxationLogsId: null,
          deletedLaxationLogsId: this.state.laxationLogsId,
          deletingDialog: false,
          loading: true
        })
        break

      case uiWords.deleteDialogCancelButtonLabel:
        this.setState({
          laxationLogsId: null,
          deletingDialog: false
        })
        break

      default:
        break
    }
  }

  // 削除確認ダイアログで「×」ボタンをクリック
  /* eslint-disable-next-line */
  handleExcretionDeletingDialogClosing(e) {
    this.setState({
      excretionResultId: null,
      deletingDialog: false
    })
  }

  handleAdd(e) {
    this.setState({
      confirmed: moment(e.time),
      excretionDetailDatas: [
        {
          id: null,
          subject_id: e.group,
          confirmed: moment(e.time),
          result: 'none',
          description: null
        }
      ],
      laxationLogsDetailDatas: [
        {
          id: null,
          subject_id: e.group,
          dosage_date: moment(e.time),
          laxative_id: null,
          amount: '',
          unit_id: null
        }
      ],

      dialogExcretion: true,
      dialogLaxation: true,
      activeTab: 1
    })
  }

  // 一時間帯のデータ取得(今のところ5つまでなので問題なし)
  handleUpdate(e) {
    const startHour = moment(e.time)
      .add(getDiffLocalTimeAndJST(), 'hours')
      .format('YYYY-MM-DDTHH:00:00')
    const endHour = moment(e.time)
      .add(getDiffLocalTimeAndJST(), 'hours')
      .format('YYYY-MM-DDTHH:59:59')
    const query = {
      subject_id: e.group,
      start: startHour,
      end: endHour
    }
    const laxativeQuery = {
      subject_id: e.group,
      start: startHour,
      end: endHour
    }
    laxationLogsAction.laxationLogsDetail(laxativeQuery)
    excretionResultAction.excretionResultsForDialog(query)
    this.setState({
      confirmed: moment(e.time),
      activeTab: 1,
      loading: true
    })
  }

  handleRemove(e) {
    switch (this.state.activeTab) {
      case 1:
        this.setState({
          reservedActiveTab: 2,
          excretionResultId: e.excretionResultId,
          deletingDialog: true,
          startHour: e.startHour,
          removedId: e.subjectId,
          unsavedExcretionDetailDatas: e.unsavedExcretionDatas,
          unsavedLaxationLogsDetailDatas: e.unsavedLaxationLogsDatas,
          unsavedLaxationLogsIndex: e.unsavedLaxationLogsIndex
        })
        break

      case 2:
        this.setState({
          reservedActiveTab: 1,
          laxationLogsId: e.laxationLogsId,
          deletingDialog: true,
          startHour: e.startHour,
          removedId: e.subjectId,
          unsavedLaxationLogsDetailDatas: e.unsavedLaxationLogsDatas,
          unsavedExcretionDetailDatas: e.unsavedExcretionDatas,
          unsavedExcretionIndex: e.unsavedExcretionIndex
        })
        break

      default:
        break
    }
  }

  // 日付フィールドの変更
  handleDatetimeFieldChange(e) {
    // YYYY/MM/DD のみ通す
    let datetime = e.formData.confirmed
    this.getExcretionResultsForTimeline(datetime, this.state.groupId)
    this.getLaxationLogsForTimeline(datetime, this.state.groupId)
    datetime = datetime.substring(0, 10)
    // if (datetime.search(/\d{4}\d\/{2}\/\d{2}/)) {
    if (/^\d{4}\/\d{2}\/\d{2}$/.test(datetime)) {
      this.setState({
        confirmed: moment(datetime),
        loading: true
      })
    }
  }

  /* eslint-disable-next-line */
  handleTodayClick(e) {
    const momentTime = moment()
    this.getExcretionResultsForTimeline(momentTime, this.state.groupId)
    this.getLaxationLogsForTimeline(momentTime, this.state.groupId)
    this.setState({
      confirmed: momentTime,
      loading: true
    })
  }

  handleRecordChange(e) {
    this.setState({
      activeTab: e.activeTab,
      unsavedExcretionDetailDatas: e.unsavedExcretionDatas,
      unsavedLaxationLogsDetailDatas: e.unsavedLaxationLogsDatas
    })
  }

  // タイムライン及び排泄詳細入力Dom取得
  handleGroupLabelClick(e) {
    // 利用者情報取得
    /* eslint-disable-next-line */
    let carePeople = this.state.carePeople.filter(
      (value) => e.group === value.id
    )
    if (carePeople.length > 0) {
      const carePerson = carePeople[0]
      /* eslint-disable-next-line */
      this.props.history.push({
        pathname: '/indivisualExcretionResults',
        state: {
          carePeople: this.state.carePeople,
          // 利用者
          carePerson: {
            id: carePerson.id,
            name: carePerson.name,
            place: carePerson.place
          },
          // 選択した日付
          selectDatetime: this.state.confirmed.format(
            SystemConst.DateFormat.Calendar
          ),
          groupId: this.state.groupId
        }
      })
    }
  }

  // タイムライン及び排泄詳細入力Dom取得
  getExcretionTimelineDom() {
    const [locale] = this.context
    const uiWords = localizedUiWords(locale.lang).pages.excretionResults

    const customFields = {
      datetime: CareNoteCalendar
    }

    // フォームの設定
    const schema = {
      type: 'object',
      required: ['confirmed'],
      properties: {
        confirmed: {
          type: 'string',
          title: ''
        }
      }
    }

    const uiSchema = {
      confirmed: {
        type: 'date',
        'ui:field': 'datetime'
      }
    }

    const formData = {
      confirmed: this.state.confirmed
        ? this.state.confirmed.format('YYYY/MM/DD')
        : moment().format('YYYY/MM/DD')
    }

    const timeline = (
      <div className="timeline-container">
        {/* TODO: CareNoteDatePicker で置き換える */}
        <div className="_calendar-date-picker">
          <button
            className="today-select-button"
            onClick={this.handleTodayClick.bind(this)}
          >
            {uiWords.todayButtonLabel}
          </button>

          <Form
            schema={schema}
            uiSchema={uiSchema}
            fields={customFields}
            formData={formData}
            showErrorList={false}
            onChange={this.handleDatetimeFieldChange.bind(this)}
          ></Form>
        </div>

        <Timeline
          carePeople={this.state.carePeople}
          excretionResults={this.state.excretionResults}
          laxationLogs={this.state.laxationLogs}
          datetime={this.state.confirmed}
          finalDefecationTime={this.state.finalDefecationTime}
          laxativeAlerts={this.state.laxativeAlerts}
          onAdd={this.handleAdd.bind(this)}
          onUpdate={this.handleUpdate.bind(this)}
          onGroupLabelClick={this.handleGroupLabelClick.bind(this)}
        />
      </div>
    )

    return {
      timeline,
      noData: null,
      noActionData: null
    }
  }

  // データなしDom取得
  getNoDataDom() {
    const [locale] = this.context
    const uiWords = localizedUiWords(locale.lang).pages.excretionResults

    const noData = (
      <div className="resident-not-found-dialog">
        <h1 className="title">{uiWords.residentNotFoundTitle}</h1>

        <Link className="center-add-btn" to={'/user/edit/new'}>
          {uiWords.addNewResidentButtonLabel}
        </Link>
      </div>
    )

    return {
      timeline: null,
      excretionDetail: null,
      noData,
      noActionData: null
    }
  }

  /**
   * 「入居中」ステータスの利用者がいない場合のダイアログ
   */
  getNoNeedToActionDom() {
    const [locale] = this.context
    const uiWords = localizedUiWords(locale.lang).pages.excretionResults

    const noActionData = (
      <div className="resident-not-found-dialog">
        <h1 className="title">{uiWords.livingResidentNotFoundTitle}</h1>
      </div>
    )

    return {
      timeline: null,
      excretionDetail: null,
      noData: null,
      noActionData
    }
  }

  onSelectWidthHour(e) {
    const widthHour = parseInt(e.target.value, 10)
    this.setState({
      widthHour
    })
  }

  render() {
    const [locale] = this.context
    const uiWords = localizedUiWords(locale.lang).pages.excretionResults

    let excretionManagementList
    if (this.state.carePeople !== null && this.state.carePeople.length > 0) {
      if (_.filter(this.state.carePeople, { status_code: '0' }).length === 0) {
        excretionManagementList = this.getNoNeedToActionDom()
      } else {
        excretionManagementList = this.getExcretionTimelineDom()
      }
    } else {
      excretionManagementList = this.getNoDataDom()
    }

    return (
      <div id="excretion-results">
        <AppHeader />
        <div className="flex">
          <div className="title-frame">
            <div className="select-fields flex space-between mt10">
              <div className="setting-space flex">
                <div className="groups">
                  <GroupSelectField
                    onChange={this.selectGroup.bind(this)}
                    formData={this.state.groupId}
                  />
                  <button onClick={this.refresh.bind(this)}>
                    <img
                      style={{ verticalAlign: 'middle' }}
                      width="24"
                      height="24"
                      src={refreshIcon}
                    />
                  </button>
                </div>
              </div>

              <CareNoteExplanation />
              <CareNoteLegends />
            </div>
            {excretionManagementList.timeline}
          </div>
          {excretionManagementList.noData}
          {excretionManagementList.noActionData}
        </div>

        <AppLoading isActive={this.state.loading} />

        <CareNoteDialog
          isActive={this.state.dialogExcretion && this.state.dialogLaxation}
          confirmed={this.state.confirmed}
          excretionResultId={this.state.excretionResultId}
          alertLogs={this.state.alertLogs}
          carePeople={this.state.carePeople}
          onLoading={this.handleLoading.bind(this)}
          onComplete={this.handleDialogComplete.bind(this)}
          onRemove={this.handleRemove.bind(this)}
          onDialogClosing={this.handleDialogClosing.bind(this)}
          onRecordChange={this.handleRecordChange.bind(this)}
          excretionDetailDatas={this.state.excretionDetailDatas}
          laxationLogsDetailDatas={this.state.laxationLogsDetailDatas}
          where="excretionResults"
          activeTab={this.state.activeTab}
          reservedActiveTab={this.state.reservedActiveTab}
          unsavedExcretionDetailDatas={this.state.unsavedExcretionDetailDatas}
          unsavedLaxationLogsDetailDatas={
            this.state.unsavedLaxationLogsDetailDatas
          }
          unsavedExcretionIndex={this.state.unsavedExcretionIndex}
          unsavedLaxationLogsIndex={this.state.unsavedLaxationLogsIndex}
          laxativeDatas={this.state.laxativeDatas}
          unitList={this.state.unitList}
          scopCooperation={this.state.scopCooperation}
        />

        <AppDialog
          isActive={this.state.deletingDialog}
          title={
            this.state.activeTab === 1
              ? uiWords.deleteExcretionRecordDialog
              : uiWords.deleteLaxativeRecordDialog
          }
          choiceButtonLists={[
            uiWords.deleteDialogOkButtonLabel,
            uiWords.deleteDialogCancelButtonLabel
          ]}
          onClick={
            this.state.activeTab === 1
              ? this.handleExcretionDeletingDialogClick.bind(this)
              : this.handleLaxationLogsDeletingDialogClick.bind(this)
          }
          onDialogClosing={this.handleExcretionDeletingDialogClosing.bind(this)}
        />
      </div>
    )
  }
}

export default withRouter(ExcretionResults)
