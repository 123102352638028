import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type LoginInputFormState = {
  login_id: {
    value: string
  }
  password: {
    value: string
  }
}

// 引数が必要なactionにはPayloadActionを第二引数に記述する。
// stateを参照するだけのactionは不要かもしれない。（getXXX）
type Reducer = {
  setLoginId: (
    state: LoginInputFormState,
    { payload }: PayloadAction<string>
  ) => void
  setPassword: (
    state: LoginInputFormState,
    { payload }: PayloadAction<string>
  ) => void
}

const loginInputFormSlice = createSlice<LoginInputFormState, Reducer>({
  name: 'LoginInputForm',
  initialState: {
    login_id: {
      value: ''
    },
    password: {
      value: ''
    }
  },
  reducers: {
    setLoginId: (state, { payload }) => {
      state.login_id.value = payload
    },
    setPassword: (state, { payload }) => {
      state.password.value = payload
    }
  }
})

export const { setLoginId, setPassword } = loginInputFormSlice.actions

export default loginInputFormSlice
