/* eslint-disable */
import config from '../../config/config.json'
import Mappings, { ApiMessage } from '../../constants/StringConstants'
import { initLoginState } from '../../features/restAPI/LoginSlice'
import { fetchLogout, LogoutState } from '../../features/restAPI/LogoutSlice'
import { RootState, AppDispatch } from '../../store'
import Loading from '../feedback/Loading'
import { localizedUiWords } from '@/features/locale'
import { LocaleContext } from '@/providers/LocaleProvider'
import AppBar from '@material-ui/core/AppBar'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Toolbar from '@material-ui/core/Toolbar'
import createStyles from '@material-ui/core/styles/createStyles'
import { Theme } from '@material-ui/core/styles/createTheme'
import withStyles, {
  WithStyles,
  StyleRules
} from '@material-ui/core/styles/withStyles'
import MenuIcon from '@material-ui/icons/Menu'
import { unwrapResult } from '@reduxjs/toolkit'
import { useSnackbar } from 'notistack'
import React, { FC, useState, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

const styles = (theme: Theme): StyleRules =>
  createStyles({
    list: {
      width: 250
    },
    root: {
      flexGrow: 1,
      [theme.breakpoints.up('lg')]: {
        display: 'none'
      }
    },
    menuButton: {
      marginLeft: -12
    },
    versionText: {
      '& span': {
        fontSize: '10px'
      }
    },
    linkTextColor: {
      '& span': {
        color: 'blue'
      }
    }
  })
interface Props extends WithStyles<typeof styles> {
  title?: string
}
const SideList: React.FC<Props> = ({ classes }: Props) => {
  const [locale] = useContext(LocaleContext)
  const uiWords = localizedUiWords(locale.lang).components.menuDrawer

  const { enqueueSnackbar } = useSnackbar()
  const dispatch: AppDispatch = useDispatch()
  const helppadWebManual =
    'https://docbase.io/posts/837149/sharing/b6b40eb2-98e4-4d22-9657-b70f6a413916'

  const onClickLogout = () => {
    dispatch(fetchLogout())
      .then(unwrapResult)
      .then((payload) => {
        console.log({ payload: payload })
      })
      .catch((error) => {
        console.log(error)
        enqueueSnackbar(ApiMessage.failed.put(Mappings.apiNames.login), {
          variant: ApiMessage.failed.variant
        })
      })
    dispatch(initLoginState())
  }
  const handleNewWindow = () => {
    window.open(helppadWebManual, 'helppadWebManual')
  }
  return (
    <div className={classes.list}>
      <List>
        <Link to="/home">
          <ListItem button>
            <ListItemText primary={uiWords.homeMenuLabel} />
          </ListItem>
        </Link>
        <Link to="/excretionResults">
          <ListItem button>
            <ListItemText primary={uiWords.careNotesMenuLabel} />
          </ListItem>
        </Link>
        <Link to="/users">
          <ListItem button>
            <ListItemText primary={uiWords.residentsMenuLabel} />
          </ListItem>
        </Link>
        <Link to="/setting/group">
          <ListItem button>
            <ListItemText primary={uiWords.settingsMenuLabel} />
          </ListItem>
        </Link>
        <ListItem button>
          <ListItemText
            primary={uiWords.logoutMenuLabel}
            onClick={onClickLogout}
            className={classes.linkTextColor}
          />
        </ListItem>
        <Divider />
        <ListItem button>
          <ListItemText
            primary={uiWords.manualMenuLabel}
            onClick={handleNewWindow}
            className={classes.linkTextColor}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={'web app version: ' + config.version}
            className={classes.versionText}
          />
        </ListItem>
      </List>
    </div>
  )
}
const MenuDrawer: FC<Props> = ({ classes, title }: Props) => {
  const { logout } = useSelector<RootState, { logout: LogoutState }>(
    (state) => ({
      logout: state.restAPIReducer.logout
    })
  )
  const [left, setLeft] = useState(false)
  const toggleDrawer = (open: any) => () => {
    setLeft(open)
  }
  return (
    <div className={classes.root}>
      <Toolbar>
        <IconButton
          className={classes.menuButton}
          color="inherit"
          aria-label="Menu"
          onClick={toggleDrawer(true)}
        >
          <MenuIcon />
        </IconButton>
        <Drawer open={left} onClose={toggleDrawer(false)}>
          <div
            tabIndex={0}
            role="button"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            <SideList classes={classes} />
          </div>
        </Drawer>
      </Toolbar>
      <Loading isOpen={logout.isLoading} />
    </div>
  )
}
// const dispatch = useDispatch();

// state = {
//   left: false,
// };

// render() {
//   const { classes } = this.props;

// }

export default withStyles(styles)(MenuDrawer)
