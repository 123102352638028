/* eslint-disable */
import { EventEmitter } from "events";
import dispatcher from "../dispatchers/Dispatcher";

class ActiveAlertLogsStore extends EventEmitter {
  handleActions(action) {
      switch (action.type) {
        case "ACTIVE_ALERT_LOGS":
          this.emit("active_alert_logs",action.body);
          break;
        case "ERROR":
          let body = action.body ? action.body : "";
          this.emit("active_alert_logs_error",body);
          break;
        default:
          break;
      }
    }
}

const activeAlertLogsStore = new ActiveAlertLogsStore();
dispatcher.register(activeAlertLogsStore.handleActions.bind(activeAlertLogsStore));

export default activeAlertLogsStore;