/* eslint-disable */
import { EventEmitter } from "events";
import dispatcher from "../dispatchers/Dispatcher";

import EventConst from "../constants/EventConstants";
/** 
 * アプリケーション全体のストア
 */
class AppStore extends EventEmitter {

  /**
   * @type {boolean} ローディング中有無 
   */
  appLoading = false;

  /**
   * ローディング中有無取得
   * @returns {boolean} ローディング中有無
   */
  getAppLoading() {
    return this.appLoading;
  }

  /**
   * イベント処理
   * @param {object} action Actionからdispatherに渡された値 
   */
  handleActions(action) {
    switch (action.type) {
      case EventConst.action_type.app.app_loading:
        this.appLoading = action.value;
        break;
      default:
        return;
    }
    this.emit(action.type);
  }
}

const appStore = new AppStore();
dispatcher.register(appStore.handleActions.bind(appStore));
export default appStore;