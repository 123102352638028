import {
  Template,
  TemplateRenderer
} from '../components/common/TemplateLiteral'
import { VariantType } from 'notistack'

const Mappings = {
  apiNames: {
    login: 'ログイン情報',
    facility: '施設情報',
    carePeople: '利用者一覧情報',
    carePerson: '利用者情報',
    groups: 'グループ一覧情報',
    group: 'グループ情報',
    alertLogs: '通知一覧情報',
    alertLog: '通知情報',
    excretionResults: '排泄記録一覧情報',
    excretionResult: '排泄記録情報',
    sensors: 'センサー一覧情報',
    sensor: 'センサー情報',
    histogram: '通知および排泄傾向グラフ情報',
    toiletiotData: 'トイレ情報',
    toileiotStatistics: '統計情報',
    statusMST: '入居ステータス情報'
  },
  excretionResult: {
    jp: {
      urine: '尿',
      feces: '便',
      urine_and_feces: '尿＆便',
      none: '何もなかった'
    },
    en: {
      urine: 'urine',
      feces: 'feces',
      urine_and_feces: 'urine_and_feces',
      none: 'none'
    }
  },
  leaked: {
    jp: {
      leaked: '漏れ出し'
    },
    en: {
      leaked: 'leaked'
    }
  }
}

type ApiMessageType = {
  success: {
    variant: VariantType
    post: TemplateRenderer
    put: TemplateRenderer
    delete: TemplateRenderer
  }
  failed: {
    variant: VariantType
    get: TemplateRenderer
    post: TemplateRenderer
    put: TemplateRenderer
    delete: TemplateRenderer
  }
}

export const ApiMessage: ApiMessageType = {
  success: {
    variant: 'success',
    post: Template`${0} の登録に成功しました。`,
    put: Template`${0} の更新に成功しました。`,
    delete: Template`${0} の削除に成功しました。`
  },
  failed: {
    variant: 'error',
    get: Template`${0} の取得に失敗しました。`,
    post: Template`${0} の登録に失敗しました。`,
    put: Template`${0} の更新に失敗しました。`,
    delete: Template`${0} の削除に失敗しました。`
  }
}

export const ApiErrorDetailMessage = {
  login: {
    401: 'ユーザー名かパスワードが間違っています。'
  }
}

export default Mappings
