import API from '../constants/ApiConstants'
import dispatcher from '../dispatchers/Dispatcher'
import request from 'superagent'

export function groups() {
  request
    .get(API.Group.All)
    .set('Accept', 'application/json')
    .end(function (err, res) {
      if (err === null) {
        const body = res.body.data
        dispatcher.dispatch({ type: 'GROUP_ALL', body })
      } else {
        dispatcher.dispatch({ type: 'ERROR', res })
      }
    })
}

export function addGroup(data) {
  request
    .post(API.Group.Add)
    .send(data)
    .set('Accept', 'application/json')
    .end(function (err, res) {
      if (err === null) {
        const body = res.body.success
        dispatcher.dispatch({ type: 'GROUP_ADD', body })
      } else {
        dispatcher.dispatch({ type: 'ERROR', res })
      }
    })
}

export function editGroup(data, id) {
  const url = API.Group.Edit.replace(':id', id)
  request
    .put(url)
    .send(data)
    .set('Accept', 'application/json')
    .end(function (err, res) {
      if (err === null) {
        const body = res.body.success
        dispatcher.dispatch({ type: 'GROUP_EDIT', body })
      } else {
        dispatcher.dispatch({ type: 'ERROR', res })
      }
    })
}

export function deleteAllGroup(data) {
  request
    .del(API.Group.DeleteAll)
    .send(data)
    .set('Accept', 'application/json')
    .end(function (err, res) {
      if (err === null) {
        const body = res.body.success
        dispatcher.dispatch({ type: 'GROUP_ALL_DELETE', body })
      } else {
        dispatcher.dispatch({ type: 'ERROR', res })
      }
    })
}
