/* eslint-disable */
import loading from '../../images/loading.gif'
import { localizedUiWords } from '@/features/locale'
import { LocaleContext } from '@/providers/LocaleProvider'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

class AppLoading extends Component {
  static contextType = LocaleContext

  constructor() {
    super()
    this.state = {
      isActive: false
    }
  }

  componentDidUpdate(prebProps) {
    if (this.props !== prebProps) {
      this.setState({
        isActive: this.props.isActive
      })
    }
  }

  render() {
    const [locale] = this.context
    const uiWords = localizedUiWords(locale.lang).components.appLoading
    let isRender = this.state.isActive ? 'app_loading' : 'app_loading hide'

    return (
      <div className={isRender}>
        <div>
          <p>
            {uiWords.loadingMessage}
            <span>{uiWords.loadingSubMessage}</span>
          </p>
          <img alt={uiWords.synchronizingAlt} src={loading} />
        </div>
      </div>
    )
  }
}

AppLoading.propTypes = {
  isActive: PropTypes.bool
}

export default AppLoading
