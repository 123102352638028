import { Box as MuiBox } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'

/**
 * 腐敗防止用 MUI Box ラッパーコンポーネント
 *
 * MUI が後方互換性を破壊するような仕様変更をした場合に影響を抑える
 */
export const Box = ({ children, ...props }) => {
  return <MuiBox {...props}>{children}</MuiBox>
}

Box.propTypes = {
  children: PropTypes.node
}
