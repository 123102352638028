import PropTypes from 'prop-types'
import React, { Component } from 'react'

class Pager extends Component {
  constructor() {
    super()
    this.state = {}
  }

  handleGotoPage(e) {
    this.props.gotoPage(e.target.getAttribute('data-page'))
  }

  componentDidUpdate(prebProbs) {
    if (this.props !== prebProbs) {
      this.setState(this.props)
    }
  }

  render() {
    if (this.props.total <= this.props.perPage) {
      return <div className="pager" />
    }

    const totalPages = Math.ceil(this.props.total / this.props.perPage)
    const current = Math.min(Math.max(1, this.props.current), totalPages)

    let beginPage = Math.max(
      1,
      this.props.current - Math.floor(this.props.showPages / 2)
    )
    if (this.props.showPages < totalPages) {
      beginPage = Math.min(beginPage, totalPages - this.props.showPages + 1)
    }

    const endPage = Math.min(totalPages, beginPage + this.props.showPages - 1)

    let prevPagesBtn
    if (beginPage > 1) {
      const page = Math.max(1, current - this.props.showPages)
      prevPagesBtn = (
        <li
          key={'prevPage'}
          className="prevPages"
          data-page={page}
          onClick={this.handleGotoPage.bind(this)}
        >
          &lt;&lt;
        </li>
      )
    }

    let prevBtn
    if (current > 1) {
      prevBtn = (
        <li
          key={'prev'}
          className="prev"
          data-page={current - 1}
          onClick={this.handleGotoPage.bind(this)}
        >
          &lt;
        </li>
      )
    }

    const pageBtns = []
    for (let p = beginPage; p <= endPage; p++) {
      if (p === current) {
        pageBtns.push(
          <li
            key={'page' + p}
            className="current"
            data-page={p}
            onClick={this.handleGotoPage.bind(this)}
          >
            {p}
          </li>
        )
      } else {
        pageBtns.push(
          <li
            key={'page' + p}
            className="page"
            data-page={p}
            onClick={this.handleGotoPage.bind(this)}
          >
            {p}
          </li>
        )
      }
    }

    let nextBtn
    if (current < totalPages) {
      nextBtn = (
        <li
          key={'next'}
          className="next"
          data-page={current + 1}
          onClick={this.handleGotoPage.bind(this)}
        >
          &gt;
        </li>
      )
    }

    let nextPagesBtn
    if (endPage < totalPages) {
      const page = Math.min(totalPages, current + this.props.showPages)
      nextPagesBtn = (
        <li
          key={'nextPage'}
          className="nextPages"
          data-page={page}
          onClick={this.handleGotoPage.bind(this)}
        >
          &gt;&gt;
        </li>
      )
    }

    return (
      <div className="pager">
        <ul>
          {prevPagesBtn}
          {prevBtn}
          {pageBtns}
          {nextBtn}
          {nextPagesBtn}
        </ul>
      </div>
    )
  }
}

Pager.propTypes = {
  total: PropTypes.number.isRequired,
  current: PropTypes.number.isRequired,
  perPage: PropTypes.number,
  showPages: PropTypes.number,
  gotoPage: PropTypes.func.isRequired
}

Pager.defaultProps = {
  current: 1,
  perPage: 10,
  showPages: 10
}

export default Pager
