import {
  isJaJP,
  Language,
  LanguageEnum,
  getAppLanguage,
  setAppLanguage,
  getBrowserLanguage
} from '@/features/locale'
import React, { ReactNode, createContext, useReducer } from 'react'

type Locale = {
  lang: LanguageEnum
}

enum LocaleActionType {
  SET_LOCALE = 'setLocale'
}

type LocaleAction = {
  type: LocaleActionType
  payload: Locale
}

const initialState = () => {
  let lang = getAppLanguage()

  // 初回時の設定:
  // アプリケーション設定がない場合はブラウザの言語設定を利用する
  // (ブラウザ) 日本語 : (アプリ) 日本語
  // (ブラウザ) 日本語以外 : (アプリ) 英語
  // 初回時以外は、アプリ設定を利用する
  if (!lang) {
    const browserLang = getBrowserLanguage()
    lang = isJaJP(browserLang) ? Language.jaJP : Language.enUS
    setAppLanguage(lang)
  }

  return {
    lang
  }
}

const LocaleContext = createContext<any>(initialState())

type LocaleProviderProps = {
  children: ReactNode
}

const LocaleProvider = ({ children }: LocaleProviderProps) => {
  const [locale, localeDispatch] = useReducer(
    (state: Locale, action: LocaleAction) => {
      switch (action.type) {
        case LocaleActionType.SET_LOCALE:
          setAppLanguage(action.payload.lang)
          return action.payload
        default:
          return state
      }
    },
    initialState()
  )

  return (
    <LocaleContext.Provider value={[locale, localeDispatch]}>
      {children}
    </LocaleContext.Provider>
  )
}

export { Language, LocaleActionType, LocaleContext, LocaleProvider }
