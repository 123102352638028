import { Routes } from './Routes'
import { unregister } from './registerServiceWorker'
import store from './store'
import './styles/index.css'
import { AppProvider } from '@/providers'
import { SnackbarProvider } from 'notistack'
import React from 'react'
import { CookiesProvider } from 'react-cookie'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

ReactDOM.render(
  <AppProvider>
    <CookiesProvider>
      <Provider store={store}>
        <SnackbarProvider>
          <Routes />
        </SnackbarProvider>
      </Provider>
    </CookiesProvider>
  </AppProvider>,
  document.getElementById('root')
)

// Service Worker を無効にする
// ユーザーが再訪問して最大24時間後にキャッシュ削除される
// registerServiceWorker()
unregister()
