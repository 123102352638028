/* eslint-disable */
import { EventEmitter } from "events";
import dispatcher from "../dispatchers/Dispatcher";

class SensorStore extends EventEmitter {
    constructor() {
      super();
      this.sensors = null;
    }
    getSensors(){
      return this.sensors;
    }
    handleActions(action) {
      switch (action.type) {
        case "SENSOR_ALL":
          this.emit("sensor_all",action.body);
          this.sensors = action.body.data;
          break;
        case "SENSOR_ALL_ERROR":
          this.emit("sensor_all_error",action.body);
          break;
        case "SENSOR_ADD":
          this.emit("sensor_add",action.body);
          break;
        case "SENSOR_ADD_ERROR":
          this.emit("sensor_add_error",action.body);
          break;
        case "SENSOR_DELETE":
          this.emit("sensor_delete",action.body);
          break;
        case "SENSOR_DELETE_ERROR":
          this.emit("sensor_delete_error",action.body);
          break;
        case "SENSOR_EDIT":
          this.emit("sensor_edit",action.body);
          break;
        case "SENSOR_EDIT_ERROR":
          this.emit("sensor_edit_error",action.body);
          break;
        case "SENSOR_STATUS":
          this.emit("sensor_status",action.body);
          break;
        case "SENSOR_STATUS_ERROR":
          this.emit("sensor_status_error",action.body);
          break;
        default:
          break;
      }
    }
}

const sensorStore = new SensorStore();
dispatcher.register(sensorStore.handleActions.bind(sensorStore));

export default sensorStore;
