/* eslint-disable */
// TODO: users でしか使われていないので features に移動
import { localizedUiWords } from '@/features/locale'
import { LocaleContext } from '@/providers/LocaleProvider'
import * as moment from 'moment'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class UserBox extends Component {
  static contextType = LocaleContext

  handleGender(gender) {
    const [locale] = this.context
    const uiWords = localizedUiWords(locale.lang).pages.users

    return gender === 'male' ? uiWords.maleLabel : uiWords.femaleLabel
  }

  render() {
    const [locale] = this.context
    const uiWords = localizedUiWords(locale.lang).pages.users

    let status
    switch (this.props.carePeople.m_status.code) {
      case '0':
        status = (
          <div className="user-status flex align-center">
            <div className="icon icon-icons_wet"></div>
            <div className="user-data">
              {this.props.carePeople.alert_log
                ? moment(this.props.carePeople.alert_log.created).format(
                    'MM/DD HH:mm'
                  )
                : uiWords.noDataLabel}
            </div>
          </div>
        )
        break
      case '1':
        status = (
          <div className="user-status flex align-center">
            <div className="icon icon-icons_hospital"></div>
            <div className="user-data">
              {this.props.carePeople.m_status.description}
            </div>
          </div>
        )
        break
      case '2':
        status = (
          <div className="user-status flex align-center">
            <div className="icon icon-icons_home2"></div>
            <div className="user-data">
              {this.props.carePeople.m_status.description}
            </div>
          </div>
        )
        break
      default:
        break
    }

    return (
      <div className="user-box">
        <div className="box-title flex align-center space-between">
          <div>
            {this.props.carePeople.place}{' '}
            {this.handleGender(this.props.carePeople.gender)}
          </div>
          <div className="flex align-center">{status}</div>
        </div>
        <div className="user-name mb30">
          {this.props.carePeople.name}
          <span>{this.props.carePeople.kana}</span>
        </div>
        <div className=" text-center">
          <Link to={`/user/${this.props.carePeople.id}`}>
            <div className="log-btn">{uiWords.detailsLabel}</div>
          </Link>
        </div>
      </div>
    )
  }
}

export default UserBox
