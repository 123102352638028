/* eslint-disable */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { AxiosError } from "axios";
import request, { Token } from "./CustomAxios";
import { AsyncThunkConfig } from "../../store";
import API from "../../constants/ApiConstants";
import ValidationErrors from "../ValidationErrors";
import TGroup from "../Types/TGroup";

// get
export type GetGroupState = {
  group: Array<TGroup>;
  message: string;
  isLoading: boolean;
};

const initialGetGroupState = {
  group: [],
  message: "",
  isLoading: false,
};

// add
export type AddGroupState = {
  success: boolean;
  message: string;
  isLoading: boolean;
};

const initialAddGroupState = {
  success: false,
  message: "",
  isLoading: false,
};

type AddGroupParam = {
  params: { name: string };
  login: Token;
};

// edit
export type EditGroupState = {
  success: boolean;
  message: string;
  isLoading: boolean;
};

const initialEditGroupState = {
  success: false,
  message: "",
  isLoading: false,
};

type EditGroupParam = {
  params: { newGroupName: string; groupId: number };
  login: Token;
};

// delete
export type DeleteGroupState = {
  success: boolean;
  message: string;
  isLoading: boolean;
};

const initialDeleteGroupState = {
  success: false,
  message: "",
  isLoading: false,
};

type DeleteGroupParam = {
  params: {
    id: number;
  };
  login: Token;
};

export type GroupState = {
  getGroup: GetGroupState;
  addGroup: AddGroupState;
  editGroup: EditGroupState;
  deleteGroup: DeleteGroupState;
};

export const fetchGetGroups = createAsyncThunk<
  GetGroupState,
  Token,
  AsyncThunkConfig<ValidationErrors>
>("group/all", async (arg, thunkAPI) => {
  let response: any = [];
  try {
    response = await request(arg).get(API.Group.All);
  } catch (err: any) {
    let error: AxiosError<ValidationErrors> = err;
    if (!error.response) {
      throw err;
    }
    return thunkAPI.rejectWithValue(error.response.data);
  }

  return response;
});

const fetchGetGroupSlice = createSlice<GetGroupState, any>({
  name: "fetch/all/Group",
  initialState: initialGetGroupState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(fetchGetGroups.fulfilled, (state: any, action: any) => {
      console.log("get group fulfilled");
      state.group = action.payload.data.data;
      state.isLoading = false;
    });
    builder.addCase(fetchGetGroups.pending, (state: any, action: any) => {
      console.log("get group  pending");
      state.isLoading = true;
    });
    builder.addCase(fetchGetGroups.rejected, (state: any, action: any) => {
      console.log("get group  rejected");
      state.status = "error";
      state.message = action.error.message;
      state.isLoading = false;
    });
  },
});

export const fetchAddGroup = createAsyncThunk<
  Object,
  AddGroupParam,
  AsyncThunkConfig<ValidationErrors>
>("group/add", async (arg, thunkAPI) => {
  let response: any = [];
  try {
    response = await request(arg.login).post(API.Group.Add, arg.params);
  } catch (err: any) {
    let error: AxiosError<ValidationErrors> = err;
    if (!error.response) {
      throw err;
    }
    return thunkAPI.rejectWithValue(error.response.data);
  }

  return response;
});

const fetchAddGroupSlice = createSlice<AddGroupState, any>({
  name: "fetch/add/Group",
  initialState: initialAddGroupState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(fetchAddGroup.fulfilled, (state: any, action: any) => {
      console.log("add group fulfilled");
      state.result = action.payload.data.data;
      state.isLoading = false;
    });
    builder.addCase(fetchAddGroup.pending, (state: any, action: any) => {
      console.log("add group pending");
      state.isLoading = true;
    });
    builder.addCase(fetchAddGroup.rejected, (state: any, action: any) => {
      console.log("add group rejected");
      state.status = "error";
      state.message = action.error.message;
      state.isLoading = false;
    });
  },
});

export const fetchEditGroup = createAsyncThunk<
  Object,
  EditGroupParam,
  AsyncThunkConfig<ValidationErrors>
>("group/edit", async (arg, thunkAPI) => {
  let response: any = [];
  try {
    response = await request(arg.login).put(
      API.Group.Edit.replace(":id", arg.params.groupId.toString()),
      { name: arg.params.newGroupName }
    );
  } catch (err: any) {
    let error: AxiosError<ValidationErrors> = err;
    if (!error.response) {
      throw err;
    }
    return thunkAPI.rejectWithValue(error.response.data);
  }

  return response;
});

const fetchEditGroupSlice = createSlice<EditGroupState, any>({
  name: "fetch/edit/Group",
  initialState: initialEditGroupState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(fetchEditGroup.fulfilled, (state: any, action: any) => {
      console.log("edit group fulfilled");
      state.result = action.payload.data.data;
      state.isLoading = false;
    });
    builder.addCase(fetchEditGroup.pending, (state: any, action: any) => {
      console.log("edit group pending");
      state.isLoading = true;
    });
    builder.addCase(fetchEditGroup.rejected, (state: any, action: any) => {
      console.log("edit group rejected");
      state.status = "error";
      state.message = action.error.message;
      state.isLoading = false;
    });
  },
});

export const fetchDeleteGroup = createAsyncThunk<
  Object,
  DeleteGroupParam,
  AsyncThunkConfig<ValidationErrors>
>("group/delete", async (arg, thunkAPI) => {
  let response: any = [];
  try {
    response = await request(arg.login).delete(
      API.Group.Delete.replace(":id", arg.params.id.toString())
    );
  } catch (err: any) {
    let error: AxiosError<ValidationErrors> = err;
    if (!error.response) {
      throw err;
    }
    return thunkAPI.rejectWithValue(error.response.data);
  }

  return response;
});

const fetchDeleteGroupSlice = createSlice<DeleteGroupState, any>({
  name: "fetch/delete/Group",
  initialState: initialDeleteGroupState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(fetchDeleteGroup.fulfilled, (state: any, action: any) => {
      console.log("delete group fulfilled");
      state.result = action.payload.data.data;
      state.isLoading = false;
    });
    builder.addCase(fetchDeleteGroup.pending, (state: any, action: any) => {
      console.log("delete group pending");
      state.isLoading = true;
    });
    builder.addCase(fetchDeleteGroup.rejected, (state: any, action: any) => {
      console.log("delete group rejected");
      state.status = "error";
      state.message = action.error.message;
      state.isLoading = false;
    });
  },
});

const reducers = combineReducers<GroupState>({
  getGroup: fetchGetGroupSlice.reducer,
  addGroup: fetchAddGroupSlice.reducer,
  editGroup: fetchEditGroupSlice.reducer,
  deleteGroup: fetchDeleteGroupSlice.reducer,
});

export default reducers;
