/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as moment from "moment";
import onClickOutside from 'react-onclickoutside';

class DatetimeField extends Component {

  constructor(props) {
    super();
    this.state = {
      value: '',            // テキストボックス値：テキストボックスに入る値
      calendarValue: null,  // カレンダー選択日時：カレンダーで選択されている日時
      showCalendar: false   // カレンダー表示フラグ
    };
    const uiSchema = props.uiSchema || {};
    this.dayOfWeekDisp = ['日', '月', '火', '水', '木', '金', '土'];
    this.dayOfWeekClass = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
    this.firstDayOfWeek = (uiSchema.firstDayOfWeek !== undefined) ?
                          uiSchema.firstDayOfWeek : 0; // 日曜
    this.internalDateFormat = 'YYYY-MM-DD';
    this.internalTimeFormat = 'HH:mm:00';
    this.displayedMonthFormat = uiSchema.monthFormat || 'YYYY/MM';
    this.displayedDateFormat = uiSchema.dateFormat || 'YYYY/MM/DD';
    this.displayedTimeFormat = 'HH:mm';
    this.withTime = (props.uiSchema.type !== 'date');
  }

  componentDidMount() {
    this.setState({
      value: this.props.formData || '',
      calendarValue: this.getValidDatetime(this.props.formData || '')
    });
  }

  
  // componentWillReceiveProps(props) {
  //   const uiSchema = props.uiSchema || {};
  //   this.withTime = (uiSchema.type !== 'date');
  //   this.setState({
  //     value: props.formData || '',
  //     calendarValue: this.getValidDatetime(props.formData || '')
  //   });
  // }
  componentDidUpdate(prebProps){
    if(this.props!==prebProps){
      const uiSchema = this.props.uiSchema || {};
    this.withTime = (uiSchema.type !== 'date');
    this.setState({
      value: this.props.formData || '',
      calendarValue: this.getValidDatetime(this.props.formData || '')
    });
    }
  }
  
  handleChange(e) {
    this.setState({
      value: e.target.value,
      calendarValue: this.getValidDatetime(e.target.value || '')
    });
    this.props.onChange && this.props.onChange(e.target.value);
  }

  handleFocus(e) {
    this.setState({
      showCalendar: true
    });
  }
  
  handleBlur(e) {
    if(this.state.value !== '' && this.validateDatetime(this.state.value)) {
      // 値に何か入っていたら補正
      this.updateCalendarValue(this.state.calendarValue);
    }
    // カレンダー内をクリックしたときにも発生するため、
    // showCalendarはfalseにしない
  }
  
  handleClickOutside(e) {
    this.setState({
      showCalendar: false
    });
  }
  
  handleKeyDown(e) {
    this.setState({
      showCalendar: false
    });
  }
  
  // カレンダー選択日時更新
  // 同時にテキストボックス値も更新
  updateCalendarValue(newCalendarValue) {
    let valueFormat = this.displayedDateFormat;
    if(this.withTime) {
      valueFormat += ' ' + this.displayedTimeFormat;
    }
    const newValue = newCalendarValue.format(valueFormat)
    this.setState({
      value: newValue,
      calendarValue: newCalendarValue
    });
    this.props.onChange && this.props.onChange(newValue);
  }
  
  handleClickDate(e) {
    let dateStr = e + ' ' + this.state.calendarValue.format(this.internalTimeFormat);
    this.updateCalendarValue(moment(dateStr));
  }
  
  handleClickHourUp(e) {
    this.updateCalendarValue(moment(this.state.calendarValue).add(1, 'hour'));
  }
  
  handleClickHourDown(e) {
    this.updateCalendarValue(moment(this.state.calendarValue).add(-1, 'hour'));
  }
  
  handleClickMinuteUp(e) {
    this.updateCalendarValue(moment(this.state.calendarValue).add(1, 'minute'));
  }
  
  handleClickMinuteDown(e) {
    this.updateCalendarValue(moment(this.state.calendarValue).add(-1, 'minute'));
  }
  
  // 日時チェック
  // 年月日、年月日時分、年月日時分秒を許可する
  validateDatetime(value) {
    if(value !== null) {
      const arr = value.split(/[/\- :]/);
      for(let i = 0; i < arr.length; i++) {
        if(isNaN(arr[i])) {
          return false;
        }
        arr[i] = +arr[i];
      }
      if(arr.length >= 3) {
        const date = new Date(arr[0], arr[1] - 1, arr[2]);
        if(
            arr[0] === date.getFullYear() &&
            arr[1] === date.getMonth() + 1 &&
            arr[2] === date.getDate()
        ) {
          if(arr.length === 3) {
            return true;
          }
          if(arr.length >= 5) {
            if(
                0 <= arr[3] && arr[3] <= 23 &&
                0 <= arr[4] && arr[4] <= 59
            ) {
              if(arr.length === 5) {
                return true;
              }
              if(arr.length === 6) {
                if(0 <= arr[5] && arr[5] <= 59) {
                  return true;
                }
              }
            }
          }
        }
      }
    }
    return false;
  }
  
  // 日時文字列の正規化
  normalizeDatetimeString(value) {
    let str = '';
    if(value !== '') {
      let date;
      const arr = value.split(/[/\- :]/);
      switch(arr.length) {
        case 3:
          date = moment({years:arr[0], months:arr[1] - 1, days:arr[2]});
          break;
        case 5:
          date = moment({years:arr[0], months:arr[1] - 1, days:arr[2],
                         hours:arr[3], minutes:arr[4]});
          break;
        case 6:
          date = moment({years:arr[0], months:arr[1] - 1, days:arr[2],
                        hours:arr[3], minutes:arr[4], seconds:arr[5]});
          break;
        default:
          ;
      }
      if(date && date.isValid()) {
        str = date.format(this.internalDateFormat);
        if(this.withTime) {
          str += ' ' + date.format(this.internalTimeFormat);
        }
      }
    }
    return str;
  }
  
  // 値が有効な日時の場合、その値をmomentで初期化した値を返す
  // 値が無効な日時の場合、現在日時を返す
  getValidDatetime(value) {
    let datetime;
    if(this.validateDatetime(value)) {
      datetime = (value !== '') ? 
                moment(this.normalizeDatetimeString(value)) : moment();
    } else {
      datetime = moment();
    }
    return datetime;
  }
  
  // ツールバー描画
  renderToolbar(selectedDatetime) {
    const prevMonthDate = moment(selectedDatetime).add(-1, 'month').format(this.internalDateFormat);
    const nextMonthDate = moment(selectedDatetime).add(1, 'month').format(this.internalDateFormat);
    return(
      <div className="toolbar">
        <div className="prev-month-btn" onClick={this.handleClickDate.bind(this, prevMonthDate)}></div>
        <div className="current-month">{selectedDatetime.format(this.displayedMonthFormat)}</div>
        <div className="next-month-btn" onClick={this.handleClickDate.bind(this, nextMonthDate)}></div>
      </div>
    );
  }
  
  // 曜日行描画
  renderDayOfWeekRow() {
    const days = [];
    for(let i = 0; i < 7; i++) {
      const dayOfWeek = (this.firstDayOfWeek + i) % 7;
      days.push(
        <th key={i} className={this.dayOfWeekClass[dayOfWeek]}>{this.dayOfWeekDisp[dayOfWeek]}</th>
      );
    }
    return (
      <tr>
        {days}
      </tr>
    );
  }
  
  // 週の始まりの日を取得
  getStartDateOfWeek(date) {
    const delta = (this.firstDayOfWeek - date.day() - 7) % 7;
    return moment(date).add(delta, 'day');
  }
  
  // 週の終わりの日を取得
  getEndDateOfWeek(date) {
    const delta = (this.firstDayOfWeek - date.day() + 6) % 7;
    return moment(date).add(delta, 'day');
  }
    
  // 1カ月分のすべての週ごとに日を描画
  renderDaysOfMonthRows(selectedDatetime) {
    // 時間切り捨て
    const selectedDate = moment(selectedDatetime.format(this.internalDateFormat));
    
    // 月初
    const startDateOfMonth = moment(selectedDate).startOf('month');
    
    // 月末
    const endDateOfMonth = moment(moment(selectedDate).endOf('month').format(this.internalDateFormat));
    
    // 月初の週初めの日
    const startDate = this.getStartDateOfWeek(startDateOfMonth);
    
    // 月末の週終わりの日
    const endDate = this.getEndDateOfWeek(endDateOfMonth);
    
    // 週数
    const weekNum = ((endDate.valueOf() - startDate.valueOf()) / 86400000 + 1) / 7;
    
    // 週描画
    const weeks = [];
    let d = startDate;
    for(let week = 0; week < weekNum; week++) {
      // 日描画
      const days = [];
      for(let weekday = 0; weekday < 7; weekday++) {
        let className = this.dayOfWeekClass[d.day()];
        if(d < startDateOfMonth || d > endDateOfMonth) {
          className += ' out-of-date';
        }
        if(d.valueOf() === selectedDate.valueOf()) {
          className += ' selected';
        }
        days.push(
          <td 
            key={'date-' + d.format('YYYY-MM-DD')}
            className={className}
            onClick={this.handleClickDate.bind(this, d.format(this.internalDateFormat))}>
            {d.format('D')}
          </td>
        );
        d = moment(d.add(1, 'day'));
      }
      weeks.push(
        <tr key={'week-' + week}>
          {days}
        </tr>
      );
    }
    return weeks;
  }
  
  // 時間の描画
  renderHours(selectedDatetime) {
    return (
      <div className="hours">
        <div className="up-btn" onClick={this.handleClickHourUp.bind(this)}></div>
        <div className="hour">{selectedDatetime.format('HH')}</div>
        <div className="down-btn" onClick={this.handleClickHourDown.bind(this)}></div>
      </div>
    );
  }
  
  // 分の描画
  renderMinutes(selectedDatetime) {
    return (
      <div className="minutes">
        <div className="up-btn" onClick={this.handleClickMinuteUp.bind(this)}></div>
        <div className="hour">{selectedDatetime.format('mm')}</div>
        <div className="down-btn" onClick={this.handleClickMinuteDown.bind(this)}></div>
      </div>
    );
  }
  
  // カレンダー描画
  renderCalendar(selectedDatetime) {
    const rootClassName = this.withTime ? 'with-time' : 'without-time';
    
    let calendar;
    if(this.state.showCalendar) {
      let time;
      if(this.withTime) {
          time = (
            <div className="time">
              {this.renderHours(selectedDatetime)}
              <div className="time-separator">:</div>
              {this.renderMinutes(selectedDatetime)}
            </div>
          );
      }
      calendar = (
        <div className={rootClassName}>
          <div className="calendar">
            {this.renderToolbar(selectedDatetime)}
            <table>
              <tbody>
                {this.renderDayOfWeekRow()}
                {this.renderDaysOfMonthRows(selectedDatetime)}
              </tbody>
            </table>
          </div>
          {time}
          <div className="clear"></div>
        </div>
      );
    }
    return calendar;
  }
  
  render() {
    
    const required = this.props.required !== undefined ? this.props.required : false;
    const disabled = this.props.disabled !== undefined ? this.props.disabled : false;
    
    return (
      <div className="datetime-picker">
        <p>日時</p>
        <input type="text" 
          value={this.state.value}
          className="form-control"
          required={required}
          disabled={disabled}
          onFocus={this.handleFocus.bind(this)}
          onBlur={this.handleBlur.bind(this)}
          onChange={this.handleChange.bind(this)}
          onKeyDown={this.handleKeyDown.bind(this)}
          />
        {this.renderCalendar(this.state.calendarValue)}
      </div>
    );
  }
}

DatetimeField.propTypes = {
  formData: PropTypes.string,
  onChange: PropTypes.func
};

export default onClickOutside(DatetimeField);
