/* eslint-disable */
import groupStore from '../../../stores/GroupStore'
import './GroupBoxHead.scss'
import { localizedUiWords } from '@/features/locale'
import { LocaleContext } from '@/providers/LocaleProvider'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Form from 'react-jsonschema-form'

class GroupBoxHead extends Component {
  static contextType = LocaleContext

  constructor(props) {
    super()
    this.state = {
      isEditing: false, // 編集モード:true、読取モード:false
      groupName: props.groupName,
      error: ''
    }
  }

  componentDidMount() {
    groupStore.on('group_edit', (v) => {
      this.setState({
        isEditing: false
      })
    })
    groupStore.on('error', (v) => {
      if (v.text !== '') {
        const json = JSON.parse(v.text)
        this.setState({
          error: json.message
        })
      } else {
        this.setState({
          error: 'グループ名の更新時にエラーが発生しました'
        })
      }
    })
  }

  componentWillUnmount() {
    groupStore.removeAllListeners('group_edit')
    groupStore.removeAllListeners('error')
  }

  componentDidUpdate(prebProps) {
    if (this.props !== prebProps) {
      this.setState({
        groupName: this.props.groupName
      })
    }
  }

  handleEdit(e) {
    this.setState({
      isEditing: true
    })
  }

  handleCancel(e) {
    this.setState({
      isEditing: false
    })
  }

  validate(formData, errors) {
    return errors
  }

  transformErrors(errors) {
    return errors.map((error) => {
      if (error.name === 'maxLength') {
        error.message = 'グループ名は255文字以内で入力してください'
      }
      return error
    })
  }

  groupNameTemplate(props) {
    const [locale] = this.context
    const uiWords = localizedUiWords(locale.lang).pages.settings

    // エラーメッセージをボタンの下に表示させたいため、先にボタンを描画
    if (props.id === 'root_name') {
      return (
        <div className={props.classNames}>
          {props.description}
          {props.children}
          <button type="submit" className="btn btn-info">
            {uiWords.updateButtonLabel}
          </button>
          <button
            type="button"
            className="cancel-button"
            data-groupid={this.props.groupId}
            onClick={this.handleCancel.bind(this)}
          >
            {uiWords.cancelButtonLabel}
          </button>
          {props.errors}
          {props.help}
        </div>
      )
    } else {
      return (
        <div className={props.classNames}>
          {props.description}
          {props.children}
          {props.errors}
          {props.help}
        </div>
      )
    }
  }

  render() {
    const [locale] = this.context
    const uiWords = localizedUiWords(locale.lang).pages.settings

    const schemaGroupName = {
      type: 'object',
      required: ['name'],
      properties: {
        name: {
          type: 'string',
          maxLength: 255
        },
        id: {
          type: 'number'
        }
      }
    }

    const uiSchemaGroupName = {
      name: {
        'ui:placeholder': uiWords.groupNamePlaceholder,
        'ui:label': false,
        'ui:autofocus': true
      },
      id: {
        'ui:widget': 'hidden'
      }
    }

    let groupName
    let actionForm
    if (this.state.isEditing) {
      // 編集モードの場合
      // グループ名を空で表示
      groupName = '\u00A0'

      // グループ名編集フォームを表示
      const formData = {
        name: this.state.groupName,
        id: this.props.groupId
      }

      actionForm = (
        <div className="edit-group-name-form">
          <Form
            className="rjsf"
            schema={schemaGroupName}
            uiSchema={uiSchemaGroupName}
            formData={formData}
            onSubmit={this.props.onUpdate.bind(this)}
            FieldTemplate={this.groupNameTemplate.bind(this)}
            showErrorList={false}
            validate={this.validate.bind(this)}
            transformErrors={this.transformErrors.bind(this)}
          >
            &nbsp;
          </Form>
          {this.state.error !== '' && (
            <div className="font-hpad-red">{this.state.error}</div>
          )}
        </div>
      )
    } else {
      // 読取モードの場合
      // グループ名をテキスト表示
      groupName = this.state.groupName

      // えんぴつマークを表示
      actionForm = (
        <div
          className="icon icon-icons_edit_2"
          onClick={this.handleEdit.bind(this)}
        ></div>
      )
    }

    return (
      <div className="group-box-head">
        <div className="checkboxes">
          <div className="checkbox">
            <label>
              <span>
                <input
                  data-groupid={this.props.groupId}
                  onChange={this.props.onClickCheckbox.bind(this)}
                  type="checkbox"
                />
                <span>{groupName}</span>
              </span>
            </label>
            {actionForm}
          </div>
        </div>
      </div>
    )
  }
}

GroupBoxHead.propTypes = {
  groupId: PropTypes.number,
  groupName: PropTypes.string,
  onUpdate: PropTypes.func,
  onClickCheckbox: PropTypes.func
}

export default GroupBoxHead
