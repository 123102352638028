/* eslint-disable */
import request from 'superagent';
import dispatcher from "../dispatchers/Dispatcher";
import API from "../constants/ApiConstants";

export function getAlertData(query) {
  request
  .get(API.AlertData.Get)
    .query(query)
    .set('Accept', 'application/json')
    .end(function(err, res){
      if (err === null) {
        let body = res.body ? res.body : JSON.parse(res.text);
        dispatcher.dispatch({type:"ALERT_DATA_ALL", body});
      } else {
        dispatcher.dispatch({type:"ALERT_DATA_ERROR", res});
      }
    });
}