/* eslint-disable */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { AxiosError } from "axios";
import request, { Token } from "./CustomAxios";
import { AsyncThunkConfig } from "../../store";
import API from "../../constants/ApiConstants";
import ValidationErrors from "../ValidationErrors";
import TSensor from "../Types/TSensor";

// get
export type GetSensorState = {
  sensors: Array<TSensor>;
  message: string;
  isLoading: boolean;
};

const initialGetSensorState = {
  sensors: [],
  message: "",
  isLoading: false,
};

// add
export type AddSensorState = {
  success: boolean;
  message: string;
  isLoading: boolean;
};

const initialAddSensorState = {
  success: false,
  message: "",
  isLoading: false,
};

type AddSensorParam = {
  id: string;
  login: Token;
};

// delete
export type DeleteSensorState = {
  success: boolean;
  message: string;
  isLoading: boolean;
};

const initialDeleteSensorState = {
  success: false,
  message: "",
  isLoading: false,
};

type DeleteSensorParam = {
  sensor_id: string;
  login: Token;
};

export type SensorState = {
  getSensor: GetSensorState;
  addSensor: AddSensorState;
  deleteSensor: DeleteSensorState;
};

export const fetchGetSensors = createAsyncThunk<
  GetSensorState,
  Token,
  AsyncThunkConfig<ValidationErrors>
>("sensor/all", async (arg, thunkAPI) => {
  let response: any = [];
  try {
    response = await request(arg).get(API.Sensor.All);
  } catch (err: any) {
    let error: AxiosError<ValidationErrors> = err;
    if (!error.response) {
      throw err;
    }
    return thunkAPI.rejectWithValue(error.response.data);
  }

  return response;
});

const fetchGetSensorSlice = createSlice<GetSensorState, any>({
  name: "fetch/all/Sensor",
  initialState: initialGetSensorState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(fetchGetSensors.fulfilled, (state: any, action: any) => {
      console.log("get sensor fulfilled");
      state.sensor = action.payload.data.data;
      state.isLoading = false;
    });
    builder.addCase(fetchGetSensors.pending, (state: any, action: any) => {
      console.log("get sensor  pending");
      state.isLoading = true;
    });
    builder.addCase(fetchGetSensors.rejected, (state: any, action: any) => {
      console.log("get sensor  rejected");
      state.status = "error";
      state.message = action.error.message;
      state.isLoading = false;
    });
  },
});

export const fetchAddSensor = createAsyncThunk<
  Object,
  AddSensorParam,
  AsyncThunkConfig<ValidationErrors>
>("sensor/add", async (arg, thunkAPI) => {
  let response: any = [];
  try {
    response = await request(arg.login).post(API.Sensor.Add, { id: arg.id });
  } catch (err: any) {
    let error: AxiosError<ValidationErrors> = err;
    if (!error.response) {
      throw err;
    }
    return thunkAPI.rejectWithValue(error.response.data);
  }

  return response;
});

const fetchAddSensorSlice = createSlice<AddSensorState, any>({
  name: "fetch/add/Sensor",
  initialState: initialAddSensorState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(fetchAddSensor.fulfilled, (state: any, action: any) => {
      console.log("add sensor fulfilled");
      state.result = action.payload.data.data;
      state.isLoading = false;
    });
    builder.addCase(fetchAddSensor.pending, (state: any, action: any) => {
      console.log("add sensor pending");
      state.isLoading = true;
    });
    builder.addCase(fetchAddSensor.rejected, (state: any, action: any) => {
      console.log("add sensor rejected");
      state.status = "error";
      state.message = action.error.message;
      state.isLoading = false;
    });
  },
});

export const fetchDeleteSensor = createAsyncThunk<
  Object,
  DeleteSensorParam,
  AsyncThunkConfig<ValidationErrors>
>("sensor/delete", async (arg, thunkAPI) => {
  let response: any = [];
  try {
    response = await request(arg.login).delete(
      API.Sensor.Delete.replace(":id", arg.sensor_id)
    );
  } catch (err: any) {
    let error: AxiosError<ValidationErrors> = err;
    if (!error.response) {
      throw err;
    }
    return thunkAPI.rejectWithValue(error.response.data);
  }

  return response;
});

const fetchDeleteSensorSlice = createSlice<DeleteSensorState, any>({
  name: "fetch/delete/Sensor",
  initialState: initialDeleteSensorState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(fetchDeleteSensor.fulfilled, (state: any, action: any) => {
      console.log("delete sensor fulfilled");
      state.result = action.payload.data.data;
      state.isLoading = false;
    });
    builder.addCase(fetchDeleteSensor.pending, (state: any, action: any) => {
      console.log("delete sensor pending");
      state.isLoading = true;
    });
    builder.addCase(fetchDeleteSensor.rejected, (state: any, action: any) => {
      console.log("delete sensor rejected");
      state.status = "error";
      state.message = action.error.message;
      state.isLoading = false;
    });
  },
});

const reducers = combineReducers<SensorState>({
  getSensor: fetchGetSensorSlice.reducer,
  addSensor: fetchAddSensorSlice.reducer,
  deleteSensor: fetchDeleteSensorSlice.reducer,
});

export default reducers;
