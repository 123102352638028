/* eslint-disable */
import * as Action from '../../actions/CarePeopleAction'
import carePeopleStore from '../../stores/CarePeopleStore'
import { localizedUiWords } from '@/features/locale'
import { LocaleContext } from '@/providers/LocaleProvider'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Autosuggest from 'react-autosuggest'

class UserSearchField extends Component {
  static contextType = LocaleContext
  constructor() {
    super()
    // Autosuggest is a controlled component.
    // This means that you need to provide an input value
    // and an onChange handler that updates this value (see below).
    // Suggestions also need to be provided to the Autosuggest,
    // and they are initially empty because the Autosuggest is closed.
    this.state = {
      value: '',
      suggestions: [],
      carePeople: null
    }
  }

  componentDidMount() {
    carePeopleStore.on('people_all', (v) => {
      if (v.length > 0) {
        this.setState({
          carePeople: this.getEffectiveUser(v),
          value: this.props.formData || ''
        })
      }
    })
    if (carePeopleStore.getPeopleList() !== null) {
      this.setState({
        carePeople: this.getEffectiveUser(carePeopleStore.getPeopleList()),
        value: this.props.formData || ''
      })
    } else {
      Action.carePeople()
    }
  }

  componentWillUnmount() {
    carePeopleStore.removeAllListeners('people_all')
  }

  componentDidUpdate(prebProps) {
    if (this.props.newValue !== prebProps.newValue) {
      if (carePeopleStore.getPeopleList() !== null) {
        this.setState({
          carePeople: this.getEffectiveUser(carePeopleStore.getPeopleList()),
          value: this.props.formData || ''
        })
      } else {
        Action.carePeople()
      }
    }
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    })
    this.props.onChange && this.props.onChange(newValue)
  }

  onKeyPress = (e) => {
    // apiの不可を軽減するためEnterを押下した場合のみ発火させる
    if (e.which === 13) {
      this.props.onKeyPress && this.props.onKeyPress(e.target.value)
    }
  }

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    })
  }

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    })
  }

  onSuggestionSelected = (e) => {
    this.props.onSuggestionSelected &&
      this.props.onSuggestionSelected(e.target.innerHTML)
  }

  // Teach Autosuggest how to calculate suggestions for any given input value.
  getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length

    if (this.state.carePeople !== null) {
      return inputLength === 0
        ? []
        : this.state.carePeople.filter(
            (lang) =>
              lang.kana.toLowerCase().slice(0, inputLength) === inputValue
          )
    }
  }

  getEffectiveUser(user) {
    let effectiveUser = []
    user.forEach((v) => {
      if (v.m_status.code === '0') {
        effectiveUser.push(v)
      }
    })
    return effectiveUser
  }

  render() {
    const [locale] = this.context
    const uiWords = localizedUiWords(locale.lang).components.userSearchField

    // When suggestion is clicked, Autosuggest needs to populate the input
    // based on the clicked suggestion. Teach Autosuggest how to calculate the
    // input value for every given suggestion.
    const getSuggestionValue = (suggestion) => suggestion.name

    // Use your imagination to render suggestions.
    const renderSuggestion = (suggestion) => <div>{suggestion.name}</div>

    const { value, suggestions } = this.state

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: uiWords.residentNamePlaceholder,
      required: this.props.required,
      disabled: this.props.disabled,
      value,
      onChange: this.onChange,
      onKeyPress: this.onKeyPress,
      onClick: this.onClick
    }

    // Finally, render it!
    return (
      <div>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          onSuggestionSelected={this.onSuggestionSelected}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
        />
        {this.props.children}
      </div>
    )
  }
}

UserSearchField.propTypes = {
  formData: PropTypes.string,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  onSuggestionSelected: PropTypes.func
}

export default UserSearchField
