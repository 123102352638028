/* eslint-disable */
import { EventEmitter } from "events";
import dispatcher from "../dispatchers/Dispatcher";
const localStorage = window.localStorage;
const STORE_TOKEN_KEY = "token";
const STORE_FACILITY_ID = "facility_id";

class AuthStore extends EventEmitter {
    constructor() {
        super();
        this.facility_id = {
          id: ""
        }
    }
    getToken() {
      return localStorage.getItem(STORE_TOKEN_KEY);
    }

    getFacilityId() {
      return localStorage.getItem(STORE_FACILITY_ID);
    }

    handleActions(action) {
      switch (action.type) {
        case "LOGIN":
          localStorage.setItem(STORE_TOKEN_KEY, action.body.token);
          localStorage.setItem(STORE_FACILITY_ID, action.body.id);
          this.emit("login");
          break;
        case "LOGOUT":
          localStorage.removeItem(STORE_TOKEN_KEY);
          localStorage.removeItem(STORE_FACILITY_ID);
          this.emit("logout");
          break;
        default:
          break;
      }
    }
}

const authStore = new AuthStore();
dispatcher.register(authStore.handleActions.bind(authStore));

export default authStore;
