import { SettingLayout } from '../components/SettingLayout'
import {
  Body,
  Box,
  FormControl,
  Heading,
  InputLabel,
  SubHeading,
  Select
} from '@/components/Elements'
import { localizedUiWords, Language } from '@/features/locale'
import { LocaleActionType, LocaleContext } from '@/providers/LocaleProvider'
import { MenuItem } from '@material-ui/core'
import React, { useContext, useState } from 'react'

export const SettingAccessibility = () => {
  const [locale, localeDispatch] = useContext(LocaleContext)
  const uiWords = localizedUiWords(locale.lang).pages.settings

  const [lang, setLang] = useState<string>(locale.lang)

  /**
   * 言語選択時のChangeイベントのハンドラ
   *
   * @param event Selectコンポーネントが発火したChangeイベント
   */
  const selectLanguageHandler = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const selectedLang = event.target.value as string
    if (selectedLang) {
      setLang(selectedLang)
      localeDispatch({
        type: LocaleActionType.SET_LOCALE,
        payload: { lang: selectedLang }
      })
    }
  }

  return (
    <SettingLayout activeTab="accessibility">
      <Box id="setting-accessibility-tab" className="setting-tab-content">
        <Heading className="setting-heading">
          {uiWords.accessibilityTabHeading}
        </Heading>

        <SubHeading className="setting-sub-heading">
          {uiWords.languageSettingSubHeading}
        </SubHeading>

        <Body className="setting-description">
          {uiWords.languageSettingDescription}
        </Body>

        <FormControl variant="outlined">
          <InputLabel id="language-select-label">
            {uiWords.languageSettingSubHeading}
          </InputLabel>
          <Select
            labelId="language-select-label"
            id="language-select"
            value={lang}
            label={uiWords.languageSettingSubHeading}
            onChange={selectLanguageHandler}
          >
            <MenuItem value={Language.jaJP}>
              {uiWords.languageSelectOptionJaJP}
            </MenuItem>
            <MenuItem value={Language.enUS}>
              {uiWords.languageSelectOptionEnUS}
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
    </SettingLayout>
  )
}
