import API from '../../constants/ApiConstants'
import EventConst from '../../constants/EventConstants'
import SystemConst from '../../constants/SystemConstants'
import dispatcher from '../../dispatchers/Dispatcher'
import UserExcretionResultsStore from '../../stores/indivisualExcretionResults/UserExcretionResultsStore'
import * as AppAction from '../AppAction'
import * as ExcretionResultAction from '../ExcretionResultAction'
import * as LaxationLogsAction from '../LaxationLogsAction'
import _ from 'lodash'
import * as moment from 'moment'
import request from 'superagent'

// 排泄記録取得対象の日数(時間単位)
const EXCRETION_RESULT_HOURS = 24 * 30
/**
 * 日付の更新
 * @param {moment} date 選択した日付
 */
export function updateCurrentDate(date) {
  dispatcher.dispatch({
    type: EventConst.action_type.user_excretion_results.current_date,
    value: date
  })
}

/**
 * 利用者の更新
 * @param {object} carePerson 利用者情報
 */
export function updateCarePerson(carePerson) {
  dispatcher.dispatch({
    type: EventConst.action_type.user_excretion_results.care_person,
    value: carePerson
  })
}

/**
 * 量平均の更新
 * @param {number} averageAmount 量平均の更新
 */
export function updateAverageAmount(averageAmount) {
  dispatcher.dispatch({
    type: EventConst.action_type.user_excretion_results.amount_average,
    value: averageAmount
  })
}

/**
 * ユーザごとの排泄情報の更新
 * @param {number} carePersonId 利用者ID
 * @param {moment} date 選択した日付
 * @param {number} amountAverage 量平均
 */
export function updateUserExcretionResults(...arg) {
  if (arg.length <= 0) return

  AppAction.startLoaded()
  // 過去30日間の排泄情報取得に必要なパラメータを取得
  const param = getUserExcretionResultsRequestParam(arg)

  // 過去30日間の排泄情報を取得
  const selectDatetime = moment(param.date)
  const beforeDatetime = selectDatetime
    .clone()
    .subtract(EXCRETION_RESULT_HOURS, 'hours')
  const startHour = beforeDatetime.format(
    SystemConst.DateFormat.excretionResultStart
  )
  const endHour = selectDatetime.format(
    SystemConst.DateFormat.excretionResultEnd
  )
  const query = {
    subject_id: param.carePersonId,
    start: startHour,
    end: endHour
  }

  // 30日間データ取得成功時の動作
  const onPaginationSuccess = (result) => {
    const detail = createDetailData(result, selectDatetime, beforeDatetime)
    // 対象期間内の同一時刻の排泄記録情報を集める。
    const interval = param.amountAverage
    const startDate = beforeDatetime.format(
      SystemConst.DateFormat.histgramExcretionResult
    )
    const endDate = moment(param.date).format(
      SystemConst.DateFormat.histgramExcretionResult
    )
    average(
      param.carePersonId,
      startDate,
      endDate,
      interval,
      (results) => {
        const average = createAverageData(results, interval)
        // 合計値取得
        sum(
          param.carePersonId,
          startDate,
          endDate,
          (results) => {
            const sumData = createSumData(results, moment(param.date))

            LaxationLogsAction.laxationLogsPagination(query, (result) => {
              // 30日間の詳細、合計、平均、下剤情報を設定
              const laxation = createLaxationData(result, moment(param.date))
              dispatcher.dispatch({
                type: EventConst.action_type.user_excretion_results
                  .excretion_results,
                value: {
                  detail,
                  average: { interval, data: average },
                  sum: sumData.sum,
                  laxation
                }
              })
            })
            // 統計情報を設定
            dispatcher.dispatch({
              type: EventConst.action_type.user_excretion_results.statistics,
              value: {
                noneCoount: sumData.noneSumCount,
                leakCoount: sumData.leakedSumCount
              }
            })
          },
          () => {
            console.log('sum error')
            AppAction.finishLoaded()
          }
        )
      },
      () => {
        console.log('average error')
        AppAction.finishLoaded()
      }
    )
  }
  ExcretionResultAction.excretionResultsPagination(query, onPaginationSuccess)
}

/**
 * 排泄情報取得に必要なリクエストパラメータの取得
 * @param {array} arg 排泄情報パラメータ(0:利用者ID, 1:選択した日付, 2:量平均)
 */
function getUserExcretionResultsRequestParam([
  carePersonId,
  date,
  amountAverage
]) {
  if (_.isEmpty(date)) {
    date = UserExcretionResultsStore.getCurrentDateTime()
  }

  if (_.isEmpty(amountAverage)) {
    amountAverage = UserExcretionResultsStore.getAmountAverage()
  }

  return { carePersonId, date, amountAverage }
}

/**
 * 排泄量情報の作成
 * @param {number} type 排泄種別
 * @param {number} amount 排泄量
 */
function createExcretionResultAmount(type, amount) {
  return { type, amount: amount == null ? -1 : amount }
}

/**
 * 同一日の排泄量情報の作成
 * @param {moment} date 選択した日付
 */
function createExcretionResultAmountSameDay(data) {
  const results = []

  // none
  if (data.result === 'none') {
    results.push(createExcretionResultAmount(SystemConst.ExcretionType.none, 0))
  }

  // 尿
  if (data.result === 'urine' || data.result === 'urine_and_feces') {
    results.push(
      createExcretionResultAmount(
        SystemConst.ExcretionType.urine,
        SystemConst.ExcretionAmount[data.urine_amount]
      )
    )
  }

  // 便
  if (data.result === 'feces' || data.result === 'urine_and_feces') {
    results.push(
      createExcretionResultAmount(
        data.firmness === 'watery'
          ? SystemConst.ExcretionType.wateryFeces
          : SystemConst.ExcretionType.feces,
        SystemConst.ExcretionAmount[data.amount]
      )
    )
  }

  return results
}

/**
 * 排泄情報の作成
 * @param {number} sequenceId シーケンス番号
 * @param {moment} datetime 選択した日付
 * @param {boolean} leaked おむつ漏れ有無
 * @param {object} excretionResultAmount 排泄量情報
 */
function createExcretionResult(
  sequenceId,
  datetime,
  leaked,
  excretionResultAmount
) {
  return {
    id: sequenceId,
    datetime,
    leaked: _.isBoolean(leaked) ? leaked : false,
    excretionResultAmount
  }
}

/**
 * 排泄記録情報の一定期間内の平均値を取得
 * @param {number} carePersonId 利用者ID
 * @param {string} startDate 期間開始日
 * @param {string} endDate 期間終了日
 * @param {number} interval 平均値取得インターバル(分)
 * @param {object} onSuccess 成功時の処理
 * @param {object} onError 失敗時の処理
 */
function average(
  carePersonId,
  startDate,
  endDate,
  interval,
  onSuccess = null,
  onError = null
) {
  const query = {
    subject_id: carePersonId,
    start_dt: startDate,
    end_dt: endDate,
    interval,
    mode: 'excretion_average'
  }
  request
    .get(API.Histogram.Get)
    .query(query)
    .set('Accept', 'application/json')
    .end(function (err, res) {
      if (err === null && onSuccess !== null) {
        onSuccess(res.body)
      } else if (onError != null) {
        onError(res)
      }
    })
}

/**
 * 排泄記録情報の一定期間内の合計値を取得
 * @param {number} carePersonId 利用者ID
 * @param {string} startDate 期間開始日
 * @param {string} endDate 期間終了日
 * @param {object} onSuccess 成功時の処理
 * @param {object} onError 失敗時の処理
 */
function sum(
  carePersonId,
  startDate,
  endDate,
  onSuccess = null,
  onError = null
) {
  const query = {
    subject_id: carePersonId,
    start_dt: startDate,
    end_dt: endDate,
    mode: 'excretion_sum'
  }
  request
    .get(API.Histogram.Get)
    .query(query)
    .set('Accept', 'application/json')
    .end(function (err, res) {
      if (err === null && onSuccess !== null) {
        onSuccess(res.body)
      } else if (onError != null) {
        onError(res)
      }
    })
}

/**
 * 排泄記録平均値情報の作成
 * @param {array} response 平均値取得時のレスポンス
 * @param {number} interval 取得する平均値の間隔
 */
function createAverageData(response, interval) {
  const average = []
  _.each(response, (result, index) => {
    if (result === null) {
      return true
    }

    const excretionAmount = []

    if (result.urine_avg !== null) {
      excretionAmount.push(
        createExcretionResultAmount(
          SystemConst.ExcretionType.urine,
          result.urine_avg
        )
      )
    }

    if (result.feces_avg !== null) {
      excretionAmount.push(
        createExcretionResultAmount(
          SystemConst.ExcretionType.feces,
          result.feces_avg
        )
      )
    }

    average.push(
      createExcretionResult(
        -1,
        moment({ hour: (interval / 60) * index }),
        false,
        excretionAmount
      )
    )
  })
  return average
}

/**
 * 下剤情報の作成
 * @param {array} response 下剤情報取得時のレスポンス
 * @param {moment} basicDatetime 基準となる日付
 */
function createLaxationData(response, basicDatetime) {
  const beforeDatetime = basicDatetime
    .clone()
    .subtract(EXCRETION_RESULT_HOURS, 'hours')
  let sequenceId = 0 // シーケンシャルな番号を割り振る
  const laxation = [] // 下剤情報

  // 対象期間内の合計値情報の作成
  while (basicDatetime.isSameOrAfter(beforeDatetime, 'day')) {
    // データの存在有無の確認
    const selectData = _.filter(response, (value) => {
      return basicDatetime.isSame(value.administered, 'day')
    })

    if (!_.isUndefined(selectData)) {
      laxation.push({ sequence_id: sequenceId, data: selectData })
    }
    sequenceId++
    basicDatetime.subtract(1, 'days')
  }
  return laxation
}

/**
 * 排泄記録詳細情報の作成
 * @param {array} response 詳細情報取得時のレスポンス
 * @param {moment} startDatetime 詳細情報作成開始日時
 * @param {moment} endDatetime 詳細情報作成終了日時
 * @return {array} 排泄記録詳細情報
 */
function createDetailData(response, startDatetime, endDatetime) {
  let sequenceId = 0 // シーケンス番号、新しい日時を先頭から番号を割り振る。
  const detail = [] // 対象期間内の排泄記録情報を保持
  let dataIndex = 0

  // 対象期間内の同一時刻の排泄記録情報を集める。
  while (startDatetime.isSameOrAfter(endDatetime, 'day')) {
    const datas = []
    const rec = response.slice(dataIndex)
    _.each(rec, (data) => {
      if (startDatetime.isSame(data.confirmed, 'day')) {
        // 同一日の排泄量オブジェクトを作成
        const results = createExcretionResultAmountSameDay(data)
        datas.push(
          createExcretionResult(data.id, data.confirmed, data.leaked, results)
        )
        dataIndex++
      } else {
        return false
      }
    })
    detail.push({
      sequence_id: sequenceId,
      date: startDatetime.format(SystemConst.DateFormat.Calendar),
      data: datas
    })
    sequenceId++
    startDatetime.subtract(1, 'days')
  }
  return detail
}
/**
 * 排泄記録合計値情報の作成
 * @param {array} response 合計値取得時のレスポンス
 * @param {moment} basicDatetime 基準となる日付
 * @return {object} 排泄記録合計値 sum:array 合計値(各日付ごとの排泄なし(空振り)、尿漏れ、便漏れ、水様便漏れの合計値)
 *                                noneSumCount:number 対象期間内の空振り回数合計
 *                                leakedSumCount:number 対象期間内の漏れ回数合計
 */
function createSumData(response, basicDatetime) {
  const beforeDatetime = basicDatetime
    .clone()
    .subtract(EXCRETION_RESULT_HOURS, 'hours')
  let sequenceId = 0 // シーケンシャルな番号を割り振る
  const sum = [] // 合計値(各日付ごとの排泄なし(空振り)、尿漏れ、便漏れ、水様便漏れの合計値)
  let noneSumCount = 0 // 対象期間内の空振り回数合計
  let leakedSumCount = 0 // 対象期間内の漏れ回数合計

  // 対象期間内の合計値情報の作成
  while (basicDatetime.isSameOrAfter(beforeDatetime, 'day')) {
    // データの存在有無の確認
    const selectDate = basicDatetime.format(
      SystemConst.DateFormat.histgramExcretionResult
    )
    const selectData = _.find(response, (value, date) => {
      return date === selectDate
    })

    const datas = []
    let leakedCount = 0
    if (!_.isUndefined(selectData)) {
      datas.push(
        createExcretionResultAmount(
          SystemConst.ExcretionType.none,
          selectData.none_count
        )
      )
      datas.push(
        createExcretionResultAmount(
          SystemConst.ExcretionType.urine,
          selectData.urine_sum
        )
      )
      datas.push(
        createExcretionResultAmount(
          SystemConst.ExcretionType.feces,
          selectData.feces_sum
        )
      )
      datas.push(
        createExcretionResultAmount(
          SystemConst.ExcretionType.wateryFeces,
          selectData.watery_sum
        )
      )
      noneSumCount += selectData.none_count
      leakedSumCount += selectData.leaked_count
      leakedCount = selectData.leaked_count
    } else {
      datas.push(createExcretionResultAmount(SystemConst.ExcretionType.none, 0))
      datas.push(
        createExcretionResultAmount(SystemConst.ExcretionType.urine, 0)
      )
      datas.push(
        createExcretionResultAmount(SystemConst.ExcretionType.feces, 0)
      )
      datas.push(
        createExcretionResultAmount(SystemConst.ExcretionType.wateryFeces, 0)
      )
    }

    sum.push({
      sequence_id: sequenceId,
      leakedCount,
      date: basicDatetime.format(SystemConst.DateFormat.Calendar),
      data: datas
    })
    sequenceId++
    basicDatetime.subtract(1, 'days')
  }

  return {
    sum,
    noneSumCount,
    leakedSumCount
  }
}
