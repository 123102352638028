export interface TemplateRenderer {
  (...values: string[]): string
}

export const Template = (
  strings: TemplateStringsArray,
  ...keys: number[]
): TemplateRenderer => {
  return (...values) => {
    const result = [strings[0]]

    keys.forEach((key, i) => {
      const value = values[key]
      result.push(value, strings[i + 1])
    })

    return result.join('')
  }
}
