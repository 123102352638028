/* eslint-disable */
import { EventEmitter } from "events";
import dispatcher from "../../dispatchers/Dispatcher";

import EventConst from "../../constants/EventConstants";
/** 
 * 量平均リストストア
 */
class AmountAverageStore extends EventEmitter {

  /**
   * @type {Array} 量平均リスト(分)
   */
  average = [60,180,360];

  /**
   * 量平均リスト取得
   * @returns {Array} 量平均リスト
   */
  getAmountAverageList() {
    return this.average;
  }

  /**
   * イベント処理
   * @param {object} action Actionからdispatherに渡された値 
   */
  handleActions(action) {
    switch (action.type) {
      case EventConst.action_type.amount_average.average_list:
        this.average = action.value;
        break;
      default:
        return;
    }
    this.emit(action.type);
  }
}

const store = new AmountAverageStore();
dispatcher.register(store.handleActions.bind(store));
export default store;