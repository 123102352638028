/* eslint-disable */
import API from '../constants/ApiConstants'
import dispatcher from '../dispatchers/Dispatcher'
import _ from 'lodash'
import request from 'superagent'

// ネットワークエラーの場合はerrを返却
const getExcretionResults = (query) => {
  return new Promise((resolve, reject) => {
    request
      .get(API.ExcretionResult.All)
      .query(query)
      .set('Accept', 'application/json')
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

async function doPagination(query, onSuccess) {
  const paginationQuery = query
  paginationQuery.page = 1
  let result
  let totalBody = null
  while (true) {
    result = await getExcretionResults(paginationQuery).catch((err) => {
      dispatcher.dispatch({ type: 'EXCRETION_RESULT_ERROR', err })
    })
    if (result === undefined) {
      return
    }
    if (totalBody === null) {
      totalBody = result.body
    } else {
      totalBody.data = totalBody.data.concat(result.body.data)
    }
    if (result.body.pagination.has_next_page === false) {
      break
    }
    paginationQuery.page++
  }
  dispatcher.dispatch({ type: 'EXCRETION_RESULT_PAGINATION', totalBody })
  if (onSuccess) onSuccess(totalBody.data)
}

export function excretionResultsPagination(query, onSuccess = null) {
  doPagination(query, onSuccess)
}

export function excretionResultsForDialog(query) {
  request
    .get(API.ExcretionResult.All)
    .query(query)
    .set('Accept', 'application/json')
    .end(function (err, res) {
      if (err === null) {
        const body = {
          data: res.body.data,
          url: res.req.url
        }
        dispatcher.dispatch({ type: 'EXCRETION_RESULT_FOR_DIALOG', body })
      } else {
        dispatcher.dispatch({ type: 'EXCRETION_RESULT_ERROR', err })
      }
    })
}

export function excretionResultsForDialogFromDelete(query) {
  request
    .get(API.ExcretionResult.All)
    .query(query)
    .set('Accept', 'application/json')
    .end(function (err, res) {
      if (err === null) {
        const body = {
          data: res.body.data,
          url: res.req.url
        }
        dispatcher.dispatch({
          type: 'EXCRETION_RESULT_FOR_DIALOG_FROM_DELETE',
          body
        })
      } else {
        dispatcher.dispatch({ type: 'EXCRETION_RESULT_ERROR', err })
      }
    })
}

const editExcretionResultPromise = (data, id) => {
  return new Promise((resolve, reject) => {
    const url = API.ExcretionResult.Edit.replace(':id', id)
    request
      .put(url)
      .send(data)
      .set('Accept', 'application/json')
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

const addExcretionResultPromise = (data) => {
  return new Promise((resolve, reject) => {
    request
      .post(API.ExcretionResult.Add)
      .send(data)
      .set('Accept', 'application/json')
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
async function registerDatas(datas) {
  const promises = datas.map(async (data) => {
    if (data.id === null) {
      await addExcretionResultPromise(data.sendData)
    } else {
      await editExcretionResultPromise(data.sendData, data.id)
    }
  })
  await Promise.all(promises)
    .then(() => {
      dispatcher.dispatch({ type: 'EXCRETION_RESULT_REGISTER', body: true })
    })
    .catch((err) => {
      dispatcher.dispatch({ type: 'EXCRETION_RESULT_ERROR', err })
    })
}

export function registerExcretionResults(datas) {
  registerDatas(datas)
}

export function deleteExcretionResult(id, onSuccess = null) {
  const url = API.ExcretionResult.Delete.replace(':id', id)
  request
    .del(url)
    .set('Accept', 'application/json')
    .then((res) => {
      const body = res.body.success
      if (onSuccess) {
        onSuccess(body)
      }

      dispatcher.dispatch({ type: 'EXCRETION_RESULT_DELETE', body })
    })
    .catch((err) => {
      dispatcher.dispatch({ type: 'EXCRETION_RESULT_ERROR', err })
    })
}
