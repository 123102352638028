/* eslint-disable */
import { EventEmitter } from "events";
import dispatcher from "../dispatchers/Dispatcher";

class LaxativeStore extends EventEmitter {
  constructor() {
    super();
    this.laxatives = null;
    this.unitList = null;
  }
  getLaxatives() {
    return this.laxatives;
  }
  getUnitList() {
    return this.unitList;
  }

  handleActions(action) {
    switch (action.type) {
      case "LAXATIVES_ALL":
        this.emit("laxatives_all", action.body);
        this.laxatives = action.body;
        break;
      case "LAXATIVES_ADD":
        this.emit("laxatives_add", action.body);
        break;
      case "LAXATIVES_EDIT":
        this.emit("laxatives_edit", action.body);
        break;
      case "LAXATIVES_DELETE":
        this.emit("laxatives_delete", action.body);
        break;
      case "LAXATIVES_DELETE_ERROR":
        this.emit("laxatives_delete_error", action.body);
        break;
      case "LAXATIVES_ALL_DELETE":
        this.emit("laxatives_all_delete", action.body);
        break;
      // 投与単位取得用。
      case "MEDICINE_UNIT_MASTER":
        this.emit("medicine_unit_master", action.body);
        this.unitList = action.body;
        break;
      default:
        break;
    }
  }
}

const laxativeStore = new LaxativeStore();
dispatcher.register(laxativeStore.handleActions.bind(laxativeStore));

export default laxativeStore;