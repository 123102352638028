/* eslint-disable */
import { EventEmitter } from "events";
import dispatcher from "../../dispatchers/Dispatcher";

import EventConst from "../../constants/EventConstants";
/** 
 * 排泄記録詳細情報ストア
 */
class ExcretionSettingStore extends EventEmitter {

  /**
   * @type {object} アラートログ
   *                  id:number アラートID
   *                  date:moment アラート時刻
   */
  alertLog = {};

  /**
   * @type {moment} 詳細情報対象日付/時刻
   */
  detailDate = null;

  /**
   * @type {array} 排泄記録詳細情報 
   */
  detailExcretionResults = [];

  /**
   * @type {array} 下剤情報 
   */
  laxationLogs = [];

  /**
   * 排泄記録詳細情報取得
   * @returns {array} 排泄記録情報
   */
  getDetailExcretionResults() {
    return this.detailExcretionResults;
  }

  /**
   * 下剤情報取得
   * @returns {array} 下剤情報取得
   */
  getLaxationLogs() {
    return this.laxationLogs;
  }

  /**
   * 詳細情報対象日付/時刻取得
   * @returns {moment} 詳細情報対象日付/時刻
   */
  getDetailExcretionResultsDate() {
    return this.detailDate;
  }

  /**
   * 排泄記録詳細情報取得
   * @returns {object} 排泄記録情報
   */
  getAlertLog() {
    return this.alertLog;
  }

  /**
   * イベント処理
   * @param {object} action Actionからdispatherに渡された値 
   */
  handleActions(action) {
    switch (action.type) {
      case EventConst.action_type.excretion_setting.excretion_results:
        this.detailExcretionResults = action.value.data;
        break;
      case EventConst.action_type.excretion_setting.excretion_results_time:
        this.detailDate = action.value.data;
        break;
      case EventConst.action_type.excretion_setting.laxation:
        this.laxationLogs = action.value.data;
        break;
      case EventConst.action_type.excretion_setting.alert_log:
          if(action.value !== null) {
            this.alertLog[action.value.id] = action.value.date;
          }
          else {
            this.alertLog = {};
          }
          break;
      default:
        return;
    }
    this.emit(action.type);
  }
}

const excretionSettingStore = new ExcretionSettingStore();
dispatcher.register(excretionSettingStore.handleActions.bind(excretionSettingStore));
export default excretionSettingStore;