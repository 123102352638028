import './NotificationCounter.scss'
import { localizedUiWords } from '@/features/locale'
import { LocaleContext } from '@/providers/LocaleProvider'
import React, { useContext } from 'react'

type NotificationCounterProps = {
  notificationCount: number
}

export const NotificationCounter = ({
  notificationCount
}: NotificationCounterProps) => {
  const [locale] = useContext(LocaleContext)
  const uiWords = localizedUiWords(locale.lang).pages.app

  return (
    <div className="notification-counter">
      <div className="waiting_for_action">
        {uiWords.careSupportRequiredLabel}
      </div>
      <div className="counter">{notificationCount}</div>
      <div className="person">{uiWords.careSupportRequiredResidentUnit}</div>
    </div>
  )
}
