import AverageItemCreator from '../../utils/AverageItemCreator'
import ItemCreator from '../../utils/ItemCreator'
import './IndividualTimeline.scss'
import {
  TimelineStatsRowHeader,
  TimelineStatsRowHeaderContents
} from './TimelineStatsRowHeader'
import './TimelineStatsRowHeader.scss'
import * as AppAction from '@/actions/AppAction'
import EventConst from '@/constants/EventConstants'
import SystemConst from '@/constants/SystemConstants'
import { localizedUiWords } from '@/features/locale'
import { LocaleContext } from '@/providers/LocaleProvider'
import UserExcretionResultsStore from '@/stores/indivisualExcretionResults/UserExcretionResultsStore'
import _ from 'lodash'
import * as moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import VisjsTimeline from 'react-visjs-timeline'

const WIDTH_HOUR = 24

/**
 * ユーザごとの排泄記録タイムライン
 */
class IndivisualTimeline extends Component {
  static contextType = LocaleContext

  /**
   * コンストラクタ
   */
  constructor() {
    super()

    // タイムラインには現在の日付の時間帯を表示
    this.currentDate = moment().hour(0).minute(0).second(0).millisecond(0)

    this.state = {
      items: [],
      groups: [],
      options: {
        /**
         * タイムラインのオプションを定義
         *
         * add: add new items by double tapping
         * updateTime: drag items horizontally
         * updateGroup: drag items from one group to another
         * overrideItems: allow these options to override item.editable
         */
        editable: {
          add: false,
          updateTime: false,
          updateGroup: false,
          overrideItems: false
        },
        width: '100%',

        // 時刻が近くて表示が重なるようなケースにどう扱うかの指定(trueにすると冒頭の例のようにずらして表示)
        stack: false,

        // 日付ラベルの非表示
        showMajorLabels: true,

        // 現在の時刻のポイントにラインを表示
        showCurrentTime: true,

        // デフォルトのアイテムのタイプ指定
        type: 'range',

        // timelineの表示の最大幅
        maxHeight: '800px',

        moveable: false,

        // タイムライン拡大縮小の禁止
        zoomable: true,

        // 最小時間軸を指定
        zoomMin: 1000 * 60 * 60 * WIDTH_HOUR,

        // 最大時間軸を指定
        zoomMax: 1000 * 60 * 60 * WIDTH_HOUR,

        zoomKey: 'ctrlKey',

        // 時間軸を指定
        timeAxis: { scale: 'hour', step: 1 },

        start: moment(this.currentDate).format('YYYY-MM-DDT00:00:00'),
        end: moment(this.currentDate)
          .add(WIDTH_HOUR, 'hours')
          .format('YYYY-MM-DDT00:00:00'),
        orientation: 'top',
        align: 'auto',
        verticalScroll: true,
        horizontalScroll: true,
        margin: 0,
        format: {
          minorLabels: {
            millisecond: 'SSS',
            second: 's',
            minute: 'HH:mm',
            hour: 'HH:mm',
            weekday: 'M/D(ddd)',
            day: 'M/D(ddd)',
            week: 'w',
            month: 'MMM',
            year: 'YYYY'
          },

          // 明示的に空を設定しないと、メジャーラベルに日付が表示される。
          majorLabels: {
            millisecond: '',
            second: '',
            minute: '',
            hour: '',
            weekday: '',
            day: '',
            week: '',
            month: '',
            year: ''
          }
        }
      }
    }
  }

  /**
   * コンポーネントマウント時
   */
  componentDidMount() {
    UserExcretionResultsStore.on(
      EventConst.action_type.user_excretion_results.excretion_results,
      () => {
        const excretionData = UserExcretionResultsStore.getExcretionResults()
        this.setItem(
          excretionData.detail,
          excretionData.average,
          excretionData.laxation
        )
        this.setGroup(excretionData.detail, excretionData.sum)

        setTimeout(() => {
          AppAction.finishLoaded()
        }, 500)
      }
    )
  }

  /**
   * コンポーネントアンマウント時
   */
  componentWillUnmount() {
    UserExcretionResultsStore.removeAllListeners(
      EventConst.action_type.user_excretion_results.excretion_results
    )
  }

  /**
   * タイムシフトのアイテム情報を設定
   *
   * @param {array} detailData 詳細データ
   * @param {array} averageData 平均データ
   * @param {array} laxationData 下剤データ
   */
  setItem(detailData, averageData, laxationData) {
    const items = _.concat(
      AverageItemCreator.createItem({
        average: averageData.data,
        interval: averageData.interval,
        currentDate: this.currentDate
      }),
      ItemCreator.createItem({
        detail: detailData,
        laxation: laxationData,
        currentDate: this.currentDate
      })
    )

    this.setState({
      items
    })
  }

  /**
   * タイムシフトのグループ情報を設定
   *
   * @param {array} details 詳細
   * @param {array} totals 合計
   */
  setGroup(details, totals) {
    const [locale] = this.context
    const uiWords = localizedUiWords(locale.lang).pages.excretionResults

    this.setState({
      groups: [
        TimelineStatsRowHeader(
          uiWords.totalHeaderLabel,
          uiWords.averageHeaderLabel
        ),
        ...TimelineStatsRowHeaderContents(
          details,
          totals,
          uiWords.leakageCountLabel
        )
      ]
    })
  }

  /**
   * タイムラインクリック
   *
   * @param {object} e マウスイベント
   */
  onClick(e) {
    console.log(e)
    if (this.checkedIgnoreClick(e)) {
      return
    }

    if (e.what === 'group-label') {
      // 日付をクリック
      if (e.event.srcElement.className === 'date') {
        const group = this.getselectedGroupId(e.group)
        this.props.onGroupLabelClick(group.date)
      }
    }

    if (e.what === 'background' || e.what === 'item') {
      const group = this.getselectedGroupId(e.group)

      // クリックは現在の日付/時刻が取得されるため、縦軸の日付に変更し、それを選択した日付時刻とする。
      const clickTime = moment(e.time)

      const selectedTime = moment(group.date, SystemConst.DateFormat.Calendar)
        .hour(clickTime.hour())
        .minute(clickTime.minute())
      e.item
        ? this.onCreateUpdateRecords(selectedTime)
        : this.onCreateNewRecords(selectedTime)
    }
  }

  /**
   * タイムラインのクリックを無視すべきか
   *
   * @param {object} e マウスイベント
   * @return {boolean} true:無視する.
   */
  checkedIgnoreClick(e) {
    return e.group === null || e.group < 0
  }

  /**
   * 選択されたカメラ情報取得
   *
   * @param {number} groupId グループID
   */
  getselectedGroupId(groupId) {
    const groups = this.state.groups.filter((value) => groupId === value.id)

    // グループ未選択
    if (groups.length === 0) {
      return null
    }
    return groups[0]
  }

  /**
   * タイムシフトのグループ情報を設定
   *
   * @param {moment} datetime 時刻
   */
  onCreateNewRecords(datetime) {
    this.props.onAdd(datetime)
  }

  /**
   * タイムシフトのグループ情報を設定
   *
   * @param {moment} datetime 時刻
   */
  onCreateUpdateRecords(datetime) {
    this.props.onUpdate(datetime)
  }

  render() {
    const groupData =
      this.props.sort === 'asc'
        ? this.state.groups.slice(1).reverse()
        : this.state.groups.slice(1)

    if (this.state.groups.length !== 0) {
      const groupHead = this.state.groups[0]
      groupData.unshift(groupHead)
    }

    return (
      <VisjsTimeline
        options={this.state.options}
        items={this.state.items}
        groups={groupData}
        clickHandler={this.onClick.bind(this)}
      />
    )
  }
}

IndivisualTimeline.propTypes = {
  sort: PropTypes.oneOfType(['asc', 'desc']),
  onGroupLabelClick: PropTypes.func,
  onAdd: PropTypes.func,
  onUpdate: PropTypes.func
}

export default IndivisualTimeline
