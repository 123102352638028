/* eslint-disable */
import API from '../constants/ApiConstants'
import dispatcher from '../dispatchers/Dispatcher'
import request from 'superagent'

export function getExcretionForecasts() {
  request
    .get(API.ExcretionForecast.All)
    .set('Accept', 'application/json')
    .end(function (err, res) {
      if (err === null) {
        let body = res.body ? res.body : JSON.parse(res.text)
        dispatcher.dispatch({ type: 'EXCRETION_FORECAST_ALL', body })
      } else {
        dispatcher.dispatch({ type: 'EXCRETION_FORECAST_ERROR', res })
      }
    })
}

export function getExcretionForecastDetail(subject_id) {
  subject_id = fixedEncodeURIComponent(subject_id)

  let query = { subject_id: subject_id }

  request
    .get(API.ExcretionForecast.Get)
    .query(query)
    .set('Accept', 'application/json')
    .end(function (err, res) {
      if (err === null) {
        let body = res.body ? res.body : JSON.parse(res.text)
        dispatcher.dispatch({ type: 'EXCRETION_FORECAST_DETAIL', body })
      } else {
        dispatcher.dispatch({ type: 'EXCRETION_FORECAST_ERROR', res })
      }
    })
}

export function addExcretionForecast(data) {
  request
    .post(API.ExcretionForecast.Add)
    .set('Accept', 'application/json')
    .send(data)
    .end(function (err, res) {
      const body = res ? res.body : null
      if (err === null) {
        dispatcher.dispatch({ type: 'EXCRETION_FORECAST_ADD', body })
      } else {
        dispatcher.dispatch({ type: 'EXCRETION_FORECAST_ERROR', res })
      }
    })
}

export function editExcretionForecast(data, id) {
  request
    .put(API.ExcretionForecast.Edit.replace(':id', id))
    .send(data)
    .set('Accept', 'application/json')
    .end(function (err, res) {
      const body = res ? res.body : null
      if (err === null) {
        dispatcher.dispatch({ type: 'EXCRETION_FORECAST_EDIT', body })
      } else {
        dispatcher.dispatch({ type: 'EXCRETION_FORECAST_ERROR', res })
      }
    })
}

function fixedEncodeURIComponent(str) {
  return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
    return '%' + c.charCodeAt(0).toString(16)
  })
}
