/* eslint-disable */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import request, { Token } from "./CustomAxios";
import { AsyncThunkConfig } from "../../store";
import API from "../../constants/ApiConstants";
import ValidationErrors from "../ValidationErrors";
import TMStatus, { initialTMStatus } from "../Types/TMStatus";

// get
export type GetStatusMSTState = {
  statusMST: TMStatus;
  message: string;
  isLoading: boolean;
};

const initialGetStatusMST: GetStatusMSTState = {
  statusMST: initialTMStatus,
  message: "",
  isLoading: false,
};

type GetStatusMSTParam = {
  login: Token;
};

export const fetchGetStatusMST = createAsyncThunk<
  Object,
  GetStatusMSTParam,
  AsyncThunkConfig<ValidationErrors>
>("StatusMST/all", async (arg, thunkAPI) => {
  let response: any = [];
  try {
    response = await request(arg.login).get(API.StatusMST.All);
  } catch (err: any) {
    let error: AxiosError<ValidationErrors> = err;
    if (!error.response) {
      throw err;
    }
    return thunkAPI.rejectWithValue(error.response.data);
  }

  return response;
});

const fetchGetStatusMSTSlice = createSlice<GetStatusMSTState, any>({
  name: "fetch/all/StatusMST",
  initialState: initialGetStatusMST,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(fetchGetStatusMST.fulfilled, (state: any, action: any) => {
      console.log("StatusMST fulfilled");
      state.statusMST = action.payload.data.data;
      state.isLoading = false;
    });
    builder.addCase(fetchGetStatusMST.pending, (state: any, action: any) => {
      console.log("StatusMST pending");
      state.isLoading = true;
    });
    builder.addCase(fetchGetStatusMST.rejected, (state: any, action: any) => {
      console.log("StatusMST rejected");
      state.status = "error";
      state.message = action.error.message;
      state.isLoading = false;
    });
  },
});

export default fetchGetStatusMSTSlice.reducer;
