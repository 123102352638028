import './CareNoteSummary.scss'
import { TemplateRenderer } from '@/components/common/TemplateLiteral'
import { localizedUiWords } from '@/features/locale'
import { LocaleContext } from '@/providers/LocaleProvider'
import React, { useContext } from 'react'

type CareNoteSummaryProps = {
  residentName: string
  missingCount: number
  leakageCount: number
}

/**
 * サマリーを作成する
 */
const getSummary = ({
  residentName,
  missingCount,
  leakageCount
}: CareNoteSummaryProps) => {
  const [locale] = useContext(LocaleContext)
  const uiWords = localizedUiWords(locale.lang).pages.excretionResults

  // 空振りとおむつ漏れがある場合
  if (missingCount > 0 && leakageCount > 0) {
    const missingLeakageSummary =
      uiWords.missingLeakageSummary as TemplateRenderer

    return missingLeakageSummary(
      residentName,
      missingCount.toString(),
      leakageCount.toString()
    )
  }

  // 空振りがある場合
  if (missingCount > 0 && leakageCount <= 0) {
    const missingSummary = uiWords.missingSummary as TemplateRenderer
    return missingSummary(residentName, missingCount.toString())
  }

  // おむつ漏れがある場合
  if (missingCount <= 0 && leakageCount > 0) {
    const leakageSummary = uiWords.leakageSummary as TemplateRenderer
    return leakageSummary(residentName, leakageCount.toString())
  }

  // 空振りとおむつ漏れがない場合
  const noMissingNoLeakageSummary =
    uiWords.noMissingNoLeakageSummary as TemplateRenderer
  return noMissingNoLeakageSummary(residentName)
}

/**
 * 利用者別のケア記録1ヶ月ビューで表示するサマリーコンポーネント
 */
export const CareNoteSummary = (props: CareNoteSummaryProps) => {
  return <div className="summary">{getSummary(props)}</div>
}
