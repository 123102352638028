/* eslint-disable */
import * as CarePeopleAction from '../../../actions/CarePeopleAction'
import * as UserExcretionResultsAction from '../../../actions/indivisualExcretionResults/UserExcretionResultsAction'
import EventConst from '../../../constants/EventConstants'
import CarePeopleStore from '../../../stores/CarePeopleStore'
import UserExcretionResultsStore from '../../../stores/indivisualExcretionResults/UserExcretionResultsStore'
import _ from 'lodash'
import React, { Component } from 'react'

/**
 * ユーザ選択プルダウン
 */
class UserSelectField extends Component {
  _isMounted = false
  /**
   * コンストラクタ
   */
  constructor() {
    super()
    this.state = {
      carePeopleList: null,
      carePerson: UserExcretionResultsStore.getCarePerson()
    }
  }

  /**
   * コンポーネントマウント時
   */
  componentDidMount() {
    this._isMounted = true

    CarePeopleStore.on(EventConst.action_type.care_people.people_group, (v) => {
      if (!this._isMounted) return
      let carePeople = v
      // ステータス「入居中」以外の利用者を除外する。
      carePeople = _.filter(carePeople, { status_code: '0' })
      this.setState({
        carePeopleList: carePeople
      })
    })

    UserExcretionResultsStore.on(
      EventConst.action_type.user_excretion_results.care_person,
      () => {
        if (!this._isMounted) return
        this.setState({
          carePerson: UserExcretionResultsStore.getCarePerson()
        })
      }
    )
    const groupId = this.props.groupId
    // グループIDが指定されているならそれを維持する。
    CarePeopleAction.carePeopleGroupFilter(
      groupId > 0 ? { group_id: groupId } : ''
    )
  }

  componentDidUpdate(prevProps) {
    const groupId = this.props.groupId
    if (prevProps.groupId !== this.props.groupId) {
      // グループIDが指定されているならそれを維持する。
      CarePeopleAction.carePeopleGroupFilter(
        groupId > 0 ? { group_id: groupId } : ''
      )
      console.log(groupId)
    }
  }

  /**
   * コンポーネントアンマウント時
   */
  componentWillUnmount() {
    this._isMounted = false
    CarePeopleStore.removeAllListeners(
      EventConst.action_type.care_people.people_group
    )
  }

  /**
   * プルダウン変更
   * @param {object} e イベント
   */
  onChange(e) {
    const selectId = parseInt(e.target.value, 10)
    const carePerson = this.state.carePeopleList.filter((value) => {
      return selectId === value.id
    })

    const selectedCarePerson = carePerson.shift()
    UserExcretionResultsAction.updateCarePerson(selectedCarePerson)
    UserExcretionResultsAction.updateUserExcretionResults(selectedCarePerson.id)
  }

  /**
   * プルダウンオプション作成
   */
  createOptions() {
    let options = []
    if (this.state.carePeopleList !== null) {
      options = _.map(this.state.carePeopleList, (carePeople) => {
        return (
          <option
            key={carePeople.id}
            value={carePeople.id}
          >{`${carePeople.place} ${carePeople.name}`}</option>
        )
      })
    }
    return options
  }

  /**
   * 描画
   */
  render() {
    return (
      <div className="hpad-pulldown-1">
        <select
          onChange={this.onChange.bind(this)}
          value={this.state.carePerson.id}
        >
          {this.createOptions()}
        </select>
      </div>
    )
  }
}
export default UserSelectField
