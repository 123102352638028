type TMStatus = {
  id: number;
  name: string;
  code: string;
  description: string;
};

export const initialTMStatus: TMStatus = {
  id: 0,
  name: "",
  code: "",
  description: "",
};
export default TMStatus;
