/* eslint-disable */
import { EventEmitter } from "events";
import dispatcher from "../dispatchers/Dispatcher";

const localStorage = window.localStorage;
const STORE_TOKEN_KEY = "token";
const STORE_FACILITY_ID = "facility_id";

class ErrorStore extends EventEmitter {
  handleActions(action) {
    switch (action.type) {
      case "ERROR":
        // エラー通知
        this.emit("error_ajax", action.res.body ? action.res : action.res.body);
        // ステータスコードでの個別処理
        switch (action.res.statusCode) {
          case 500:
            this.emit("500_error", action.res.body);
            break;
          case 401:
            console.log(action.res);
            localStorage.removeItem(STORE_TOKEN_KEY);
            localStorage.removeItem(STORE_FACILITY_ID);
            document.cookie = "token=; max-age=0";

            // 認証エラーの場合、ログイントップへリダイレクト
            // TODO: できればreactRouterでやりたい
            const splitUrl = window.location.href.split("/");
            if (splitUrl[splitUrl.length - 1] !== "") {
              window.location.href = "/";
            }
            if (action.res.error.url === "/api/pub/login") {
              // ログインエラーの判定
              this.emit("login_error");
            }

            break;
          case 400:
            this.emit("400_error", action.res.body);
            break;
          case 422:
            this.emit("422_error", action.res.body);
            break;
          default:
            break;
        }
        break;
      case "EXCRETION_RESULT_ERROR" || "LAXATION_LOGS_ERROR":
        if (action.err.status !== undefined) {
          switch (action.err.status) {
            case 400:
              this.emit("400_error", action.err);
              break;
            case 401:
              console.log(action.err);
              localStorage.removeItem(STORE_TOKEN_KEY);
              localStorage.removeItem(STORE_FACILITY_ID);
              document.cookie = "token=; max-age=0";

              // 認証エラーの場合、ログイントップへリダイレクト
              // TODO: できればreactRouterでやりたい
              const splitUrl = window.location.href.split("/");
              if (splitUrl[splitUrl.length - 1] !== "") {
                window.location.href = "/";
              }
              if (action.res.error.url === "/api/pub/login") {
                // ログインエラーの判定
                this.emit("login_error");
              }
              break;
            case 422:
              this.emit("422_error", action.err);
              break;
            case 500:
              this.emit("500_error", action.err);
              break;
            default:
              this.emit("other_status_error", action.err);
              break;
          }
        } else {
          this.emit("unexpected_error", action.err);
        }
    }
  }
}

const errorStore = new ErrorStore();
dispatcher.register(errorStore.handleActions.bind(errorStore));

export default errorStore;
