import * as Action from '../actions/AuthAction'
import { AppHeader } from '../components/App/AppHeader'
import authStore from '../stores/AuthStore'
import errorStore from '../stores/ErrorStore'
import { localizedUiWords } from '@/features/locale'
import eye from '@/images/eye.svg'
import eyeX from '@/images/eye_x.svg'
import { LocaleContext } from '@/providers/LocaleProvider'
import React, { Component } from 'react'
import Form from 'react-jsonschema-form'
import { Redirect } from 'react-router-dom'

// フォームの設定
const schema = {
  type: 'object',
  required: ['name', 'password'],
  properties: {
    name: { type: 'string' },
    password: { type: 'string' }
  }
}

export class Login extends Component {
  static contextType = LocaleContext

  constructor() {
    super()
    this.state = {
      login: false,
      isLoginMiss: false
    }
  }

  componentDidMount() {
    authStore.on('login', () => {
      this.setState({
        login: true,
        isLoginMiss: false
      })
    })
    errorStore.on('login_error', () => {
      this.setState({
        isLoginMiss: true
      })
    })
  }

  click() {
    const passwordForm = document.getElementById('root_password')
    const newType = passwordForm.type === 'password' ? 'text' : 'password'
    passwordForm.type = newType
    const pasdom = document.getElementById('pasdom')
    pasdom.src = newType === 'password' ? eyeX : eye
  }

  handleSubmit(e) {
    Action.login(e.formData)
  }

  componentWillUnmount() {
    authStore.removeAllListeners('login')
    authStore.removeAllListeners('login_error')
  }

  GetCookies() {
    const cookieStr = document.cookie
    const cookies = cookieStr.split('; ')
    const res = new Map()
    cookies.forEach((v) => {
      const vv = v.split('=')
      res.set(vv[0], vv[1])
    })
    return res
  }

  render() {
    // ログインしていたらリダイレクト
    if (this.state.login) {
      const cookie = this.GetCookies()
      let redirect2 = cookie.get('redirect2')
      if (redirect2 == null) redirect2 = '/home'
      return <Redirect to={redirect2} />
    }

    const [locale] = this.context
    const uiWords = localizedUiWords(locale.lang).pages.login

    return (
      <div id="login">
        <AppHeader />
        <div className="loginForm">
          <div className="title">{uiWords.title}</div>
          <div
            className="error"
            style={{ display: this.state.isLoginMiss ? '' : 'none' }}
          >
            {uiWords.failedLoginErrorMessage.map((message) => (
              <>
                {message}
                <br />
              </>
            ))}
          </div>
          <div className="hpad-form-1">
            <Form
              schema={schema}
              uiSchema={{
                name: {
                  'ui:placeholder': uiWords.usernamePlaceholder
                },
                password: {
                  'ui:placeholder': uiWords.passwordPlaceholder,
                  'ui:widget': 'password'
                }
              }}
              onSubmit={this.handleSubmit}
              autocomplete="off"
            >
              <button type="submit" className="btn btn-info">
                {uiWords.loginButtonLabel}
              </button>
            </Form>
            <img
              className="eye-image"
              id="pasdom"
              src={eyeX}
              width="30"
              onClick={(e) => this.click(e)}
            />
          </div>
        </div>
      </div>
    )
  }
}
