import './NavbarMenu.scss'
import * as AuthAction from '@/actions/AuthAction'
import config from '@/config/config.json'
import dispatcher from '@/dispatchers/Dispatcher'
import { localizedUiWords } from '@/features/locale'
import { LocaleContext } from '@/providers/LocaleProvider'
import authStore from '@/stores/AuthStore'
import clickStore from '@/stores/ClickStore'
import PropTypes from 'prop-types'
import React, { useEffect, useState, useRef, useContext } from 'react'
import ReactDOM from 'react-dom'

export const NavbarMenu = ({ name }) => {
  const [isMenu, setIsMenu] = useState(false)
  const menuRef = useRef(null)
  const openMenuButtonRef = useRef(null)
  const helppadWebManual =
    'https://docbase.io/posts/837149/sharing/b6b40eb2-98e4-4d22-9657-b70f6a413916'

  useEffect(() => {
    authStore.on('logout', () => {
      window.location.href = '/'
    })
    document.onclick = function (e) {
      dispatcher.dispatch({ type: 'CLICK', pos: { x: e.pageX, y: e.pageY } })
    }
    clickStore.on('click', (pos) => {
      checkCloseMenu(pos.x, pos.y)
    })
    return () => {
      clickStore.removeAllListeners('click')
      authStore.removeAllListeners('logout')
    }
  }, [])

  const clickMenuButton = () => {
    setIsMenu(!isMenu)
  }

  const checkCloseMenu = (x, y) => {
    if (isMenu) {
      // TODO: 非推奨なので findDOMNode を使わないようにする
      /* eslint-disable react/no-find-dom-node */
      const pointButton = ReactDOM.findDOMNode(openMenuButtonRef.current)

      const isInButtonX =
        pointButton.offsetLeft <= x &&
        x <= pointButton.offsetLeft + pointButton.offsetWidth

      const isInButtonY =
        pointButton.offsetTop <= y &&
        y <= pointButton.offsetTop + pointButton.offsetHeight

      let isIn = isInButtonX && isInButtonY

      if (menuRef.current) {
        // TODO: 非推奨なので findDOMNode を使わないようにする
        /* eslint-disable react/no-find-dom-node */
        const pointMenu = ReactDOM.findDOMNode(menuRef.current)

        const isInMenuX =
          pointMenu.offsetLeft <= x &&
          x <= pointMenu.offsetLeft + pointMenu.offsetWidth
        const isInMenuY =
          pointMenu.offsetTop <= y &&
          y <= pointMenu.offsetTop + pointMenu.offsetHeight

        isIn = isIn || (isInMenuX && isInMenuY)
      }

      if (!isIn) {
        setIsMenu(false)
      }
    }
  }

  const handleNewWindow = () => {
    window.open(helppadWebManual, 'helppadWebManual')
  }

  const [locale] = useContext(LocaleContext)
  const uiWords = localizedUiWords(locale.lang).components.appHeader

  return (
    <div className="info-button" ref={openMenuButtonRef}>
      <div className="icon icon-icons_arrow" onClick={clickMenuButton}></div>
      {isMenu ? (
        <div className={'header-menu'} ref={menuRef}>
          <div className="name">{uiWords.loginUserStatement(name)}</div>

          <div className="menu-items">
            <div className="menu-item" onClick={handleNewWindow}>
              {uiWords.manualMenuItem}
            </div>

            <div className="menu-item" onClick={AuthAction.logout}>
              {uiWords.logoutMenuItem}
            </div>
          </div>

          <div className="version">{uiWords.webAppVersion(config.version)}</div>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

NavbarMenu.propTypes = {
  name: PropTypes.string
}
