import './SettingTab.scss'
import { Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import { NavLink } from 'react-router-dom'

export const SettingTab = ({ icon, tabLabel, to, isActive }) => {
  return (
    <NavLink className={'setting-tab' + (isActive ? ' active' : '')} to={to}>
      {icon}
      <Typography className="tab-label">{tabLabel}</Typography>
    </NavLink>
  )
}

SettingTab.propTypes = {
  icon: PropTypes.node,
  tabLabel: PropTypes.string,
  to: PropTypes.string,
  isActive: PropTypes.bool
}
