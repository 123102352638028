/* eslint-disable */
import { EventEmitter } from "events";
import dispatcher from "../dispatchers/Dispatcher";

class LaxationLogsStore extends EventEmitter {
    constructor() {
      super();
      this.laxationLogs = null;
    }
    getLaxationLogs(){
      return this.laxationLogs;
    }
    handleActions(action) {
      switch (action.type) {
        case "LAXATION_LOGS_ALL":
          this.emit("laxation_logs_all",action.body);
          this.laxationLogs = action.body;
          break;
        case "LAXATION_LOGS_PAGINATION":
          this.emit("laxation_logs_pagination", action.totalBody);
          break;
        case "LAXATION_LOGS_DETAIL":
          this.emit("laxation_logs_detail",action.body);
          break;
        case "LAXATION_LOGS_DETAIL_FROM_DELETE":
          this.emit("laxation_logs_detail_from_delete",action.body);
          break;
        case "LAXATION_LOGS_ADD":
          this.emit("laxation_logs_add",action.body);
          break;
        case "LAXATION_LOGS_EDIT":
          this.emit("laxation_logs_edit",action.body);
          break;
        case "LAXATION_LOGS_REGISTER":
          this.emit("laxation_logs_register", action.body);
          break;
        case "LAXATION_LOGS_DELETE":
          this.emit("laxation_logs_delete",action.body);
          break;
        case "ERROR":
          this.emit("laxation_logs_error", action.body);
          break;
         default:
          break;
      }
    }
}

const laxationLogsStore = new LaxationLogsStore();
dispatcher.register(laxationLogsStore.handleActions.bind(laxationLogsStore));

export default laxationLogsStore;