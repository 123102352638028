import React, { ReactNode } from 'react'

import { LocaleProvider } from './LocaleProvider'

type AppProviderProps = {
  children: ReactNode
}

/**
 * React Context プロバイダの設定
 *
 * @param children 子要素
 */
export const AppProvider = ({ children }: AppProviderProps) => {
  return <LocaleProvider>{children}</LocaleProvider>
}
