import API from '../constants/ApiConstants'
import dispatcher from '../dispatchers/Dispatcher'
import request from 'superagent'

// ネットワークエラーの場合はerrを返却
const getLaxationLogs = (query) => {
  return new Promise((resolve, reject) => {
    request
      .get(API.LaxationLogs.All)
      .query(query)
      .set('Accept', 'application/json')
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

async function doPagination(query, onSuccess) {
  const paginationQuery = query
  paginationQuery.page = 1

  let result
  let totalBody = null
  while (true) {
    result = await getLaxationLogs(paginationQuery).catch((err) => {
      dispatcher.dispatch({ type: 'LAXATION_LOGS_ERROR', err })
    })
    if (result === undefined) {
      return
    }
    if (totalBody === null) {
      totalBody = result.body
    } else {
      totalBody.data = totalBody.data.concat(result.body.data)
    }
    if (result.body.pagination.has_next_page === false) {
      break
    }
    paginationQuery.page++
  }
  dispatcher.dispatch({ type: 'LAXATION_LOGS_PAGINATION', totalBody })
  if (onSuccess) onSuccess(totalBody.data)
}

export function laxationLogsPagination(query, onSuccess = null) {
  doPagination(query, onSuccess)
}

export function laxationLogs() {
  request
    .get(API.LaxationLogs.All)
    .set('Accept', 'application/json')
    .end(function (err, res) {
      if (err === null) {
        const body = res.body.data
        dispatcher.dispatch({ type: 'LAXATION_LOGS_ALL', body })
      } else {
        dispatcher.dispatch({ type: 'ERROR', res })
      }
    })
}

export function laxationLogsDetail(query, onSuccess = null) {
  request
    .get(API.LaxationLogs.All)
    .query(query)
    .set('Accept', 'application/json')
    .end(function (err, res) {
      if (err === null) {
        const body = { data: res.body.data, url: res.req.url }
        dispatcher.dispatch({ type: 'LAXATION_LOGS_DETAIL', body })

        if (onSuccess) onSuccess(res.body.data)
      } else {
        dispatcher.dispatch({ type: 'ERROR', res })
      }
    })
}

export function laxationLogsDetailFromDelete(query) {
  request
    .get(API.LaxationLogs.All)
    .query(query)
    .set('Accept', 'application/json')
    .end(function (err, res) {
      if (err === null) {
        const body = { data: res.body.data, url: res.req.url }
        dispatcher.dispatch({ type: 'LAXATION_LOGS_DETAIL_FROM_DELETE', body })
      } else {
        dispatcher.dispatch({ type: 'ERROR', res })
      }
    })
}

const editLaxationLogsPromise = (data, id) => {
  return new Promise((resolve, reject) => {
    const url = API.LaxationLogs.Edit.replace(':id', id)
    request
      .put(url)
      .send(data)
      .set('Accept', 'application/json')
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
const addLaxationLogsPromise = (data) => {
  return new Promise((resolve, reject) => {
    request
      .post(API.LaxationLogs.Add)
      .send(data)
      .set('Accept', 'application/json')
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
async function registerDatas(datas) {
  const promises = datas.map(async (data) => {
    if (data.id === null) {
      await addLaxationLogsPromise(data.sendData)
    } else {
      await editLaxationLogsPromise(data.sendData, data.id)
    }
  })
  await Promise.all(promises)
    .then(() => {
      dispatcher.dispatch({ type: 'LAXATION_LOGS_REGISTER', body: true })
    })
    .catch((err) => {
      dispatcher.dispatch({ type: 'LAXATION_LOGS_ERROR', err })
    })
}

export function registerLaxationLogs(datas) {
  registerDatas(datas)
}

export function deleteLaxationLogs(id) {
  const url = API.LaxationLogs.Delete.replace(':id', id)
  request
    .del(url)
    .set('Accept', 'application/json')
    .end(function (err, res) {
      if (err === null) {
        const body = res.body.success
        dispatcher.dispatch({ type: 'LAXATION_LOGS_DELETE', body })
      } else {
        dispatcher.dispatch({ type: 'ERROR', res })
      }
    })
}
