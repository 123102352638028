/* eslint-disable */
import { EventEmitter } from "events";
import dispatcher from "../dispatchers/Dispatcher";

class ExcretionForecastStore extends EventEmitter {
  handleActions(action) {
    switch (action.type) {
      case "EXCRETION_FORECAST_ALL":
        this.emit("excretion_forecast_all", action.body);
        break;
      case "EXCRETION_FORECAST_DETAIL":
        this.emit("excretion_forecast_detail", action.body);
        break;
      case "EXCRETION_FORECAST_EDIT":
        this.emit("excretion_forecast_edit", action.body);
        break;
      case "EXCRETION_FORECAST_ADD":
        this.emit("excretion_forecast_add", action.body);
        break;
      case "EXCRETION_FORECAST_ERROR":
        this.emit("excretion_forecast_error", action.body);
        break;
      default:
        break;
    }
  }
}

const excretionForecastStore = new ExcretionForecastStore();
dispatcher.register(excretionForecastStore.handleActions.bind(excretionForecastStore));

export default excretionForecastStore;