import SystemConst from '@/constants/SystemConstants'
import _ from 'lodash'
import * as moment from 'moment'

const ITEM_NONE = 'item-none'
const ITEM_LEAKED = 'item-leaked'

const ITEM_WATERY = 'item-watery'
const ITEM_URINE = 'item-urine'
const ITEM_FECES = 'item-feces'
const ITEM_BLANK = 'item-blank'
const ITEM_LAXATIVE = 'item-laxative'

const TOTAL_AMOUNT_MAX = 4

/**
 * タイムラインのアイテム作成
 */
export default class ItemCreator {
  // public

  /**
   * タイムラインアイテムの作成
   * @param {object} パラメータ
   *                  moment：currentDate カレント日付
   *                  array：detail 全排泄記録詳細情報
   * @return {array} タイムラインのアイテム
   */
  static createItem(param) {
    let items = []
    _.each(param.detail, (data) => {
      items = items.concat(this.createDetailItem(data, param.currentDate))
    })

    _.each(param.laxation, (data) => {
      items = items.concat(this.createLaxationItem(data, param.currentDate))
    })

    return items
  }

  // private

  /**
   * タイムラインアイテムの作成
   * @param {array} detail 排泄記録詳細情報
   * @param {moment} currentDate カレント日付
   * @return {array} タイムラインのアイテム
   */
  static createDetailItem(detail, currentDate) {
    const lastData = []

    // 同時間帯のデータを集める。
    const detailDataBySameTimeList = {}
    _.each(detail.data, (data) => {
      const hourKey = moment(data.datetime).hour()
      detailDataBySameTimeList[hourKey] =
        detailDataBySameTimeList[hourKey] || []
      detailDataBySameTimeList[hourKey].push(data)
    })

    _.each(detailDataBySameTimeList, (detailDataBySameTime, hourKey) => {
      const startHour = currentDate.clone().hour(hourKey)
      const halfHour = startHour.clone().add(30, 'minutes')
      const endHour = startHour.clone().add(1, 'hours')
      const urineData = this.buildExcretionData(
        detailDataBySameTime,
        SystemConst.ExcretionType.urine
      )
      const fecesData = this.buildExcretionData(
        detailDataBySameTime,
        SystemConst.ExcretionType.feces
      )
      const wateryFecesData = this.buildExcretionData(
        detailDataBySameTime,
        SystemConst.ExcretionType.wateryFeces
      )
      const noneData = this.buildExcretionData(
        detailDataBySameTime,
        SystemConst.ExcretionType.none
      )

      // noneを表示する。
      if (
        this.isEnableNone(
          noneData.exist,
          urineData.exist,
          fecesData.exist,
          wateryFecesData.exist
        )
      ) {
        lastData.push(
          this.buildItem(ITEM_NONE, startHour, endHour, 0, detail.sequence_id)
        )
        return true
      }

      // 尿を表示する。
      if (urineData.exist === true) {
        const style = urineData.leaked
          ? ITEM_URINE + ' ' + ITEM_LEAKED
          : ITEM_URINE
        lastData.push(
          this.buildItem(
            style,
            startHour,
            halfHour,
            this.makeContent(urineData.amount),
            detail.sequence_id
          )
        )
      } else {
        lastData.push(
          this.buildItem(ITEM_BLANK, startHour, endHour, '', detail.sequence_id)
        )
      }

      // 水様便を表示する。
      if (wateryFecesData.exist === true) {
        const style =
          wateryFecesData.leaked || fecesData.leaked
            ? ITEM_WATERY + ' ' + ITEM_LEAKED
            : ITEM_WATERY
        lastData.push(
          this.buildItem(
            style,
            halfHour,
            endHour,
            this.makeContent(wateryFecesData.amount + fecesData.amount),
            detail.sequence_id
          )
        )
        return true
      }

      // 便を表示する。
      if (fecesData.exist === true) {
        const style =
          wateryFecesData.leaked || fecesData.leaked
            ? ITEM_FECES + ' ' + ITEM_LEAKED
            : ITEM_FECES
        lastData.push(
          this.buildItem(
            style,
            halfHour,
            endHour,
            this.makeContent(wateryFecesData.amount + fecesData.amount),
            detail.sequence_id
          )
        )
        return true
      }
      lastData.push(
        this.buildItem(ITEM_BLANK, halfHour, endHour, '', detail.sequence_id)
      )
      return true
    })

    return lastData
  }

  /**
   * 下剤アイテムの作成
   * @param {array} laxation 下剤情報
   * @param {moment} currentDate カレント日付
   * @return {array} タイムラインのアイテム
   */
  static createLaxationItem(laxation, currentDate) {
    const lastData = []

    // 同時間帯のデータを集める。
    const dataBySameTimeList = {}
    _.each(laxation.data, (data) => {
      const hourKey = moment(data.administered).hour()
      dataBySameTimeList[hourKey] = dataBySameTimeList[hourKey] || []
      dataBySameTimeList[hourKey].push(data)
    })

    _.each(dataBySameTimeList, (dataBySameTime, hourKey) => {
      const startHour = currentDate.clone().hour(hourKey)
      const endHour = startHour.clone().add(1, 'hours')
      lastData.push(
        this.buildLaxationItem(startHour, endHour, laxation.sequence_id)
      )
      return true
    })

    return lastData
  }

  /**
   * none(空振り)表示条件
   * @param {number} totalAmount 排泄量
   * @return {string} アイテム内に表示するコンテンツ
   */
  static isEnableNone(noneExist, urineExist, fecesExist, wateryFecesExist) {
    return (
      noneExist === true &&
      urineExist === false &&
      fecesExist === false &&
      wateryFecesExist === false
    )
  }

  /**
   * アイテム構築
   * @param {string} className クラス名
   * @param {moment} start アイテムの開始時刻
   * @param {moment} end アイテムの終了時刻
   * @param {string} content アイテム内に表示するコンテンツ
   * @param {number} groupId タイムラインのグループId
   * @return {object} タイムラインアイテム
   */
  static buildItem(className, start, end, content, groupId) {
    return {
      className,
      start,
      end,
      content,
      type: 'range',
      group: groupId,
      editable: { updateTime: false, updateGroup: false }
    }
  }

  /**
   * 下剤アイテム構築
   * @param {moment} start アイテムの開始時刻
   * @param {moment} end アイテムの終了時刻
   * @param {number} groupId タイムラインのグループId
   * @return {object} 下剤アイテム
   */
  static buildLaxationItem(start, end, groupId) {
    return {
      className: ITEM_LAXATIVE,
      start,
      end,
      content: '',
      type: 'range',
      group: groupId,
      editable: { updateTime: false, updateGroup: false }
    }
  }

  /**
   * コンテンツ構築
   * @param {number} totalAmount 排泄量
   * @return {string} アイテム内に表示するコンテンツ
   */
  static makeContent(totalAmount) {
    if (totalAmount === 0) return ''
    const amount =
      totalAmount > TOTAL_AMOUNT_MAX ? TOTAL_AMOUNT_MAX : totalAmount
    return amount.toString()
  }

  /**
   * 排泄データの構築
   * @param {object} detailDataBySameTime 同一時刻の排泄詳細情報
   * @param {number} type 排泄種別
   * @return {obejct} 排泄データ
   *                   amount:number 排泄量
   *                   leaked:boolean おむつ漏れ
   *                   exist:boolean 対象の排泄種別の存在有無
   */
  static buildExcretionData(detailDataBySameTime, type) {
    let amount = 0
    let isExist = false
    let isLeaked = false
    _.each(detailDataBySameTime, (detailData) => {
      _.each(detailData.excretionResultAmount, (data) => {
        // 同一の排泄種別の情報のみ取得する。
        if (data.type === type) {
          isExist = true
          if (data.amount > 0) {
            amount += data.amount
          }
          isLeaked |= detailData.leaked
        }
      })
    })
    return { amount, leaked: isLeaked, exist: isExist }
  }
}
