/* eslint-disable */
import API from '../constants/ApiConstants'
import dispatcher from '../dispatchers/Dispatcher'
import request from 'superagent'

export function facilities() {
  let url = API.Facility.All
  request
    .get(url)
    .set('Accept', 'application/json')
    .end(function (err, res) {
      if (err === null) {
        let body = res.body.data
        dispatcher.dispatch({ type: 'FACILITY_ALL', body })
      } else {
        dispatcher.dispatch({ type: 'ERROR', res })
      }
    })
}

export function facility(id) {
  let url = API.Facility.Detail.replace(':id', id)
  request
    .get(url)
    .set('Accept', 'application/json')
    .end(function (err, res) {
      if (err === null) {
        let body = res.body.data
        dispatcher.dispatch({ type: 'FACILITY_DETAIL', body })
      } else {
        dispatcher.dispatch({ type: 'ERROR', res })
      }
    })
}

export function editFacility(data, id) {
  let url = API.Facility.Detail.replace(':id', id)
  request
    .put(url)
    .send(data)
    .set('Accept', 'application/json')
    .end(function (err, res) {
      if (err === null) {
        let body = res.body.success
        dispatcher.dispatch({ type: 'FACILITY_EDIT', body })
      } else {
        dispatcher.dispatch({ type: 'ERROR', res })
      }
    })
}
