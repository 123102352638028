/* eslint-disable */
import { EventEmitter } from "events";
import dispatcher from "../dispatchers/Dispatcher";

class ClickStore extends EventEmitter {
  handleActions(action) {
    switch (action.type) {
      case "CLICK":
        this.emit("click",action.pos);
        break;
      default:
        break;
    }
  }
}

const clickStore = new ClickStore();
dispatcher.register(clickStore.handleActions.bind(clickStore));

export default clickStore;
