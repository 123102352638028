import './NotificationTableHeader.scss'
import { localizedUiWords } from '@/features/locale'
import { LocaleContext } from '@/providers/LocaleProvider'
import React, { useContext } from 'react'

export const NotificationTableHeader = () => {
  const [locale] = useContext(LocaleContext)
  const uiWords = localizedUiWords(locale.lang).pages.app

  return (
    <table className="notification-table-header">
      <tbody>
        <tr>
          <th className="room-resident">
            <p>{uiWords.userColumnLabel}</p>
          </th>
          <th className="notified-at">
            <p>{uiWords.notifiedAtColumnLabel}</p>
          </th>
        </tr>
      </tbody>
    </table>
  )
}
