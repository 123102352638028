import logo1 from '../../images/Logo_Header.svg'
import MenuDrawer from '../common/MenuDrawer'
import { NavbarMenu } from './NavbarMenu'
import * as AuthAction from '@/actions/AuthAction'
import * as CarePeopleAction from '@/actions/CarePeopleAction'
import * as FacilityAction from '@/actions/FacilityAction'
import { localizedUiWords } from '@/features/locale'
import { LocaleContext } from '@/providers/LocaleProvider'
import authStore from '@/stores/AuthStore'
import carePeopleStore from '@/stores/CarePeopleStore'
import facilityStore from '@/stores/FacilityStore'
import { makeStyles } from '@material-ui/core/styles'
import * as moment from 'moment'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  appHeader: {
    background: 'linear-gradient(to right, #F2992B, #E27130)',
    justifyContent: 'space-between',
    position: 'relative',
    display: 'flex',
    '& span': {
      color: '#fff'
    },
    [theme.breakpoints.up('md')]: {
      minWidth: '1183px',
      height: '56px'
    }
  },
  headerLeft: {
    display: 'flex',
    '& p': {
      display: 'flex',
      alignItems: 'start'
    },
    '& span': {
      paddingLeft: '8px'
    }
  },
  headerCenter: {
    height: '56px',
    display: 'flex',
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    '& .menu-text': {
      color: 'rgba(255, 255, 255, 0.5)',
      fontSize: '16px'
    }
  },
  menuText: {
    color: 'rgba(255, 255, 255, 0.5)',
    fontSize: '16px'
  },
  menuButton: {
    '& > div': {
      width: '144px',
      height: '56px',
      paddingTop: '23.8px',
      display: 'flex',
      justifyContent: 'center',
      boxSizing: 'border-box',
      '&:hover': {
        backgroundColor: '#4d3830'
      }
    },
    '&:last-child > div': {
      border: 'none'
    },
    '&.active': {
      borderBottom: '3.4px solid #fff',
      '& .menu-text': {
        color: '#fff'
      }
    }
  },
  headerRight: {
    marginRight: '33px',
    display: 'flex',
    '& span': {
      padding: '25px 11px 0px 15px',
      whiteSpace: 'nowrap',
      [theme.breakpoints.down('md')]: {
        padding: '22px 11px 0px 15px'
      }
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: '0px'
    }
  },
  settingButton: {
    padding: '22px 18px 0px',
    display: 'flex',
    alignItems: 'start',
    boxSizing: 'border-box',
    '&:last-child > div': {
      border: 'none'
    },
    '&.active': {
      borderBottom: '3.4px solid #fff'
    },
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  visibleElement: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  viewTime: {
    whiteSpace: 'nowrap',
    marginTop: '25px',
    marginLeft: '50px',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  logo: {
    height: '32.8px',
    marginTop: '14px',
    marginBottom: '9.2px',
    marginLeft: '26px',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  logoNoText: {
    marginTop: '14px',
    marginBottom: '9.2px',
    height: '30px',
    width: '50px',
    objectFit: 'cover',
    objectPosition: '0 100%',
    marginLeft: '0px',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  iconIconsSetting2: {
    color: '#fff',
    fontSize: '20px'
  }
}))

const AppLogo = ({ isLink }) => {
  const classes = useStyles()
  // TODO:
  // - 別ファイルに分離
  // - ロゴをコンポーネント化
  // - scss も分離

  // ログインしていないときはロゴのリンクを無効化
  return (
    <>
      {isLink ? (
        <Link to="/home">
          <img src={logo1} className={classes.logo} alt="helppad" />
        </Link>
      ) : (
        <img src={logo1} className={classes.logo} alt="helppad" />
      )}
    </>
  )
}

AppLogo.propTypes = {
  isLink: PropTypes.bool.isRequired
}

export const AppHeader = ({ activeMenu }) => {
  const classes = useStyles()

  const [facilityDetail, setFacilityDetail] = useState(null)
  const [, setCarePeople] = useState(null)
  const [currentTime, setCurrentTime] = useState(
    moment().format('YYYY/MM/DD HH:mm')
  )
  let timer = null

  const getCookies = () => {
    const cookie = document.cookie
    if (cookie !== '') {
      const cookies = {}

      const splitedCookie = cookie.split('; ')
      splitedCookie.forEach((v) => {
        const [key, value] = v.split('=')
        const pair = { [key]: value }
        Object.assign(cookies, pair)
      })

      return cookies
    }

    return null
  }

  const isExistCookies = () => {
    // ログインできているかを判定
    const cookie = getCookies()
    if (cookie && cookie.token) {
      return true
    }
    return false
  }

  const timerLoop = (loopSeconds) => {
    timer = setTimeout(() => {
      setCurrentTime(moment().format('YYYY/MM/DD HH:mm'))
      timerLoop()
    }, loopSeconds || 60000)
  }

  useEffect(() => {
    facilityStore.on('facility_detail', (v) => {
      setFacilityDetail(v)
    })

    carePeopleStore.on('people_all', (v) => {
      if (v.length > 0) {
        setCarePeople(v)
      }
    })

    // すでにデータとっていたら通信しない
    if (facilityStore.getFacilityDetail() !== null) {
      setFacilityDetail(facilityStore.getFacilityDetail())
      // ローカルストレージからfacilityIdを取得できるか
    } else if (authStore.getFacilityId()) {
      FacilityAction.facility(authStore.getFacilityId())
    } else {
      // クッキーはあるか
      if (isExistCookies()) {
        // クッキーはあるのに、ローカルストレージからfacility_idを取得できない = ログインAPIを実行していない
        // ログイン画面にご案内
        AuthAction.logout()
        window.history.pushState(null, null, '/')
      }
    }

    if (carePeopleStore.getPeopleList() !== null) {
      setCarePeople(carePeopleStore.getPeopleList())
    } else {
      CarePeopleAction.carePeople()
    }

    // 現在時刻表示のsettimeout
    const loopSeconds = moment().format('ss')
    timerLoop((60 - loopSeconds) * 1000)
    return () => {
      carePeopleStore.removeAllListeners('people_all')
      carePeopleStore.removeAllListeners('people_sort')
      carePeopleStore.removeAllListeners('people_async_sort')
      facilityStore.removeAllListeners('facility_detail')
      clearTimeout(timer)
    }
  }, [])

  // TODO: 関数名が抽象的なので具体化したい
  // propにきたアクティブにしたいナビを判定
  const handleActive = (page) => {
    return activeMenu === page ? 'active' : ''
  }

  const [locale] = useContext(LocaleContext)
  const uiWords = localizedUiWords(locale.lang).components.appHeader

  let login
  let headerMenu
  let menuDrawer
  // クッキーはあるか
  if (isExistCookies()) {
    login = (
      // コンポーネント化
      <div className={classes.headerRight}>
        <NavLink className={classes.settingButton} to="/setting/group">
          <div className={handleActive('setting')}>
            <div
              className={
                'icon icon-icons_setting2' + ' ' + classes.iconIconsSetting2
              }
            ></div>
          </div>
        </NavLink>
        <span>{facilityDetail ? facilityDetail.display_name : ''}</span>
        <div>
          {facilityDetail ? <NavbarMenu name={facilityDetail.name} /> : ''}
        </div>
      </div>
    )

    // TODO: コンポーネント化
    headerMenu = (
      <div className={classes.visibleElement}>
        <nav className={classes.headerCenter}>
          <NavLink className={classes.menuButton} to="/home">
            <div className={handleActive('home')}>
              <div className="menu-text">{uiWords.homeTabLabel}</div>
            </div>
          </NavLink>
          <NavLink className={classes.menuButton} to="/excretionResults">
            <div className={handleActive('excretionResults')}>
              <div className="menu-text">{uiWords.careNotesTabLabel}</div>
            </div>
          </NavLink>
          <NavLink className={classes.menuButton} to="/users">
            <div className={handleActive('users')}>
              <div className="menu-text">{uiWords.residentsTabLabel}</div>
            </div>
          </NavLink>
        </nav>
      </div>
    )
    menuDrawer = <MenuDrawer />
  }

  // TODO: コンポーネント化
  const viewTime = (
    <div className={classes.viewTime}>
      {uiWords.currentTimeLabel}
      <span>{currentTime || ''}</span>
    </div>
  )

  return (
    <div className={classes.appHeader}>
      <div className={classes.headerLeft}>
        {menuDrawer}
        <AppLogo isLink={isExistCookies()} />
        {viewTime}
      </div>
      {headerMenu}
      {login}
      <img src={logo1} className={classes.logoNoText} alt="helppad" />
    </div>
  )
}

AppHeader.propTypes = {
  activeMenu: PropTypes.string
}
