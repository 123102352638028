/* eslint-disable */
import { EventEmitter } from "events";
import dispatcher from "../dispatchers/Dispatcher";

class AlertDataStore extends EventEmitter {
  handleActions(action) {
    switch (action.type) {
      case "ALERT_DATA_ALL":
        this.emit("alert_data_all", action.body);
        break;
      default:
        break;
    }
  }
}

const alertDataStore = new AlertDataStore();
dispatcher.register(alertDataStore.handleActions.bind(alertDataStore));

export default alertDataStore;