/* eslint-disable */
import { EventEmitter } from "events";
import dispatcher from "../../dispatchers/Dispatcher";
import * as moment from 'moment';

import EventConst from "../../constants/EventConstants";
/** 
 * ユーザごとの排泄記録情報ストア
 */
class UserExcretionResultsStore extends EventEmitter {

  /**
   * @type {motion} カレント日付
   */
  currentDatetime = moment().hour(0).minute(0).second(0).millisecond(0);
  /**
   * @type {object} 利用者情報 id:number 利用者ID
   *                          name:string 利用者名
   *                          place:string 部屋
   */
  carePerson = {id:0, name:'', place:''};

  /**
   * @type {object} 統計情報 noneCount:number 空振り回数
   *                        leakCoount:number おむつ漏れ回数
   */
  statistics = {noneCoount:0, leakCoount:0};

  /**
   * @type {object} 排泄記録情報 
   *                  detail:array  日ごと排泄情報
   *                  average:array 排泄情報平均
   *                  sum:array     排泄情報合計
   *                  laxation:array  下剤情報
   */
  excretionResults = {detail:[], average:[], sum:[], laxation:[]};

  /**
   * @type {number} 量平均
   */
  amountAverage = 60;

  /**
   * カレント日付取得
   * @returns {moment} カレント日付
   */
  getCurrentDateTime() {
    return this.currentDatetime;
  }

  /**
   * 利用者情報
   * @returns {object} 利用者情報
   */
  getCarePerson() {
    return this.carePerson;
  }

  /**
   * 統計情報取得
   * @returns {object} 統計情報
   */
  getStatistics() {
    return this.statistics;
  }

  /**
   * 排泄記録情報取得
   * @returns {object} 排泄記録情報
   */
  getExcretionResults() {
    return this.excretionResults;
  }
  /**
   * 量平均取得
   * @returns {number} 量平均
   */
  getAmountAverage() {
    return this.amountAverage;
  }

  /**
   * イベント処理
   * @param {object} action Actionからdispatherに渡された値 
   */
  handleActions(action) {
    switch (action.type) {
      case EventConst.action_type.user_excretion_results.current_date:
        this.currentDatetime = action.value;
        break;
      case EventConst.action_type.user_excretion_results.care_person:
        this.carePerson = action.value;
        break;
      case EventConst.action_type.user_excretion_results.statistics:
        this.statistics = action.value;
        break;
      case EventConst.action_type.user_excretion_results.excretion_results:
        this.excretionResults = action.value;
        break;
      case EventConst.action_type.user_excretion_results.amount_average:
        this.amountAverage = action.value;
        break;
      default:
        return;
    }
    this.emit(action.type);
  }
}

const userExcretionResultsStore = new UserExcretionResultsStore();
dispatcher.register(userExcretionResultsStore.handleActions.bind(userExcretionResultsStore));
export default userExcretionResultsStore;