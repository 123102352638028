export const API = {
  Auth: {
    Login: '/api/pub/login',
    Logout: '/api/pub/logout'
  },
  Group: {
    All: '/api/pub/groups',
    Add: '/api/pub/groups',
    Edit: '/api/pub/groups/:id',
    Delete: '/api/pub/groups/:id',
    DeleteAll: '/api/pub/groups/deleteAll'
  },
  CarePeople: {
    All: '/api/pub/care_subjects',
    Detail: '/api/pub/care_subjects/:id',
    Edit: '/api/pub/care_subjects/:id',
    Add: '/api/pub/care_subjects',
    Delete: '/api/pub/care_subjects/:id'
  },
  Facility: {
    All: '/api/pub/facilities',
    Edit: '/api/pub/facilities/:id',
    Detail: '/api/pub/facilities/:id'
  },
  Laxative: {
    All: '/api/pub/laxatives',
    Edit: '/api/pub/laxatives/:id',
    Add: '/api/pub/laxatives',
    Delete: '/api/pub/laxatives/:id',
    DeleteAll: '/api/pub/laxatives/deleteAll',
    Unit: '/api/pub/medicine_unit_master'
  },
  LaxationLogs: {
    All: '/api/pub/laxation_logs',
    Detail: '/api/pub/laxation_logs/:id',
    Edit: '/api/pub/laxation_logs/:id',
    Add: '/api/pub/laxation_logs',
    Delete: '/api/pub/laxation_logs/:id'
  },
  Sensor: {
    All: '/api/pub/sensors',
    Add: '/api/pub/sensors',
    Delete: '/api/pub/sensors/:id',
    editAleartFreq: '/api/pub/sensors/:id'
  },
  Alert: {
    All: '/api/pub/alert_logs',
    Detail: '/api/pub/alert_logs/:id',
    Edit: '/api/pub/alert_logs/:id'
  },
  Predict: {
    Diaper: '/api/pub/predicts/diaper'
  },
  ExcretionResult: {
    All: 'api/pub/excretion_results',
    Detail: '/api/pub/excretion_results/:id',
    Edit: '/api/pub/excretion_results/:id',
    Add: '/api/pub/excretion_results',
    Delete: '/api/pub/excretion_results/:id'
  },
  SensorStatus: {
    Get: '/api/pub/sensor_status'
  },
  Histogram: {
    Get: '/api/pub/histogram'
  },
  StatusMST: {
    All: 'api/pub/m_status',
    Detail: 'api/pub/m_status/:id',
    Edit: 'api/pub/m_status/:id',
    Add: 'api/pub/m_status',
    Delete: 'api/pub/m_status/:id'
  },
  FinalDefecationTime: {
    Get: 'api/pub/final_defecation_time'
  },
  ActiveAlertLogs: {
    Get: 'api/pub/active_alert_logs'
  },
  ExcretionForecast: {
    All: '/api/pub/excretion_forecast',
    Get: '/api/pub/excretion_forecast',
    Add: '/api/pub/excretion_forecast',
    Edit: '/api/pub/excretion_forecast/:id'
  },
  AlertData: {
    Get: '/api/pub/alert_data'
  }
}

export default API
