/* eslint-disable */
import { makeStyles, Theme } from "@material-ui/core/styles";

// カラー定義
export const hpadWhite = "#fff";
export const hpadGray = "#90a4ae";
export const hpadLightGray = "#e9edef";
export const hpadGreen = "#26a69a";
export const hpadLightOrange = "#ffecac";
export const hpadOrange = "#f39a2b";
export const hpadBorderGray = "#ddd";
export const hpadBlack = "#000";
export const hpadRed = "#cc0000";
export const hpadBlue = "#00b7ee";
export const hpadBrown = "#6a3906";

/**
 *
 * @param min 最小画面サイズのときになってほしい要素の幅(px)　（例：min:12px ⇒ 12 を指定する）
 * @param maxMinuxMin 最大画面サイズのときになってほしい要素の幅(px)　（例：min:12px,max:18px ⇒ 18 - 12 = 4を指定する）
 * @returns 画面サイズに応じた要素の幅
 * - 320pxは最小画面幅
 * - 1120は最大画面幅から最小画面幅を引いた値（1920 - 320 = 1600）
 */
export const responsiveWidth = (min: string, maxMinuxMin: string) => {
    return "calc(" + min + "px + " + maxMinuxMin + "* (100vw - 320px)/1600)";
};

// とりえあず必要な部分だけ。徐々に広げていく
export default makeStyles((theme: Theme) => ({
    // @font-face 置換方法が分からないのでステイ
    // @font-face {
    //   font-family: "Yu Gothic";
    //   src: local("Yu Gothic Medium");
    //   font-weight: 100;
    // }
    // @font-face {
    //   font-family: "Yu Gothic";
    //   src: local("Yu Gothic Medium");
    //   font-weight: 200;
    // }
    // @font-face {
    //   font-family: "Yu Gothic";
    //   src: local("Yu Gothic Medium");
    //   font-weight: 300;
    // }
    // @font-face {
    //   font-family: "Yu Gothic";
    //   src: local("Yu Gothic Medium");
    //   font-weight: 400;
    // }
    // @font-face {
    //   font-family: "Yu Gothic";
    //   src: local("Yu Gothic Bold");
    //   font-weight: bold;
    // }

    // body: {
    //   font-family: "Yu Gothic", YuGothic, sans-serif;
    // }

    root: {
        fontSize: responsiveWidth("12", "4"),
    },

    // button, input, select, textarea {
    //   font-family : inherit;
    //   font-size : 100%;
    // }
    // input {
    //   padding: 10px;
    //   border: 1px solid $hpad-border-gray;
    // }
    // input[type="submit"] {
    //   appearance: none;
    //   border: 0;
    // }
    // input:focus, select:focus {
    //   outline: 0;
    // }
    // button {
    //   border: none;
    // }
    // a {
    //   text-decoration: none;
    // }
    // a:active,a:visited {
    //   color: $hpad-gray;
    // }
    /*
     * 共通パーツ
     */

    // ヘッダー
    // .app_header {
    //   min-width: 1183px;
    //   height: 56px;
    //   background: linear-gradient(to right, #F2992B, #E27130);
    //   justify-content: space-between;
    //   position: relative;
    //   span {
    //     color: #fff;
    //   }
    //   .header-left {
    //     display: flex;
    //     p {
    //       display: flex;
    //       align-items: start;
    //     }
    //     span {
    //       padding-left: 8px;
    //     }
    //     .logo {
    //       height: 32.8px;
    //       margin-top: 14px;
    //       margin-bottom: 9.2px;
    //       margin-left: 26px;
    //     }
    //     .view-time {
    //       white-space: nowrap;
    //       margin-top: 25px;
    //       margin-left: 50px;
    //     }
    //   }
    //   .header-center {
    //     height: 56px;
    //     display: flex;
    //     position: absolute;
    //     left: 50%;
    //     transform: translateX(-50%);
    //     .menu-text {
    //       color: rgba(255, 255, 255, 0.5);
    //       font-size: 16px;
    //     }
    //     .menu-button {
    //       & > div {
    //         width: 144px;
    //         height: 56px;
    //         padding-top: 23.8px;
    //         display: flex;
    //         justify-content: center;
    //         box-sizing: border-box;
    //         &:hover{
    //           background-color: #4d3830;
    //         }
    //       }
    //       &:last-child > div {
    //         border: none;
    //       }
    //       &.active {
    //         border-bottom: 3.4px solid #fff;
    //         .menu-text {
    //           color: #fff;
    //         }
    //       }
    //     }
    //   }
    //   .header-right {
    //     margin-right: 33px;
    //     display: flex;
    //     span {
    //       padding: 25px 11px 0px 15px;
    //       white-space: nowrap;
    //     }
    //     .setting-button {
    //       padding: 22px 18px 0px;
    //       display: flex;
    //       align-items: start;
    //       box-sizing: border-box;
    //       & > div {
    //         &:hover {
    //           //background-color: #4d3830;
    //         }
    //       }
    //       &:last-child > div {
    //         border: none;
    //       }
    //       &.active {
    //         border-bottom: 3.4px solid #fff;
    //       }
    //     }
    //     .info-button {
    //       width: 30px;
    //       height: 30px;
    //       margin-top: 16px;
    //       display: flex;
    //       align-items: center;
    //       justify-content: center;
    //     }
    //   }
    //   .icon-icons_setting2 {
    //     color: #fff;
    //     font-size: 20px;
    //   }
    //   .icon-icons_arrow {
    //     color: #fff;
    //     width: 16.25px;
    //     height: 9.38px;
    //   }
    // }

    // .header-menu {
    //   position: absolute;
    //   z-index: 1;
    //   top: 65px;
    //   right: 16px;
    //   font-size: 13px;
    //   border: solid 1px #999;
    //   border-radius: 9px;
    //   background-color: $hpad-white;
    //   box-shadow: 0px 0px 3px 3px #eee;
    //   padding: 0 5px;
    //   justify-content: flex-end;
    //   align-items: right;

    //   .bb1 {
    //     border-bottom: 1px solid #000;
    //   }

    //   div > div {
    //     padding: 6px 0;
    //     background-color: #eee;
    //     &:hover {
    //       transition: 1s;
    //       background-color: #ddd;
    //       button {
    //         background-color: #ddd;
    //       }
    //     }
    //   }

    //   .name {
    //     color: $hpad-gray;
    //     font-size: 15px;
    //     border-bottom: 1px solid #999;
    //     padding: 2px 0;
    //   }

    //   .version {
    //     border-top: 1px solid #999;
    //     font-size: 10px;
    //     text-align: right;
    //   }

    //   &:before {
    //     content: "";
    //     position: absolute;
    //     top: -18px;
    //     right: 31px;
    //     margin-left: -15px;
    //     border: 6px solid transparent;
    //     border-bottom: 12px solid #FFF;
    //     z-index: 10002;
    //   }
    //   &:after {
    //     content: "";
    //     position: absolute;
    //     top: -21px;
    //     right: 30px;
    //     margin-left: -17px;
    //     border: 7px solid transparent;
    //     border-bottom: 14px solid #999;
    //     z-index: 10001;
    //   }
    // }

    // // グローバルメニュー
    // .app_menu {
    //   border: 1px solid $hpad-border-gray;
    //   width: 80px;
    //   min-width: 80px;
    //   text-align: center;
    //   font-size: 12px;
    //   color: $hpad-gray;
    //   height: 100%;

    //   .menu-button {
    //     & > div {
    //       border-bottom: 1px solid $hpad-border-gray;
    //       padding: 15px 0;
    //       text-decoration: none;
    //       &:hover {
    //         @extend %hover-animation;
    //         background-color: $hpad-gray;
    //         color: $hpad-white;
    //       }
    //       &.active {
    //         background-color: $hpad-gray;
    //         color: $hpad-white;
    //         width: 80px;
    //       }
    //     }
    //     &:last-child {
    //       & > div {
    //         border: none;
    //       }
    //     }
    //   }

    //   .icon {
    //     font-size: 38px;
    //   }

    // }

    // // ローディング画面
    // .app_loading {
    //   position: fixed;
    //   width: 100%;
    //   height: 100%;
    //   background-color: rgba(256, 256, 256, 0.8);
    //   top: 0;
    //   left: 0;
    //   z-index: 10000;
    //   display: flex;

    //   &.hide {
    //     display: none;
    //   }

    //   & > div {
    //     margin: auto;
    //     text-align: center;
    //     p {
    //       font-size: 28px;
    //       line-height: 1.4;
    //       margin-bottom: 20px;
    //       span {
    //         font-size: 21px;
    //         display: block;
    //       }
    //     }
    //   }
    // }

    // ダイアログ画面
    appDialog: {
        position: 'fixed',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(256, 256, 256, 0.8)',
        top: '0',
        left: '0',
        zIndex: 10000,
        display: 'flex',

        '&.hide': {
            display: 'none'
        },

        '& > .content': {
            margin: 'auto',
            textAlign: 'center',
            width: '80%',
            '& p': {
                fontSize: '28px',
                lineHeight: '1.4',
                marginBottom: '20px',
                '& span': {
                    fontSize: '21px',
                    display: 'block'
                }
            }
        },
        '& .choiceButtons': {
            justifyContent: 'center',
            '& div': {
                margin: '0 10px',
                color: hpadWhite,
                backgroundColor: hpadGray,
                padding: '20px 50px',
                borderRadius: '15px',
                cursor: 'pointer',
                [theme.breakpoints.down('sm')]: {
                    padding: '20px 20px',
                    width: '82px'
                },
            }
        },
        '& .closeButton': {
            position: 'absolute',
            display: 'inline-block',
            top: '0px',
            right: '0px',
            width: '64px',
            height: '64px',
            borderRadius: '64px',
            border: 'solid 4px ' + hpadGray,
            backgroundColor: 'white',
            margin: '0',
            padding: '0',
            transform: 'scale(0.5)',
            cursor: 'pointer',
            '&:before': {
                content: '""',
                position: 'absolute',
                display: 'inline-block',
                top: '13%',
                left: '45%',
                width: '6px',
                height: '70%',
                border: '0',
                margin: '0',
                padding: '0',
                backgroundColor: hpadGray,
                //   -moz-transform: rotate(45deg);
                //   -webkit-transform: rotate(45deg);
                transform: 'rotate(45deg)'
            },
            '&:after': {
                content: '""',
                position: 'absolute',
                display: 'inline-block',
                top: '13%',
                left: '45%',
                width: '6px',
                height: '70%',
                border: '0',
                margin: '0',
                padding: '0',
                backgroundColor: hpadGray,
                //   -moz-transform: rotate(-45deg);
                //   -webkit-transform: rotate(-45deg);
                transform: 'rotate(-45deg)',
            },
            '&:hover': {
                backgroundColor: hpadGray,
                '&:before': {
                    backgroundColor: 'white',
                },
                '&:after': {
                    backgroundColor: 'white'
                }
            }
        }
    },

    // // コンテンツの横幅
    appContainer: {
        width: responsiveWidth("320", "770"),
        marginLeft: responsiveWidth("5", "105"),
    },

    // .home_container {
    //   width: 100%;
    //   min-width: 1183px;
    //   margin: 0 110px;
    // }
    // @media screen and (max-width:1439px) {
    //   .home_container {
    //     margin: 0;
    //   }
    // }

    // プルダウン
    hpadPulldown1: {
        color: hpadBlack,
        display: "inline-block",
        verticalAlign: "middle",
        position: "relative",
        "& > p": {
            display: "inline-block",
            marginRight: "10px",
        },
        "& span": {
            paddingRight: "10px",
        },
        "& select": {
            display: "inline-block",
            appearance: "none",
            width: responsiveWidth("142", "20"),
            padding: "8px",
            borderRadius: "0px",
            backgroundColor: hpadWhite,
            border: "1px solid" + hpadBorderGray,
            color: hpadBlack,
        },
        "&::after": {
            content: '"▼"',
            position: "absolute",
            top: "12px",
            right: "10px",
            fontSize: "10px",
            color: hpadGray,
            pointerEvents: "none",
        },
    },

    // // 継承専用テーブルスタイル
    // %hpad-table-1 {
    //   th,td {
    //     border: 1px solid $hpad-border-gray;
    //   }
    //   th {
    //     background-color: $hpad-gray;
    //     color: $hpad-white;
    //     padding: 22px 10px;
    //   }
    //   td {
    //     vertical-align: middle;
    //     padding: 21px;
    //   }
    // }

    // //タブ
    // .hpad-tab-1 {
    //   .tab {
    //     color: $hpad-gray;
    //     background-color: $hpad-white;
    //     font-size: 15px;
    //     padding: 12px 28px;
    //     min-width: 120px;
    //     border: solid $hpad-border-gray;
    //     border-width: 1px 1px 0px;
    //     justify-content: center;
    //     margin-right: 10px;
    //    .icon {
    //      font-size: 40px;
    //      margin-right: 5px;
    //    }
    //    &:hover {
    //      @extend %hover-animation;
    //      background-color: $hpad-gray;
    //      color: $hpad-white;
    //    }
    //    &.active {
    //      background-color: $hpad-gray;
    //      color: $hpad-white;
    //    }
    //   }
    //   .tab-content {
    //     border: 1px solid $hpad-border-gray;
    //   }
    // }

    // .tab-content {
    //   padding: 50px;
    // }

    //フォーム
    hpadForm1: {
        "& input": {
            fontSize: responsiveWidth("18", "0"),
        },
        "& input[disabled]": {
            backgroundColor: "#eee",
            color: "#666",
        },
        "& button[type=submit]": {
            padding: "10px 20px",
            backgroundColor: hpadOrange,
            color: hpadWhite,
            borderRadius: "10px",
        },
        "& button[type=button]": {
            padding: "10px 20px",
            backgroundColor: hpadOrange,
            color: hpadWhite,
            borderRadius: "10px",
        },
        // react-jsonschema-formに依存
        "& .rjsf": {
            "& .field": {
                display: "flex",
                alignItems: "center",
                "& .control-label": {
                    minWidth: responsiveWidth("88", "74"),
                    fontSize: responsiveWidth("18", "0"),
                },
            },
            "& fieldset": {
                "& > div": {
                    marginTop: responsiveWidth("5", "10"),
                },
                "& > div:first-child": {
                    marginTop: "0px",
                },
            },
        },
        // プルダウンはuiSchemaで個別にスタイルつける
        "& .form-pulldown": {
            position: "relative",
            "& select": {
                display: "inline-block",
                appearance: "none",
                minWidth: responsiveWidth("120", "20"),
                padding: "8px",
                borderRadius: "0px",
                backgroundColor: hpadWhite,
                border: "1px solid " + hpadBorderGray,
                color: hpadBlack,
            },
            "&::after": {
                content: '"▼"',
                position: "absolute",
                top: "12px",
                right: "10px",
                fontSize: "10px",
                color: hpadGray,
                pointerEvents: "none",
            },
        },
    },

    // .hpad-check-list-1 {
    //   input[type=checkbox] {
    //     display: none;
    //   }
    //   .checkboxes {
    //     .checkbox {
    //       & > label span {
    //         span {
    //           transition: background-color 0.2s linear;
    //           position: relative;
    //           padding: 0 0 0 42px;
    //           cursor: pointer;
    //           display: block;
    //           margin-bottom: 15px;
    //           &::after {
    //             transition: border-color 0.2s linear;
    //             background-color: #fff;
    //             content: '';
    //             position: absolute;
    //             left: 15px;
    //             top: 50%;
    //             display: block;
    //             margin-top: -10px;
    //             width: 16px;
    //             height: 16px;
    //             border: 2px solid #ccc;
    //             z-index: 1;
    //           }
    //           &::before {
    //             transition: opacity 0.2s linear;
    //             position: absolute;
    //             left: 21px;
    //             top: 50%;
    //             display: block;
    //             margin-top: -7px;
    //             width: 5px;
    //             height: 9px;
    //             border-right: 3px solid $hpad-green;
    //             border-bottom: 3px solid $hpad-green;
    //             content: '';
    //             opacity: 0;
    //             transform: rotate(45deg);
    //             z-index: 2;
    //           }
    //         }
    //         input[type=checkbox]:checked + span::before {
    //           opacity: 1;
    //         }

    //       }
    //     }
    //   }

    // }

    // // react-autosuggestのクラス
    // .react-autosuggest__container {
    //   position: relative;
    // }

    // .react-autosuggest__input {
    // }

    // .react-autosuggest__input--focused {
    //   outline: none;
    // }

    // .react-autosuggest__input--open {
    //   border-bottom-left-radius: 0;
    //   border-bottom-right-radius: 0;
    // }

    // .react-autosuggest__suggestions-container {
    //   display: none;
    // }

    // .react-autosuggest__suggestions-container--open {
    //   display: block;
    //   position: absolute;
    //   top: 38px;
    //   border: 1px solid $hpad-border-gray;
    //   background-color: #fff;
    //   font-size: 16px;
    //   border-bottom-left-radius: 4px;
    //   border-bottom-right-radius: 4px;
    //   max-height: 100px;
    //   overflow-y: auto;
    //   z-index: 2;
    // }

    // .react-autosuggest__suggestions-list {
    //   margin: 0;
    //   padding: 0;
    //   list-style-type: none;
    // }

    // .react-autosuggest__suggestion {
    //   cursor: pointer;
    //   padding: 10px 20px;
    // }

    // .react-autosuggest__suggestion--highlighted {
    //   background-color: #ddd;
    // }

    // // ツールチップ
    // .tooltip {
    //   position: absolute;
    //   background-color: #ffffff;
    //   border: solid 1px #000000;
    //   padding: 0.2rem;
    //   z-index: 10;
    // }

    // .tooltip-parent {
    //   position: relative;
    //   .tooltip {
    //     font-size: 1.2rem;
    //     transition: opacity 0.2s, visibility 0.2s;
    //     opacity: 0;
    //     visibility: hidden;
    //     white-space: nowrap;
    //     position:absolute;
    //     padding: 0.1rem 0.3rem;
    //     bottom: -1.8rem;
    //     border-radius: 0.2rem;
    //     background-color: #ffffff;
    //     border: solid 1px #000000;
    //     z-index: 999;
    //   }
    // }
    // .tooltip-parent:hover {
    //   .tooltip {
    //     opacity: 1;
    //     visibility: visible;
    //   }
    // }
}));