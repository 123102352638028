import API from '@/constants/ApiConstants'
import dispatcher from '@/dispatchers/Dispatcher'
import request from 'superagent'

export function login(data) {
  request
    .post(API.Auth.Login)
    .send(data)
    .end(function (err, res) {
      if (err === null) {
        const body = res.body.data
        dispatcher.dispatch({
          type: 'LOGIN',
          body
        })
      } else {
        dispatcher.dispatch({
          type: 'ERROR',
          res
        })
      }
    })
}

export function logout() {
  request
    .post(API.Auth.Logout)
    .send()
    .end(function (err, res) {
      if (err === null) {
        const body = res.body.data
        dispatcher.dispatch({
          type: 'LOGOUT',
          body
        })
      } else {
        dispatcher.dispatch({
          type: 'ERROR',
          res
        })
      }
    })
}
