/* eslint-disable */
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Sortable from 'sortablejs'

class SortableList extends Component {
  constructor() {
    super()
    this.state = {
      listId: 'sortable-list',
      sortableInfo: null,
      data: null
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      listId: `sortable-list-${nextProps.id}`,
      data: nextProps.data
    }
  }

  componentDidMount() {
    const dom = document.getElementById(this.state.listId)
    const sortableInfo = Sortable.create(dom, {
      filter: '.laxative-delete-button',
      onEnd: this.onMove.bind(this)
    })
    this.setState({
      sortableInfo: sortableInfo
    })
  }

  onMove(e) {
    const oldIndex = e.oldIndex
    const newIndex = e.newIndex
    if (oldIndex !== newIndex) {
      const changeId = this.state.sortableInfo.toArray()[newIndex]
      this.props.onSortChange({ newIndex, changeId })
    }
  }

  render() {
    return (
      <ul id={this.state.listId}>
        {this.state.data.map((v) => {
          return (
            <li key={v.id} data-id={v.id}>
              <div>
                <span className="sortable-list-elem-value" title={v.value}>
                  {v.value}
                </span>
                <button
                  className="sortable-list-elem-delete-button"
                  onClick={function () {
                    this.props.onClickDeleteElement(v.id)
                  }.bind(this)}
                >
                  ×
                </button>
              </div>
            </li>
          )
        })}
      </ul>
    )
  }
}

SortableList.propTypes = {
  id: PropTypes.string,
  data: PropTypes.array,
  onClickDeleteElement: PropTypes.func,
  onSortChange: PropTypes.func
}

export default SortableList
