import { SettingLayout } from '../components/SettingLayout'
import './SettingAccount.scss'
import * as Action from '@/actions/FacilityAction'
import AppLoading from '@/components/App/AppLoading'
import { isJaJP, localizedUiWords } from '@/features/locale'
import { LocaleContext } from '@/providers/LocaleProvider'
import authStore from '@/stores/AuthStore'
import errorStore from '@/stores/ErrorStore'
import facilityStore from '@/stores/FacilityStore'
import { lengthConsideredCharacterWidth } from '@/utils/string'
import { Box, Typography } from '@material-ui/core'
import _ from 'lodash'
import React, { Component } from 'react'
import Form from 'react-jsonschema-form'
import zipcodeJa from 'zipcode-ja'

export class SettingAccount extends Component {
  static contextType = LocaleContext

  constructor() {
    super()
    this.state = {
      facilityDetail: null,
      loading: false,
      notices: {},
      errors: {}
    }
  }

  componentDidMount() {
    const [locale] = this.context
    const uiWords = localizedUiWords(locale.lang).pages.settings

    facilityStore.on('facility_detail', (v) => {
      this.setState({
        facilityDetail: v,
        loading: false
      })
    })

    // eslint-disable-next-line
    facilityStore.on('facility_edit', (v) => {
      Action.facility(authStore.getFacilityId())
    })

    errorStore.on('422_error', (v) => {
      if (v.message.email) {
        this.setState({
          loading: false,
          errors: { email: uiWords.invalidCharacterErrorMessage }
        })
      }
    })

    // すでにデータあれば通信しない
    if (facilityStore.getFacilityDetail() !== null) {
      this.setState({
        facilityDetail: facilityStore.getFacilityDetail()
      })
    } else if (authStore.getFacilityId()) {
      // 念のためFacilityIdを取得しているか確認
      Action.facility(authStore.getFacilityId())
      this.setState({
        loading: true
      })
    }
  }

  componentWillUnmount() {
    facilityStore.removeAllListeners('facility_detail')
    facilityStore.removeAllListeners('facility_edit')
  }

  validate(e) {
    const [locale] = this.context
    const uiWords = localizedUiWords(locale.lang).pages.settings

    const errors = {}
    if (
      lengthConsideredCharacterWidth(e.formData.display_name.toString()) > 60
    ) {
      errors.display_name = uiWords.overLimitDisplayNameErrorMessage
    }

    if (lengthConsideredCharacterWidth(e.formData.kana.toString()) > 120) {
      errors.kana = uiWords.overLimitKanaErrorMessage
    }

    if (!e.formData.tell.match('^\\d{10,11}$')) {
      errors.tell = uiWords.invalidTelErrorMessage
    }

    if (!e.formData.postal_code.match('^\\d{7}$')) {
      errors.postal_code = uiWords.invalidPostalErrorMessage
    }

    if (lengthConsideredCharacterWidth(e.formData.address.toString()) > 100) {
      errors.address = uiWords.overLimitAddressErrorMessage
    }

    // eslint-disable-next-line
    if (e.formData.email.toString().match(/[^\x01-\x7E]/)) {
      errors.email = uiWords.inputFullWidthErrorMessage
    } else if (e.formData.email.toString().length > 50) {
      errors.email = uiWords.overLimitEmailErrorMessage
    }

    if (e.formData.password) {
      // eslint-disable-next-line
      if (e.formData.password.toString().match(/[^\x01-\x7E]/)) {
        errors.password = uiWords.inputFullWidthErrorMessage
      } else if (e.formData.password.toString().length > 20) {
        errors.password = uiWords.overLimitPasswordErrorMessage
      }
    }

    if (e.formData.password !== e.formData.password_confirm) {
      errors.password_confirm = uiWords.confirmNewPasswordErrorMessage
    }

    return errors
  }

  // eslint-disable-next-line
  click(e) {
    const val = document.getElementById('root_postal_code').value
    const zipcode = zipcodeJa[val]

    if (zipcode) {
      let address = ''
      for (let i = 0; i < zipcode.address.length; i++) {
        address += zipcode.address[i]
      }
      document.getElementById('root_address').value = address
      this.setState({
        facilityDetail: this.replaceHTMLFormsToJSObject()
      })
    }
  }

  replaceHTMLFormsToJSObject() {
    const htmlForms = document.getElementsByClassName('form-control')
    const formObject = {
      name: null,
      display_name: null,
      kana: null,
      tell: null,
      postal_code: null,
      address: null,
      email: null,
      password: null,
      password_confirm: null
    }

    _.forEach(htmlForms, (form) => {
      const key = form.id.replace('root_', '')
      if (!formObject[key] === undefined) return
      if (form.value === null || form.value === undefined) {
        formObject[key] = ''
      } else {
        formObject[key] = form.value
      }
    })

    return formObject
  }

  /**
   * パスワードを正常に更新できた場合に、パスワードを変更した旨のメッセージを出すため
   * Formのバリデーションを利用せず、Submit時にバリデーションを行う。
   * Formのバリデーションは、
   * バリデーションエラー時に当クラスのrenderが実行されない。
   * (バリデーションメソッド内で、setStatusでstateを変更しても効かない)
   * このためパスワード正常変更後、もう一度パスワードを変更しようとして、
   * それがバリデーションエラーとなった場合、
   * パスワードを正常に変更した旨のメッセージと、
   * バリデーションエラーのメッセージが同時に表示された状態となってしまう。
   */
  handleSubmit(e) {
    const [locale] = this.context
    const uiWords = localizedUiWords(locale.lang).pages.settings

    const errors = this.validate(e)
    if (Object.keys(errors).length > 0) {
      this.setState({
        facilityDetail: e.formData,
        notices: {},
        errors
      })
    } else {
      const formData = {}
      Object.assign(formData, e.formData)
      if (e.formData.password) {
        // パスワード入力あり
        delete formData.password_confirm
        Action.editFacility(formData, authStore.getFacilityId())
      } else {
        // パスワード入力なし
        delete formData.password
        delete formData.password_confirm
        Action.editFacility(formData, authStore.getFacilityId())
      }

      const notices = {}
      if (e.formData.password) {
        notices.password = uiWords.changedPasswordMessage
      }

      this.setState({
        facilityDetail: e.formData,
        loading: true,
        notices,
        errors: {}
      })
    }
  }

  render() {
    const [locale] = this.context
    const uiWords = localizedUiWords(locale.lang).pages.settings

    // フォームの設定
    const schema = {
      type: 'object',
      required: [
        'name',
        'display_name',
        'kana',
        'tell',
        'postal_code',
        'address',
        'email'
      ],
      properties: {
        name: { type: 'string', title: uiWords.usernameInputLabel },
        display_name: { type: 'string', title: uiWords.displayNameInputLabel },
        kana: {
          type: 'string',
          title: uiWords.kanaInputLabel
        },
        tell: { type: 'string', title: uiWords.telInputLabel },
        postal_code: { type: 'string', title: uiWords.postalInputLabel },
        address: { type: 'string', title: uiWords.addressInputLabel },
        email: {
          type: 'string',
          title: uiWords.mailInputLabel,
          format: 'email'
        },
        password: { type: 'string', title: uiWords.newPasswordInputLabel },
        password_confirm: {
          type: 'string',
          title: uiWords.confirmPasswordInputLabel
        }
      }
    }

    const uiSchema = {
      name: { 'ui:disabled': true },
      kana: {
        classNames: `input-kana-form ${isJaJP(locale.lang) ? 'ja' : ''}`
      },
      tell: {
        'ui:options': {
          inputType: 'tel'
        }
      },
      address: { 'ui:help': '' }
    }

    // メッセージ（エラー、通知）はhelpフィールドを利用して出力する
    for (const param in schema.properties) {
      if (param === 'address') {
        // 画面初期表示時 または バリデーションエラー解消時の、「住所」横のボタン表示（※エラーメッセージ表示時と統一の必要あり）
        uiSchema[param]['ui:help'] = (
          <button
            type="button"
            className={`autocomplete-address-button ${
              isJaJP(locale.lang) ? 'ja' : ''
            }`}
            onClick={(e) => this.click(e)}
          >
            {uiWords.autocompleteAddressButtonLabel}
          </button>
        )
      } else if (
        typeof uiSchema[param] !== 'undefined' &&
        typeof uiSchema[param]['ui:help'] !== 'undefined'
      ) {
        uiSchema[param]['ui:help'] = ''
      }

      if (this.state.errors[param] && !this.state.loading) {
        if (!uiSchema[param]) {
          uiSchema[param] = {}
        }

        if (param === 'address') {
          // 「住所」の場合、ボタンとエラーメッセージを併記（※画面初期表示時と統一の必要あり）
          uiSchema[param]['ui:help'] = (
            <div>
              <button
                type="button"
                className={`autocomplete-address-button ${
                  isJaJP(locale.lang) ? 'ja' : ''
                }`}
                onClick={(e) => this.click(e)}
              >
                {uiWords.autocompleteAddressButtonLabel}
              </button>
              <div className="error">{this.state.errors[param]}</div>
            </div>
          )
        } else {
          // その他の項目の場合、エラーメッセージのみ表示
          uiSchema[param]['ui:help'] = (
            <div>
              <div className="error">{this.state.errors[param]}</div>
            </div>
          )
        }
      }

      if (this.state.notices[param] && !this.state.loading) {
        if (!uiSchema[param]) {
          uiSchema[param] = {}
        }
        uiSchema[param]['ui:help'] = (
          <div className="notice">{this.state.notices[param]}</div>
        )
      }
    }

    // ユーザー編集ならデータを代入する
    // カナ項目は日本語以外の言語では、非表示とするので表示名と同じものを代入しておく
    const formData = this.state.facilityDetail
      ? {
          name: this.state.facilityDetail.name,
          display_name: this.state.facilityDetail.display_name,
          kana: isJaJP(locale.lang)
            ? this.state.facilityDetail.kana
            : this.state.facilityDetail.display_name,
          tell: this.state.facilityDetail.tell,
          postal_code: this.state.facilityDetail.postal_code,
          address: this.state.facilityDetail.address,
          email: this.state.facilityDetail.email,
          password: this.state.facilityDetail.password,
          password_confirm: this.state.facilityDetail.password_confirm
        }
      : {}

    return (
      <SettingLayout activeTab="account">
        <Box id="setting-account-tab" className="setting-tab-content">
          <Typography variant="h1" className="setting-heading">
            {uiWords.accountTabTitle}
          </Typography>

          <Form
            schema={schema}
            uiSchema={uiSchema}
            formData={formData}
            onSubmit={this.handleSubmit.bind(this)}
            showErrorList={false}
            noValidate={true}
          >
            <button type="submit" className="save-button">
              {uiWords.saveButtonLabel}
            </button>
          </Form>
        </Box>

        <AppLoading isActive={this.state.loading} />
      </SettingLayout>
    )
  }
}
