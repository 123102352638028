/**
 * システム全体の定数定義
 */
export const SYSTEM = {
  /**
   * 日付フォーマット
   */
  DateFormat: {
    Calendar: 'YYYY/MM/DD',
    excretionResultStart: 'YYYY-MM-DDT00:00:00+09:00',
    excretionResultEnd: 'YYYY-MM-DDT23:59:59+09:00',
    detailExcretionResultStart: 'YYYY-MM-DDTHH:00:00+09:00',
    detailExcretionResultEnd: 'YYYY-MM-DDTHH:59:59+09:00',
    histgramExcretionResult: 'YYYY-MM-DD',
    alerLog: 'YYYY-MM-DD HH:mm',
    timeline: 'YYYY-MM-DDTHH:mm:ss+09:00'
  },
  /**
   * 排泄量
   */
  ExcretionAmount: {
    adhesion: 1,
    little: 2,
    medium: 3,
    many: 4
  },
  /**
   * 排泄種別
   */
  ExcretionType: {
    none: 0, // none
    urine: 1, // 尿
    feces: 2, // 通常便
    wateryFeces: 3 // 水様便
  }
}

export default SYSTEM
