/* eslint-disable */
import { EventEmitter } from "events";
import dispatcher from "../dispatchers/Dispatcher";

class ExcretionResultStore extends EventEmitter {
  handleActions(action) {
    switch (action.type) {
      case "EXCRETION_RESULT_ALL":
        this.emit("excretion_result_all", action.body);
        break;
      case "EXCRETION_RESULT_PAGINATION":
        this.emit("excretion_result_pagination", action.totalBody);
        break;
      case "EXCRETION_RESULT_FOR_DIALOG":
        this.emit("excretion_result_for_dialog", action.body);
        break;
      case "EXCRETION_RESULT_FOR_DIALOG_FROM_DELETE":
        this.emit("excretion_result_for_dialog_from_delete", action.body);
        break;
      case "EXCRETION_RESULT_DETAIL":
        this.emit("excretion_result_detail", action.body);
        break;
      case "EXCRETION_RESULT_EDIT":
        this.emit("excretion_result_edit", action.body);
        break;
      case "EXCRETION_RESULT_ADD":
        this.emit("excretion_result_add", action.body);
        break;
      case "EXCRETION_RESULT_REGISTER":
        this.emit("excretion_result_register", action.body);
        break;
      case "EXCRETION_RESULT_DELETE":
        this.emit("excretion_result_delete", action.body);
        break;
      case "ERROR":
        this.emit("excretion_result_error", action.body);
        break;
      default:
        break;
    }
  }
}

const excretionResultStore = new ExcretionResultStore();
dispatcher.register(excretionResultStore.handleActions.bind(excretionResultStore));

export default excretionResultStore;