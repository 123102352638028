/* eslint-disable */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { AxiosError } from "axios";
import request, { Token } from "./CustomAxios";
import { AsyncThunkConfig } from "../../store";
import API from "../../constants/ApiConstants";
import ValidationErrors from "../ValidationErrors";
import TExcretionResult from "../Types/TExcretionResult";

// get
export type GetExcretionResultLogsState = {
  excretionResultLogs: Array<TExcretionResult>;
  message: string;
  isLoading: boolean;
};

const initialGetExcretionResultLogs: GetExcretionResultLogsState = {
  excretionResultLogs: [],
  message: "",
  isLoading: false,
};
// care_subject_id=1&group_id=1,2,3&active=true&start=2017-06-01&end=2017-07-31&with_related_laxation_log=true
type GetExcretionResultLogsParam = {
  params: {
    subject_id?: number;
    group_id?: number;
    start?: string;
    end?: string;
  };
  login: Token;
};

// edit
export type EditExcretionResultState = {
  success: boolean;
  message: string;
  isLoading: boolean;
};

const initialEditExcretionResult: EditExcretionResultState = {
  success: false,
  message: "",
  isLoading: false,
};

export type ExcretionResultState = {
  getExcretionResultLogs: GetExcretionResultLogsState;
  // addExcretionResultLogs: AddExcretionResultLogsState;
  editExcretionResultLogs: EditExcretionResultState;
};

export const fetchGetExcretionResultLogs = createAsyncThunk<
  Object,
  GetExcretionResultLogsParam,
  AsyncThunkConfig<ValidationErrors>
>("ExcretionResult/all", async (arg, thunkAPI) => {
  let response: any = [];
  try {
    response = await request(arg.login).get(API.ExcretionResult.All, {
      params: arg.params,
    });
  } catch (err: any) {
    const error: AxiosError<ValidationErrors> = err;
    if (!error.response) {
      throw err;
    }
    return thunkAPI.rejectWithValue(error.response.data);
  }

  return response;
});

const fetchGetExcretionResultLogsSlice = createSlice<
  GetExcretionResultLogsState,
  any
>({
  name: "fetch/all/ExcretionResult",
  initialState: initialGetExcretionResultLogs,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(
      fetchGetExcretionResultLogs.fulfilled,
      (state: any, action: any) => {
        console.log("ExcretionResultLogs fulfilled");
        state.excretionResultLogs = action.payload.data.data;
        state.isLoading = false;
      }
    );
    builder.addCase(
      fetchGetExcretionResultLogs.pending,
      (state: any, action: any) => {
        console.log("ExcretionResultLogs pending");
        state.isLoading = true;
      }
    );
    builder.addCase(
      fetchGetExcretionResultLogs.rejected,
      (state: any, action: any) => {
        console.log("ExcretionResultLogs rejected");
        state.status = "error";
        state.message = action.error.message;
        state.isLoading = false;
      }
    );
  },
});

//edit
export const fetchEditExcretionResult = createAsyncThunk<
  Object,
  { carePersonId: string; groupId: string | null; login: Token },
  AsyncThunkConfig<ValidationErrors>
>("ExcretionResult/edit", async (arg, thunkAPI) => {
  let response: any = [];
  try {
    const replaceString = API.ExcretionResult.Edit.replace(
      ":id",
      arg.carePersonId
    );
    response = await request(arg.login).put(replaceString, {
      group_id: arg.groupId,
    });
  } catch (err: any) {
    let error: AxiosError<ValidationErrors> = err;
    if (!error.response) {
      throw err;
    }
    return thunkAPI.rejectWithValue(error.response.data);
  }

  return response;
});

const fetchEditExcretionResultSlice = createSlice<
  EditExcretionResultState,
  any
>({
  name: "fetch/edit/ExcretionResult",
  initialState: initialEditExcretionResult,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(
      fetchEditExcretionResult.fulfilled,
      (state: any, action: any) => {
        console.log("edit ExcretionResult fulfilled");
        state.excretionResultLogs = action.payload.data.data;
        state.isLoading = false;
      }
    );
    builder.addCase(
      fetchEditExcretionResult.pending,
      (state: any, action: any) => {
        console.log("edit ExcretionResult pending");
        state.isLoading = true;
      }
    );
    builder.addCase(
      fetchEditExcretionResult.rejected,
      (state: any, action: any) => {
        console.log("edit ExcretionResult rejected");
        state.status = "error";
        state.message = action.error.message;
        state.isLoading = false;
      }
    );
  },
});

const reducers = combineReducers<ExcretionResultState>({
  getExcretionResultLogs: fetchGetExcretionResultLogsSlice.reducer,
  editExcretionResultLogs: fetchEditExcretionResultSlice.reducer,
});

export default reducers;
