/* eslint-disable */
// TODO: UserEdit でしか使われていないので、features ディレクトリでの管理を検討する
import * as carePeopleAction from '../../actions/CarePeopleAction'
import carePeopleStore from '../../stores/CarePeopleStore'
import { localizedUiWords } from '@/features/locale'
import { LocaleContext } from '@/providers/LocaleProvider'
import _ from 'lodash'
import React, { Component } from 'react'

class PlaceField extends Component {
  static contextType = LocaleContext

  constructor() {
    super()
    this.state = {
      formData: '',
      carePeople: null
    }
  }

  componentDidMount() {
    carePeopleStore.on('people_all', (v) => {
      this.setState({
        carePeople: v
      })
    })
    carePeopleAction.carePeople()
  }

  componentWillUnmount() {
    carePeopleStore.removeAllListeners('people_all')
  }

  componentDidUpdate(prebProps) {
    if (this.props !== prebProps) {
      this.setState({
        formData: this.props.formData,
        carePeople: this.state.carePeople
      })
    }
  }
  handleChange(e) {
    this.setState({
      formData: e.target.value
    })
  }
  handleBlur(e) {
    this.props.onChange(this.state.formData)
  }

  render() {
    const [locale] = this.context
    const uiWords = localizedUiWords(locale.lang).pages.userEdit

    let options = []
    let places = []
    if (this.state.carePeople !== null) {
      places = _.map(this.state.carePeople, (v, k) => {
        return v.place
      })
      let sortedPlaces = [...new Set(places)].sort()
      options = _.map(sortedPlaces, (v, k) => {
        return <option key={k} value={v} />
      })
    }
    return (
      <div className="hpad-datalist-1">
        <label className="control-label" htmlFor="root_place">
          {uiWords.roomNameLabel}
          <span className="required">*</span>
        </label>
        <input
          id="root_place"
          type="text"
          name="place"
          autoComplete="off"
          list="places"
          required
          value={this.state.formData}
          onChange={this.handleChange.bind(this)}
          onBlur={this.handleBlur.bind(this)}
        />
        <datalist id="places">{options}</datalist>
      </div>
    )
  }
}

export default PlaceField
