/* eslint-disable */
import { EventEmitter } from "events";
import dispatcher from "../dispatchers/Dispatcher";

class GroupStore extends EventEmitter {
    constructor() {
      super();
      this.groups = null;
    }
    handleActions(action) {
      switch (action.type) {
        case "GROUP_ALL":
          this.emit("group_all",action.body);
          this.groups = action.body;
          break;
        case "GROUP_ADD":
          this.emit("group_add",action.body);
          break;
        case "GROUP_EDIT":
          this.emit("group_edit",action.body);
          break;
        case "GROUP_ALL_DELETE":
          this.emit("group_all_delete",action.body);
          break;
        default:
          break;
      }
    }
}

const groupStore = new GroupStore();
dispatcher.register(groupStore.handleActions.bind(groupStore));

export default groupStore;