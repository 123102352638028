import { localizedUiWords } from '@/features/locale'
import { LocaleContext } from '@/providers/LocaleProvider'
import _ from 'lodash'
import React, { useContext } from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts'

export default function AlertHistogram(props) {
  const [locale] = useContext(LocaleContext)
  const uiWords = localizedUiWords(locale.lang).pages.userDetail

  // TODO: 後で対応
  // const histogramConfirmedBarLabel = isJaJP(locale.lang)
  //   ? userDetailUiWords.histogramConfirmedBarLabel.ja
  //   : userDetailUiWords.histogramConfirmedBarLabel.en
  // const histogramUnconfirmedBarLabel = isJaJP(locale.lang)
  //   ? userDetailUiWords.histogramUnconfirmedBarLabel.ja
  //   : userDetailUiWords.histogramUnconfirmedBarLabel.en
  // const histogramUrineBarLabel = isJaJP(locale.lang)
  //   ? userDetailUiWords.histogramUrineBarLabel.ja
  //   : userDetailUiWords.histogramUrineBarLabel.en
  // const histogramFecesBarLabel = isJaJP(locale.lang)
  //   ? userDetailUiWords.histogramFecesBarLabel.ja
  //   : userDetailUiWords.histogramFecesBarLabel.en
  // const histogramUrineAndFecesBarLabel = isJaJP(locale.lang)
  //   ? userDetailUiWords.histogramUrineAndFecesBarLabel.ja
  //   : userDetailUiWords.histogramUrineAndFecesBarLabel.en
  // const histogramNoneBarLabel = isJaJP(locale.lang)
  //   ? userDetailUiWords.histogramNoneBarLabel.ja
  //   : userDetailUiWords.histogramNoneBarLabel.en

  /* eslint-disable-next-line */
  const { mode, data, switchVisible, xKey, height } = props
  let confirmedBar
  let unconfirmedBar
  let urineBar
  let fecesBar
  let urineAndFecesBar
  let noneBar

  switch (mode) {
    case 'alert_log':
      if (data) {
        for (const key in data[0]) {
          switch (key) {
            case '件数':
              confirmedBar = (
                <Bar dataKey="件数" stackId="result" fill="#f09000" />
              )
              break
            case '未確認':
              unconfirmedBar = (
                <Bar dataKey="未確認" stackId="result" fill="#a0a0a0" />
              )
              break
            default:
              break
          }
        }
      } else {
        confirmedBar = <Bar dataKey="件数" stackId="result" fill="#f09000" />
        unconfirmedBar = (
          <Bar dataKey="未確認" stackId="result" fill="#a0a0a0" />
        )
      }
      break

    case 'excretion_result':
      _.forEach(data, (tmpData) => {
        _.forEach(switchVisible, (checkbox) => {
          if (!checkbox.checked) {
            delete tmpData[checkbox.value]
          }
        })
      })
      if (data) {
        for (const key in data[0]) {
          switch (key) {
            case '尿':
              urineBar = <Bar dataKey="尿" stackId="result" fill="#5876ff" />
              break
            case '便':
              fecesBar = <Bar dataKey="便" stackId="result" fill="#ffb500" />
              break
            case '尿＆便':
              urineAndFecesBar = (
                <Bar dataKey="尿＆便" stackId="result" fill="url(#border)" />
              )
              break
            case 'なし':
              noneBar = <Bar dataKey="なし" stackId="result" fill="#a0a0a0" />
              break
            default:
              break
          }
        }
      } else {
        urineBar = <Bar dataKey="尿" stackId="result" fill="#5876ff" />
        fecesBar = <Bar dataKey="便" stackId="result" fill="#ffb500" />
        urineAndFecesBar = (
          <Bar dataKey="尿＆便" stackId="result" fill="url(#border)" />
        )
        noneBar = <Bar dataKey="なし" stackId="result" fill="#a0a0a0" />
      }
      break

    default:
      break
  }

  return (
    <ResponsiveContainer>
      <BarChart
        height={height}
        data={data}
        stackOffset="sign"
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <defs>
          <pattern
            id="border"
            patternUnits="userSpaceOnUse"
            width="5"
            height="5"
            patternTransform="rotate(45)"
          >
            <rect fill="#5867ff" x="0" y="0" height="5" width="5" />
            <rect fill="#ffb500" x="0" y="0" height="5" width="2.5" />
          </pattern>
        </defs>
        <XAxis
          dataKey={xKey}
          tickFormatter={(tickItem) => tickItem.substring(0, 2)}
          label={{ value: uiWords.histogramXAxisUnitLabel, position: 'right' }}
        />
        <YAxis
          allowDecimals={false}
          label={{
            value: uiWords.histogramYAxisUnitLabel,
            position: 'insideTop',
            offset: 10
          }}
        />
        <Tooltip />
        {unconfirmedBar}
        {confirmedBar}
        {noneBar}
        {urineBar}
        {urineAndFecesBar}
        {fecesBar}
      </BarChart>
    </ResponsiveContainer>
  )
}
