import SensorSelectField from '../components/SensorSelectField'
import { SettingLayout } from '../components/SettingLayout'
import './SettingPairing.scss'
import * as CarePeopleAction from '@/actions/CarePeopleAction'
import * as SensorAction from '@/actions/SensorAction'
import AppLoading from '@/components/App/AppLoading'
import GroupSelectField from '@/components/common/GroupSelectField'
import { localizedUiWords } from '@/features/locale'
import { LocaleContext } from '@/providers/LocaleProvider'
import carePeopleStore from '@/stores/CarePeopleStore'
import sensorStore from '@/stores/SensorStore'
import { Box, Typography } from '@material-ui/core'
import _ from 'lodash'
import React, { Component } from 'react'
import { Prompt } from 'react-router'

const XOR = (a, b) => {
  return (a || b) && !(a && b)
}

export class SettingPairing extends Component {
  static contextType = LocaleContext

  constructor() {
    super()
    this.state = {
      groupId: null,
      carePeople: null,
      loading: false,
      changeSensors: {},
      sensors: null,
      load_cnt: 0,
      sortKey: '',
      isSortOrderAsc: true,
      errors: {}
    }
    this.changeAnimation = {}
    this.pairingData = {}
  }

  componentDidMount() {
    const [locale] = this.context
    const uiWords = localizedUiWords(locale.lang).pages.settings

    carePeopleStore.on('people_sort', (v) => {
      this.setState({
        carePeople: v,
        loading: false,
        load_cnt: this.state.load_cnt > 0 ? this.state.load_cnt - 1 : 0
      })
    })

    // eslint-disable-next-line
    carePeopleStore.on('person_edit', (v) => {
      const query = {}
      if (this.state.groupId !== null) {
        query.group_id = this.state.groupId
      }
      CarePeopleAction.carePeopleSort(query)
    })

    carePeopleStore.on('people_error', (v) => {
      const errors = []
      _.forEach(Object.keys(this.changeAnimation), (key) => {
        this.changeAnimation[key] = false
      })

      switch (v.statusCode) {
        // 同施設での重複
        case 409:
          errors.sensor_id = uiWords.alreadyRegisteredSensorErrorMessage
          break

        default:
          break
      }

      this.setState({
        errors,
        loading: false,
        load_cnt: this.state.load_cnt > 0 ? this.state.load_cnt - 1 : 0
      })
    })

    sensorStore.on('sensor_all', (v) => {
      this.setState({
        sensors: v.data,
        loading: false,
        load_cnt: this.state.load_cnt > 0 ? this.state.load_cnt - 1 : 0
      })
    })

    CarePeopleAction.carePeopleSort({})
    SensorAction.sensors()
    this.setState({
      loading: true
    })
  }

  componentWillUnmount() {
    carePeopleStore.removeAllListeners('people_sort')
    carePeopleStore.removeAllListeners('person_edit')
    carePeopleStore.removeAllListeners('people_error')
    sensorStore.removeAllListeners('sensor_all')
  }

  handleGroup(e) {
    const query = {
      group_id: e
    }
    CarePeopleAction.carePeopleSort(query)
    this.setState({
      loading: true,
      groupId: e,
      errors: {}
    })
  }

  handleChange(e) {
    // ユーザーが選択したチェンジ情報を記録
    const changeSensors = this.state.changeSensors
    changeSensors[e.id] = e.sensor_id

    this.setState({
      changeSensors,
      errors: {}
    })
  }

  handlePairing(e) {
    // データ属性のuserIDを取得
    const userId = parseInt(e.currentTarget.getAttribute('data-userid'))
    let loadCnt = this.state.load_cnt
    const user = this.state.carePeople.filter((v) => {
      return v.id === userId
    })[0]

    const isChangeSensor =
      Object.keys(this.state.changeSensors).indexOf(userId.toString()) >= 0 &&
      this.state.changeSensors[userId] !== user.sensor_id

    // 後続処理のためデータを保存
    this.pairingData = {
      userId,
      load_cnt: loadCnt,
      user,
      isChangeSensor
    }

    // センサーIDに変更があれば、そのユーザに紐づくセンサーIDを上書きする。
    if (isChangeSensor) {
      let sensorId = this.state.changeSensors[userId]
      if (sensorId === '') {
        sensorId = null
      }

      CarePeopleAction.editPerson({ sensor_id: sensorId }, userId)
      loadCnt += 1
      this.changeAnimation[userId] = true
      this.setState({
        loading: true,
        load_cnt: loadCnt
      })
    }
  }

  isAnimation(userId) {
    if (
      this.changeAnimation[userId] &&
      !this.state.loading &&
      this.state.load_cnt === 0
    ) {
      this.changeAnimation[userId] = false
      return 'click'
    }
    return ''
  }

  /**
   * TODO: ドキュメントコメント追加
   */
  isActive(v) {
    let ret = false
    // 変化があったらactive
    if (
      this.state.changeSensors[v.id] !== undefined &&
      this.state.changeSensors[v.id] !== v.sensor_id
    ) {
      ret = true
    }

    // 設定なしに戻した場合
    if (this.state.changeSensors[v.id] === '' && v.sensor_id === null) {
      ret = false
    }
    return ret
  }

  sortChange(sortKey) {
    // 初回は降順としそうでない場合は現在と違う値にする
    const isSortOrderAsc =
      sortKey === this.state.sortKey ? !this.state.isSortOrderAsc : false
    this.setState({
      sortKey,
      isSortOrderAsc
    })
  }

  sortMark(key) {
    if (this.state.sortKey !== key) {
      return ''
    }
    return this.state.isSortOrderAsc ? ' ▼' : ' ▲'
  }

  render() {
    const [locale] = this.context
    const uiWords = localizedUiWords(locale.lang).pages.settings

    // メッセージ（エラー、通知）はhelpフィールドを利用して出力する
    let errors
    if (!this.state.loading && this.state.errors.sensor_id) {
      errors = <span className="error">{this.state.errors.sensor_id}</span>
    }

    // テーブル空の時のだしわけ
    let tableView
    if (this.state.carePeople === null || this.state.sensors === null) {
      tableView = <div>{uiWords.loadingMessage}</div>
    } else {
      let tableContent
      if (this.state.carePeople.length > 0) {
        const changeSensorKeys = Object.keys(this.state.changeSensors)

        const carePeople =
          this.state.sortKey !== ''
            ? JSON.parse(JSON.stringify(this.state.carePeople)).sort((a, b) => {
                return XOR(
                  this.state.isSortOrderAsc,
                  a[this.state.sortKey] < b[this.state.sortKey]
                )
                  ? 1
                  : -1
              })
            : this.state.carePeople

        tableContent = carePeople.map((v, k) => {
          const userId = v.id + ''
          const sensorId =
            changeSensorKeys.indexOf(userId.toString()) >= 0
              ? this.state.changeSensors[userId]
              : v.sensor_id

          const isDisable = v.status_code !== '0'
          const statusDisplay = isDisable ? v.m_status.description : null
          const outingBg = statusDisplay ? 'outingBg' : ''

          return (
            <tr key={k} className={this.isAnimation(v.id) + ' ' + outingBg}>
              <td>
                {v.place}
                <div className="outingText">{statusDisplay}</div>
              </td>
              <td>{v.name}</td>
              <td>
                <div className="pairing-group mr20">
                  <SensorSelectField
                    onChange={this.handleChange.bind(this)}
                    formData={sensorId}
                    userId={v.id}
                    sensors={this.state.sensors}
                    sensorDisable={isDisable}
                  />
                </div>
              </td>
              <td>
                <a onClick={this.handlePairing.bind(this)} data-userid={v.id}>
                  <div
                    className={
                      this.isActive(v) ? 'pairing-btn active' : 'pairing-btn'
                    }
                  >
                    <div className="icon icon-icons_signal"></div>
                  </div>
                </a>
              </td>
            </tr>
          )
        })
      } else {
        tableContent = (
          <tr key="1">
            <td colSpan="4">{uiWords.noDataMessage}</td>
          </tr>
        )
      }

      const cursorPointer = {
        cursor: 'pointer'
      }

      tableView = (
        <table>
          <tbody>
            <tr>
              <th
                style={cursorPointer}
                onClick={() => {
                  this.sortChange('place')
                }}
              >
                {uiWords.roomTableHeader}
                {this.sortMark('place')}
              </th>
              <th
                style={cursorPointer}
                onClick={() => {
                  this.sortChange('kana')
                }}
              >
                {uiWords.residentTableHeader}
                {this.sortMark('kana')}
              </th>
              <th>{uiWords.sensorIdTableHeader}</th>
              <th>{uiWords.pairingTableHeader}</th>
            </tr>
            {tableContent}
          </tbody>
        </table>
      )
    }

    let isActive = false
    if (this.state.carePeople !== null && this.state.sensors !== null) {
      for (const resident of this.state.carePeople) {
        isActive = isActive || this.isActive(resident)
      }
    }

    return (
      <SettingLayout activeTab="pairing">
        <Box id="setting-pairing-tab" className="setting-tab-content">
          <Typography variant="h1" className="setting-heading">
            {uiWords.pairingTabTitle}
          </Typography>

          <Box className="mb20">
            <GroupSelectField onChange={this.handleGroup.bind(this)} />
            {errors}
          </Box>

          <Box id="pairing-table">{tableView}</Box>
        </Box>

        <AppLoading isActive={this.state.loading} />

        <Prompt when={isActive} message={uiWords.leaveEnsureMessage} />
      </SettingLayout>
    )
  }
}
