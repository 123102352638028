import API from '@/constants/ApiConstants'
import dispatcher from '@/dispatchers/Dispatcher'
import request from 'superagent'

export function laxatives() {
  request
    .get(API.Laxative.All)
    .set('Accept', 'application/json')
    .end(function (err, res) {
      if (err === null) {
        const body = res.body.data
        dispatcher.dispatch({ type: 'LAXATIVES_ALL', body })
      } else {
        dispatcher.dispatch({ type: 'ERROR', res })
      }
    })
}

export function addLaxative(data) {
  request
    .post(API.Laxative.Add)
    .send(data)
    .set('Accept', 'application/json')
    .end(function (err, res) {
      if (err === null) {
        const body = res.body.success
        dispatcher.dispatch({ type: 'LAXATIVES_ADD', body })
      } else {
        dispatcher.dispatch({ type: 'ERROR', res })
      }
    })
}

export function deleteLaxative(id) {
  const url = API.Laxative.Delete.replace(':id', id)
  request
    .del(url)
    .set('Accept', 'application/json')
    .end(function (err, res) {
      if (err === null) {
        const body = res.body.success
        dispatcher.dispatch({ type: 'LAXATIVES_DELETE', body })
      } else if (res.body.code === 400) {
        const body = res.body
        dispatcher.dispatch({ type: 'LAXATIVES_DELETE_ERROR', body })
      } else {
        dispatcher.dispatch({ type: 'ERROR', res })
      }
    })
}

export function editLaxative(data, id) {
  const url = API.Laxative.Delete.replace(':id', id)
  request
    .put(url)
    .send(data)
    .set('Accept', 'application/json')
    .end(function (err, res) {
      if (err === null) {
        const body = res.body.success
        dispatcher.dispatch({ type: 'LAXATIVES_EDIT', body })
      } else {
        dispatcher.dispatch({ type: 'ERROR', res })
      }
    })
}

export function deleteAllLaxative(data) {
  request
    .del(API.Laxative.DeleteAll)
    .send(data)
    .set('Accept', 'application/json')
    .end(function (err, res) {
      if (err === null) {
        const body = res.body.success
        dispatcher.dispatch({ type: 'LAXATIVES_ALL_DELETE', body })
      } else {
        dispatcher.dispatch({ type: 'ERROR', res })
      }
    })
}

// 投与単位取得用。
export function medicineUnitMaster() {
  request
    .get(API.Laxative.Unit)
    .set('Accept', 'application/json')
    .end(function (err, res) {
      if (err === null) {
        const body = res.body.data
        dispatcher.dispatch({ type: 'MEDICINE_UNIT_MASTER', body })
      } else {
        dispatcher.dispatch({ type: 'ERROR', res })
      }
    })
}
