/* eslint-disable */
import React, { FC, useState } from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  })
);

interface LoadingProps {
  isOpen: boolean;
}

const Loading: FC<LoadingProps> = (props) => {
  const { isOpen } = props;
  const classes = useStyles();
  const [, setOpen] = useState(isOpen);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Backdrop
        className={classes.backdrop}
        open={isOpen}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};
export default Loading;
