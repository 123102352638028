/* eslint-disable */
import { EventEmitter } from "events";
import dispatcher from "../../dispatchers/Dispatcher";

import EventConst from "../../constants/EventConstants";
/** 
 * 排泄記録削除情報ストア
 */
class DeleteExcretionResultStore extends EventEmitter {

  /**
   * @type {object} 排泄記録削除情報 
   *                  id:number  排泄記録削除Id
   */
  excretionResult = {id:-1};

  /**
   * 排泄記録削除情報取得
   * @returns {object} 排泄記録削除情報
   */
  getDeleteExcretionResult() {
    return this.excretionResult;
  }

  /**
   * イベント処理
   * @param {object} action Actionからdispatherに渡された値 
   */
  handleActions(action) {
    switch (action.type) {
      case EventConst.action_type.delete_excretion_result.excretion_result:
        this.excretionResult = action.value;
        break;
      case EventConst.action_type.delete_excretion_result.clear:
          this.excretionResult = {id:-1};
          break;
      default:
        return;
    }
    this.emit(action.type);
  }
}

const deleteExcretionResultStore = new DeleteExcretionResultStore();
dispatcher.register(deleteExcretionResultStore.handleActions.bind(deleteExcretionResultStore));
export default deleteExcretionResultStore;