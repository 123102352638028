const storagePrefix = 'helppad-web-'

const storage = {
  getLanguage: () => {
    return JSON.parse(
      window.localStorage.getItem(`${storagePrefix}lang`) as string
    )
  },
  setLanguage: (lang: string) => {
    window.localStorage.setItem(`${storagePrefix}lang`, JSON.stringify(lang))
  }
}

export default storage
