import * as ExcretionResultAction from '../ExcretionResultAction'
import EventConst from '@/constants/EventConstants'
import dispatcher from '@/dispatchers/Dispatcher'
import DeleteExcretionResultStore from '@/stores/indivisualExcretionResults/DeleteExcretionResultStore'

/**
 * 削除対象の排泄記録情報作成
 *
 * @param {object}  deleteExcretionResult 削除対象の排泄記録情報
 */
export function createDeleteExcretionResult(deleteExcretionResult) {
  dispatcher.dispatch({
    type: EventConst.action_type.delete_excretion_result.excretion_result,
    value: deleteExcretionResult
  })
}

/**
 * 削除対象の排泄記録情報をクリア
 */
export function clearDeleteExcretionResult() {
  dispatcher.dispatch({
    type: EventConst.action_type.delete_excretion_result.clear
  })
}

/**
 * 排泄記録情報を削除
 */
export function deleteExcretionResult() {
  const deleteExcretionResultId =
    DeleteExcretionResultStore.getDeleteExcretionResult().id

  ExcretionResultAction.deleteExcretionResult(deleteExcretionResultId, () => {})
}
