/* eslint-disable */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import request, { AxiosError } from "axios";
import { AsyncThunkConfig } from "../../store";
import API from "../../constants/ApiConstants";
import { ApiErrorDetailMessage } from "../../constants/StringConstants";
import ValidationErrors from "../ValidationErrors";

// 引数の型定義
type UserInfo = {
  name: string;
  password: string;
};

// 戻り値の型定義
export type LoginState = {
  id: number;
  token: string;
  message: string;
  isLoading: boolean;
};

// 戻り値の初期値定義
const initialState: LoginState = {
  id: 0,
  token: "",
  message: "",
  isLoading: false,
};

type Reducer = {
  initLoginState: (state: LoginState) => void;
};
export const fetchLogin = createAsyncThunk<
  LoginState,
  UserInfo,
  AsyncThunkConfig<ValidationErrors>
>("auth/login", async (arg, thunkAPI) => {
  let response: any = [];
  try {
    response = await request.post(API.Auth.Login, arg);
  } catch (err: any) {
    let error: AxiosError<ValidationErrors> = err;
    let message: string = "";
    if (!error.response) {
      throw err;
    }

    if (error.response.status === 401) {
      message = ApiErrorDetailMessage.login[401];
    }

    return thunkAPI.rejectWithValue({
      errorMessage: message,
    });
  }

  return response;
});

const fetchSlice = createSlice<LoginState, Reducer>({
  name: "fetch/login",
  initialState: initialState,
  reducers: {
    initLoginState: (state) => {
      state.id = initialState.id;
      state.token = initialState.token;
      state.message = initialState.message;
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(fetchLogin.fulfilled, (state: any, action: any) => {
      console.log("login fulfilled");
      state.token = action.payload.data.data.token;
      state.id = action.payload.data.data.id;
      state.isLoading = false;
    });
    builder.addCase(fetchLogin.pending, (state: any, action: any) => {
      console.log("login pending");
      state.isLoading = true;
    });
    builder.addCase(fetchLogin.rejected, (state: any, action: any) => {
      console.log("login rejected");
      state.status = "error";
      state.message = action.error.message;
      state.isLoading = false;
    });
  },
});

export const { initLoginState } = fetchSlice.actions;

export default fetchSlice.reducer;
