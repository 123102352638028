/* eslint-disable */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import request, { Token } from "./CustomAxios";
import { AsyncThunkConfig } from "../../store";
import API from "../../constants/ApiConstants";
import ValidationErrors from "../ValidationErrors";

// get
export type GetHistogramState = {
  histogram: Array<any>;
  message: string;
  isLoading: boolean;
};

const initialGetHistogram: GetHistogramState = {
  histogram: [],
  message: "",
  isLoading: false,
};

type GetHistogramParam = {
  params: {
    subject_id: number;
    mode: string;
    interval: number;
    start_dt: string;
    end_dt: string;
  };
  login: Token;
};

export const fetchGetHistogram = createAsyncThunk<
  Object,
  GetHistogramParam,
  AsyncThunkConfig<ValidationErrors>
>("Histogram/get", async (arg, thunkAPI) => {
  let response: any = [];
  try {
    response = await request(arg.login).get(API.Histogram.Get, {
      params: arg.params,
    });
  } catch (err: any) {
    let error: AxiosError<ValidationErrors> = err;
    if (!error.response) {
      throw err;
    }
    return thunkAPI.rejectWithValue(error.response.data);
  }

  return response;
});

const fetchGetHistogramSlice = createSlice<GetHistogramState, any>({
  name: "fetch/get/Histogram",
  initialState: initialGetHistogram,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(fetchGetHistogram.fulfilled, (state: any, action: any) => {
      console.log("Histogram fulfilled");
      state.histogram = action.payload.data;
      state.isLoading = false;
    });
    builder.addCase(fetchGetHistogram.pending, (state: any, action: any) => {
      console.log("Histogram pending");
      state.isLoading = true;
    });
    builder.addCase(fetchGetHistogram.rejected, (state: any, action: any) => {
      console.log("Histogram rejected");
      state.status = "error";
      state.message = action.error.message;
      state.isLoading = false;
    });
  },
});

export default fetchGetHistogramSlice.reducer;
