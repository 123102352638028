import { EventEmitter } from 'events'
import dispatcher from '@/dispatchers/Dispatcher'

class AlertStore extends EventEmitter {
  handleActions(action) {
    switch (action.type) {
      case 'ALERT_ALL':
        this.emit('alert_all', action.body)
        break
      case 'ALERT_DETAIL':
        this.emit('alert_detail', action.body)
        break
      case 'ALERT_EDIT':
        this.emit('alert_edit', action.body)
        break
      default:
        break
    }
  }
}

const alertStore = new AlertStore()
dispatcher.register(alertStore.handleActions.bind(alertStore))

export default alertStore;