/* eslint-disable */
import React, { Component } from "react";
import _ from 'lodash';

class ListBoxField extends Component {
    constructor(props) {
        super(props);
        this.state = { [props.name]: "" }
    }
    onChange() {
        return (event) => {
            this.setState({
                [this.props.name]: event.target.value
            }, () => this.props.onChange(this.state[this.props.name]));
        };
    }
    render() {
        let Ids = this.props.schema.enum;
        let Names = this.props.schema.enumNames;
        let isDefault = this.props.formData === null || this.props.formData === "" || this.props.formData === undefined;
        let optionsDom = _.zipWith(Ids, Names, (id, name) =>
            <option
                value={id}
                key={name}
                onClick={this.onChange()}
            >{name}</option>
        );


        return (
            <div>
                <label className="control-label" htmlFor={"root_" + this.props.name}>{this.props.schema.title}</label>
                <select
                    id={"root_" + this.props.name}
                    className="form-control"
                    size={this.props.uiSchema["ui:options"].size}
                    value={isDefault ? Ids[0] : this.props.formData}
                    onChange={this.onChange()}
                >
                    {optionsDom}
                </select>
            </div>);
    }
}

export default ListBoxField;