/* eslint-disable */
// Home.tsx
import AlertTableSlice, { AlertTableState } from './features/AlertTableSlice'
// Login.tsx
import LoginFormSlice, { LoginInputFormState } from './features/LoginFormSlice'
import AlertLogsSlice, {
  AlertLogsState
} from './features/restAPI/AlertLogsSlice'
import CarePeopleSlice, {
  CarePeopleState
} from './features/restAPI/CarePeopleSlice'
import ExcretionResultSlice, {
  ExcretionResultState
} from './features/restAPI/ExcretionResultLogsSlice'
import FacilitySlice, { FacilityState } from './features/restAPI/FacilitySlice'
import GroupSlice, { GroupState } from './features/restAPI/GroupSlice'
import fetchGetHistogramSlice, {
  GetHistogramState
} from './features/restAPI/HistogramSlice'
import LoginSlice, { LoginState } from './features/restAPI/LoginSlice'
import LogoutSlice, { LogoutState } from './features/restAPI/LogoutSlice'
import SensorSlice, { SensorState } from './features/restAPI/SensorSlice'
import fetchGetStatusMSTSlice, {
  GetStatusMSTState
} from './features/restAPI/StatusMSTSlice'
import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import { save, load } from 'redux-localstorage-simple'

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export type AsyncThunkConfig<T = unknown> = {
  state: RootState
  dispatch: AppDispatch
  rejectValue: T
}

type AppState = {
  loginInputForm: LoginInputFormState
  alertTable: AlertTableState
}
type restAPIState = {
  login: LoginState
  logout: LogoutState
  facility: FacilityState
  carePeople: CarePeopleState
  group: GroupState
  alertLogs: AlertLogsState
  excretionResult: ExcretionResultState
  getHistogram: GetHistogramState
  getStatusMST: GetStatusMSTState
  sensor: SensorState
}

const rootReducer = combineReducers<AppState>({
  loginInputForm: LoginFormSlice.reducer,
  alertTable: AlertTableSlice.reducer
})
const restAPIReducer = combineReducers<restAPIState>({
  login: LoginSlice,
  logout: LogoutSlice,
  facility: FacilitySlice,
  carePeople: CarePeopleSlice,
  group: GroupSlice,
  alertLogs: AlertLogsSlice,
  excretionResult: ExcretionResultSlice,
  getHistogram: fetchGetHistogramSlice,
  getStatusMST: fetchGetStatusMSTSlice,
  sensor: SensorSlice
})

const store = configureStore({
  reducer: { rootReducer: rootReducer, restAPIReducer: restAPIReducer },
  preloadedState: load(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(save())
})

export default store
