import './HistogramPeriodSelector.scss'
import { isJaJP, localizedUiWords } from '@/features/locale'
import { LocaleContext } from '@/providers/LocaleProvider'
import * as moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Form from 'react-jsonschema-form'

// フォームの設定
const schemaDuration = {
  type: 'object',
  required: ['start', 'end'],
  properties: {
    start: { type: 'string', format: 'date' },
    end: { type: 'string', format: 'date' }
  }
}

const uiSchemaDuration = {
  start: {
    'ui:label': false,
    'ui:disabled': false
  },
  end: {
    'ui:label': false,
    'ui:disabled': false
  }
}

const timeFormat = 'YYYY-MM-DD'

export class HistogramPeriodSelector extends Component {
  static contextType = LocaleContext

  constructor() {
    super()
    this.state = {
      start: moment().subtract(30, 'days').format(timeFormat),
      end: moment().format(timeFormat),
      error: null
    }
  }

  componentDidMount() {
    this.props.onChange({
      start: this.state.start,
      end: this.state.end
    })
  }

  handleSubmit(e) {
    const [locale] = this.context
    const uiWords = localizedUiWords(locale.lang).pages.userDetail

    // 開始日、終了日が逆転
    if (moment(e.formData.end).isBefore(e.formData.start)) {
      this.setState({
        start: e.formData.start,
        end: e.formData.end,
        error: uiWords.invalidPeriodErrorMessage
      })
      return
    }

    // 期間が5年以上
    if (
      moment(e.formData.start).isBefore(
        moment(e.formData.end).subtract(5, 'years')
      )
    ) {
      this.setState({
        start: e.formData.start,
        end: e.formData.end,
        error: uiWords.over5YearsPeriodErrorMessage
      })
      return
    }

    // 終了日が未来
    if (moment().startOf('day').isBefore(e.formData.end)) {
      this.setState({
        start: e.formData.start,
        end: e.formData.end,
        error: uiWords.futurePeriodErrorMessage
      })
      return
    }

    this.props.onChange({
      start: e.formData.start,
      end: e.formData.end
    })

    this.setState({
      start: e.formData.start,
      end: e.formData.end,
      error: null
    })
  }

  render() {
    const [locale] = this.context
    const uiWords = localizedUiWords(locale.lang).pages.userDetail

    const formData = {
      /* eslint-disable-next-line */
      start: this.props.duration ? this.props.duration.start : this.state.start,
      /* eslint-disable-next-line */
      end: this.props.duration ? this.props.duration.end : this.state.end
    }
    /* eslint-disable-next-line */
    const isDisabled = this.props.isDisabled

    return (
      <div className="histogram-duration-selector">
        <Form
          // TODO: react-jsonschema-form のカスタム機能でラベルの出し分けを JS で行う
          // ラベルが CSS 側で付与されていたので、クラスの付け替えで制御している
          className={isJaJP(locale.lang) ? 'rjsf' : 'rjsf locale-en'}
          schema={schemaDuration}
          uiSchema={uiSchemaDuration}
          formData={formData}
          disabled={isDisabled}
          onSubmit={this.handleSubmit.bind(this)}
        >
          <button type="submit" className="btn btn-info" disabled={isDisabled}>
            {uiWords.histogramPeriodSelectorButtonLabel}
          </button>
        </Form>

        {this.state.error && (
          <p className="duration-error">{this.state.error}</p>
        )}
      </div>
    )
  }
}

HistogramPeriodSelector.propTypes = {
  onChange: PropTypes.func
}
