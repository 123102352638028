import * as Action from '../../actions/GroupAction'
import groupStore from '../../stores/GroupStore'
import { localizedUiWords } from '@/features/locale'
import { LocaleContext } from '@/providers/LocaleProvider'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

class GroupSelectField extends Component {
  static contextType = LocaleContext

  constructor() {
    super()
    this.state = {
      // 初期表示でselectedする値
      formData: 0,
      groups: null
    }
  }

  componentDidMount() {
    groupStore.on('group_all', (v) => {
      this.setState({
        groups: v
      })
    })
    Action.groups()
  }

  componentWillUnmount() {
    groupStore.removeAllListeners('group_all')
  }

  componentDidUpdate(prebProps) {
    if (this.props !== prebProps) {
      this.setState({
        formData: this.props.formData,
        groups: this.state.groups
      })
    }
  }

  date2sec(day) {
    return day * 24 * 60 * 60
  }

  handleChange(e) {
    this.props.onChange(parseInt(e.target.value, 10))
    document.cookie = `groupValue=${
      e.target.value
    }; path=/; max-age=${this.date2sec(7)}"`
  }

  render() {
    const [locale] = this.context
    const uiWords = localizedUiWords(locale.lang).components.groupSelectField

    let options = []
    if (this.state.groups !== null) {
      // eslint-disable-next-line
      options = _.map(this.state.groups, (v, k) => {
        return (
          <option key={v.id} value={v.id}>
            {v.name}
          </option>
        )
      })
    }
    options.unshift(
      <option key={0} value={0}>
        ---
      </option>
    )

    return (
      <div className="hpad-pulldown-1">
        <p>{uiWords.groupLabel}</p>
        <select
          onChange={this.handleChange.bind(this)}
          value={this.state.formData}
        >
          {options}
        </select>
      </div>
    )
  }
}

GroupSelectField.propTypes = {
  onChange: PropTypes.func,
  formData: PropTypes.number
}

GroupSelectField.getDefaultProps = {
  formData: 0
}

export default GroupSelectField
