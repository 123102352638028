import {
  GroupIcon,
  SensorIcon,
  PairingIcon,
  AccessibilityIcon,
  AccountIcon
} from '../Icons'
import { SettingTab } from './SettingTab'
import { LaxativeIcon } from '@/components/Icons'
import { localizedUiWords } from '@/features/locale'
import { LocaleContext } from '@/providers/LocaleProvider'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'

export const SettingTabs = ({ activeTab }) => {
  const [locale] = useContext(LocaleContext)
  const uiWords = localizedUiWords(locale.lang).pages.settings

  return (
    <Grid container justifyContent="flex-start">
      <Grid item>
        <SettingTab
          to="/setting/group"
          icon={<GroupIcon />}
          tabLabel={uiWords.groupTabLabel}
          isActive={activeTab === 'group'}
        />
      </Grid>
      <Grid item>
        <SettingTab
          to="/setting/laxative"
          icon={<LaxativeIcon />}
          tabLabel={uiWords.laxativeTabLabel}
          isActive={activeTab === 'laxative'}
        />
      </Grid>
      <Grid item>
        <SettingTab
          to="/setting/sensor"
          icon={<SensorIcon />}
          tabLabel={uiWords.sensorTabLabel}
          isActive={activeTab === 'sensor'}
        />
      </Grid>
      <Grid item>
        <SettingTab
          to="/setting/pairing"
          icon={<PairingIcon />}
          tabLabel={uiWords.pairingTabLabel}
          isActive={activeTab === 'pairing'}
        />
      </Grid>
      <Grid item>
        <SettingTab
          to="/setting/accessibility"
          icon={<AccessibilityIcon />}
          tabLabel={uiWords.accessibilityTabLabel}
          isActive={activeTab === 'accessibility'}
        />
      </Grid>
      <Grid item>
        <SettingTab
          to="/setting/account"
          icon={<AccountIcon />}
          tabLabel={uiWords.accountTabLabel}
          isActive={activeTab === 'account'}
        />
      </Grid>
    </Grid>
  )
}

SettingTabs.propTypes = {
  activeTab: PropTypes.string
}
