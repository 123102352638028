/* eslint-disable */
import { ErrorMessage } from './ErrorMessage'
import { NoNotificationMessage } from './NoNotificationMessage'
import './NotificationTable.scss'
import { NotificationTableHeader } from './NotificationTableHeader'
import { NotPairingError, sensorErrors } from './SensorErrors'
import notificationBell from '@/audios/notification_bell.mp3'
import { Tooltip } from '@/components/Tooltip'
import { localizedUiWords } from '@/features/locale'
import { LocaleContext } from '@/providers/LocaleProvider'
import facilityStore from '@/stores/FacilityStore'
import _ from 'lodash'
import * as moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export class NotificationTable extends Component {
  static contextType = LocaleContext

  constructor() {
    super()
    this.state = {
      carePeople: null,
      sensorStatus: null
    }

    String.prototype.bytes = function () {
      var length = 0
      for (var i = 0; i < this.length; i++) {
        var c = this.charCodeAt(i)
        if (
          (c >= 0x0 && c < 0x81) ||
          c === 0xf8f0 ||
          (c >= 0xff61 && c < 0xffa0) ||
          (c >= 0xf8f1 && c < 0xf8f4)
        ) {
          length += 1
        } else {
          length += 2
        }
      }
      return length
    }
  }

  componentDidMount() {
    this.setState({
      carePeople: this.props.carePeople,
      sensorStatus: this.props.sensorStatus
    })
  }

  componentWillReceiveProps(props) {
    if (this.state.carePeople !== props.carePeople) {
      this.setState({
        carePeople: props.carePeople
      })
    }

    // 15分間隔の関数のみ有効
    if (props.doSound) {
      // アラートがあれば音を鳴らす
      if (this.existsAlert(props.carePeople)) {
        this.ding()
      }
    }

    if (this.state.sensorStatus !== props.sensorStatus) {
      this.setState({
        sensorStatus: props.sensorStatus
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.carePeople !== this.props.carePeople ||
      prevState.carePeople !== this.state.carePeople
    ) {
      this.setState({
        carePeople: this.props.carePeople
      })
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const propsDiff = _.isEqual(nextProps, this.props)
    const stateDiff = _.isEqual(nextState, this.state)
    return !(propsDiff && stateDiff)
  }

  /**
   * エラーアイコンの表示関数
   *
   * args:
   *   errorName: エラーの内容
   *     connection_error (通信エラー)
   *     gyro_error (傾き検知)
   *     HW_error (ハード故障)
   *     water_error (水没検知)
   *
   *   num: アイコンの表示場所(左から順に4, 3, 2, 1)
   **/
  errorIconDraw(errorName) {
    return (
      <td className="error_td">
        <div className={'error-icon icon-' + errorName.type}>
          <div className="path1"></div>
          <div className="path2"></div>
          <div className="path3"></div>
          <div className="path4"></div>
          <div className="path5"></div>
          <div className="path6"></div>
          {errorName.msg}
        </div>
      </td>
    )
  }

  existsAlert(carePeople) {
    if (carePeople !== null) {
      return carePeople.some((v) => v.alert_log && v.alert_log.active)
    }
    return false
  }

  ding() {
    const audio = new Audio(notificationBell)
    audio.play()
  }

  roomNameAdjuster(roomName) {
    const len = roomName.length
    // 8 byte over ?
    const is8bytesOver =
      roomName.bytes() >= 8 || (roomName.bytes() < 8 && len >= 4)

    if (len <= 5) {
      return (
        <p className={is8bytesOver ? 'font_size_adjuster' : ''}>{roomName}</p>
      )
    }

    if (len <= 10) {
      return (
        <p className={is8bytesOver ? 'font_size_adjuster' : ''}>
          {roomName.substring(0, 5)}
          <br />
          {roomName.substring(5, len)}
        </p>
      )
    }

    if (len <= 15) {
      return (
        <p className={is8bytesOver ? 'font_size_adjuster' : ''}>
          {roomName.substring(0, 5)}
          <br />
          {roomName.substring(5, 10)}
          <br />
          {roomName.substring(10, len)}
        </p>
      )
    }
  }

  handleAlertClick(e) {
    const data = {
      alert_log_id: parseInt(e.target.getAttribute('data-alertlogid'), 10),
      subject_id: parseInt(e.target.getAttribute('data-subjectid'), 10),
      is_alert_active: e.target.getAttribute('data-isalertactive')
    }
    if (
      Number.isNaN(data.alert_log_id) ||
      Number.isNaN(data.subject_id) ||
      data.is_alert_active === 'false'
    ) {
      return
    }
    this.props.onAlertClick(data)
  }

  /**
   * 現在時刻と通知時間の差分が5分以内かどうか判定する
   */
  blinksWithin5minute(created) {
    const now = moment()
    const alertDate = moment(created)
    const diff = now.diff(alertDate, 'minutes')
    return diff <= 5
  }

  render() {
    const [locale] = this.context
    const uiWords = localizedUiWords(locale.lang).pages.app

    const facility = facilityStore.getFacilityDetail()

    // テーブル空の時のだしわけ
    let tableView
    if (this.state.carePeople !== null && this.props.defecationTime !== null) {
      const currentTime = moment()
      let attachSortKeyCarePeople = _.map(this.state.carePeople, (v, k) => {
        // 優先度高排泄通知 データ準備
        let personDefecationTime = _.filter(this.props.defecationTime, {
          subject_id: v.id
        })[0]

        // 排泄通知後、3時間以上経過している場合の利用者情報
        let residentElapsedThreeHour = _.filter(this.props.activeAlertLogs, {
          subject_id: v.id
        })[0]

        let personLaxativeAlerts = _.filter(this.props.laxativeAlerts, {
          subject_id: v.id
        })[0]
        let laxativeAlertDiff = personLaxativeAlerts
          ? currentTime.diff(personLaxativeAlerts.confirmed, 'hours')
          : null

        // おむつ交換予報 データ準備
        let personExcretionForecast = _.filter(this.props.alertData, {
          subject_id: v.id
        })[0]
        personExcretionForecast = personExcretionForecast
          ? personExcretionForecast.alert_info.excretion_forecast
          : false

        // 優先度最高下剤記録済み通知 データ準備
        let isHighestPriority =
          laxativeAlertDiff !== null && v.alert_log && v.alert_log.active
            ? laxativeAlertDiff < facility.medicine_alert_prioritize_hour
            : false

        // 優先度高排泄通知 利用者情報に紐付け
        v['priority_yellow'] =
          (personDefecationTime && personDefecationTime.alert) ||
          //通知から三時間経過しており、かつ現在通知がある場合
          (residentElapsedThreeHour &&
            residentElapsedThreeHour.alert &&
            v.alert_log &&
            v.alert_log.active)
            ? true
            : false

        // おむつ交換予報 利用者情報に紐付け
        v['priority_green'] = personExcretionForecast

        // 優先度最高下剤記録済み通知 利用者情報に紐付け
        v['priority_red'] = isHighestPriority

        //ソート順 4(優先度最高下剤記録済み) > 3(おむつ交換予報) > 2(優先度高通知) > 1(通常通知) > 0(通知なし)
        if (
          personLaxativeAlerts &&
          isHighestPriority &&
          v.alert_log &&
          v.alert_log.active
        ) {
          v['sort'] = 4
        } else if (v.priority_green) {
          v['sort'] = 3
        } else if (v.priority_yellow) {
          v['sort'] = 2
        } else if (v.alert_log && v.alert_log.active) {
          v['sort'] = 1
        } else {
          v['sort'] = 0
        }

        // 利用者情報に優先度高排泄通知情報をマージする
        v['defecationTime'] = {
          enable:
            personDefecationTime && personDefecationTime.alert ? true : false,
          between: personDefecationTime
            ? currentTime.diff(personDefecationTime.time, 'days')
            : null
        }

        v['activeAlertLogs'] = {
          enable:
            residentElapsedThreeHour && residentElapsedThreeHour.alert
              ? true
              : false,
          between: residentElapsedThreeHour
            ? currentTime.diff(residentElapsedThreeHour.created, 'hours')
            : null
        }

        v['laxativeAlerts'] = {
          enable:
            personLaxativeAlerts &&
            isHighestPriority &&
            v.alert_log &&
            v.alert_log.active
              ? true
              : false,
          between: personLaxativeAlerts
            ? 60 > currentTime.diff(personLaxativeAlerts.confirmed, 'minutes')
              ? uiWords.minutesBeforeLabel(
                  currentTime.diff(personLaxativeAlerts.confirmed, 'minutes')
                )
              : uiWords.hoursBeforeLabel(
                  currentTime.diff(personLaxativeAlerts.confirmed, 'hours')
                )
            : null
        }

        v['excretionForecast'] = { enable: personExcretionForecast }
        if (v.alert_log && v.alert_log.active) {
          let tmpCreated = new Date(Date.parse(v.alert_log.created))
          // "2019-10-04T11:30:04+09:00" -> "201910041130" に変換し、秒は含まず分まででソートする
          v['alert_logs_created'] = parseInt(
            moment(tmpCreated).format('YYYYMMDDHHmm')
          )
        } else {
          v['alert_logs_created'] = 0
        }

        return v
      })

      // 順序変更
      const sortedCarePeople = _.orderBy(
        _.cloneDeep(attachSortKeyCarePeople),
        ['sort', 'alert_logs_created', 'place'],
        ['desc', 'desc', 'asc']
      )

      let tableContent = _.map(sortedCarePeople, (v, k) => {
        let errorIcon = <td></td>

        // TODO: ifブロックが長過ぎる
        if (v.m_status.code === '0') {
          let isError = false
          let alertDateDOM
          let errRes

          // エラーアイコン及びメッセージ表示
          if (this.state.sensorStatus !== null) {
            errRes = this.state.sensorStatus.filter((statElem) => {
              return statElem.sensor_id === v.sensor_id
            })

            if (errRes.length > 0 && sensorErrors[errRes[0].error]) {
              isError = !isError

              const sensorError = sensorErrors[errRes[0].error]
              errorIcon = (
                <ErrorMessage
                  errorType={sensorError.type}
                  errorMessage={sensorError.message}
                />
              )
            }
          }

          // ペアリング未実施もエラーとして表示
          if (errRes && errRes.length === 0 && !v.sensor_id) {
            isError = !isError
            errorIcon = (
              <ErrorMessage
                errorType={'disconnected_error'}
                errorMessage={<NotPairingError />}
              />
            )
          }

          // 通知がなければ表示しないが、センサーエラーがある場合は表示する。
          if (
            (!v.alert_log || !v.alert_log.active) &&
            !isError &&
            v.excretionForecast &&
            !v.excretionForecast.enable
          ) {
            return
          }

          const isAlertActive =
            v.alert_log !== null ? v.alert_log.active : false

          const caresubjectDOM = (
            <td className="room_careperson">
              <div
                className="individual"
                data-alertlogid={v.alert_log_id}
                data-subjectid={v.id}
                data-isalertactive={isAlertActive}
              >
                <Link to={'/user/' + v.id}>
                  <div className="place_card">
                    {this.roomNameAdjuster(v.place)}
                  </div>
                </Link>
                <div
                  className="name_card"
                  data-alertlogid={v.alert_log_id}
                  data-subjectid={v.id}
                  data-isalertactive={isAlertActive}
                >
                  <span
                    className={this.props.cookieData.showName ? '' : 'hide'}
                    data-alertlogid={v.alert_log_id}
                    data-subjectid={v.id}
                    data-isalertactive={isAlertActive}
                  >
                    {v.name}
                    {/* TODO: コンポーネント化 */}
                    {v.laxativeAlerts && v.laxativeAlerts.enable ? (
                      <div
                        className={
                          this.props.cookieData.showName
                            ? 'icon icon-icons_laxative high_priority_alert'
                            : 'icon icon-icons_laxative high_priority_alert hide'
                        }
                        data-alertlogid={v.alert_log_id}
                        data-subjectid={v.id}
                        data-isalertactive={isAlertActive}
                      >
                        <div
                          className="expiration"
                          data-alertlogid={v.alert_log_id}
                          data-subjectid={v.id}
                          data-isalertactive={isAlertActive}
                        >
                          {v.laxativeAlerts.between}
                        </div>
                        <Tooltip top="-1rem">
                          <div style={{ textAlign: 'left', fontSize: '1rem' }}>
                            {uiWords.redLaxativeNotificationTooltip(
                              facility.medicine_alert_prioritize_hour
                            )}
                          </div>
                        </Tooltip>
                      </div>
                    ) : (
                      ''
                    )}
                    {v.excretionForecast && v.excretionForecast.enable ? (
                      <div
                        className={
                          this.props.cookieData.showName
                            ? 'icon icon-icons_calendar high_priority_alert'
                            : 'icon icon-icons_calendar high_priority_alert hide'
                        }
                        data-alertlogid={v.alert_log_id}
                        data-subjectid={v.id}
                      >
                        <Tooltip top="-1rem">
                          <div style={{ textAlign: 'left', fontSize: '1rem' }}>
                            {uiWords.greenForecastNotificationTooltip}
                          </div>
                        </Tooltip>
                      </div>
                    ) : (
                      ''
                    )}
                    {v.defecationTime && v.defecationTime.enable ? (
                      <div
                        className={
                          this.props.cookieData.showName
                            ? 'icon icon-icons_constipation high_priority_alert'
                            : 'icon icon-icons_constipation high_priority_alert hide'
                        }
                        data-alertlogid={v.alert_log_id}
                        data-subjectid={v.id}
                      >
                        <div
                          className="expiration"
                          data-alertlogid={v.alert_log_id}
                          data-subjectid={v.id}
                        >
                          {uiWords.daysPassedLabel(v.defecationTime.between)}
                        </div>
                        <Tooltip top="-1rem">
                          <div style={{ textAlign: 'left', fontSize: '1rem' }}>
                            {uiWords.yellow72HoursPassedNotificationTooltip}
                          </div>
                        </Tooltip>
                      </div>
                    ) : (
                      ''
                    )}
                  </span>
                </div>
              </div>
            </td>
          )

          // TODO: NotificationTimeColumn で置き換える
          if (v.alert_log && v.alert_log.active) {
            const isBlinking = this.blinksWithin5minute(v.alert_log.created)
            alertDateDOM = (
              <td
                className="alert_date"
                onClick={this.handleAlertClick.bind(this)}
                data-alertlogid={v.alert_log_id}
                data-subjectid={v.id}
                data-isalertactive={isAlertActive}
              >
                <span
                  data-alertlogid={v.alert_log_id}
                  data-subjectid={v.id}
                  data-isalertactive={isAlertActive}
                >
                  {v.alert_log && v.alert_log.active ? (
                    <span
                      className={isBlinking ? 'blinking' : ''}
                      data-alertlogid={v.alert_log_id}
                      data-subjectid={v.id}
                      data-isalertactive={isAlertActive}
                    >
                      {moment(v.alert_log.created).format('HH:mm')}
                    </span>
                  ) : (
                    <div className="blank"></div>
                  )}
                  {v.activeAlertLogs && v.activeAlertLogs.enable ? (
                    <div
                      className="icon icon-icons_loadtime high_priority_alert"
                      data-alertlogid={v.alert_log_id}
                      data-subjectid={v.id}
                      data-isalertactive={isAlertActive}
                    >
                      <div
                        className="expiration"
                        data-alertlogid={v.alert_log_id}
                        data-subjectid={v.id}
                        data-isalertactive={isAlertActive}
                      >
                        {uiWords.hoursPassedLabel(v.activeAlertLogs.between)}
                      </div>
                      <Tooltip top="-1rem">
                        <div style={{ textAlign: 'left', fontSize: '1rem' }}>
                          {uiWords.yellow3HoursPassedNotificationTooltip}
                        </div>
                      </Tooltip>
                    </div>
                  ) : (
                    <></>
                  )}
                </span>
              </td>
            )
          } else {
            alertDateDOM = (
              <td className="alert_date">
                <span>
                  {v.alert_log && v.alert_log.active ? (
                    moment(v.alert_log.created).format('HH:mm')
                  ) : (
                    <div className="blank"></div>
                  )}
                  {v.activeAlertLogs && v.activeAlertLogs.enable ? (
                    <div className="icon icon-icons_loadtime high_priority_alert">
                      <div className="expiration">
                        {uiWords.hoursPassedLabel(v.activeAlertLogs.between)}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </span>
              </td>
            )
          }

          return (
            <tr
              key={k}
              ref={v.id}
              data-user-id={v.id}
              className={
                v.priority_red
                  ? 'data_row priority-red'
                  : v.priority_green
                  ? 'data_row priority-green'
                  : v.priority_yellow
                  ? 'data_row priority-yellow'
                  : 'data_row'
              }
              onClick={this.handleAlertClick.bind(this)}
            >
              {caresubjectDOM}
              {errorIcon}
              {alertDateDOM}
            </tr>
          )
        }
      })

      // 利用者情報はあるが、tableContentの要素が全て未定義 = 「入居中」の利用者がいない
      if (tableContent && tableContent instanceof Array) {
        let isEmpty = true
        _.forEach(tableContent, (data) => {
          if (data) {
            isEmpty = false
            return
          }
        })

        if (isEmpty) {
          tableContent = <NoNotificationMessage />
        }
      }

      tableView = (
        <div>
          <NotificationTableHeader />
          <div ref="tableContent" className="user-table-content">
            <table>
              <tbody>{tableContent}</tbody>
            </table>
          </div>
        </div>
      )
    }

    return <div className="user-table">{tableView}</div>
  }
}

NotificationTable.propTypes = {
  carePeople: PropTypes.array,
  clickUserId: PropTypes.string,
  sensorStatus: PropTypes.array,
  doSound: PropTypes.bool,
  defecationTime: PropTypes.array,
  cookieData: PropTypes.object,
  alertData: PropTypes.array,
  onAlertClick: PropTypes.func
}
