import TCarePerson from "./TCarePerson";

type TAlertLog = {
  id: number;
  sensor_id: number;
  care_subject: TCarePerson | any;
  care_subject_id: number;
  created: string;
  modified: string;
  active: boolean;
  result: string;
  type: number;
};

export const initialAlertLog: TAlertLog = {
  id: 0,
  sensor_id: 0,
  care_subject: {},
  care_subject_id: 0,
  created: "",
  modified: "",
  active: false,
  result: "",
  type: 0,
};

export default TAlertLog;
