/* eslint-disable */
import { EventEmitter } from "events";
import dispatcher from "../dispatchers/Dispatcher";

class FacilityStore extends EventEmitter {
    constructor() {
      super();
      this.facilityDetail = null;
      this.facilities = null;
    }
    getFacilityDetail(){
      return this.facilityDetail;
    }
    handleActions(action) {
      switch (action.type) {
        case "FACILITY_ALL":
          this.emit("facility_all",action.body);
          this.facilities = action.body;
          break;
        case "FACILITY_DETAIL":
          this.emit("facility_detail",action.body);
          this.facilityDetail = action.body;
          break;
        case "FACILITY_EDIT":
          this.emit("facility_edit",action.body);
          break;
        default:
          break;
      }
    }
}

const facilityStore = new FacilityStore();
dispatcher.register(facilityStore.handleActions.bind(facilityStore));

export default facilityStore;