import { localizedUiWords } from '@/features/locale'
import { LocaleContext } from '@/providers/LocaleProvider'
import React, { useContext } from 'react'

export const CareNoteLegends = () => {
  const [locale] = useContext(LocaleContext)
  const uiWords = localizedUiWords(locale.lang).pages.excretionResults

  return (
    <div className="note-space flex">
      <div className="notes">
        <span className="none">{uiWords.noneColorLabel}</span>
        <span className="urine">{uiWords.urineColorLabel}</span>
        <span className="watery">{uiWords.diarrheaColorLabel}</span>
        <span className="feces">{uiWords.fecesColorLabel}</span>
        <span className="laxative">{uiWords.laxativeColorLabel}</span>
      </div>
    </div>
  )
}
