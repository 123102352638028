import EventConst from '../../constants/EventConstants'
import SystemConst from '../../constants/SystemConstants'
import dispatcher from '../../dispatchers/Dispatcher'
import UserExcretionResultsStore from '../../stores/indivisualExcretionResults/UserExcretionResultsStore'
import * as AlertAction from '../AlertAction'
import * as AppAction from '../AppAction'
import * as ExcretionResultAction from '../ExcretionResultAction'
import * as laxationLogsAction from '../LaxationLogsAction'
import _ from 'lodash'
import * as moment from 'moment'

/**
 * 排泄記録情報の作成
 * @param {moment} date 詳細情報を作成する日付
 */
export function createNewExcretionResults(date) {
  createNewDetailExcretionResults(date)

  createNewLaxationLogs(date)
}

/**
 * 詳細情報の作成
 * @param {moment} date 詳細情報を作成する日付
 */
function createNewDetailExcretionResults(date) {
  const carePerson = UserExcretionResultsStore.getCarePerson()

  dispatcher.dispatch({
    type: EventConst.action_type.excretion_setting.excretion_results,
    value: {
      data: [
        {
          id: null,
          subject_id: carePerson.id,
          confirmed: date,
          result: 'none',
          description: null
        }
      ]
    }
  })

  dispatcher.dispatch({
    type: EventConst.action_type.excretion_setting.excretion_results_time,
    value: { data: date }
  })

  dispatcher.dispatch({
    type: EventConst.action_type.excretion_setting.alert_log,
    value: null
  })
}

/**
 * 下剤情報の作成
 * @param {moment} date 下剤情報を作成する日付
 */
function createNewLaxationLogs(date) {
  const carePerson = UserExcretionResultsStore.getCarePerson()

  dispatcher.dispatch({
    type: EventConst.action_type.excretion_setting.laxation,
    value: {
      data: [
        {
          subject_id: carePerson.id,
          name: '',
          dosage_date: date,
          laxative_id: null,
          amount: '',
          unit_id: null,
          isEdit: false
        }
      ]
    }
  })
}

/**
 * 下剤情報クリア
 */
export function clearLaxationLogs() {
  dispatcher.dispatch({
    type: EventConst.action_type.excretion_setting.laxation,
    value: { data: [] }
  })
}

/**
 * 排泄詳細情報クリア
 */
export function clearDetailExcretionResults() {
  dispatcher.dispatch({
    type: EventConst.action_type.excretion_setting.excretion_results,
    value: { data: [] }
  })
  dispatcher.dispatch({
    type: EventConst.action_type.excretion_setting.alert_log,
    value: null
  })
}

/**
 * 排泄情報クリア
 */
export function clearExcretionResults() {
  clearLaxationLogs()
  clearDetailExcretionResults()

  dispatcher.dispatch({
    type: EventConst.action_type.excretion_setting.excretion_results_time,
    value: { data: null }
  })
}

/**
 * 詳細情報の更新
 * @param {moment} basicDate 詳細情報作成時に使用する基準日
 * @param {string} startDate 取得対象開始日時
 * @param {string} endDate 取得対象終了日時
 */
export function createUpdateExcretionResults(basicDate, startDate, endDate) {
  AppAction.startLoaded()
  createUpdateLaxationLogsMain(basicDate, startDate, endDate)
  createUpdateDetailExcretionResultsMain(basicDate, startDate, endDate)
}

/**
 * 下剤情報の更新(Main)
 * @param {moment} basicDate 詳細情報作成時に使用する基準日
 * @param {string} startDate 取得対象開始日時
 * @param {string} endDate 取得対象終了日時
 */
function createUpdateLaxationLogsMain(basicDate, startDate, endDate) {
  const carePerson = UserExcretionResultsStore.getCarePerson()
  const query = {
    subject_id: carePerson.id,
    start: startDate,
    end: endDate
  }

  dispatcher.dispatch({
    type: EventConst.action_type.excretion_setting.excretion_results_time,
    value: { data: basicDate }
  })

  laxationLogsAction.laxationLogsDetail(query, (result) => {
    const datas = _.reduce(
      result,
      (addArray, data) => {
        addArray.push(
          _.assign(data, {
            dosage_date: moment(data.administered),
            isEdit: false
          })
        )
        return addArray
      },
      []
    )

    // 既存データ0件、初期値準備
    if (datas.length === 0) {
      createNewLaxationLogs(basicDate)
      return
    }
    datas.sort((currentData, nextData) => {
      return currentData.dosage_date.format() > nextData.dosage_date.format()
        ? 1
        : -1
    })
    dispatcher.dispatch({
      type: EventConst.action_type.excretion_setting.laxation,
      value: { data: datas }
    })
  })
}

/**
 * 詳細情報の更新(Main)
 * @param {moment} basicDate 詳細情報作成時に使用する基準日
 * @param {string} startDate 取得対象開始日時
 * @param {string} endDate 取得対象終了日時
 */
function createUpdateDetailExcretionResultsMain(basicDate, startDate, endDate) {
  const carePerson = UserExcretionResultsStore.getCarePerson()
  const query = {
    subject_id: carePerson.id,
    start: startDate,
    end: endDate
  }

  // 詳細情報の取得
  ExcretionResultAction.excretionResultsPagination(query, (result) => {
    const excretionDetailDatas = _.reduce(
      result,
      (addArray, excretionDetailData) => {
        if (excretionDetailData.alert_log_id !== null) {
          // 通知時刻の取得
          AlertAction.alert(excretionDetailData.alert_log_id, (alertResult) => {
            dispatcher.dispatch({
              type: EventConst.action_type.excretion_setting.alert_log,
              value: {
                id: alertResult.data.id,
                date: moment(alertResult.data.created)
                  .add(9, 'hour')
                  .format(SystemConst.DateFormat.alerLog)
              }
            })
          })
        }
        addArray.push(
          _.assign(excretionDetailData, {
            confirmed: moment(excretionDetailData.confirmed),
            isEdit: false
          })
        )
        return addArray
      },
      []
    )

    if (excretionDetailDatas.length === 0) {
      createNewDetailExcretionResults(basicDate)
      AppAction.finishLoaded()
      return
    }
    // 日付の若い順にソート
    excretionDetailDatas.sort((currentData, nextData) => {
      return currentData.confirmed.format() > nextData.confirmed.format()
        ? 1
        : -1
    })
    dispatcher.dispatch({
      type: EventConst.action_type.excretion_setting.excretion_results,
      value: { data: excretionDetailDatas }
    })
    AppAction.finishLoaded()
  })
}
