/* eslint-disable */
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'

// TODO: lodash 脱却
// TODO: MUI v5 に向けて makeStyles 脱却
const useStyles = makeStyles((theme) => ({
  userLists: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    padding: '0 0 0 1rem',

    '& > div': {
      display: 'flex',
      background: '#e9edef',
      padding: '5px 10px',
      borderRadius: '5px',
      marginRight: '5px',
      marginBottom: '10px',
      fontSize: '14px',
      [theme.breakpoints.up('md')]: {
        alignItems: 'center'
      }
    }
  },
  removeIcon: {
    fontSize: '15px',
    lineHeight: '15px',
    marginRight: '5px',
    color: '#999999',
    cursor: 'pointer'
  }
}))

const GroupMemberList = (props) => {
  const [groupId, setGroupId] = useState(null)
  const [carePeople, setCarePeople] = useState(null)

  useEffect(() => {
    setGroupId(props.groupId)
    setCarePeople(props.carePeople)
  })

  const handleRemove = (e) => {
    props.onChange({
      userId: e.target.getAttribute('data-userid'),
      groupId: groupId
    })
  }

  let nameLists
  const classes = useStyles()
  // 二つのデータがそろっていたら
  if (groupId !== null && carePeople !== null) {
    nameLists = _.map(carePeople, (v, k) => {
      if (v.group_id === groupId) {
        return (
          <div key={v.id}>
            <p
              className={classes.removeIcon}
              onClick={handleRemove}
              data-userid={v.id}
            >
              ×
            </p>
            <p>{v.name}</p>
          </div>
        )
      }
    })
  }

  return <div className={classes.userLists}>{nameLists}</div>
}

export default GroupMemberList
