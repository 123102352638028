/* eslint-disable */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { AxiosError } from "axios";
import request, { Token } from "./CustomAxios";
import { AsyncThunkConfig } from "../../store";
import API from "../../constants/ApiConstants";
import ValidationErrors from "../ValidationErrors";
import TFacility from "../Types/TFacility";

// get param
type GetFacilityParam = {
  facility_id: string;
  login: Token;
};

// get
export type GetFacilityState = {
  facility: TFacility;
  message: string;
  isLoading: boolean;
};

const initialGetFacility: GetFacilityState = {
  facility: {
    id: 0,
    name: "",
    kana: "",
    display_name: "",
    tell: "",
    postal_code: "",
    address: "",
    email: "",
    medicine_alert_prioritize_hour: "",
    demo_expired: "",
    basic_amount: 0,
    min_au: 0,
    login_type: "",
    scop_cooperation: 0,
    created: "",
    modified: "",
    contract_date: "",
    deleted: "",
  },
  message: "",
  isLoading: false,
};

// form data
export type FacilityFormData = {
  name: string;
  display_name: string;
  kana: string;
  tell: string;
  postal_code: string;
  address: string;
  email: string;
  password: string;
  password_confirm: string;
};

// edit param
type EditFacilityParam = {
  facility_id: string;
  login: Token;
  data: FacilityFormData;
};

// edit
export type EditFacilityState = {
  success: boolean;
  message: string;
  isLoading: boolean;
};

const initialEditFacility: EditFacilityState = {
  success: false,
  message: "",
  isLoading: false,
};

export type FacilityState = {
  getFacility: GetFacilityState;
  editFacility: EditFacilityState;
};

export const fetchGetFacility = createAsyncThunk<
  Object,
  GetFacilityParam,
  AsyncThunkConfig<ValidationErrors>
>("Facility/all", async (arg, thunkAPI) => {
  let response: any = [];
  try {
    response = await request(arg.login).get(
      API.Facility.Detail.replace(":id", arg.facility_id)
    );
  } catch (err: any) {
    let error: AxiosError<ValidationErrors> = err;
    if (!error.response) {
      throw err;
    }
    return thunkAPI.rejectWithValue(error.response.data);
  }

  return response;
});

const fetchGetFacilitySlice = createSlice<GetFacilityState, any>({
  name: "fetch/all/Facility",
  initialState: initialGetFacility,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(fetchGetFacility.fulfilled, (state: any, action: any) => {
      console.log("Facility fulfilled");
      state.facility = action.payload.data.data;
      state.isLoading = false;
    });
    builder.addCase(fetchGetFacility.pending, (state: any, action: any) => {
      console.log("Facility pending");
      state.isLoading = true;
    });
    builder.addCase(fetchGetFacility.rejected, (state: any, action: any) => {
      console.log("Facility rejected");
      state.status = "error";
      state.message = action.error.message;
      state.isLoading = false;
    });
  },
});

//edit
export const fetchEditFacility = createAsyncThunk<
  Object,
  EditFacilityParam,
  AsyncThunkConfig<ValidationErrors>
>("Facility/edit", async (arg, thunkAPI) => {
  let response: any = [];
  try {
    const replaceString = API.Facility.Edit.replace(":id", arg.facility_id);
    response = await request(arg.login).put(replaceString, arg.data);
  } catch (err: any) {
    let error: AxiosError<ValidationErrors> = err;
    if (!error.response) {
      throw err;
    }
    return thunkAPI.rejectWithValue(error.response.data);
  }

  return response;
});

const fetchEditFacilitySlice = createSlice<EditFacilityState, any>({
  name: "fetch/edit/Facility",
  initialState: initialEditFacility,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(fetchEditFacility.fulfilled, (state: any, action: any) => {
      console.log("edit Facility fulfilled");
      state.result = action.payload.data.data;
      state.isLoading = false;
    });
    builder.addCase(fetchEditFacility.pending, (state: any, action: any) => {
      console.log("edit Facility pending");
      state.isLoading = true;
    });
    builder.addCase(fetchEditFacility.rejected, (state: any, action: any) => {
      console.log("edit Facility rejected");
      state.status = "error";
      state.message = action.error.message;
      state.isLoading = false;
    });
  },
});

const reducers = combineReducers<FacilityState>({
  getFacility: fetchGetFacilitySlice.reducer,
  editFacility: fetchEditFacilitySlice.reducer,
});

export default reducers;
