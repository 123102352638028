import TCarePerson from './Types/TCarePerson'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'

export type AlertRowState = {
  carePersonName: string
  group: string
  alertDate: string
  alertType: string
}

export type AlertTableState = Array<AlertRowState>

// 引数が必要なactionにはPayloadActionを第二引数に記述する。
// stateを参照するだけのactionは不要かもしれない。（getXXX）
type Reducer = {
  getAlertTable: (
    state: AlertTableState,
    { payload }: PayloadAction<Array<TCarePerson>>
  ) => void
}

const initialState: AlertTableState = []

const alertTableSlice = createSlice<AlertTableState, Reducer>({
  name: 'AlertTable',
  initialState,
  reducers: {
    getAlertTable: (state, { payload }) => {
      _.forEach(payload, (v) => {
        // 通知のある利用者だけ表示する
        if (_.keys(v.alert_log).length === 0) return
        if (!v.alert_log.active) return

        state.push({
          carePersonName: v.name,
          group: _.keys(v.groups).length !== 0 ? v.groups[0].name : '',
          alertDate:
            _.keys(v.alert_log).length !== 0 ? v.alert_log.created : '',
          alertType:
            _.keys(v.alert_log).length !== 0
              ? v.alert_log.type === 1
                ? '排泄通知'
                : '未定義の通知'
              : ''
        })
      })
    }
  }
})

export const { getAlertTable } = alertTableSlice.actions

export default alertTableSlice
