import { localizedUiWords } from '@/features/locale'
import { LocaleContext } from '@/providers/LocaleProvider'
import React, { useContext } from 'react'

type FirmnessFieldSchema = {
  type: string
  title: string
  enum: Array<string>
  enumNames: Array<string>
  hasAlert: boolean
}

type FirmnessFieldProps = {
  schema: FirmnessFieldSchema
  disabled: boolean
  formData: string
  onChange: (eventValue: string) => void
}

export const FirmnessField = ({
  schema,
  disabled,
  formData,
  onChange
}: FirmnessFieldProps) => {
  const [locale] = useContext(LocaleContext)
  const uiWords = localizedUiWords(locale.lang).components.careNoteDialog

  // キーがCareNoteDialogのenum値と対応している必要があるので注意
  const firmnessDescriptions: Record<string, string> = {
    very_hard: uiWords.rabitDroppingsScaleDescription as string,
    hard: uiWords.hardScaleDescription as string,
    somewhat_hard: uiWords.littleHardScaleDescription as string,
    normal: uiWords.normalScaleDescription as string,
    somewhat_soft: uiWords.littleSoftScaleDescription as string,
    turbid: uiWords.porridgeScaleDescription as string,
    watery: uiWords.diarrheaScaleDescription as string
  }

  const hasAlert = schema.hasAlert
  const firmnesses = schema.enum
  const firmnessNames = schema.enumNames

  return (
    <div className="field-radio-group" id="root_firmness">
      {firmnesses.map((firmness, index) => (
        <label
          className={'radio-inline' + (disabled ? ' disabled' : '')}
          key={firmness}
        >
          <span>
            <input
              type="radio"
              value={firmness}
              name={'firmness'}
              onChange={(event) => onChange(event.target.value)}
              disabled={disabled}
              checked={firmness === formData}
            ></input>

            <span>{firmnessNames[index]}</span>

            <div
              className="firmness-description"
              style={{ bottom: hasAlert ? '280px' : '250px' }}
            >
              {firmnessDescriptions[firmness]}
            </div>
          </span>
        </label>
      ))}
    </div>
  )
}
