import * as CarePeopleAction from '../actions/CarePeopleAction'
import * as ExcretionForecastAction from '../actions/ExcretionForecastAction'
import AppDialog from '../components/App/AppDialog'
import AppLoading from '../components/App/AppLoading'
import AlertHistogram from '../components/common/AlertHistogram'
import UserDetailTable from '../components/common/UserDetailTable'
import API from '../constants/ApiConstants'
import alertStore from '../stores/AlertStore'
import carePeopleStore from '../stores/CarePeopleStore'
import excretionForecastStore from '../stores/ExcretionForecastStore'
import './UserDetail.scss'
import { AppHeader } from '@/components/App/AppHeader'
import { HistogramPeriodSelector } from '@/features/histogram/components/HistogramPeriodSelector'
import { localizedUiWords } from '@/features/locale'
import { LocaleContext } from '@/providers/LocaleProvider'
import _ from 'lodash'
import * as moment from 'moment'
import React, { Component } from 'react'
import Form from 'react-jsonschema-form'
import { Link, withRouter } from 'react-router-dom'
import request from 'superagent'

function zeroPad(x, n) {
  return ('00000' + x).slice(-n)
}

function idxToTime(idx, interval) {
  const t = idx * interval
  const h = Math.floor(t / 60)
  const m = t % 60
  return zeroPad(h, 2) + ':' + zeroPad(m, 2)
}

async function getHistogramDataRequest(subjectId, mode, startDt, endDt) {
  const res = await request
    .get(API.Histogram.Get)
    .set('Accept', 'application/json')
    .query({
      subject_id: subjectId,
      mode,
      interval: 60,
      start_dt: startDt,
      end_dt: endDt
    })

  return res
}

async function getHistogramData(subjectId, mode, startDt, endDt) {
  const resLog = await getHistogramDataRequest(subjectId, mode, startDt, endDt)
  let interval = 60
  const data = resLog && resLog.body ? resLog.body : JSON.parse(resLog.text)
  if (data.length > 0) {
    interval = Math.floor((24 * 60) / data.none.length)
  }

  const histogramData = []
  switch (mode) {
    case 'alert_log':
      for (const idx in data.none) {
        histogramData.push({
          x: idxToTime(idx, interval),
          件数:
            data.urine[idx] +
            data.feces[idx] +
            data.urine_and_feces[idx] +
            data.none[idx] +
            data.unconfirmed[idx]
          //, "未確認":data.unconfirmed[idx]
        })
      }
      break

    case 'excretion_result':
      for (const idx in data.none) {
        histogramData.push({
          x: idxToTime(idx, interval),
          尿: data.urine[idx],
          便: data.feces[idx],
          '尿＆便': data.urine_and_feces[idx],
          なし: data.none[idx] * -1
        })
      }
      break

    default:
      break
  }

  return histogramData
}

const timeFormat = 'YYYY-MM-DD'

const modeAlertLog = 'alert_log'
const modeExcretionResult = 'excretion_result'

const marginValues = {
  '00:00': 84,
  '01:00': 105,
  '02:00': 125,
  '03:00': 146,
  '04:00': 167,
  '05:00': 187,
  '06:00': 208,
  '07:00': 229,
  '08:00': 249,
  '09:00': 270,
  '10:00': 291,
  '11:00': 311,
  '12:00': 332,
  '13:00': 353,
  '14:00': 373,
  '15:00': 394,
  '16:00': 415,
  '17:00': 435,
  '18:00': 456,
  '19:00': 477,
  '20:00': 497,
  '21:00': 518,
  '22:00': 539,
  '23:00': 559
}
class UserDetail extends Component {
  static contextType = LocaleContext

  constructor() {
    super()
    this.state = {
      personDetail: null,
      excretionForecastDetail: null,
      loading: false,
      deleteDialog: false,
      noticeDialog: false,
      errorDialog: false,
      alerts: null,
      description: null,
      histogramDataAlertLog: null,
      histogramDataInitAlertLog: null,
      topHistogramDataAlertLog: null,
      topHistogramDataInitAlertLog: null,
      topHistogramDataExcretionResult: null,
      topHistogramDataInitExcretionResult: null,
      bottomHistogramDataAlertLog: null,
      bottomHistogramDataInitAlertLog: null,
      bottomHistogramDataExcretionResult: null,
      bottomHistogramDataInitExcretionResult: null,
      totalCount: 0,
      currentPage: 1,
      topStart: 0,
      topEnd: 0,
      topIsEnableStartAndEnd: false,
      topExchangeCount: 1,
      topDoExchangeWhenEnd: false,
      topDoExchangeWhenStart: false,
      topShowExchangeMarkForJSX: {},
      topIsShowCalcForm: false,
      topDisabled: true,
      bottomStart: 0,
      bottomEnd: 0,
      bottomIsEnableStartAndEnd: false,
      bottomExchangeCount: 1,
      bottomDoExchangeWhenEnd: false,
      bottomDoExchangeWhenStart: false,
      bottomShowExchangeMarkForJSX: {},
      bottomIsShowCalcForm: false,
      bottomDisabled: true,
      // TODO: キャメルケースにする
      top_start_dt: moment().subtract(30, 'days').format(timeFormat),
      top_end_dt: moment().format(timeFormat),
      errorTopDate: false,
      // TODO: キャメルケースにする
      bottom_start_dt: moment().subtract(30, 'days').format(timeFormat),
      bottom_end_dt: moment().format(timeFormat),
      errorBottomDate: false,
      // TODO: キャメルケースにする
      mode_top: 'alert_log',
      mode_bottom: 'excretion_result',
      readButtonDisabled: true,
      isTopModeCheckForecast: false,
      isBottomModeCheckForecast: false
    }
    this.perPage = 20
    this.handleChange = this.handleChange.bind(this)
    this.handleOnChangeForTopHistogram =
      this.handleOnChangeForTopHistogram.bind(this)
    this.handleChangeTopStartValue = this.handleChangeTopStartValue.bind(this)
    this.handleChangeTopEndValue = this.handleChangeTopEndValue.bind(this)
    this.handleChangeTopExchangeCount =
      this.handleChangeTopExchangeCount.bind(this)
    this.handleChangeTopDoExchangeWhenStart =
      this.handleChangeTopDoExchangeWhenStart.bind(this)
    this.handleChangeTopDoExchangeWhenEnd =
      this.handleChangeTopDoExchangeWhenEnd.bind(this)
    this.handleOnChangeForBottomHistogram =
      this.handleOnChangeForBottomHistogram.bind(this)
    this.handleChangeBottomStartValue =
      this.handleChangeBottomStartValue.bind(this)
    this.handleChangeBottomEndValue = this.handleChangeBottomEndValue.bind(this)
    this.handleChangeBottomExchangeCount =
      this.handleChangeBottomExchangeCount.bind(this)
    this.handleChangeBottomDoExchangeWhenStart =
      this.handleChangeBottomDoExchangeWhenStart.bind(this)
    this.handleChangeBottomDoExchangeWhenEnd =
      this.handleChangeBottomDoExchangeWhenEnd.bind(this)
    this.handleCalculateTopHistogram =
      this.handleCalculateTopHistogram.bind(this)
    this.handleCalculateBottomHistogram =
      this.handleCalculateBottomHistogram.bind(this)
    this.executeCalculations = this.executeCalculations.bind(this)
    this.getDataFromStartToEnd = this.getDataFromStartToEnd.bind(this)
    this.calcThreshold = this.calcThreshold.bind(this)
    this.compareDataWithThreshold = this.compareDataWithThreshold.bind(this)
    this.generateObjectForJSX = this.generateObjectForJSX.bind(this)
    this.generateMarkJSX = this.generateMarkJSX.bind(this)
    this.showExchangeMark = this.showExchangeMark.bind(this)
    this.handleClickShowTopCalcForm = this.handleClickShowTopCalcForm.bind(this)
    this.handleClickShowBottomCalcForm =
      this.handleClickShowBottomCalcForm.bind(this)
    this.handleOnReadTopExcretionForecast =
      this.handleOnReadTopExcretionForecast.bind(this)
    this.handleTopSubmitExcretionForecast =
      this.handleTopSubmitExcretionForecast.bind(this)
    this.handleOnReadTopHistogram = this.handleOnReadTopHistogram.bind(this)
    this.updateTopHistogramDataFromReadButton =
      this.updateTopHistogramDataFromReadButton.bind(this)
    this.updateBottomHistogramDataFromReadButton =
      this.updateBottomHistogramDataFromReadButton.bind(this)
    this.handleOnReadBottomExcretionForecast =
      this.handleOnReadBottomExcretionForecast.bind(this)
    this.handleBottomSubmitExcretionForecast =
      this.handleBottomSubmitExcretionForecast.bind(this)
    this.handleClickSlideWeek = this.handleClickSlideWeek.bind(this)
    this.handleClickSyncAnotherDate = this.handleClickSyncAnotherDate.bind(this)
    this.histogramModeOptions = this.histogramModeOptions.bind(this)
  }

  componentDidMount() {
    alertStore.on('alert_all', (v) => {
      this.setState({
        alerts: v.data,
        loading: false,
        currentPage: v.pagination ? v.pagination.current_page : 1,
        totalCount: v.pagination ? v.pagination.count : 0
      })
    })
    carePeopleStore.on('person_detail', (v) => {
      this.setState({
        personDetail: v,
        loading: false,
        description: v.description
      })
      ExcretionForecastAction.getExcretionForecastDetail(v.id)
    })

    carePeopleStore.on('person_edit', () => {
      /* eslint-disable-next-line */
      CarePeopleAction.carePerson(this.props.match.params.id)
    })

    /* eslint-disable-next-line */
    carePeopleStore.on('person_delete', (v) => {
      /* eslint-disable-next-line */
      this.props.history.push('/users')
    })

    excretionForecastStore.on('excretion_forecast_detail', (v) => {
      if (v.success) {
        if (v.data.length > 0) {
          // データ整形
          const data = v.data[0]
          data.start_hour = zeroPad(data.start_hour, 2)
          data.end_hour = zeroPad(data.end_hour, 2)
          this.setState({
            excretionForecastDetail: data,
            readButtonDisabled: false
          })
        }
      }
    })

    excretionForecastStore.on('excretion_forecast_add', (v) => {
      if (v.success) {
        this.setState({
          noticeDialog: true
        })
        ExcretionForecastAction.getExcretionForecastDetail(
          this.state.personDetail.id
        )
      } else {
        this.setState({
          errorDialog: true
        })
      }
    })

    excretionForecastStore.on('excretion_forecast_edit', (v) => {
      if (v.success) {
        this.setState({
          noticeDialog: true
        })
        ExcretionForecastAction.getExcretionForecastDetail(
          this.state.personDetail.id
        )
      } else {
        this.setState({
          errorDialog: true
        })
      }
    })

    /* eslint-disable-next-line */
    excretionForecastStore.on('excretion_forecast_error', (v) => {
      this.setState({
        errorDialog: true
      })
    })

    /* eslint-disable-next-line */
    CarePeopleAction.carePerson(this.props.match.params.id)
    this.updateTopHistogramData()
    this.updateBottomHistogramData()
  }

  async updateTopHistogramData() {
    switch (this.state.mode_top) {
      case 'alert_log':
        getHistogramData(
          /* eslint-disable-next-line */
          this.props.match.params.id,
          modeAlertLog,
          this.state.top_start_dt,
          this.state.top_end_dt
        ).then((histogramData) => {
          // 計算後に期間を変更した場合は同条件で再計算する
          if (_.size(this.state.topShowExchangeMarkForJSX)) {
            this.handleCalculateTopHistogram(_.cloneDeep(histogramData))
          }

          this.setState({
            topShowExchangeMarkForJSX: {},
            topHistogramDataAlertLog: histogramData,
            topHistogramDataInitAlertLog: histogramData,
            isTopModeCheckForecast: false
          })
        })
        break

      case 'excretion_result':
        getHistogramData(
          /* eslint-disable-next-line */
          this.props.match.params.id,
          modeExcretionResult,
          this.state.top_start_dt,
          this.state.top_end_dt
        ).then((histogramData) => {
          this.setState({
            topHistogramDataExcretionResult: histogramData,
            topHistogramDataInitExcretionResult: histogramData,
            topIsShowCalcForm: false,
            isTopModeCheckForecast: false
          })
        })
        break

      case 'check_forecast':
        await getHistogramData(
          /* eslint-disable-next-line */
          this.props.match.params.id,
          modeAlertLog,
          this.state.top_start_dt,
          this.state.top_end_dt
        ).then((histogramData) => {
          console.log('check_forecast')
          this.setState({
            topHistogramDataAlertLog: histogramData,
            topHistogramDataInitAlertLog: histogramData,
            isTopModeCheckForecast: true
          })
        })
        this.handleOnReadTopExcretionForecast()
        break
      default:
        break
    }
  }

  updateTopHistogramDataFromReadButton() {
    getHistogramData(
      /* eslint-disable-next-line */
      this.props.match.params.id,
      modeAlertLog,
      this.state.top_start_dt,
      this.state.top_end_dt
    ).then((histogramData) => {
      this.setState({
        topHistogramDataAlertLog: histogramData,
        topHistogramDataInitAlertLog: histogramData
      })
    })
  }

  async updateBottomHistogramData() {
    switch (this.state.mode_bottom) {
      case 'alert_log':
        getHistogramData(
          /* eslint-disable-next-line */
          this.props.match.params.id,
          modeAlertLog,
          this.state.bottom_start_dt,
          this.state.bottom_end_dt
        ).then((histogramData) => {
          // 計算後に期間を変更した場合は同条件で再計算する
          if (_.size(this.state.bottomShowExchangeMarkForJSX)) {
            this.handleCalculateBottomHistogram(_.cloneDeep(histogramData))
          }

          this.setState({
            bottomShowExchangeMarkForJSX: {},
            bottomHistogramDataAlertLog: histogramData,
            bottomHistogramDataInitAlertLog: histogramData,
            isBottomModeCheckForecast: false
          })
        })
        break
      case 'excretion_result':
        getHistogramData(
          /* eslint-disable-next-line */
          this.props.match.params.id,
          modeExcretionResult,
          this.state.bottom_start_dt,
          this.state.bottom_end_dt
        ).then((histogramData) => {
          this.setState({
            bottomHistogramDataExcretionResult: histogramData,
            bottomHistogramDataInitExcretionResult: histogramData,
            bottomIsShowCalcForm: false,
            isBottomModeCheckForecast: false
          })
        })
        break

      case 'check_forecast':
        await getHistogramData(
          /* eslint-disable-next-line */
          this.props.match.params.id,
          modeAlertLog,
          this.state.bottom_start_dt,
          this.state.bottom_end_dt
        ).then((histogramData) => {
          console.log('check_forecast')
          this.setState({
            bottomHistogramDataAlertLog: histogramData,
            bottomHistogramDataInitAlertLog: histogramData,
            isBottomModeCheckForecast: true
          })
        })
        this.handleOnReadBottomExcretionForecast()
        break
      default:
        break
    }
  }

  updateBottomHistogramDataFromReadButton() {
    getHistogramData(
      /* eslint-disable-next-line */
      this.props.match.params.id,
      modeAlertLog,
      this.state.bottom_start_dt,
      this.state.bottom_end_dt
    ).then((histogramData) => {
      this.setState({
        bottomHistogramDataAlertLog: histogramData,
        bottomHistogramDataInitAlertLog: histogramData
      })
    })
  }

  componentWillUnmount() {
    alertStore.removeAllListeners('alert_all')
    carePeopleStore.removeAllListeners('person_detail')
    carePeopleStore.removeAllListeners('person_edit')
    carePeopleStore.removeAllListeners('person_delete')
    excretionForecastStore.removeAllListeners('excretion_forecast_detail')
    excretionForecastStore.removeAllListeners('excretion_forecast_add')
    excretionForecastStore.removeAllListeners('excretion_forecast_edit')
    excretionForecastStore.removeAllListeners('excretion_forecast_error')
  }

  handleSubmit(e) {
    if (e.formData.description == null) {
      e.formData.description = ''
    }

    if (e.formData.description.toString().length <= 1000) {
      /* eslint-disable-next-line */
      CarePeopleAction.editPerson(e.formData, this.props.match.params.id)
      this.setState({
        loading: true
      })
    }
  }

  // ダイアログ内のハンドリング
  handleDeleteDialogClick(e) {
    const [locale] = this.context
    const uiWords = localizedUiWords(locale.lang).components.appDialog
    switch (e) {
      case uiWords.yesButtonLabel:
        /* eslint-disable-next-line */
        CarePeopleAction.deletePerson(this.props.match.params.id)
        this.setState({
          deleteDialog: false,
          loading: true
        })
        break
      case uiWords.noButtonLabel:
        this.setState({
          deleteDialog: false
        })
        break
      default:
        break
    }
  }

  // ダイアログクローズ時のハンドリング
  handleDeleteDialogClosing() {
    this.setState({
      deleteDialog: false
    })
  }

  handleChange(e) {
    if (e.formData.description == null) {
      e.formData.description = ''
    }

    this.setState({
      description: e.formData.description
    })
  }

  handleOnChangeForTopHistogram(e) {
    this.setState(
      {
        top_start_dt: e.start,
        top_end_dt: e.end
      },
      () => {
        this.updateTopHistogramData()
      }
    )
  }

  /* eslint-disable-next-line */
  handleOnReadTopHistogram(e) {
    if (!this.state.excretionForecastDetail) return
    const start = this.state.excretionForecastDetail.calc_start_date
    const end = this.state.excretionForecastDetail.calc_end_date
    this.setState(
      {
        top_start_dt: start,
        top_end_dt: end
      },
      () => {
        this.updateTopHistogramDataFromReadButton()
      }
    )
  }

  handleOnChangeForBottomHistogram(e) {
    this.setState(
      {
        bottom_start_dt: e.start,
        bottom_end_dt: e.end
      },
      () => {
        this.updateBottomHistogramData()
      }
    )
  }

  /* eslint-disable-next-line */
  handleOnReadBottomHistogram(e) {
    if (!this.state.excretionForecastDetail) return
    const start = this.state.excretionForecastDetail.calc_start_date
    const end = this.state.excretionForecastDetail.calc_end_date
    this.setState(
      {
        bottom_start_dt: start,
        bottom_end_dt: end
      },
      () => {
        this.updateBottomHistogramDataFromReadButton()
      }
    )
  }

  handleOnChangeForTopMode(e) {
    this.setState(
      {
        mode_top: e.target.value
      },
      () => {
        this.updateTopHistogramData()
      }
    )
  }

  handleOnChangeForBottomMode(e) {
    this.setState(
      {
        mode_bottom: e.target.value
      },
      () => {
        this.updateBottomHistogramData()
      }
    )
  }

  handleChangeTopStartValue(e) {
    const val = e.target.value
    this.setState({
      topStart: parseInt(val)
    })
  }

  handleChangeTopEndValue(e) {
    const val = e.target.value
    this.setState({
      topEnd: parseInt(val)
    })
  }

  handleChangeTopExchangeCount(e) {
    const val = e.target.value
    this.setState({
      topExchangeCount: parseInt(val)
    })
  }

  handleChangeTopDoExchangeWhenStart(e) {
    const val = e.target.checked
    const isEnableStartAndEnd = val && this.state.topDoExchangeWhenEnd

    this.setState({
      topDoExchangeWhenStart: val && true,
      topIsEnableStartAndEnd: isEnableStartAndEnd,
      topExchangeCount:
        isEnableStartAndEnd && this.state.topExchangeCount === 1
          ? 2
          : this.state.topExchangeCount
    })
  }

  handleChangeTopDoExchangeWhenEnd(e) {
    const val = e.target.checked
    const isEnableStartAndEnd = val && this.state.topDoExchangeWhenStart

    this.setState({
      topDoExchangeWhenEnd: val && true,
      topIsEnableStartAndEnd: isEnableStartAndEnd,
      topExchangeCount:
        isEnableStartAndEnd && this.state.topExchangeCount === 1
          ? 2
          : this.state.topExchangeCount
    })
  }

  handleChangeBottomStartValue(e) {
    const val = e.target.value
    this.setState({
      bottomStart: parseInt(val)
    })
  }

  handleChangeBottomEndValue(e) {
    const val = e.target.value
    this.setState({
      bottomEnd: parseInt(val)
    })
  }

  handleChangeBottomExchangeCount(e) {
    const val = e.target.value
    this.setState({
      bottomExchangeCount: parseInt(val)
    })
  }

  handleChangeBottomDoExchangeWhenStart(e) {
    const val = e.target.checked
    const isEnableStartAndEnd = val && this.state.bottomDoExchangeWhenEnd

    this.setState({
      bottomDoExchangeWhenStart: val && true,
      bottomIsEnableStartAndEnd: isEnableStartAndEnd,
      bottomExchangeCount:
        isEnableStartAndEnd && this.state.bottomExchangeCount === 1
          ? 2
          : this.state.bottomExchangeCount
    })
  }

  handleChangeBottomDoExchangeWhenEnd(e) {
    const val = e.target.checked
    const isEnableStartAndEnd = val && this.state.bottomDoExchangeWhenStart

    this.setState({
      bottomDoExchangeWhenEnd: val && true,
      bottomIsEnableStartAndEnd: isEnableStartAndEnd,
      bottomExchangeCount:
        isEnableStartAndEnd && this.state.bottomExchangeCount === 1
          ? 2
          : this.state.bottomExchangeCount
    })
  }

  // ヒストグラム上側のボタンハンドラ
  handleCalculateTopHistogram(e) {
    const o = {
      start: this.state.topStart, // 始点
      end: this.state.topEnd, // 終点
      exchangeCount: this.state.topExchangeCount, // 交換回数
      doExchangeWhenStart: this.state.topDoExchangeWhenStart, // 開始時に交換するか
      doExchangeWhenEnd: this.state.topDoExchangeWhenEnd, // 終了時に交換するか
      data: e.target ? _.cloneDeep(this.state.topHistogramDataAlertLog) : e // ヒストグラムデータ
    }
    const result = this.executeCalculations(o)
    this.setState({
      topShowExchangeMarkForJSX: result,
      topDisabled: false
    })
  }

  // ヒストグラム上側の排泄予報読み込みハンドラ
  handleOnReadTopExcretionForecast(e) {
    const o = {
      start: this.state.excretionForecastDetail.start_hour, // 始点
      end: this.state.excretionForecastDetail.end_hour, // 終点
      exchangeCount: this.state.excretionForecastDetail.exchange_count, // 交換回数
      doExchangeWhenStart:
        this.state.excretionForecastDetail.do_exchange_when_start, // 開始時に交換するか
      doExchangeWhenEnd:
        this.state.excretionForecastDetail.do_exchange_when_end, // 終了時に交換するか
      timings: this.state.excretionForecastDetail.timings // 交換タイミング
    }
    this.handleOnReadTopHistogram(e)

    const result = this.generateExcretionForecast(o)
    this.setState({
      topShowExchangeMarkForJSX: result,
      topStart: o.start,
      topEnd: o.end,
      topDoExchangeWhenStart: o.doExchangeWhenStart,
      topDoExchangeWhenEnd: o.doExchangeWhenEnd,
      topExchangeCount:
        o.doExchangeWhenStart && o.doExchangeWhenEnd && o.exchangeCount === 1
          ? 2
          : o.exchangeCount,
      topIsEnableStartAndEnd: o.doExchangeWhenStart && o.doExchangeWhenEnd
    })
  }

  // ヒストグラム下側のボタンハンドラ
  handleCalculateBottomHistogram(e) {
    const o = {
      start: this.state.bottomStart, // 始点
      end: this.state.bottomEnd, // 終点
      exchangeCount: this.state.bottomExchangeCount, // 交換回数
      doExchangeWhenStart: this.state.bottomDoExchangeWhenStart, // 開始時に交換するか
      doExchangeWhenEnd: this.state.bottomDoExchangeWhenEnd, // 終了時に交換するか
      data: e.target ? _.cloneDeep(this.state.bottomHistogramDataAlertLog) : e // ヒストグラムデータ
    }
    const result = this.executeCalculations(o)
    this.setState({
      bottomShowExchangeMarkForJSX: result,
      bottomDisabled: false
    })
  }

  // ヒストグラム下側の排泄予報読み込みハンドラ
  handleOnReadBottomExcretionForecast(e) {
    const o = {
      start: this.state.excretionForecastDetail.start_hour, // 始点
      end: this.state.excretionForecastDetail.end_hour, // 終点
      exchangeCount: this.state.excretionForecastDetail.exchange_count, // 交換回数
      doExchangeWhenStart:
        this.state.excretionForecastDetail.do_exchange_when_start, // 開始時に交換するか
      doExchangeWhenEnd:
        this.state.excretionForecastDetail.do_exchange_when_end, // 終了時に交換するか
      timings: this.state.excretionForecastDetail.timings // 交換タイミング
    }
    this.handleOnReadBottomHistogram(e)

    const result = this.generateExcretionForecast(o)
    this.setState({
      bottomShowExchangeMarkForJSX: result,
      bottomStart: o.start,
      bottomEnd: o.end,
      bottomDoExchangeWhenStart: o.doExchangeWhenStart,
      bottomDoExchangeWhenEnd: o.doExchangeWhenEnd,
      bottomExchangeCount:
        o.doExchangeWhenStart && o.doExchangeWhenEnd && o.exchangeCount === 1
          ? 2
          : o.exchangeCount,
      bottomIsEnableStartAndEnd: o.doExchangeWhenStart && o.doExchangeWhenEnd
    })
  }

  // 計算処理
  executeCalculations(o) {
    // データのフィルタリング1 (始点から終点のデータ)
    const startToEndData = this.getDataFromStartToEnd(
      o.data,
      parseInt(o.start),
      parseInt(o.end)
    )
    // 閾値取得
    const threshold = this.calcThreshold(
      o.exchangeCount,
      startToEndData,
      o.doExchangeWhenStart,
      o.doExchangeWhenEnd
    )
    // データのフィルタリング2 (閾値を上回るデータ)
    const exceedData = this.compareDataWithThreshold(startToEndData, threshold)
    // jsx用のオブジェクトを生成する
    const result = this.generateObjectForJSX(
      startToEndData,
      exceedData,
      o.exchangeCount,
      o.doExchangeWhenStart,
      o.doExchangeWhenEnd
    )
    return result
  }

  // DBからのデータを扱えるように処理する
  generateExcretionForecast(o) {
    const mark = {
      start: { x: null },
      end: { x: null },
      exchange: []
    }

    mark.start.x = o.start + ':00'
    mark.end.x = o.end + ':00'
    _.forEach(
      o.timings.indexOf(',') !== -1 ? o.timings.split(',') : [o.timings],
      (v) => {
        mark.exchange.push({ x: zeroPad(v, 2) + ':00' })
      }
    )
    return mark
  }

  // 始点から終点までのデータを返す @return array
  getDataFromStartToEnd(data, start, end) {
    const filteringData = []
    const s = start
    let e = end
    const startGreaterThenEnd = s > e
    let idx = 0
    const hoursDay = 24

    // 始点 > 終点の場合、終点に24を足す
    e = startGreaterThenEnd ? e + hoursDay : e

    _.forEach(data, (v) => {
      const strHour = v.x
      let hour = parseInt(strHour.substring(0, 2))

      // 始点、終点のデータにフラグを立てる
      if (s === hour) v.start = true
      if (e === hour || e - hoursDay === hour) v.end = true
      // 始点 > 終点の場合、12時未満の数値に24を足す
      hour =
        startGreaterThenEnd && hour <= e - hoursDay ? hour + hoursDay : hour
      if (s <= hour && hour <= e) {
        // index
        v.index = idx
        filteringData.push(v)

        idx++
      }
    })

    // 始点 > 終点の場合、配列の順序を整える
    if (startGreaterThenEnd) {
      // 始点を配列の先頭へ
      filteringData.splice(
        0,
        0,
        filteringData.splice(_.filter(filteringData, 'start')[0].index, 1)[0]
      )
      // 終点を配列の末尾へ
      filteringData.splice(
        filteringData.length,
        0,
        filteringData.splice(_.filter(filteringData, 'end')[0].index + 1, 1)[0]
      )

      /* eslint-disable-next-line */
      filteringData.sort((a, b) => {
        // 始点、終点の比較はしない
        if (a.start || a.end) return 0
        if (b.start || b.end) return 0

        const strHourA = a.x
        const strHourB = b.x
        let hourA = parseInt(strHourA.substring(0, 2))
        let hourB = parseInt(strHourB.substring(0, 2))
        // 12時未満の数値に24を足す
        hourA = hourA <= e - hoursDay ? hourA + hoursDay : hourA
        // 12時未満の数値に24を足す
        hourB = hourB <= e - hoursDay ? hourB + hoursDay : hourB

        if (hourA > hourB) return 1
        else if (hourB > hourA) return -1
      })
    }
    return filteringData
  }

  // 閾値を返す
  calcThreshold(exchangeCount, data, doExchangeWhenStart, doExchangeWhenEnd) {
    let threshold = 0
    const ecnt = exchangeCount
    let sum = 0
    const des = doExchangeWhenStart & true
    const dee = doExchangeWhenEnd & true

    _.forEach(data, (v) => {
      sum += v['件数']
    })

    threshold = sum / (ecnt - (des + dee) + 1)

    return threshold
  }

  // 閾値を上回るデータを返す。 交換回数以上のデータ数にはしない。 @return array
  compareDataWithThreshold(data, threshold) {
    const filteringData = []
    let dtsum = 0
    _.forEach(data, (v) => {
      // データのない時刻は対象としない
      if (v['件数'] === 0) return
      dtsum += v['件数']

      // 始点、終点のデータは比較しない。
      if (v.start || v.end) return

      if (dtsum >= threshold) {
        filteringData.push(v)
        dtsum -= threshold
      }
    })
    return filteringData
  }

  // JSX生成用データを返す
  generateObjectForJSX(
    startToEndData,
    exceedData,
    exchangeCount,
    doExchangeWhenStart,
    doExchangeWhenEnd
  ) {
    const mark = {
      start: null,
      end: null,
      exchange: []
    }

    _.forEach(startToEndData, (v) => {
      // 始点、終点マーク
      if (v.start) {
        mark.start = v
        if (doExchangeWhenStart) {
          mark.exchange.push(v)
          exchangeCount -= 1
        }
      }
      if (v.end) {
        mark.end = v
        if (doExchangeWhenEnd) {
          mark.exchange.push(v)
          exchangeCount -= 1
        }
      }
    })

    _.forEach(exceedData, (v, i) => {
      if (i >= exchangeCount) return
      mark.exchange.push(v)
    })
    return mark
  }

  removeUser() {
    this.setState({
      deleteDialog: true
    })
  }

  histogramModeOptions() {
    const [locale] = this.context
    const uiWords = localizedUiWords(locale.lang).pages.userDetail

    const result = [
      { name: 'alert_log', display: uiWords.histogramAlertLogModeOption },
      {
        name: 'excretion_result',
        display: uiWords.histogramExcretionResultModeOption
      },
      {
        name: 'check_forecast',
        display: uiWords.histogramCheckForecastModeOption,
        disabled: !this.state.excretionForecastDetail
      }
    ].map((m) => {
      return (
        <option value={m.name} key={m.name} disabled={m.disabled}>
          {m.display}
        </option>
      )
    })
    return result
  }

  topSwitchByMode() {
    const switchVisible = document.getElementsByName('excretionResultTop')
    switch (this.state.mode_top) {
      case 'check_forecast':
        return (
          <AlertHistogram
            mode="alert_log"
            data={this.state.topHistogramDataAlertLog}
            switchVisible={switchVisible}
            xKey="x"
            yKey="count"
            height={300}
          />
        )
      case 'alert_log':
        return (
          <AlertHistogram
            mode="alert_log"
            data={this.state.topHistogramDataAlertLog}
            switchVisible={switchVisible}
            xKey="x"
            yKey="count"
            height={300}
          />
        )
      case 'excretion_result':
        return (
          <AlertHistogram
            mode="excretion_result"
            data={this.state.topHistogramDataExcretionResult}
            switchVisible={switchVisible}
            xKey="x"
            yKey="count"
            height={300}
          />
        )
      default:
        break
    }
  }

  bottomSwitchByMode() {
    const switchVisible = document.getElementsByName('excretionResultBottom')
    switch (this.state.mode_bottom) {
      case 'check_forecast':
        return (
          <AlertHistogram
            mode="alert_log"
            data={this.state.bottomHistogramDataAlertLog}
            switchVisible={switchVisible}
            xKey="x"
            yKey="count"
            height={300}
          />
        )
      case 'alert_log':
        return (
          <AlertHistogram
            mode="alert_log"
            data={this.state.bottomHistogramDataAlertLog}
            switchVisible={switchVisible}
            xKey="x"
            yKey="count"
            height={300}
          />
        )
      case 'excretion_result':
        return (
          <AlertHistogram
            mode="excretion_result"
            data={this.state.bottomHistogramDataExcretionResult}
            switchVisible={switchVisible}
            xKey="x"
            yKey="count"
            height={300}
          />
        )
      default:
        break
    }
  }

  showExchangeMark() {
    const ret = {}
    // 上側
    if (['alert_log', 'check_forecast'].includes(this.state.mode_top)) {
      if (this.state.topShowExchangeMarkForJSX.exchange) {
        ret.top = this.generateMarkJSX(this.state.topShowExchangeMarkForJSX)
      }
    }

    if (['alert_log', 'check_forecast'].includes(this.state.mode_bottom)) {
      if (this.state.bottomShowExchangeMarkForJSX.exchange) {
        ret.bottom = this.generateMarkJSX(
          this.state.bottomShowExchangeMarkForJSX
        )
      }
    }

    return ret
  }

  generateMarkJSX(data) {
    // 始点マーク
    const start = (
      <div
        className="right-triangle"
        style={{ marginLeft: marginValues[data.start.x] + 'px' }}
      ></div>
    )
    // 終点マーク
    const end = (
      <div
        className="left-triangle"
        style={{ marginLeft: marginValues[data.end.x] + 'px' }}
      ></div>
    )
    // 交換マーク
    const exchange = _.map(data.exchange, (v, k) => {
      return (
        <div
          className="circle"
          style={{ marginLeft: marginValues[v.x] + 'px' }}
          key={k}
        ></div>
      )
    })
    return { start, end, exchange }
  }

  /* eslint-disable-next-line */
  handleClickShowTopCalcForm(e) {
    this.setState({
      topIsShowCalcForm: !this.state.topIsShowCalcForm
    })
  }

  /* eslint-disable-next-line */
  handleClickShowBottomCalcForm(e) {
    this.setState({
      bottomIsShowCalcForm: !this.state.bottomIsShowCalcForm
    })
  }

  formater(date) {
    // input: data型
    // output: format形式の文字列
    let format = 'yyyy-MM-dd'
    format = format.replace(/yyyy/g, date.getFullYear())
    format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2))
    format = format.replace(/dd/g, ('0' + date.getDate()).slice(-2))
    return format
  }

  slideDate(date, diff = 7) {
    // output: dateからdiffずれた日付の文字列
    date.setDate(date.getDate() + diff)
    date = this.formater(date)
    return date
  }

  checkDate(start, end) {
    let error = null
    if (moment(end).isBefore(start)) {
      // 開始日、終了日が逆転
      error = '無効な期間です。再度入力してください。'
    } else if (moment(start).isBefore(moment(end).subtract(5, 'years'))) {
      error = '期間が5年を超えています。再度入力してください。'
    } else if (moment().startOf('day').isBefore(end)) {
      // 終了日が未来
      error = '未来の日付は検索できません。'
    } else {
      error = null
    }
    return error
  }

  /* eslint-disable-next-line */
  handleClickSlideWeek(position, slideTo, e) {
    const diff = slideTo === 'before' ? -7 : 7
    const startDt = this.slideDate(
      position === 'top'
        ? new Date(this.state.top_start_dt)
        : new Date(this.state.bottom_start_dt),
      diff
    )
    const endDt = this.slideDate(
      position === 'top'
        ? new Date(this.state.top_end_dt)
        : new Date(this.state.bottom_end_dt),
      diff
    )
    const error = this.checkDate(startDt, endDt)

    switch (position) {
      case 'top':
        if (error !== null) {
          this.setState({
            errorTopDate: error
          })
          break
        }
        this.setState(
          {
            top_start_dt: startDt,
            top_end_dt: endDt,
            errorTopDate: error
          },
          () => {
            this.updateTopHistogramData()
          }
        )
        break
      case 'bottom':
        if (error !== null) {
          this.setState({
            errorBottomDate: error
          })
          break
        }
        this.setState(
          {
            bottom_start_dt: startDt,
            bottom_end_dt: endDt,
            errorBottomDate: error
          },
          () => {
            this.updateBottomHistogramData()
          }
        )
        break
    }
  }

  /* eslint-disable-next-line */
  handleClickSyncAnotherDate(position, e) {
    switch (position) {
      case 'top': {
        const bottomStartDt = this.state.bottom_start_dt
        const bottomEndDt = this.state.bottom_end_dt
        this.setState(
          {
            top_start_dt: bottomStartDt,
            top_end_dt: bottomEndDt,
            errorTopDate: false
          },
          () => {
            this.updateTopHistogramData()
          }
        )
        break
      }

      case 'bottom': {
        const topStartDt = this.state.top_start_dt
        const topEndDt = this.state.top_end_dt
        this.setState(
          {
            bottom_start_dt: topStartDt,
            bottom_end_dt: topEndDt,
            errorBottomDate: false
          },
          () => {
            this.updateBottomHistogramData()
          }
        )
        break
      }
    }
  }

  /* eslint-disable-next-line */
  handleTopSubmitExcretionForecast(e) {
    /*
    {
      "subject_id": 1,
      "start_hour": 23,
      "end_hour": 6,
      "calc_start_date": "2020-06-01",
      "calc_end_date": "2020-08-01",
      "do_exchange_when_start": true,
      "do_exchange_when_end": true,
      "exchange_count": 2,
      "timings": "1,15"
    }
    */

    if (this.state.personDetail === null) return

    let timings = ''
    _.forEach(this.state.topShowExchangeMarkForJSX.exchange, (v) => {
      const timing = parseInt(v.x.substring(0, 2))

      timings += timings === '' ? timing : ',' + timing
    })

    const params = {
      subject_id: this.state.personDetail.id,
      start_hour: this.state.topStart,
      end_hour: this.state.topEnd,
      calc_start_date: this.state.top_start_dt,
      calc_end_date: this.state.top_end_dt,
      do_exchange_when_start: this.state.topDoExchangeWhenStart,
      do_exchange_when_end: this.state.topDoExchangeWhenEnd,
      exchange_count: this.state.topExchangeCount,
      timings
    }

    if (this.state.excretionForecastDetail) {
      delete params.subject_id
      ExcretionForecastAction.editExcretionForecast(
        params,
        this.state.excretionForecastDetail.id
      )
    } else {
      ExcretionForecastAction.addExcretionForecast(params)
    }
  }

  /* eslint-disable-next-line */
  handleBottomSubmitExcretionForecast(e) {
    if (this.state.personDetail === null) return

    let timings = ''
    _.forEach(this.state.bottomShowExchangeMarkForJSX.exchange, (v) => {
      const timing = parseInt(v.x.substring(0, 2))

      timings += timings === '' ? timing : ',' + timing
    })

    const params = {
      subject_id: this.state.personDetail.id,
      start_hour: this.state.bottomStart,
      end_hour: this.state.bottomEnd,
      calc_start_date: this.state.bottom_start_dt,
      calc_end_date: this.state.bottom_end_dt,
      do_exchange_when_start: this.state.bottomDoExchangeWhenStart,
      do_exchange_when_end: this.state.bottomDoExchangeWhenEnd,
      exchange_count: this.state.bottomExchangeCount,
      timings
    }

    if (this.state.excretionForecastDetail) {
      delete params.subject_id
      ExcretionForecastAction.editExcretionForecast(
        params,
        this.state.excretionForecastDetail.id
      )
    } else {
      ExcretionForecastAction.addExcretionForecast(params)
    }
  }

  /* eslint-disable-next-line */
  handleNoticeDialogClosing(e) {
    this.setState({
      noticeDialog: false
    })
  }

  /* eslint-disable-next-line */
  handleErrorDialogClosing(e) {
    this.setState({
      errorDialog: false
    })
  }

  render() {
    const [locale] = this.context
    const uiWords = localizedUiWords(locale.lang).pages.userDetail
    const appDialogUiWords = localizedUiWords(locale.lang).components.appDialog

    // フォームの設定
    const schema = {
      type: 'object',
      properties: {
        description: { type: 'string', title: uiWords.noteTitle }
      }
    }
    const uiSchema = {
      description: { 'ui:widget': 'textarea' }
    }
    let formData = {}
    let lenChart = 0

    // ユーザー編集ならデータを代入する
    if (this.state.description) {
      formData = {
        description: this.state.description || ''
      }
      lenChart = this.state.description.toString().length
    }

    const chartMsg = () => {
      if (lenChart > 1000) {
        return (
          <div className="error" style={{ textAlign: 'right' }}>
            1000文字以内で入力してください {lenChart}/1000
          </div>
        )
      } else {
        return <div style={{ textAlign: 'right' }}>{lenChart}/1000</div>
      }
    }

    // TODO: 排泄傾向グラフの凡例を上下で共通のコンポーネントにできないか検討
    // 排泄傾向グラフの凡例用のチェックボックス (上)
    let checkboxFieldTop
    if (this.state.mode_top === 'excretion_result') {
      checkboxFieldTop = (
        <div className="switchVisibleFieldTop">
          <fieldset name="switchVisible">
            <div className="switchSet bgcolor-blue">
              <input
                type="checkbox"
                name="excretionResultTop"
                id="urineTop"
                className="excretionDataLabel"
                value="尿"
                onChange={this.updateTopHistogramData.bind(this)}
                defaultChecked
              />
              <label htmlFor="urineTop">{uiWords.histogramUrineLegend}</label>
            </div>
            <div className="switchSet bgcolor-border">
              <input
                type="checkbox"
                name="excretionResultTop"
                id="urineAndFecesTop"
                className="excretionDataLabel"
                value="尿＆便"
                onChange={this.updateTopHistogramData.bind(this)}
                defaultChecked
              />
              <label htmlFor="urineAndFecesTop">
                {uiWords.histogramUrineAndFecesLegend}
              </label>
            </div>
            <div className="switchSet bgcolor-brown">
              <input
                type="checkbox"
                name="excretionResultTop"
                id="fecesTop"
                className="excretionDataLabel"
                value="便"
                onChange={this.updateTopHistogramData.bind(this)}
                defaultChecked
              />
              <label htmlFor="fecesTop">{uiWords.histogramFecesLegend}</label>
            </div>
            <div className="switchSet bgcolor-gray">
              <input
                type="checkbox"
                name="excretionResultTop"
                id="noneTop"
                className="excretionDataLabel"
                value="なし"
                onChange={this.updateTopHistogramData.bind(this)}
                defaultChecked
              />
              <label htmlFor="noneTop">{uiWords.histogramNoneLegend}</label>
            </div>
          </fieldset>
        </div>
      )
    }

    // 排泄傾向グラフの凡例用のチェックボックス (下)
    let checkboxFieldBottom
    if (this.state.mode_bottom === 'excretion_result') {
      checkboxFieldBottom = (
        <div className="switchVisibleFieldBottom">
          <fieldset name="switchVisible">
            <div className="switchSet bgcolor-blue">
              <input
                type="checkbox"
                name="excretionResultBottom"
                id="urineBottom"
                className="excretionDataLabel"
                value="尿"
                onChange={this.updateBottomHistogramData.bind(this)}
                defaultChecked
              />
              <label htmlFor="urineBottom">
                {uiWords.histogramUrineLegend}
              </label>
            </div>
            <div className="switchSet bgcolor-border">
              <input
                type="checkbox"
                name="excretionResultBottom"
                id="urineAndFecesBottom"
                className="excretionDataLabel"
                value="尿＆便"
                onChange={this.updateBottomHistogramData.bind(this)}
                defaultChecked
              />
              <label htmlFor="urineAndFecesBottom">
                {uiWords.histogramUrineAndFecesLegend}
              </label>
            </div>
            <div className="switchSet bgcolor-brown">
              <input
                type="checkbox"
                name="excretionResultBottom"
                id="fecesBottom"
                className="excretionDataLabel"
                value="便"
                onChange={this.updateBottomHistogramData.bind(this)}
                defaultChecked
              />
              <label htmlFor="fecesBottom">
                {uiWords.histogramFecesLegend}
              </label>
            </div>
            <div className="switchSet bgcolor-gray">
              <input
                type="checkbox"
                name="excretionResultBottom"
                id="noneBottom"
                className="excretionDataLabel"
                value="なし"
                onChange={this.updateBottomHistogramData.bind(this)}
                defaultChecked
              />
              <label htmlFor="noneBottom">{uiWords.histogramNoneLegend}</label>
            </div>
          </fieldset>
        </div>
      )
    }

    // 始点、終点、交換マークの準備
    const jsx = this.showExchangeMark()

    return (
      <div id="userDetail">
        <AppHeader />
        <div className="flex">
          <div className="main-content flex space-between">
            <div id="user-data">
              <div className="flex space-between mb20">
                <p className="title">{uiWords.title}</p>
                <div className="flex">
                  <Link
                    className="editBtn userDataBtn flex align-center"
                    /* eslint-disable-next-line */
                    to={`/user/edit/${this.props.match.params.id}`}
                  >
                    <div className="icon icon-icons_edit_2"></div>
                    <p>{uiWords.editButtonLabel}</p>
                  </Link>
                  <div
                    onClick={this.removeUser.bind(this)}
                    className="removeBtn userDataBtn flex align-center"
                  >
                    <div className="icon icon-icons_delete"></div>
                    <p>{uiWords.removeButtonLabel}</p>
                  </div>
                </div>
              </div>

              {/* TODO: ユーザー情報表示テーブルコンポーネントとして分離 */}
              <div id="user-data-table" className="mb30">
                <UserDetailTable personDetail={this.state.personDetail} />
              </div>
              <div className="input-field">
                <div className="flex align-center mb10">
                  <p className="title">{uiWords.excretionTrendGraphHeading}</p>
                  <p className="sort-comment">
                    {uiWords.excretionTrendGraphAnnotation}
                  </p>
                </div>
                {/* TODO: コンポーネント化}
                {/* 排泄傾向グラフモードの Dropdown リスト */}
                {uiWords.modeDropdownLabel}
                <div className="hpad-pulldown-1">
                  <select
                    className="histogram-mode"
                    defaultValue="alert_log"
                    onChange={this.handleOnChangeForTopMode.bind(this)}
                  >
                    {this.histogramModeOptions()}
                  </select>
                </div>
                <HistogramPeriodSelector
                  onChange={this.handleOnChangeForTopHistogram}
                  duration={{
                    start: this.state.top_start_dt,
                    end: this.state.top_end_dt
                  }}
                  isDisabled={this.state.isTopModeCheckForecast}
                />
                <button
                  type="button"
                  className="btn btn-info"
                  disabled={this.state.isTopModeCheckForecast}
                  onClick={(e) => this.handleClickSlideWeek('top', 'before', e)}
                >
                  {uiWords.lastWeekButtonLabel}
                </button>
                <button
                  type="button"
                  className="btn btn-info"
                  disabled={this.state.isTopModeCheckForecast}
                  onClick={(e) => this.handleClickSyncAnotherDate('top', e)}
                >
                  {uiWords.syncPeriodBelowButtonLabel}
                </button>
                <button
                  type="button"
                  className="btn btn-info"
                  disabled={this.state.isTopModeCheckForecast}
                  onClick={(e) => this.handleClickSlideWeek('top', 'after', e)}
                >
                  {uiWords.nextWeekButtonLabel}
                </button>
                {this.state.errorTopDate && (
                  <p className="duration-error">{this.state.errorTopDate}</p>
                )}
                {/* TODO: ↑ここまでのヒストグラムの計算期間の操作用ボタンも HistgramPeriodSelector に含まられないか検討 */}

                {/* TODO: 排泄予報設定コンポーネントとして切り出す */}
                <div className="calcForm">
                  <div
                    onClick={this.handleClickShowTopCalcForm}
                    className={
                      ['alert_log', 'check_forecast'].includes(
                        this.state.mode_top
                      )
                        ? 'clickableElem'
                        : 'clickableElem hide'
                    }
                  >
                    <div className="calcTextBg"></div>
                    <span className="calcText">
                      {this.state.topIsShowCalcForm ? '▼' : '▶'}
                    </span>
                    <span
                      style={{
                        position: 'absolute',
                        marginTop: '5px',
                        marginLeft: '30px'
                      }}
                    >
                      {uiWords.forecastSettingsToggleLabel}
                    </span>
                  </div>
                  <div
                    className={
                      this.state.topIsShowCalcForm
                        ? 'calcTable'
                        : 'calcTable hide-animation'
                    }
                  >
                    <div className="calcRow">
                      <div className="calcCell"></div>
                      <div className="calcCell"></div>
                      <div className="calcCell"></div>
                    </div>
                    <div className="calcRow">
                      <div className="calcCell text-right">
                        {uiWords.forecastStartTimeLabel}
                      </div>
                      <div className="calcCell pulldown">
                        <select
                          disabled={this.state.isTopModeCheckForecast}
                          onChange={this.handleChangeTopStartValue}
                          value={zeroPad(this.state.topStart, 2)}
                        >
                          {[...Array(24)].map((_, index) => (
                            <option key={index}>
                              {String(index).padStart(2, '0')}
                            </option>
                          ))}
                        </select>
                        {uiWords.timeUnitLabel}
                      </div>
                      <div className="calcCell pl35">
                        <input
                          type="checkbox"
                          id="topDoExchangeWhenStart"
                          disabled={this.state.isTopModeCheckForecast}
                          onChange={this.handleChangeTopDoExchangeWhenStart}
                          style={{ cursor: 'pointer' }}
                          checked={this.state.topDoExchangeWhenStart}
                        />
                        <label
                          htmlFor="topDoExchangeWhenStart"
                          style={{ cursor: 'pointer' }}
                        >
                          {uiWords.diaperChangesAtStartCheckboxLabel}
                        </label>
                      </div>
                    </div>
                    <div className="calcRow">
                      <div className="calcCell text-right">
                        {uiWords.forecastEndTimeLabel}
                      </div>
                      <div className="calcCell pulldown">
                        <select
                          disabled={this.state.isTopModeCheckForecast}
                          onChange={this.handleChangeTopEndValue}
                          value={zeroPad(this.state.topEnd, 2)}
                        >
                          {[...Array(24)].map((_, index) => (
                            <option key={index}>
                              {String(index).padStart(2, '0')}
                            </option>
                          ))}
                        </select>
                        {uiWords.timeUnitLabel}
                      </div>
                      <div className="calcCell pl35">
                        <input
                          type="checkbox"
                          id="topDoExchangeWhenEnd"
                          disabled={this.state.isTopModeCheckForecast}
                          onChange={this.handleChangeTopDoExchangeWhenEnd}
                          style={{ cursor: 'pointer' }}
                          checked={this.state.topDoExchangeWhenEnd}
                        />
                        <label
                          htmlFor="topDoExchangeWhenEnd"
                          style={{ cursor: 'pointer' }}
                        >
                          {uiWords.diaperChangesAtEndCheckboxLabel}
                        </label>
                      </div>
                    </div>
                    <div className="calcRow">
                      <div className="calcCell text-right">
                        {uiWords.diaperChangesThresholdLabel}
                      </div>
                      <div className="calcCell pulldown">
                        <select
                          value={this.state.topExchangeCount}
                          disabled={this.state.isTopModeCheckForecast}
                          onChange={this.handleChangeTopExchangeCount}
                        >
                          {[...Array(10)].map((_, index) =>
                            index + 1 === 1 ? (
                              <option
                                key={index + 1}
                                hidden={this.state.topIsEnableStartAndEnd}
                              >
                                {String(index + 1).padStart(2, '0')}
                              </option>
                            ) : (
                              <option key={index + 1}>
                                {String(index + 1).padStart(2, '0')}
                              </option>
                            )
                          )}
                        </select>
                        {uiWords.diaperChengesTimesUnitLabel}
                      </div>
                      <div className="calcCell">
                        <button
                          type="button"
                          className="btn btn-info"
                          disabled={this.state.isTopModeCheckForecast}
                          onClick={this.handleCalculateTopHistogram}
                        >
                          {uiWords.forecastCalculateButtonLabel}
                        </button>
                        <button
                          type="button"
                          className="btn btn-info"
                          disabled={
                            this.state.topDisabled ||
                            this.state.isTopModeCheckForecast
                          }
                          onClick={this.handleTopSubmitExcretionForecast}
                        >
                          {uiWords.forecastRegisterButtonLabel}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {uiWords.modeDropdownLabel}
                <div className="hpad-pulldown-1">
                  <select
                    className="histogram-mode"
                    defaultValue="excretion_result"
                    onChange={this.handleOnChangeForBottomMode.bind(this)}
                  >
                    {this.histogramModeOptions()}
                  </select>
                </div>
                <HistogramPeriodSelector
                  onChange={this.handleOnChangeForBottomHistogram}
                  duration={{
                    start: this.state.bottom_start_dt,
                    end: this.state.bottom_end_dt
                  }}
                  isDisabled={this.state.isBottomModeCheckForecast}
                />
                <button
                  type="button"
                  className="btn btn-info"
                  disabled={this.state.isBottomModeCheckForecast}
                  onClick={(e) =>
                    this.handleClickSlideWeek('bottom', 'before', e)
                  }
                >
                  {uiWords.lastWeekButtonLabel}
                </button>
                <button
                  type="button"
                  className="btn btn-info"
                  disabled={this.state.isBottomModeCheckForecast}
                  onClick={(e) => this.handleClickSyncAnotherDate('bottom', e)}
                >
                  {uiWords.syncPeriodAboveButtonLabel}
                </button>
                <button
                  type="button"
                  className="btn btn-info"
                  disabled={this.state.isBottomModeCheckForecast}
                  onClick={(e) =>
                    this.handleClickSlideWeek('bottom', 'after', e)
                  }
                >
                  {uiWords.nextWeekButtonLabel}
                </button>
                {this.state.errorBottomDate && (
                  <p className="duration-error">{this.state.errorBottomDate}</p>
                )}
                <div className="calcForm">
                  <div
                    onClick={this.handleClickShowBottomCalcForm}
                    className={
                      ['alert_log', 'check_forecast'].includes(
                        this.state.mode_bottom
                      )
                        ? 'clickableElem'
                        : 'clickableElem hide'
                    }
                  >
                    <div className="calcTextBg"></div>
                    <span className="calcText">
                      {this.state.bottomIsShowCalcForm ? '▼' : '▶'}
                    </span>
                    <span
                      style={{
                        position: 'absolute',
                        marginTop: '5px',
                        marginLeft: '30px'
                      }}
                    >
                      {uiWords.forecastSettingsToggleLabel}
                    </span>
                  </div>
                  <div
                    className={
                      this.state.bottomIsShowCalcForm
                        ? 'calcTable'
                        : 'calcTable hide-animation'
                    }
                  >
                    <div className="calcRow">
                      <div className="calcCell"></div>
                      <div className="calcCell"></div>
                      <div className="calcCell"></div>
                    </div>
                    <div className="calcRow">
                      <div className="calcCell text-right">
                        {uiWords.forecastStartTimeLabel}
                      </div>
                      <div className="calcCell pulldown">
                        <select
                          disabled={this.state.isBottomModeCheckForecast}
                          onChange={this.handleChangeBottomStartValue}
                          value={zeroPad(this.state.bottomStart, 2)}
                        >
                          {[...Array(24)].map((_, index) => (
                            <option key={index}>
                              {String(index).padStart(2, '0')}
                            </option>
                          ))}
                        </select>
                        {uiWords.timeUnitLabel}
                      </div>
                      <div className="calcCell pl35">
                        <input
                          type="checkbox"
                          id="bottomDoExchangeWhenStart"
                          disabled={this.state.isBottomModeCheckForecast}
                          onChange={this.handleChangeBottomDoExchangeWhenStart}
                          style={{ cursor: 'pointer' }}
                          checked={this.state.bottomDoExchangeWhenStart}
                        />
                        <label
                          htmlFor="bottomDoExchangeWhenStart"
                          style={{ cursor: 'pointer' }}
                        >
                          {uiWords.diaperChangesAtStartCheckboxLabel}
                        </label>
                      </div>
                    </div>
                    <div className="calcRow">
                      <div className="calcCell text-right">
                        {uiWords.forecastEndTimeLabel}
                      </div>
                      <div className="calcCell pulldown">
                        <select
                          disabled={this.state.isBottomModeCheckForecast}
                          onChange={this.handleChangeBottomEndValue}
                          value={zeroPad(this.state.bottomEnd, 2)}
                        >
                          {[...Array(24)].map((_, index) => (
                            <option key={index}>
                              {String(index).padStart(2, '0')}
                            </option>
                          ))}
                        </select>
                        {uiWords.timeUnitLabel}
                      </div>
                      <div className="calcCell pl35">
                        <input
                          type="checkbox"
                          id="bottomDoExchangeWhenEnd"
                          disabled={this.state.isBottomModeCheckForecast}
                          onChange={this.handleChangeBottomDoExchangeWhenEnd}
                          style={{ cursor: 'pointer' }}
                          checked={this.state.bottomDoExchangeWhenEnd}
                        />
                        <label
                          htmlFor="bottomDoExchangeWhenEnd"
                          style={{ cursor: 'pointer' }}
                        >
                          {uiWords.diaperChangesAtEndCheckboxLabel}
                        </label>
                      </div>
                    </div>
                    <div className="calcRow">
                      <div className="calcCell text-right">
                        {uiWords.diaperChangesThresholdLabel}
                      </div>
                      <div className="calcCell pulldown">
                        <select
                          value={this.state.bottomExchangeCount}
                          disabled={this.state.isBottomModeCheckForecast}
                          onChange={this.handleChangeBottomExchangeCount}
                        >
                          {[...Array(10)].map((_, index) =>
                            index + 1 === 1 ? (
                              <option
                                key={index + 1}
                                hidden={this.state.topIsEnableStartAndEnd}
                              >
                                {String(index + 1).padStart(2, '0')}
                              </option>
                            ) : (
                              <option key={index + 1}>
                                {String(index + 1).padStart(2, '0')}
                              </option>
                            )
                          )}
                        </select>
                        {uiWords.diaperChengesTimesUnitLabel}
                      </div>
                      <div className="calcCell">
                        <button
                          type="button"
                          className="btn btn-info"
                          disabled={this.state.isBottomModeCheckForecast}
                          onClick={this.handleCalculateBottomHistogram}
                        >
                          {uiWords.forecastCalculateButtonLabel}
                        </button>
                        <button
                          type="button"
                          className="btn btn-info"
                          disabled={
                            this.state.bottomDisabled ||
                            this.state.isBottomModeCheckForecast
                          }
                          onClick={this.handleBottomSubmitExcretionForecast}
                        >
                          {uiWords.forecastRegisterButtonLabel}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* ヒストグラム */}
            <div id="user-Alert-table">
              <div id="user-Alert-histogram">
                {/* ヒストグラム (上) */}
                {/* TODO: ヒストグラムコンポーネントに機能を隠蔽する */}
                {this.topSwitchByMode()}
                <div className="switchVisibleField">{checkboxFieldTop}</div>
                {jsx ? (jsx.top ? jsx.top.start : '') : ''}
                {jsx ? (jsx.top ? jsx.top.end : '') : ''}
                {jsx ? (jsx.top ? jsx.top.exchange : '') : ''}
                <div className="mt30">&nbsp;</div>

                {/* ヒストグラム (下) */}
                {/* TODO: ヒストグラムコンポーネントに機能を隠蔽する */}
                {this.bottomSwitchByMode()}
                <div className="switchVisibleField">{checkboxFieldBottom}</div>
                {jsx ? (jsx.bottom ? jsx.bottom.start : '') : ''}
                {jsx ? (jsx.bottom ? jsx.bottom.end : '') : ''}
                {jsx ? (jsx.bottom ? jsx.bottom.exchange : '') : ''}
                <div className="mt30">&nbsp;</div>
              </div>
            </div>

            <div className="karte-area mb30 mt20">
              <Form
                schema={schema}
                uiSchema={uiSchema}
                formData={formData}
                onChange={this.handleChange}
                onSubmit={this.handleSubmit.bind(this)}
              >
                {chartMsg()}
                <button type="submit" className="mt20 btn btn-info">
                  {uiWords.noteSaveButtonLabel}
                </button>
              </Form>
            </div>
          </div>
        </div>

        <AppLoading isActive={this.state.loading} />
        <AppDialog
          isActive={this.state.deleteDialog}
          title={appDialogUiWords.deleteMessage}
          choiceButtonLists={[
            appDialogUiWords.yesButtonLabel,
            appDialogUiWords.noButtonLabel
          ]}
          onClick={this.handleDeleteDialogClick.bind(this)}
          onDialogClosing={this.handleDeleteDialogClosing.bind(this)}
        />

        <AppDialog
          isActive={this.state.noticeDialog}
          title={uiWords.registeredForecastDialogMessage}
          choiceButtonLists={[appDialogUiWords.okButtonLabel]}
          onClick={this.handleNoticeDialogClosing.bind(this)}
          onDialogClosing={this.handleNoticeDialogClosing.bind(this)}
        />

        <AppDialog
          isActive={this.state.errorDialog}
          title={uiWords.failedRegisterForecastDialogMessage}
          choiceButtonLists={[appDialogUiWords.okButtonLabel]}
          onClick={this.handleErrorDialogClosing.bind(this)}
          onDialogClosing={this.handleErrorDialogClosing.bind(this)}
        />
      </div>
    )
  }
}

export default withRouter(UserDetail)
