/* eslint-disable */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { AxiosError } from "axios";
import request, { Token } from "./CustomAxios";
import { AsyncThunkConfig } from "../../store";
import API from "../../constants/ApiConstants";
import ValidationErrors from "../ValidationErrors";
import TCarePerson, { initialCarePerson } from "../Types/TCarePerson";

// get
export type GetCarePeopleState = {
  carePeople: Array<TCarePerson>;
  message: string;
  isLoading: boolean;
};

const initialGetCarePeople: GetCarePeopleState = {
  carePeople: [],
  message: "",
  isLoading: false,
};

// add
export type AddCarePersonState = {
  success: boolean;
  message: string;
  isLoading: boolean;
};

const initialAddCarePerson: AddCarePersonState = {
  success: false,
  message: "",
  isLoading: false,
};

export type CarePersonFormData = {
  name?: string;
  kana?: string;
  place?: string;
  birth_date?: string;
  gender?: string;
  group_id?: number | null;
  sensor_id?: string | null;
  status_code?: string;
};

type AddCarePersonParams = {
  params: CarePersonFormData;
  login: Token;
};

// edit
export type EditCarePersonState = {
  success: boolean;
  message: string;
  isLoading: boolean;
};

const initialEditCarePerson: EditCarePersonState = {
  success: false,
  message: "",
  isLoading: false,
};

type EditCarePersonParams = {
  params: {
    carePersonId: string;
    sendData: CarePersonFormData;
  };
  login: Token;
};

// detail
export type GetCarePersonState = {
  carePerson: TCarePerson;
  message: string;
  isLoading: boolean;
};

const initialGetCarePerson: GetCarePersonState = {
  carePerson: initialCarePerson,
  message: "",
  isLoading: false,
};

type GetCarePersonParams = {
  params: {
    carePersonId: string;
  };
  login: Token;
};

// delete
export type DeleteCarePersonState = {
  success: boolean;
  message: string;
  isLoading: boolean;
};

const initialDeleteCarePerson: DeleteCarePersonState = {
  success: false,
  message: "",
  isLoading: false,
};

type DeleteCarePersonParams = {
  params: {
    carePersonId: string;
  };
  login: Token;
};

// reducer
export type CarePeopleState = {
  getCarePeople: GetCarePeopleState;
  addCarePerson: AddCarePersonState;
  editCarePerson: EditCarePersonState;
  getCarePerson: GetCarePersonState;
  deleteCarePerson: DeleteCarePersonState;
};

// get
export const fetchGetCarePeople = createAsyncThunk<
  Object,
  Token,
  AsyncThunkConfig<ValidationErrors>
>("carePeople/all", async (arg, thunkAPI) => {
  let response: any = [];
  try {
    response = await request(arg).get(API.CarePeople.All);
  } catch (err: any) {
    let error: AxiosError<ValidationErrors> = err;
    if (!error.response) {
      throw err;
    }
    return thunkAPI.rejectWithValue(error.response.data);
  }

  return response;
});

const fetchGetCarePeopleSlice = createSlice<GetCarePeopleState, any>({
  name: "fetch/all/CarePeople",
  initialState: initialGetCarePeople,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(fetchGetCarePeople.fulfilled, (state: any, action: any) => {
      console.log("carePeople fulfilled");
      state.carePeople = action.payload.data.data;
      state.isLoading = false;
    });
    builder.addCase(fetchGetCarePeople.pending, (state: any, action: any) => {
      console.log("carePeople pending");
      state.isLoading = true;
    });
    builder.addCase(fetchGetCarePeople.rejected, (state: any, action: any) => {
      console.log("carePeople rejected");
      state.status = "error";
      state.message = action.error.message;
      state.isLoading = false;
    });
  },
});

// add
export const fetchAddCarePerson = createAsyncThunk<
  Object,
  AddCarePersonParams,
  AsyncThunkConfig<ValidationErrors>
>("carePeople/add", async (arg, thunkAPI) => {
  let response: any = [];
  try {
    response = await request(arg.login).post(API.CarePeople.Add, arg.params);
  } catch (err: any) {
    let error: AxiosError<ValidationErrors> = err;
    if (!error.response) {
      throw err;
    }
    return thunkAPI.rejectWithValue(error.response.data);
  }

  return response;
});

const fetchAddCarePersonSlice = createSlice<AddCarePersonState, any>({
  name: "fetch/add/CarePeople",
  initialState: initialAddCarePerson,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(fetchAddCarePerson.fulfilled, (state: any, action: any) => {
      console.log("add carePerson fulfilled");
      state.result = action.payload.data.data;
      state.isLoading = false;
    });
    builder.addCase(fetchAddCarePerson.pending, (state: any, action: any) => {
      console.log("add carePerson pending");
      state.isLoading = true;
    });
    builder.addCase(fetchAddCarePerson.rejected, (state: any, action: any) => {
      console.log("add carePerson rejected");
      state.status = "error";
      state.message = action.error.message;
      state.isLoading = false;
    });
  },
});

// edit
export const fetchEditCarePerson = createAsyncThunk<
  Object,
  EditCarePersonParams,
  AsyncThunkConfig<ValidationErrors>
>("carePeople/edit", async (arg, thunkAPI) => {
  let response: any = [];
  try {
    const replaceString = API.CarePeople.Edit.replace(
      ":id",
      arg.params.carePersonId
    );
    response = await request(arg.login).put(replaceString, arg.params.sendData);
  } catch (err: any) {
    let error: AxiosError<ValidationErrors> = err;
    if (!error.response) {
      throw err;
    }
    return thunkAPI.rejectWithValue(error.response.data);
  }

  return response;
});

const fetchEditCarePersonSlice = createSlice<EditCarePersonState, any>({
  name: "fetch/edit/CarePeople",
  initialState: initialEditCarePerson,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(
      fetchEditCarePerson.fulfilled,
      (state: any, action: any) => {
        console.log("edit carePerson fulfilled");
        state.result = action.payload.data.data;
        state.isLoading = false;
      }
    );
    builder.addCase(fetchEditCarePerson.pending, (state: any, action: any) => {
      console.log("edit carePerson pending");
      state.isLoading = true;
    });
    builder.addCase(fetchEditCarePerson.rejected, (state: any, action: any) => {
      console.log("edit carePerson rejected");
      state.status = "error";
      state.message = action.error.message;
      state.isLoading = false;
    });
  },
});

// detail
export const fetchGetCarePerson = createAsyncThunk<
  Object,
  GetCarePersonParams,
  AsyncThunkConfig<ValidationErrors>
>("carePeople/detail", async (arg, thunkAPI) => {
  let response: any = [];
  try {
    response = await request(arg.login).get(
      API.CarePeople.Detail.replace(":id", arg.params.carePersonId)
    );
  } catch (err: any) {
    let error: AxiosError<ValidationErrors> = err;
    if (!error.response) {
      throw err;
    }
    return thunkAPI.rejectWithValue(error.response.data);
  }

  return response;
});

const fetchGetCarePersonSlice = createSlice<GetCarePersonState, any>({
  name: "fetch/detail/CarePeople",
  initialState: initialGetCarePerson,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(fetchGetCarePerson.fulfilled, (state: any, action: any) => {
      console.log("carePerson fulfilled");
      state.carePerson = action.payload.data.data;
      state.isLoading = false;
    });
    builder.addCase(fetchGetCarePerson.pending, (state: any, action: any) => {
      console.log("carePerson pending");
      state.isLoading = true;
    });
    builder.addCase(fetchGetCarePerson.rejected, (state: any, action: any) => {
      console.log("carePerson rejected");
      state.status = "error";
      state.message = action.error.message;
      state.isLoading = false;
    });
  },
});

// delete
export const fetchDeleteCarePerson = createAsyncThunk<
  Object,
  DeleteCarePersonParams,
  AsyncThunkConfig<ValidationErrors>
>("carePeople/delete", async (arg, thunkAPI) => {
  let response: any = [];
  try {
    response = await request(arg.login).delete(
      API.CarePeople.Delete.replace(":id", arg.params.carePersonId)
    );
  } catch (err: any) {
    let error: AxiosError<ValidationErrors> = err;
    if (!error.response) {
      throw err;
    }
    return thunkAPI.rejectWithValue(error.response.data);
  }

  return response;
});

const fetchDeleteCarePersonSlice = createSlice<DeleteCarePersonState, any>({
  name: "fetch/delete/CarePeople",
  initialState: initialDeleteCarePerson,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(
      fetchDeleteCarePerson.fulfilled,
      (state: any, action: any) => {
        console.log("delete carePerson fulfilled");
        state.result = action.payload.data.data;
        state.isLoading = false;
      }
    );
    builder.addCase(
      fetchDeleteCarePerson.pending,
      (state: any, action: any) => {
        console.log("delete carePerson pending");
        state.isLoading = true;
      }
    );
    builder.addCase(
      fetchDeleteCarePerson.rejected,
      (state: any, action: any) => {
        console.log("delete carePerson rejected");
        state.status = "error";
        state.message = action.error.message;
        state.isLoading = false;
      }
    );
  },
});

const reducers = combineReducers<CarePeopleState>({
  getCarePeople: fetchGetCarePeopleSlice.reducer,
  addCarePerson: fetchAddCarePersonSlice.reducer,
  editCarePerson: fetchEditCarePersonSlice.reducer,
  getCarePerson: fetchGetCarePersonSlice.reducer,
  deleteCarePerson: fetchDeleteCarePersonSlice.reducer,
});

export default reducers;
