import SystemConst from '@/constants/SystemConstants'
import * as moment from 'moment'

/**
 * 空振り回数の取得
 *
 * @param {object} counts 空振り回数
 */
const missingCount = (counts) => {
  const count = counts.find(
    (count) => count.type === SystemConst.ExcretionType.none
  )
  return count ? count.amount : 0
}

/**
 * 排尿の回数の取得
 *
 * @param {object} counts 排尿の回数
 */
const urineCount = (counts) => {
  const count = counts.find(
    (count) => count.type === SystemConst.ExcretionType.urine
  )
  return count ? count.amount : 0
}

/**
 * 排便の回数の取得
 *
 * @param {object} counts 排便の回数
 */
const fecesCount = (counts) => {
  const count = counts.find(
    (count) => count.type === SystemConst.ExcretionType.feces
  )
  return count ? count.amount : 0
}

/**
 * 下痢の回数の取得
 *
 * @param {object} counts 下痢の回数
 */
const diarrheaCount = (counts) => {
  const count = counts.find(
    (count) => count.type === SystemConst.ExcretionType.wateryFeces
  )
  return count ? count.amount : 0
}

/**
 * 排泄種別ごとの回数
 *
 * @param {object} excretionResultTotals 排泄回数情報
 * @param {number} squenceId シーケンスID
 * @param {string} timesUnit 回数単位
 * @return {string} 合計値のHTML
 */
const dailyExcretionResultTotals = (
  excretionResultTotals,
  squenceId,
  leakageCountLabel
) => {
  const dailyTotals = excretionResultTotals.find(
    (totals) => totals.sequence_id === squenceId
  )

  const isLeaked = dailyTotals.leakedCount > 0

  return (
    '<div class="daily-excretion-counts">' +
    '  <div class="daily-excretion-count item-none">' +
    `    ${missingCount(dailyTotals.data)}` +
    '  </div>' +
    '  <div class="daily-excretion-count item-urine">' +
    `    ${urineCount(dailyTotals.data)}` +
    '  </div>' +
    '  <div class="daily-excretion-count item-feces">' +
    `    ${fecesCount(dailyTotals.data)}` +
    '  </div>' +
    '  <div class="daily-excretion-count item-watery">' +
    `    ${diarrheaCount(dailyTotals.data)}` +
    '  </div>' +
    `  <div class="leak-count${isLeaked ? ' item-leaked' : ''}">` +
    '    <span class="leakage-count-label">' +
    `      ${leakageCountLabel}` +
    '    </span>' +
    `    ${dailyTotals.leakedCount}` +
    `  </div>` +
    '</div>'
  )
}

/**
 * 利用者別ケア記録1ヶ月ビューのタイムラインにおける列ヘッダーのヘッダー (合計 \ 平均)
 *
 * react-visjs-timeline 用に plain string を返しています
 *
 * @return {string} 列ヘッダーのヘッダー (合計 \ 平均)
 */
export const TimelineStatsRowHeader = (
  totalHeaderLabel,
  averageHeaderLabel
) => {
  return {
    id: -1,
    date: '',
    content:
      '<div class="group-item">' +
      '  <div class="average-header"></div>' +
      '  <div class="group-header">' +
      `    <div class="total-header-label">${totalHeaderLabel}</div>` +
      `    <div class="average-header-label">${averageHeaderLabel}</div>` +
      '  </div>' +
      '</div>'
  }
}

/**
 * 利用者別ケア記録1ヶ月ビューのタイムラインにおける列ヘッダーの内容 (日付と排泄種別ごとの回数)
 *
 * react-visjs-timeline 用に plain string を返しています
 *
 * @return {object} 列ヘッダーの内容
 */
export const TimelineStatsRowHeaderContents = (
  details,
  excretionResultTotals,
  timesUnit
) => {
  const currentDate = moment()

  return details.map((detail) => {
    const date = currentDate.isSame(detail.date, 'day')
      ? `<div class="current-date date">${detail.date}</div>`
      : `<div class="date">${detail.date}</div>`

    const totals = dailyExcretionResultTotals(
      excretionResultTotals,
      detail.sequence_id,
      timesUnit
    )

    return {
      id: detail.sequence_id,
      date: detail.date,
      content: `<div class="group-item">${date}${totals}</div>`
    }
  })
}
