/* eslint-disable */
import API from '../constants/ApiConstants'
import dispatcher from '../dispatchers/Dispatcher'
import request from 'superagent'

export function carePeople() {
  request
    .get(API.CarePeople.All)
    .set('Accept', 'application/json')
    .end(function (err, res) {
      if (err === null) {
        let body = res.body.data
        dispatcher.dispatch({ type: 'PEOPLE_ALL', body })
      } else {
        dispatcher.dispatch({ type: 'ERROR', res })
      }
    })
}

// ソート、検索用にcarePeopleとアクションをわける
export function carePeopleSort(query) {
  // query_sample
  // ?page=1&group_id=1,2,3&care_subjetct_id=1,2,3&keyword=名前&sort=kana&direction=asc
  request
    .get(API.CarePeople.All)
    .query(query)
    .set('Accept', 'application/json')
    .end(function (err, res) {
      if (err === null) {
        let body = res.body.data
        dispatcher.dispatch({ type: 'PEOPLE_SORT', body })
      } else {
        dispatcher.dispatch({ type: 'ERROR', res })
      }
    })
}

// 非同期ソート用。 AppHeader.jsの干渉を防ぐため。
export function carePeopleAsyncSort(query) {
  // query_sample
  // ?page=1&group_id=1,2,3&care_subjetct_id=1,2,3&keyword=名前&sort=kana&direction=asc
  request
    .get(API.CarePeople.All)
    .query(query)
    .set('Accept', 'application/json')
    .end(function (err, res) {
      if (err === null) {
        let body = res.body.data
        dispatcher.dispatch({ type: 'PEOPLE_ASYNC_SORT', body })
      } else {
        dispatcher.dispatch({ type: 'ERROR', res })
      }
    })
}

// App.jsのグループ選択用。AppHeader.jsの干渉を防ぐため。
export function carePeopleGroupFilter(query) {
  // query_sample
  // ?page=1&group_id=1,2,3&care_subjetct_id=1,2,3&keyword=名前&sort=kana&direction=asc
  request
    .get(API.CarePeople.All)
    .query(query)
    .set('Accept', 'application/json')
    .end(function (err, res) {
      if (err === null) {
        let body = res.body.data
        dispatcher.dispatch({ type: 'PEOPLE_GROUP', body })
      } else {
        dispatcher.dispatch({ type: 'ERROR', res })
      }
    })
}

export function carePerson(id) {
  let url = API.CarePeople.Detail.replace(':id', id)
  request
    .get(url)
    .set('Accept', 'application/json')
    .end(function (err, res) {
      if (err === null) {
        let body = res.body.data
        dispatcher.dispatch({ type: 'PERSON_DETAIL', body })
      } else {
        dispatcher.dispatch({ type: 'ERROR', res })
      }
    })
}

export function editPerson(data, id) {
  let url = API.CarePeople.Edit.replace(':id', id)
  request
    .put(url)
    .send(data)
    .set('Accept', 'application/json')
    .end(function (err, res) {
      if (err === null) {
        let body = res.body.success
        dispatcher.dispatch({ type: 'PERSON_EDIT', body })
      } else {
        dispatcher.dispatch({ type: 'ERROR', res })
      }
    })
}

export function addPerson(data) {
  request
    .post(API.CarePeople.Add)
    .send(data)
    .set('Accept', 'application/json')
    .end(function (err, res) {
      if (err === null) {
        let body = res.body.data
        dispatcher.dispatch({ type: 'PERSON_ADD', body })
      } else {
        dispatcher.dispatch({ type: 'ERROR', res })
      }
    })
}

export function deletePerson(id) {
  let url = API.CarePeople.Delete.replace(':id', id)
  request
    .del(url)
    .set('Accept', 'application/json')
    .end(function (err, res) {
      if (err === null) {
        let body = res.body.success
        dispatcher.dispatch({ type: 'PERSON_DELETE', body })
      } else {
        dispatcher.dispatch({ type: 'ERROR', res })
      }
    })
}
