import { localizedUiWords } from '@/features/locale'
import { LocaleContext } from '@/providers/LocaleProvider'
import React, { useContext } from 'react'

export const NoNotificationMessage = () => {
  const [locale] = useContext(LocaleContext)
  const uiWords = localizedUiWords(locale.lang).pages.app

  return (
    <tr>
      <td colSpan="3" className="no_need_to_action">
        {uiWords.message}
      </td>
    </tr>
  )
}
