/* eslint-disable */
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

class SensorSelectField extends Component {
  constructor() {
    super()
    this.state = {
      formData: null, // 初期表示でselectedする値
      sensors: null
    }
  }

  componentDidMount() {
    this.setState({
      formData: this.props.formData,
      sensors: this.props.sensors
    })
  }

  componentDidUpdate(prebProps) {
    if (this.props !== prebProps) {
      const state = {}
      if (this.state.sensors !== this.props.sensors) {
        state['sensors'] = this.props.sensors
      }
      if (this.state.formData !== this.props.formData) {
        state['formData'] = this.props.formData
      }
      if (Object.keys(state).length > 0) {
        this.setState(state)
      }
    }
  }
  handleChange(e) {
    this.props.onChange({
      sensor_id: e.target.value,
      id: this.props.userId
    })
    this.setState({
      sensors: this.state.sensors,
      formData: e.target.value
    })
  }

  render() {
    let selectField
    if (this.state.sensors !== null) {
      let options = _.map(this.state.sensors, (v, k) => {
        return (
          <option key={v.id} value={v.id}>
            {v.id}
          </option>
        )
      })
      selectField = (
        <select
          value={this.state.formData ? this.state.formData : ''}
          onChange={this.handleChange.bind(this)}
          disabled={this.props.sensorDisable ? true : false}
        >
          <option key="0" value={''}>
            設定なし
          </option>
          {options}
        </select>
      )
    } else {
      selectField = <div>読み込み中</div>
    }

    return <div>{selectField}</div>
  }
}

SensorSelectField.propTypes = {
  onChange: PropTypes.func,
  formData: PropTypes.string,
  userId: PropTypes.number,
  sensors: PropTypes.any,
  sensorDisable: PropTypes.bool
}

export default SensorSelectField
