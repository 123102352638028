import moment from 'moment'

/**
 * 日本のタイムゾーン
 *  */
const JST = 9

/**
 * UTCからの時差を返す
 *
 * @returns hour
 */
export const getUtcOffsetAtHour = () => {
  return moment().utcOffset() / 60
}

/**
 * 日本のタイムゾーン時間と現地のタイムゾーン時間の差分を返す
 *
 * @returns hour
 */
export const getDiffLocalTimeAndJST = () => {
  return JST - getUtcOffsetAtHour()
}
