/* eslint-disable */
import * as Action from '../actions/CarePeopleAction'
import AppLoading from '../components/App/AppLoading'
import AddGroupDialog from '../components/common/AddGroupDialog'
import GroupSelectField from '../components/common/GroupSelectField'
import PlaceField from '../components/common/PlaceField'
import carePeopleStore from '../stores/CarePeopleStore'
import errorStore from '../stores/ErrorStore'
import { AppHeader } from '@/components/App/AppHeader'
import { localizedUiWords } from '@/features/locale'
import { LocaleContext } from '@/providers/LocaleProvider'
import * as moment from 'moment'
import React, { Component, useContext } from 'react'
import Form from 'react-jsonschema-form'
import { withRouter } from 'react-router-dom'

// 全角を2半角を1文字としてカウントする関数
function cntStrLength(str) {
  str = escape(str)
  str = str.replace(/%u..../g, '--') //全角文字を二文字分に変換
  str = str.replace(/%../g, '-') //半角記号類は一文字分
  return str.length
}

function calcAge(birthDate) {
  // ゼロパディングするための関数
  const paddingZero = (num, digit) => ('0000' + num).slice(-1 * digit)

  // 誕生日
  let birthDateSplit = birthDate.split('-')
  const birth = new Date(
    parseInt(birthDateSplit[0], 10),
    parseInt(birthDateSplit[1] - 1, 10),
    parseInt(birthDateSplit[2], 10)
  )
  const y2 = paddingZero(birth.getFullYear(), 4)
  const m2 = paddingZero(birth.getMonth() + 1, 2)
  const d2 = paddingZero(birth.getDate(), 2)

  // 今日の日付
  const today = new Date()
  const y1 = paddingZero(today.getFullYear(), 4)
  const m1 = paddingZero(today.getMonth() + 1, 2)
  const d1 = paddingZero(today.getDate(), 2)

  const age = Math.floor((Number(y1 + m1 + d1) - Number(y2 + m2 + d2)) / 10000)

  return age
}

// グループセレクトフィールドはajax通信が必要なため外だし
const customFields = {
  groupSelect: GroupSelectField,
  place: PlaceField
}

class UserEdit extends Component {
  static contextType = LocaleContext

  constructor() {
    super()
    this.state = {
      personDetail: null,
      editingPersonDetail: null,
      carePeople: null,
      loading: false,
      error: null,
      errors: {},
      isActive: false
    }
  }

  componentDidMount() {
    carePeopleStore.on('person_detail', (v) => {
      this.setState({
        personDetail: v,
        loading: false
      })
    })
    carePeopleStore.on('person_edit', (v) => {
      Action.carePerson(this.props.match.params.id)
      this.props.history.push(`/user/${this.props.match.params.id}`)
    })
    carePeopleStore.on('person_add', (v) => {
      this.props.history.push(`/user/${v.id}`)
    })
    errorStore.on('error_ajax', (v) => {
      this.setState({
        loading: false,
        error: v.message
      })
    })
    if (this.props.match.params.id !== 'new') {
      Action.carePerson(this.props.match.params.id)
      this.setState({
        loading: true
      })
    }
    document
      .getElementById('root_status_code')
      .parentElement.classList.add('form-pulldown')
  }

  componentWillUnmount() {
    carePeopleStore.removeAllListeners('person_detail')
    carePeopleStore.removeAllListeners('person_edit')
    carePeopleStore.removeAllListeners('person_add')
    errorStore.removeAllListeners('error_ajax')
  }

  getSchema() {
    const [locale] = this.context
    const uiWords = localizedUiWords(locale.lang).pages.userEdit

    // フォームの設定
    return {
      type: 'object',
      required: [
        'name',
        'kana',
        'place',
        'birth_date',
        'gender',
        'status_code'
      ],
      properties: {
        name: { type: 'string', title: uiWords.fullNameLabel },
        kana: { type: 'string', title: uiWords.furiganaLabel },
        place: { type: 'string', title: uiWords.placeLabel },
        birth_date: {
          type: 'string',
          title: uiWords.birthDateLabel,
          format: 'date'
        },
        gender: {
          type: 'string',
          enum: ['male', 'female'],
          enumNames: [uiWords.maleLabel, uiWords.femaleLabel],
          title: uiWords.genderLabel
        },
        group_id: { type: 'number' },
        status_code: {
          type: 'string',
          enum: ['0', '1', '2'],
          enumNames: [
            uiWords.livingLabel,
            uiWords.hospitalizedLabel,
            uiWords.outingLabel
          ],
          title: uiWords.statusLabel
        }
      }
    }
  }

  validate(e) {
    const [locale] = this.context
    const uiWords = localizedUiWords(locale.lang).pages.userEdit

    let errors = {}
    if (cntStrLength(e.formData.name.toString()) > 30) {
      errors.name = uiWords.inputCharacterLimitErrorMessage(15, 30)
    }

    if (cntStrLength(e.formData.kana.toString()) > 60) {
      errors.kana = uiWords.inputCharacterLimitErrorMessage(30, 60)
    }

    if (cntStrLength(e.formData.place.toString()) > 30) {
      errors.place = uiWords.inputCharacterLimitErrorMessage(15, 30)
    }

    if (calcAge(e.formData.birth_date) < 0) {
      errors.birth_date = uiWords.futureDateHasBeenEnteredErrorMessage
    }

    if (this.state.personDetail) {
      if (
        this.state.personDetail.sensor_id !== null &&
        this.state.personDetail.sensor_id !== undefined &&
        e.formData.status_code !== '0'
      ) {
        errors.status_code = uiWords.statusCannotBeChangedErrorMessage
      }
    }

    return errors
  }

  handleChange(e) {
    const [locale] = this.context
    const uiWords = localizedUiWords(locale.lang).pages.userEdit

    let errors = this.state.errors
    let isChange = false
    let isChangeStatus = false
    if (e.formData.birth_date) {
      if (calcAge(e.formData.birth_date) < 0) {
        errors.birth_date = uiWords.futureDateHasBeenEnteredErrorMessage
        isChange = true
      } else {
        delete errors['birth_date']
        isChange = true
      }
    }

    if (this.state.personDetail && e.formData.status_code) {
      if (this.state.editingPersonDetail) {
        isChangeStatus =
          e.formData.status_code !== this.state.editingPersonDetail.status_code
            ? true
            : false
      } else {
        isChangeStatus =
          e.formData.status_code !== this.state.personDetail.status_code
            ? true
            : false
      }
      if (isChangeStatus) {
        if (
          this.state.personDetail.sensor_id !== null &&
          this.state.personDetail.sensor_id !== undefined
        ) {
          if (e.formData.status_code !== '0') {
            errors.status_code = uiWords.statusCannotBeChangedErrorMessage
          } else {
            delete errors['status_code']
          }
        }
      }
    }

    if (this.setState.personDetail && isChange) {
      let tempPersonDetail = this.state.personDetail
      tempPersonDetail.birth_date = e.formData.birth_date
      tempPersonDetail.status_code = e.formData.status_code
      this.setState({
        editingPersonDetail: tempPersonDetail,
        errors: errors
      })
    } else {
      this.setState({
        editingPersonDetail: e.formData,
        errors: errors
      })
    }
  }

  handleSubmit(e) {
    const errors = this.validate(e)
    if (Object.keys(errors).length > 0) {
      if (this.state.personDetail) {
        this.setState({
          errors: errors
        })
      } else {
        this.setState({
          errors: errors
        })
      }
    } else {
      if (e.formData.group_id === 0) {
        e.formData.group_id = null
      }
      if (this.props.match.params.id === 'new') {
        Action.addPerson(e.formData)
      } else {
        Action.editPerson(e.formData, this.props.match.params.id)
      }

      this.setState({
        loading: true,
        errors: {}
      })
    }
  }

  showAddGroupDialog(e) {
    this.setState({
      isActive: true
    })
  }

  handleDialogClosing(e) {
    this.setState({
      isActive: false
    })
  }

  render() {
    const [locale] = this.context
    const uiWords = localizedUiWords(locale.lang).pages.userEdit

    let formData = {
      birth_date: moment().format('YYYY-MM-DD'),
      gender: 'male',
      status_code: '0'
    }

    // この画面で編集していたらそのデータを描画する
    if (this.state.editingPersonDetail) {
      formData = {
        name: this.state.editingPersonDetail.name,
        kana: this.state.editingPersonDetail.kana,
        place: this.state.editingPersonDetail.place,
        birth_date: moment(this.state.editingPersonDetail.birth_date).format(
          'YYYY-MM-DD'
        ),
        gender: this.state.editingPersonDetail.gender,
        group_id:
          this.state.editingPersonDetail.group_id !== null
            ? this.state.editingPersonDetail.group_id
            : 0,
        status_code: this.state.editingPersonDetail.status_code
      }
      // 初期表示時
    } else if (this.state.personDetail) {
      formData = {
        name: this.state.personDetail.name,
        kana: this.state.personDetail.kana,
        place: this.state.personDetail.place,
        birth_date: moment(this.state.personDetail.birth_date).format(
          'YYYY-MM-DD'
        ),
        gender: this.state.personDetail.gender,
        group_id:
          this.state.personDetail.group_id !== null
            ? this.state.personDetail.group_id
            : 0,
        status_code: this.state.personDetail.status_code
      }
    }
    // else処理は何もしません。
    let uiSchema = {
      group_id: {
        'ui:help': (
          <div className="add-group-button">
            <span onClick={this.showAddGroupDialog.bind(this)}>
              {uiWords.createGroupLabel}
            </span>
          </div>
        ),
        'ui:field': 'groupSelect',
        type: 'number'
      },
      gender: {
        classNames: 'form-pulldown'
      },
      place: {
        'ui:field': 'place',
        type: 'string',
        'ui:options': {
          inline: true
        }
      }
    }
    const schema = this.getSchema()
    for (var param in schema.properties) {
      if (this.state.errors[param] && !this.state.loading) {
        if (!uiSchema[param]) {
          uiSchema[param] = {}
        }
        uiSchema[param]['ui:help'] = (
          <div className="error">{this.state.errors[param]}</div>
        )
      }
    }

    return (
      <div id="userEdit">
        <AppHeader />
        <div className="flex">
          <div className="app_container">
            <p className="title mb30 mt30">
              {this.props.match.params.id === 'new'
                ? uiWords.addNewResidentLabel
                : uiWords.editLabel}
            </p>
            <div className="hpad-form-1">
              <Form
                uiSchema={uiSchema}
                fields={customFields}
                schema={schema}
                formData={formData}
                onChange={this.handleChange.bind(this)}
                onSubmit={this.handleSubmit.bind(this)}
              >
                <button type="submit" className="mt30 btn btn-info">
                  {uiWords.saveButtonLabel}
                </button>
              </Form>
              <AddGroupDialog
                isActive={this.state.isActive}
                onDialogClosing={this.handleDialogClosing.bind(this)}
              />
            </div>
          </div>
        </div>
        <AppLoading isActive={this.state.loading} />
      </div>
    )
  }
}

export default withRouter(UserEdit)
