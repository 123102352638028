import axios from "axios";
import { LoginState } from "./LoginSlice";
export type Token = LoginState;
const instance = (token: LoginState) => {
  return axios.create({
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token.token,
    },
  });
};

export default instance;
