import { uiWords } from '../constants'
import {
  UiWord,
  UiWordCollection,
  LocalizedUiWordCollection,
  PartUiWordEachLanguage,
  Language
} from '../types'

/**
 * 言語を選択する
 */
const selectUiWordLanguage = (
  partUiWord: PartUiWordEachLanguage,
  lang: string
): UiWord => {
  switch (lang) {
    case Language.jaJP:
    case Language.ja:
      return partUiWord.ja
    case Language.enUS:
      return partUiWord.en
    default:
      // 基本言語はアメリカ英語とする
      return partUiWord.en
  }
}

/**
 * uiWordsを指定言語でフィルタリングして返す
 *
 * @param lang 言語値
 * @returns
 */
export const localizedUiWords = (lang: string): LocalizedUiWordCollection => {
  const words: UiWordCollection = uiWords

  const localizedWords: Record<string, Record<string, any>> = {}

  // 共通コンポーネントとページで分けて管理しているので、管理単位ごとに言語選択する
  for (const managementUnit of Object.keys(words)) {
    const componentsWords: Record<string, Record<string, any>> = {}

    // 管理単位ごとに走査する
    for (const component of Object.keys(words[managementUnit])) {
      const componentUiWords = words[managementUnit][component]

      const componentPartsWords: Record<string, UiWord> = {}

      // コンポーネントあるいはページ内の部品の文言の言語を選択する
      for (const componentPart of Object.keys(componentUiWords)) {
        componentPartsWords[componentPart] = selectUiWordLanguage(
          componentUiWords[componentPart],
          lang
        )
      }

      componentsWords[component] = componentPartsWords
    }

    localizedWords[managementUnit] = componentsWords
  }

  return localizedWords
}
