/* eslint-disable */
import { SettingLayout } from '../components/SettingLayout'
import './SettingSensor.scss'
import * as CarePeopleAction from '@/actions/CarePeopleAction'
import * as sensorAction from '@/actions/SensorAction'
import AppDialog from '@/components/App/AppDialog'
import AppLoading from '@/components/App/AppLoading'
import { localizedUiWords } from '@/features/locale'
import Pager from '@/features/sensor/components/Pager'
import SensorTable from '@/features/sensor/components/SensorTable'
import { LocaleContext } from '@/providers/LocaleProvider'
import carePeopleStore from '@/stores/CarePeopleStore'
import sensorStore from '@/stores/SensorStore'
import { Box, Typography } from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import Form from 'react-jsonschema-form'

export const SettingSensor = () => {
  const [locale] = useContext(LocaleContext)
  const uiWords = localizedUiWords(locale.lang).pages.settings
  const appDialogUiWords = localizedUiWords(locale.lang).components.appDialog

  const [sensors, setSensors] = useState(null)
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(true)
  const [totalCount, setTotalCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [sensorId, setSensorId] = useState(null)
  const [deletingSensorId, setDeletingSensorId] = useState(null)
  const [deletingDialog, setDeletingDialog] = useState(false)
  const [carePeople, setCarePeople] = useState(null)
  const [groupId] = useState(null)
  const perPage = 10

  useEffect(() => {
    carePeopleStore.on('people_sort', (v) => {
      setCarePeople(v)
      setLoading(false)
    })

    carePeopleStore.on('person_edit', (v) => {
      const query = {}
      if (groupId !== null) {
        query.group_id = groupId
      }
      CarePeopleAction.carePeopleSort(query)
    })

    carePeopleStore.on('people_error', (v) => {
      setLoading(false)
    })

    sensorStore.on('sensor_all', (v) => {
      setSensors(v.data)
      setCurrentPage(v.pagination.current_page)
      setTotalCount(v.pagination.count)
      setLoading(false)
    })

    sensorStore.on('sensor_all_error', (v) => {
      setSensors([])
      setLoading(false)
    })

    sensorStore.on('sensor_add', (v) => {
      sensorAction.sensors({
        limit: perPage,
        page: currentPage
      })
      setSensorId(null)
      setErrors([])
      setLoading(true)
    })

    sensorStore.on('sensor_add_error', (v) => {
      const errors = []

      switch (v.code) {
        // 他施設との重複
        case 400:
          errors.sensor_id = uiWords.otherFacilityUsingSensorErrorMessage
          break

        // 同施設での重複
        case 409:
          errors.sensor_id = uiWords.alreadyRegisteredSensorErrorMessage
          break

        default:
          break
      }
      setErrors(errors)
      setLoading(false)
    })

    sensorStore.on('sensor_delete', (v) => {
      let page = currentPage
      if (sensors) {
        if (page > 1 && sensors.length === 1) {
          page--
        }
      }
      sensorAction.sensors({
        page,
        limit: perPage
      })
      setLoading(true)
    })

    sensorStore.on('sensor_delete_error', (v) => {
      setLoading(false)
    })

    CarePeopleAction.carePeopleSort({})

    setLoading(true)

    sensorAction.sensors({
      limit: perPage
    })

    setLoading(true)

    return () => {
      carePeopleStore.removeAllListeners('people_sort')
      carePeopleStore.removeAllListeners('person_edit')
      carePeopleStore.removeAllListeners('people_error')
      sensorStore.removeAllListeners('sensor_all')
      sensorStore.removeAllListeners('sensor_all_error')
      sensorStore.removeAllListeners('sensor_add')
      sensorStore.removeAllListeners('sensor_add_error')
      sensorStore.removeAllListeners('sensor_delete')
      sensorStore.removeAllListeners('sensor_delete_error')
    }
  }, [])

  // ページ移動
  const gotoPage = (e) => {
    sensorAction.sensors({
      page: e,
      limit: perPage
    })
    setLoading(true)
  }

  // 新規作成ボタンクリック
  const handleNewClick = (e) => {
    sensorAction.addSensor({ id: e.formData.sensor_id })
    setSensorId(e.formData.sensor_id)
    setErrors({})
    setLoading(true)
  }

  // 削除ボタンクリック
  const handleDeleteClick = (e) => {
    setDeletingSensorId(e.id)
    setDeletingDialog(true)
  }

  // 該当センサを利用しているユーザーの検索
  const searchSensorUser = (sensorId) => {
    let userId = null
    carePeople.forEach((v) => {
      if (v.sensor_id === sensorId) {
        userId = v.id
      }
    })
    return userId
  }

  const handleSensorDeletingDialogClick = (e) => {
    switch (e) {
      case appDialogUiWords.yesButtonLabel: {
        const userId = searchSensorUser(deletingSensorId)
        if (userId !== null) {
          CarePeopleAction.editPerson({ sensor_id: null }, userId)
        }

        sensorAction.deleteSensor(deletingSensorId)
        setDeletingSensorId(null)
        setDeletingDialog(false)
        setLoading(true)
        break
      }

      case appDialogUiWords.noButtonLabel:
        setDeletingSensorId(null)
        setDeletingDialog(false)
        break

      default:
        break
    }
  }

  // 削除確認ダイアログで「×」ボタンをクリック
  const handleSensorDeletingDialogClosing = (e) => {
    setDeletingSensorId(null)
    setDeletingDialog(false)
  }

  const sensorTemplate = (props) => {
    return (
      <div className={props.classNames}>
        <label htmlFor={props.id}>{props.label}</label>
        {props.children}
        {props.help}
      </div>
    )
  }

  const getSensorsDom = () => {
    return (
      <div>
        <SensorTable sensors={sensors} onDeleteClick={handleDeleteClick} />

        <Pager
          total={totalCount}
          current={currentPage}
          perPage={perPage}
          gotoPage={gotoPage}
        />
      </div>
    )
  }

  // フォームの設定
  const schema = {
    type: 'object',
    required: ['sensor_id'],
    properties: {
      sensor_id: {
        type: 'string',
        title: uiWords.sensorIdInputLabel
      }
    }
  }

  const uiSchema = {
    sensor_id: {
      classNames: 'sensor_id',
      'ui:placeholder': uiWords.sensorIdInputPlaceholder
    }
  }

  // メッセージ（エラー、通知）はhelpフィールドを利用して出力する
  if (!loading) {
    for (const param in schema.properties) {
      if (errors[param]) {
        if (!uiSchema[param]) {
          uiSchema[param] = {}
        }
        uiSchema[param]['ui:help'] = (
          <div className="error">{errors[param]}</div>
        )
      }
    }
  }

  const formData = {
    sensor_id: sensorId || ''
  }

  const sensorsDom = sensors !== null ? getSensorsDom() : null

  return (
    <SettingLayout activeTab={'sensor'}>
      <Box id="setting-sensor-tab" className="setting-tab-content">
        <Typography variant="h1" className="setting-heading">
          {uiWords.sensorTabTitle}
        </Typography>

        <Form
          className="add-new-sensor-id-form"
          schema={schema}
          uiSchema={uiSchema}
          FieldTemplate={sensorTemplate}
          formData={formData}
          onSubmit={handleNewClick}
          showErrorList={false}
        >
          <button type="submit">{uiWords.registerButtonLabel}</button>
        </Form>

        {sensorsDom}
      </Box>

      <AppLoading isActive={loading} />

      <AppDialog
        isActive={deletingDialog}
        title={appDialogUiWords.deleteMessage}
        choiceButtonLists={[
          appDialogUiWords.yesButtonLabel,
          appDialogUiWords.noButtonLabel
        ]}
        onClick={handleSensorDeletingDialogClick}
        onDialogClosing={handleSensorDeletingDialogClosing}
      />
    </SettingLayout>
  )
}
