import { AppHeader } from '../App/AppHeader'
import React from 'react'

type MainLayoutProps = {
  children: React.ReactNode
}

export const MainLayout = ({ children }: MainLayoutProps) => {
  // TODO: React18 になったら div を Box にする
  return (
    <div id="main-view">
      <AppHeader />
      {children}
    </div>
  )
}
