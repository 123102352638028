import './CareNoteExplanation.scss'
import { localizedUiWords } from '@/features/locale'
import { LocaleContext } from '@/providers/LocaleProvider'
import React, { useContext } from 'react'

export const CareNoteExplanation = () => {
  const [locale] = useContext(LocaleContext)
  const uiWords = localizedUiWords(locale.lang).pages.excretionResults
  const explanation = uiWords.explanation as Array<string>

  return (
    <div className="explanation">
      {explanation[0]}
      <br />
      {explanation[1]}
    </div>
  )
}
