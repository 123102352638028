import * as ActiveAlertLogsAction from '../actions/ActiveAlertLogsAction'
import * as AlertAction from '../actions/AlertAction'
import * as AlertDataAction from '../actions/AlertDataAction'
import * as CarePeopleAction from '../actions/CarePeopleAction'
import * as GroupAction from '../actions/GroupAction'
import * as SensorAction from '../actions/SensorAction'
import AppLoading from '../components/App/AppLoading'
import GroupSelectField from '../components/common/GroupSelectField'
import refreshIcon from '../images/redo-alt-solid.svg'
import activeAlertLogsStore from '../stores/ActiveAlertLogsStore'
import alertDataStore from '../stores/AlertDataStore'
import carePeopleStore from '../stores/CarePeopleStore'
import facilityStore from '../stores/FacilityStore'
import groupStore from '../stores/GroupStore'
import SensorStore from '../stores/SensorStore'
import './App.scss'
import { AppHeader } from '@/components/App/AppHeader'
import { CareNoteDialog } from '@/features/careNotes/components/CareNoteDialog'
import { localizedUiWords } from '@/features/locale'
import { NotificationCounter } from '@/features/notification/components/NotificationCounter'
import { NotificationTable } from '@/features/notification/components/NotificationTable'
import { LocaleContext } from '@/providers/LocaleProvider'
import alertStore from '@/stores/AlertStore'
import _ from 'lodash'
import * as moment from 'moment'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'

const REROAD_MINUTE = 5
const HIGH_PRIORITY_BORDER_HOUR = 72
const HIGH_PRIORITY_ELASPED_HOUR = 3

export class App extends Component {
  static contextType = LocaleContext

  constructor() {
    super()
    this.state = {
      facility: null,
      groupId: 0,
      carePeople: null,
      carePerson: null,
      carePeopleCounter: 0,
      loading: false,
      dialog: false,
      clickUserId: null,
      doSound: false,

      sensors: null,
      sensorStatus: null,
      groups: null,

      finalDefecationTime: null,
      activeAlertLogs: null,
      laxativeAlerts: null,
      alertData: null,

      cookieData: {
        showName: true
      },
      isAsync: false,
      notificationSetting: this.getNotificationSetting(),
      showCarePeople: null
    }
    this.timerIdForCarePeopleReload = []
    this.timerIdForSensorError = null

    this.timerIdForAlertSnooze = []
    this.timerIdForActiveAlertLogs = []
    this.timerIdForAlertData = []

    this.selectGroup = this.selectGroup.bind(this)
    this.handleDialogSave = this.handleDialogSave.bind(this)
    this.handleDialogClosing = this.handleDialogClosing.bind(this)
    this.onChangeNotifSetting = this.onChangeNotifSetting.bind(this)
    this.notifDropDown = this.notifDropDown.bind(this)
    this.excretionDetailDatas = null
  }

  shouldComponentUpdate(nextProps, nextState) {
    const propsDiff = _.isEqual(nextProps, this.props)
    const stateDiff = _.isEqual(nextState, this.state)
    return !(propsDiff && stateDiff)
  }

  getCookie() {
    const res = {}

    const cookieStr = document.cookie
    if (cookieStr !== '') {
      const cookies = cookieStr.split('; ')
      cookies.forEach((v) => {
        v = v.split('=')
        res[v[0]] = v[1]
      })
    }
    return res
  }

  getGroupFromCookie() {
    const res = this.getCookie()
    return isNaN(parseInt(res.groupValue)) ? null : parseInt(res.groupValue)
  }

  getNotificationSetting() {
    const res = this.getCookie()
    return isNaN(parseInt(res.notification)) ? 2 : parseInt(res.notification)
  }

  getShowNameValueFromCookie() {
    const res = {}

    const cookieStr = document.cookie
    if (cookieStr !== '') {
      const cookies = cookieStr.split('; ')
      cookies.forEach((v) => {
        v = v.split('=')
        res[v[0]] = v[1]
      })
    }

    // cookieデータがなかったら初期値trueを返す
    return res.showName === (null || undefined) ? true : res.showName === 'true'
  }

  reload() {
    SensorAction.sensorStatus()
  }

  refresh() {
    this.setState({
      loading: true
    })
    GroupAction.groups()
    CarePeopleAction.carePeople()
    CarePeopleAction.carePeopleSort({ sort: 'alert' })
    SensorAction.sensors()
    SensorAction.sensorStatus()

    // 通知を受け取るためのリロードの設定
    this.timerIdForSensorError = setInterval(
      this.reload,
      REROAD_MINUTE * 60 * 1000
    )

    // 次の15分までの時間だけ待機してリロードする。
    this.refreshEveryQuarterHour()

    // 次の1分までの時間だけ待機してリロードする。
    this.refreshEveryMinute()

    // 次の１分までの時間だけ待機して、最終排泄記録後の最初の排泄通知日時を取得
    this.refreshToGetActiveAlertCheck()
  }

  componentDidMount() {
    facilityStore.on('facility_detail', (v) => {
      this.setState({
        facility: v
      })
    })
    groupStore.on('group_all', (v) => {
      const groupIdList = v.map((g) => {
        return g.id
      })
      const cookieData = this.getGroupFromCookie()
      let peopleQuery = { sort: 'alert' }
      if (cookieData !== null && groupIdList.indexOf(cookieData) >= 0) {
        peopleQuery = { group_id: cookieData, sort: 'alert' }
        this.setState({
          groupId: cookieData,
          isAsync: false
        })
      }
      CarePeopleAction.carePeopleSort(peopleQuery)
      this.setState({
        loading: false,
        doSound: false,
        groups: v,
        isAsync: false
      })
    })

    carePeopleStore.on('people_sort', (v) => {
      this.setState({
        carePeople: v,
        loading: false,
        doSound: false,
        isAsync: false
      })

      ActiveAlertLogsAction.getActiveAlertLogs(
        this.state.groupId > 0 ? { group_id: this.state.groupId } : ''
      )

      AlertDataAction.getAlertData(
        this.state.groupId > 0 ? { group_id: this.state.groupId } : ''
      )
    })

    carePeopleStore.on('people_async_sort', (v) => {
      this.setState({
        carePeople: v,
        loading: false,
        doSound: false,
        isAsync: true
      })
    })
    carePeopleStore.on('people_group', (v) => {
      this.setState({
        carePeople: v,
        loading: false,
        doSound: false
      })
      ActiveAlertLogsAction.getActiveAlertLogs(
        this.state.groupId > 0 ? { group_id: this.state.groupId } : ''
      )
    })

    SensorStore.on('sensor_all', (v) => {
      this.setState({
        sensors: v.data,
        loading: false,
        doSound: false,
        isAsync: true
      })
    })

    alertStore.on('alert_edit', () => {
      // グループ選択状態の保持
      const query =
        this.state.groupId > 0
          ? { group_id: this.state.groupId, sort: 'alert' }
          : { sort: 'alert' }
      CarePeopleAction.carePeopleSort(query)
    })

    SensorStore.on('sensor_status', (v) => {
      this.setState({
        sensorStatus: v,
        doSound: false,
        isAsync: true
      })
    })

    activeAlertLogsStore.on('active_alert_logs', (v) => {
      const currentTime = moment()
      const carePeople = this.state.carePeople
      const activeAlertLogs = _.map(v.active_alert_logs, (o) => {
        const activeAlertLogs = moment(o.created)
        const person = _.filter(carePeople, { id: o.subject_id })[0]

        o.alert =
          person &&
          currentTime.diff(activeAlertLogs, 'hours') >=
            HIGH_PRIORITY_ELASPED_HOUR
        return o
      })
      const finalDefecationTime = _.map(v.final_defecation_times, (o) => {
        const defecationTime = moment(o.time)
        const person = _.filter(carePeople, { id: o.subject_id })[0]
        o.alert =
          person &&
          person.alert_log &&
          person.alert_log.active &&
          currentTime.diff(defecationTime, 'hours') >= HIGH_PRIORITY_BORDER_HOUR
        return o
      })
      this.setState({
        activeAlertLogs,
        finalDefecationTime,
        laxativeAlerts: v.laxative_alerts,
        isAsync: true
      })
    })
    alertDataStore.on('alert_data_all', (v) => {
      this.setState({
        alertData: v.data
      })
    })

    const cookieData = {
      showName: this.getShowNameValueFromCookie()
    }

    this.setState({ cookieData })

    this.refresh()

    // UI 文言管理
    // context は constructor では取得できない
  }

  componentWillUnmount() {
    carePeopleStore.removeAllListeners('people_async_sort')
    carePeopleStore.removeAllListeners('people_sort')
    carePeopleStore.removeAllListeners('people_group')
    SensorStore.removeAllListeners('sensor_all')
    alertStore.removeAllListeners('alert_edit')
    SensorStore.removeAllListeners('sensor_status')
    groupStore.removeAllListeners('group_all')

    activeAlertLogsStore.removeAllListeners('active_alert_logs')

    alertDataStore.removeAllListeners('alert_data_all')

    if (this.timerIdForCarePeopleReload.length > 0) {
      _.forEach(this.timerIdForCarePeopleReload, (v) => {
        clearTimeout(v)
      })
      this.timerIdForCarePeopleReload = []
    }

    if (this.timerIdForSensorError != null) {
      clearInterval(this, this.timerIdForSensorError)
      this.timerIdForSensorError = null
    }
    if (this.timerIdForAlertSnooze.length > 0) {
      _.forEach(this.timerIdForAlertSnooze, (v) => {
        clearTimeout(v)
      })
      this.timerIdForAlertSnooze = []
    }
    if (this.timerIdForActiveAlertLogs.length > 0) {
      _.forEach(this.timerIdForActiveAlertLogs, (v) => {
        clearTimeout(v)
      })
      this.timerIdForActiveAlertLogs = []
    }
  }

  selectGroup(e) {
    if (e > 0) {
      const query = {
        group_id: e,
        sort: 'alert'
      }
      // グループ選択時用のActionを呼び出して、ヘッダーの通知件数に干渉しないようにする。
      CarePeopleAction.carePeopleGroupFilter(query)
    } else {
      CarePeopleAction.carePeopleSort({ sort: 'alert' })
    }
    // 選択したグループIDを保存する。
    this.setState({
      groupId: e,
      isAsync: false
    })
  }

  handleDialogOpen(e) {
    const excretionDetailDatas = []
    excretionDetailDatas.push({
      id: null,
      subject_id: e.subject_id,
      confirmed: moment(e.start),
      result: 'none',
      description: null
    })
    this.setState({
      excretionDetailDatas,
      carePerson: e,
      dialog: true,
      isAsync: false
    })
  }

  // ダイアログ内のハンドリング
  handleDialogSave(e, data) {
    AlertAction.editAlert(data, this.state.carePerson.alert_log_id)
    this.setState({
      loading: true,
      dialog: false
    })
  }

  // ダイアログクローズ時のハンドリング
  handleDialogClosing() {
    this.setState({
      dialog: false,
      doSound: false
    })
  }

  // 読み込み中Dom取得
  getLoadingDom() {
    const [locale] = this.context
    const uiWords = localizedUiWords(locale.lang).pages.app
    return <div className="loadingView">{uiWords.loadingMessage}</div>
  }

  // TODO: コンポーネントに分離
  // データなしDom取得
  getNoDataDom(carePeople) {
    const [locale] = this.context
    const uiWords = localizedUiWords(locale.lang).pages.app

    return (
      <div className="no-data">
        <NotificationTable
          carePeople={carePeople}
          clickUserId={this.state.clickUserId}
        />
        <p>{uiWords.residentNotFoundMessage}</p>
        <Link to={'/user/edit/new'}>
          <div className="add_btn">
            <div>{uiWords.addResidentButtonLabel}</div>
          </div>
        </Link>
      </div>
    )
  }

  // ユーザテーブルDom取得
  getNotificationTableDom(carePeople) {
    return (
      <div className="main-view flex">
        <NotificationTable
          carePeople={carePeople}
          clickUserId={this.state.clickUserId}
          sensorStatus={this.state.sensorStatus}
          doSound={this.state.doSound}
          defecationTime={this.state.finalDefecationTime}
          activeAlertLogs={this.state.activeAlertLogs}
          laxativeAlerts={this.state.laxativeAlerts}
          cookieData={this.state.cookieData}
          alertData={this.state.alertData}
          onAlertClick={this.handleDialogOpen.bind(this)}
        />
      </div>
    )
  }

  filterShowUserData() {
    // 1:下剤投与, 2:下剤投与&3日以上便秘&検知から3時間以上, 3:全部
    const facility = facilityStore.getFacilityDetail()
    const notificationSetting = this.state.notificationSetting
    const defecationTimeThreshold = 1000 * 60 * 60 * 24 * 3
    const isDetectionTimeThreshold = 1000 * 60 * 60 * 3
    const now = new Date()
    const carePeople = this.state.carePeople.filter((v) => {
      // 「入居中」以外の利用者は対応待ち人数に含めない。
      if (v.status_code !== '0') return false

      if (
        !(
          this.state.finalDefecationTime &&
          this.state.activeAlertLogs &&
          this.state.laxativeAlerts &&
          this.state.alertData &&
          this.state.sensorStatus
        )
      ) {
        return false
      }
      const filterdSensorStatus = this.state.sensorStatus.filter(
        (s) => s.sensor_id === v.sensor_id
      )[0]
      const isSensorError =
        typeof filterdSensorStatus !== 'undefined'
          ? filterdSensorStatus.error !== 0
          : !v.sensor_id
      const activeAlertLogs = this.state.activeAlertLogs.filter(
        (w) => w.subject_id === v.id
      )[0]
      const personAlertData = this.state.alertData.filter(
        (w) => w.subject_id === v.id
      )[0]
      const isExcretionForecast = personAlertData
        ? personAlertData.alert_info.excretion_forecast
        : false

      // 通知がなければ表示しないが、センサーエラーがある場合は表示する。 => refs: NotificaitonTable.jsx L271
      if (
        (!v.alert_log || !v.alert_log.active) &&
        !isSensorError &&
        !isExcretionForecast
      ) {
        return false
      }

      // 排泄予報のある利用者は無条件で表示する．
      if (isExcretionForecast) {
        return true
      }

      const finalDefecationTime = this.state.finalDefecationTime.filter(
        (w) => w.subject_id === v.id
      )[0]

      const laxativeAlerts = this.state.laxativeAlerts.filter(
        (w) => w.subject_id === v.id
      )[0]

      // 下剤記録があるか
      const laxativeAlertsConfirmed =
        laxativeAlerts &&
        laxativeAlerts.confirmed &&
        new Date(laxativeAlerts.confirmed)
      const medicineAlertPrioritizeSecond =
        facility.medicine_alert_prioritize_hour * 60 * 60 * 1000
      const isLaxativeAlerts =
        typeof activeAlertLogs !== 'undefined' &&
        laxativeAlertsConfirmed &&
        now.getTime() - laxativeAlertsConfirmed.getTime() <
          medicineAlertPrioritizeSecond

      const finalDefecationDate =
        finalDefecationTime &&
        finalDefecationTime.time &&
        new Date(finalDefecationTime.time)
      // 3日以上便秘かどうか
      const isDefecationTime =
        notificationSetting > 1 &&
        finalDefecationTime &&
        now.getTime() - finalDefecationDate.getTime() >=
          defecationTimeThreshold &&
        activeAlertLogs

      const alertDate =
        activeAlertLogs &&
        activeAlertLogs.created &&
        new Date(activeAlertLogs.created)
      // 検知から3時間経っているか
      const isDetectionTime =
        notificationSetting > 1 &&
        alertDate &&
        now.getTime() - alertDate.getTime() >= isDetectionTimeThreshold
      return (
        notificationSetting === 3 ||
        isLaxativeAlerts ||
        isDefecationTime ||
        isDetectionTime ||
        isSensorError
      )
    })

    return carePeople
  }

  // 次の15分まで待機してリロードする。
  refreshEveryQuarterHour() {
    const now = moment()
    const nextTime = this.roundDownToNearestQuarterHour(
      moment().add(15, 'minutes')
    )
    const toNextTime = nextTime.diff(now, 'seconds') + 1

    // タイマーIDが2件以上ある場合は、一番古いタイマーIDを消す。
    if (this.timerIdForAlertSnooze.length > 1) {
      clearTimeout(this.timerIdForAlertSnooze[0])
      this.timerIdForAlertSnooze.shift()
    }

    const timerId = setTimeout(
      function () {
        if (this.timerIdForAlertSnooze != null) {
          this.setState({
            doSound: true,
            isAsync: true
          })
        }
        this.refreshEveryQuarterHour()
      }.bind(this),
      1000 * toNextTime
    )

    // タイマーIDを配列の末尾に保存する
    this.timerIdForAlertSnooze.push(timerId)
  }

  // 15分未満を切り捨てた日時を取得
  // detectorとの連携を意識して意図的に[HH:01][HH:16][HH:31][HH:46]に動かす
  roundDownToNearestQuarterHour(time) {
    const quarter = Math.floor((time.format('mm') - 0) / 15) * 15
    const mm = parseInt(('00' + quarter).slice(-2), 10) + 1
    return moment(time.format('YYYY-MM-DD HH:') + mm)
  }

  refreshEveryMinute() {
    // タイマーIDが2件以上ある場合は、一番古いタイマーIDを消す。
    if (this.timerIdForCarePeopleReload.length > 1) {
      clearTimeout(this.timerIdForCarePeopleReload[0])
      this.timerIdForCarePeopleReload.shift()
    }

    const timerId = setTimeout(
      function () {
        if (this.timerIdForCarePeopleReload.length > 0) {
          // 非同期用Actionを呼び出して、ハンドラで非同期のとき用stateを更新するようにする。
          // グループ選択があればその条件を保つ
          this.state.groupId > 0
            ? CarePeopleAction.carePeopleAsyncSort({
                group_id: this.state.groupId,
                sort: 'alert'
              })
            : CarePeopleAction.carePeopleAsyncSort({ sort: 'alert' })
        }
        this.refreshEveryMinute()
      }.bind(this),
      1000 * 60
    )

    // タイマーIDを配列の末尾に保存する
    this.timerIdForCarePeopleReload.push(timerId)
  }

  refreshToGetActiveAlertCheck() {
    if (this.timerIdForActiveAlertLogs.length > 1) {
      clearTimeout(this.timerIdForActiveAlertLogs[0])
      this.timerIdForActiveAlertLogs.shift()
    }

    const timerId = setTimeout(
      function () {
        if (this.timerIdForActiveAlertLogs.length > 0) {
          this.state.groupId > 0
            ? ActiveAlertLogsAction.getActiveAlertLogs({
                group_id: this.state.groupId
              })
            : ActiveAlertLogsAction.getActiveAlertLogs()
        }
        this.refreshToGetActiveAlertCheck()
      }.bind(this),
      1000 * 60
    )
    this.timerIdForActiveAlertLogs.push(timerId)
  }

  refreshToGetAlertData() {
    if (this.timerIdForAlertData.length > 1) {
      clearTimeout(this.timerIdForAlertData[0])
      this.timerIdForAlertData.shift()
    }
    const timerId = setTimeout(
      function () {
        if (this.timerIdForAlertData.length > 0) {
          this.state.groupId > 0
            ? AlertDataAction.getAlertData({ group_id: this.state.groupId })
            : AlertDataAction.getAlertData()
        }
        this.refreshToGetAlertData()
      }.bind(this),
      1000 * 60
    )
    this.timerIdForAlertData.push(timerId)
  }

  // 利用者名表示切り替え
  showNameSwitch(e) {
    // cookieに保存する
    document.cookie = `showName=${e.target.checked}; max-age=${
      7 * 24 * 60 * 60
    }"`
    const cookieData = { ...this.state.cookieData }
    cookieData.showName = e.target.checked
    // stateに保存する
    this.setState({
      cookieData,
      isAsync: false
    })
  }

  onChangeNotifSetting(e) {
    const cookieSec = 7 * 24 * 60 * 60
    this.setState({
      notificationSetting: parseInt(e.target.value)
    })
    document.cookie = `notification=${e.target.value}; max-age=${cookieSec}`
  }

  // TODO: 別コンポーネントに切り出す
  // TODO: Tooltip コンポーネントを利用できないか検討
  notifDropDown() {
    const [locale] = this.context
    const uiWords = localizedUiWords(locale.lang).pages.app

    return (
      <div className="notification-filter-dropbox hpad-pulldown-1 __tooltip-parent">
        <select
          onChange={this.onChangeNotifSetting}
          value={this.state.notificationSetting}
        >
          <option value={1}>{uiWords.showEmergencyDropdownItem}</option>
          <option value={2}>
            {uiWords.showEmergencyAndPriorityDropdownItem}
          </option>
          <option value={3}>{uiWords.showAllDropdownItem}</option>
        </select>
        <span className="__tooltip" style={{ top: '2.5rem', left: '-45%' }}>
          {uiWords.notificationFilterDropdownTooltip.map((word, index) => {
            return <p key={index}>{word}</p>
          })}
        </span>
      </div>
    )
  }

  render() {
    const showCarePeople =
      this.state.carePeople !== null ? this.filterShowUserData() : []

    let mainView
    if (this.state.carePeople === null) {
      // 読み込み中
      mainView = this.getLoadingDom()
    } else if (this.state.carePeople.length === 0) {
      // 通知データなし
      mainView = this.getNoDataDom(showCarePeople)
    } else {
      // 通知テーブル
      mainView = this.getNotificationTableDom(showCarePeople)
    }

    // UI 文言管理
    const [locale] = this.context
    const uiWords = localizedUiWords(locale.lang).pages.app

    return (
      <div id="app">
        <AppHeader />

        <div className="sub-header flex">
          <NotificationCounter notificationCount={showCarePeople.length} />
        </div>

        <div className="flex">
          <div className="home_container">
            <div className="mt20 mb30 flex-between">
              <div>
                <GroupSelectField
                  onChange={this.selectGroup}
                  formData={this.state.groupId}
                />
                <div className="show-name">
                  <label htmlFor="showNameCheckBox">
                    <input
                      type="checkbox"
                      name="showName"
                      id="showNameCheckBox"
                      onChange={this.showNameSwitch.bind(this)}
                      checked={this.state.cookieData.showName}
                    />
                    <div className="show-name-text">
                      {uiWords.showResidentNameCheckboxLabel}
                    </div>
                  </label>

                  {/* TODO: show-name の中に入れている理由がなければ階層を上げる */}
                  <button
                    className="reload-button"
                    style={{ cursor: 'pointer' }}
                    onClick={this.refresh.bind(this)}
                  >
                    <img
                      style={{ verticalAlign: 'middle' }}
                      src={refreshIcon}
                    />
                  </button>
                </div>
              </div>
              {/* TODO: 通知種別の凡例 コンポーネントとして分離 */}
              <div className="flex">
                <div className="note-space">
                  <div className="notes">
                    <span className="note note-top-priority __tooltip-parent">
                      {uiWords.emergencyNotificationLabel}
                      <span className="__tooltip" style={{ right: '-20rem' }}>
                        {uiWords.emergencyNotificationTooltip}
                      </span>
                    </span>
                    <span className="note note-excretion-forecast __tooltip-parent">
                      {uiWords.forecastedNotificationLabel}
                      <span className="__tooltip" style={{ right: '-10rem' }}>
                        {uiWords.forecastedNotificationTooltip}
                      </span>
                    </span>
                    <span className="note note-high-priority __tooltip-parent">
                      {uiWords.priorityNotificationLabel}
                      <span className="__tooltip" style={{ right: '-20rem' }}>
                        {uiWords.priorityNotificationTooltip}
                      </span>
                    </span>
                    <span className="note note-normal __tooltip-parent">
                      {uiWords.normalNotificationLabel}
                      <span className="__tooltip" style={{ right: '-10rem' }}>
                        {uiWords.normalNotificationTooltip}
                      </span>
                    </span>
                  </div>
                </div>
                <div>{this.notifDropDown()}</div>
              </div>
            </div>

            <div
              className={
                'alert-notification-text' +
                (this.state.activeAlertLogs !== null &&
                this.state.activeAlertLogs.length > 0
                  ? ''
                  : ' not-show')
              }
            >
              <p>{uiWords.noteRequestMessage}</p>
            </div>

            {mainView}
          </div>
        </div>

        <CareNoteDialog
          excretionDetailDatas={this.state.excretionDetailDatas}
          isActive={this.state.dialog}
          carePeople={this.state.carePeople}
          carePerson={this.state.carePerson}
          onDialogClosing={this.handleDialogClosing}
          onComplete={this.handleDialogSave}
          where="home"
          isAsync={this.state.isAsync}
        />

        <AppLoading isActive={this.state.loading} />
      </div>
    )
  }
}
