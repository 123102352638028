/* eslint-disable */
// UserEdit でしか使われていないので features ディレクトリでの管理を検討する
import * as groupAction from '../../actions/GroupAction'
import groupStore from '../../stores/GroupStore'
import { localizedUiWords } from '@/features/locale'
import { LocaleContext } from '@/providers/LocaleProvider'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Form from 'react-jsonschema-form'

class AddGroupDialog extends Component {
  static contextType = LocaleContext

  constructor() {
    super()
    this.state = {
      groups: null
    }
  }

  componentDidMount() {
    groupStore.on('group_all', (v) => {
      this.setState({
        groups: v,
        error: null
      })
    })
    groupStore.on('group_add', (v) => {
      groupAction.groups()
    })
    groupAction.groups()
  }

  componentWillUnmount() {
    groupStore.removeAllListeners('group_all')
    groupStore.removeAllListeners('group_add')
  }

  handleFormClick(e) {
    // 背景のonClickに伝播しないようにする。
    e.stopPropagation()
  }

  handleDialogClosing(e) {
    this.writeDOMErrorField('')
    this.props.onDialogClosing(e)
  }

  handleSubmit1(e) {
    const [locale] = this.context
    const uiWords = localizedUiWords(locale.lang).pages.userEdit

    if (e.formData.name === '' || e.formData.name === undefined) {
      return
    }

    if (this.isDuplicate(e)) {
      this.writeDOMErrorField(uiWords.groupAlreadyExistsErrorMessage)
      return
    }

    this.writeDOMErrorField('')
    groupAction.addGroup(e.formData)
    this.props.onDialogClosing(e)
  }

  isDuplicate(e) {
    const inputValue = e.formData.name
    const groups = this.state.groups
    let isDuplicate = false
    _.forEach(groups, (group) => {
      if (group.name === inputValue) {
        isDuplicate = true
        return
      }
    })
    return isDuplicate
  }

  writeDOMErrorField(str) {
    document.getElementsByClassName('errorField')[0].innerHTML = str
  }

  render() {
    const [locale] = this.context
    const uiWords = localizedUiWords(locale.lang).pages.userEdit

    // フォームの設定
    const schema1 = {
      type: 'object',
      properties: {
        name: { type: 'string', title: uiWords.addGroupNameLabel }
      }
    }

    const uiSchema1 = {
      name: { 'ui:placeholder': uiWords.newGroupNamePlaceholder }
    }

    const groups = this.state.groups
    let groupList
    if (groups) {
      if (groups.length > 0) {
        groupList = _.map(groups, (v, k) => {
          return (
            <div className="groupData" key={k}>
              {v.name}
            </div>
          )
        })
      } else {
        groupList = <div className="noGroupData">{uiWords.thereIsNoGroup}</div>
      }
    }

    return (
      <div
        className={
          this.props.isActive
            ? 'setting-group-dialog'
            : 'setting-group-dialog hide'
        }
        onClick={this.handleDialogClosing.bind(this)}
      >
        <div className="content" onClick={this.handleFormClick.bind(this)}>
          <div className="groupDialogTitle">{uiWords.createNewGroupLabel}</div>
          <div
            className="close-btn"
            onClick={this.handleDialogClosing.bind(this)}
          ></div>
          <Form
            schema={schema1}
            uiSchema={uiSchema1}
            onSubmit={this.handleSubmit1.bind(this)}
            className="rjsf mb10"
          >
            <button type="submit" className="btn btn-info">
              {uiWords.createButtonLabel}
            </button>
          </Form>
          <div className="errorField"></div>
          <div className="groupList">
            <div className="groupListTitle">{uiWords.groupListLabel}</div>
            <div className="groupDataField">{groupList}</div>
          </div>
        </div>
      </div>
    )
  }
}

AddGroupDialog.propTypes = {
  isActive: PropTypes.bool,
  onDialogClosing: PropTypes.func
}

export default AddGroupDialog
