import storage from '@/utils/storage'

const getAppLanguage = () => {
  return storage.getLanguage()
}

const setAppLanguage = (lang: string) => {
  return storage.setLanguage(lang)
}

const getBrowserLanguage = () => {
  return window.navigator.language
}

export { getAppLanguage, setAppLanguage, getBrowserLanguage }
