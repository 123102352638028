import './Tooltip.scss'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

export class Tooltip extends Component {
  constructor() {
    super()
    this.myRef = React.createRef()
    this.state = {
      isShow: false
    }
  }

  componentDidMount() {
    const node = this.myRef.current
    const parentNode = node.parentNode

    parentNode.addEventListener('mouseover', () => {
      this.setState({
        isShow: true
      })
    })

    parentNode.addEventListener('mouseout', () => {
      this.setState({
        isShow: false
      })
    })
  }

  render() {
    return (
      <div
        className="tooltip-container"
        ref={this.myRef}
        style={{ position: 'relative', width: 'auto' }}
      >
        <div
          className="tooltip"
          style={{
            top: this.props.top,
            left: this.props.left,
            display: this.state.isShow ? '' : 'none'
          }}
        >
          {this.props.children}
        </div>
      </div>
    )
  }
}

Tooltip.defaultProps = {
  top: '0px',
  left: '0px'
}

Tooltip.propTypes = {
  top: PropTypes.string,
  left: PropTypes.string,
  children: PropTypes.node.isRequired
}
