/* eslint-disable */
import request from 'superagent';
import dispatcher from "../dispatchers/Dispatcher";
import API from "../constants/ApiConstants";

export function alert(id, onSuccess=null) {
  let url = API.Alert.Detail.replace(":id", id);
  request
    .get(url)
    .set('Accept', 'application/json')
    .end(function (err, res) {
      if (err === null) {
        let body = res.body;
        dispatcher.dispatch({ type: "ALERT_DETAIL", body });
        if(onSuccess) onSuccess(body);
      } else {
        dispatcher.dispatch({ type: "ERROR", res });
      }
    });
}

export function alerts(query) {
  //  ?care_subject_id=1&group_id=1,2,3&active=true&start=2017-06-01&end=2017-07-31&with_related_laxation_log=true
  request
    .get(API.Alert.All)
    .query(query)
    .set('Accept', 'application/json')
    .end(function (err, res) {
      if (err === null) {
        let body = res.body;
        dispatcher.dispatch({ type: "ALERT_ALL", body });
      } else {
        dispatcher.dispatch({ type: "ERROR", res });
      }
    });
}

function fixedEncodeURIComponent(str) {
  return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
    return '%' + c.charCodeAt(0).toString(16);
  });
}

export function editAlert(data, id) {
  let url = API.Alert.Edit.replace(":id", id);
  //クエリパラメータ生成
  url = url + "/?";
  url = (data.confirmed === null) ? url + "" : url + "confirmed=" + data.confirmed + "&";  //確認日時
  url = (data.urine_amount === null) ? url + "" : url + "urine_amount=" + data.urine_amount + "&";  //尿の量
  url = (data.amount === null) ? url + "" : url + "amount=" + data.amount + "&";  //便の量
  url = (data.firmness === null) ? url + "" : url + "firmness=" + data.firmness + "&";  //便の固さ
  url = (data.leaked === null) ? url + "" : url + "leaked=" + data.leaked + "&";  //漏れ出し有無
  url = (data.type === null) ? url + "" : url + "type=" + data.type + "&";  //排泄場所
  url = (data.description === null) ? url + "" : url + "description=" + fixedEncodeURIComponent(data.description) + "&";  //説明
  url = url.slice(0, -1); //最後の 1文字を除去


  //Alert.Edit に必要な送信データを生成
  let result = data.result;
  data = {};
  data['result'] = result;
  data['active'] = false;

  request
    .put(url)
    .send(data)
    .set('Accept', 'application/json')
    .end(function (err, res) {
      if (err === null) {
        let resText = res.text;
        resText = resText[0].match("{") ? resText : resText.substring(1, resText.length);
        let body = res.body ? res.body : JSON.parse(resText);
        dispatcher.dispatch({ type: "ALERT_EDIT", body });
      } else {
        dispatcher.dispatch({ type: "ERROR", res });
      }
    });
}