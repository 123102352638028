type TLaxative = {
  id: number;
  facility_id: number;
  name: string;
  created: string;
  modified: string;
  rank: number;
};

export const initialLaxative = {
  id: 0,
  facility_id: 0,
  name: "",
  created: "",
  modified: "",
  rank: 0,
};
export default TLaxative;
